import React from "react";
import './styles.scss'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import logo from '../assets/images/logo.png';
import gorillas from '../assets/images/gorillas.svg.png';
import paribus from "../assets/images/paribus.png";
import LogoSectionHeadline from './LogoSectionHeadline';

// import "~slick-carousel/slick/slick.css"; 
// import "~slick-carousel/slick/slick-theme.css";

export default function TrustedBy() {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2500,
    autoplaySpeed: 2500,
    cssEase: "linear",
  };
  return (
    <React.Fragment>
      <div className="logos">
        <div className="logosshade"/>
      <Slider {...settings}>
        <img className="logo logo1" src={logo} alt="images" />
        <img className="logo"  src={gorillas}  alt="images" />
        <img className="logo"  src={paribus} alt="images" />
        <img className="logo logo1"  src={logo} alt="images" />
        <img className="logo"  src={gorillas} alt="images" />
        <img className="logo"  src={paribus} alt="images" />
        </Slider>
      </div>
    </React.Fragment>
  );
}
