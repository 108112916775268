/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Icon, Text, View } from "@aws-amplify/ui-react";
import { ReactComponent as Chat } from "../assets/icons/chat.svg"
import { ReactComponent as Arrow } from '../assets/icons/rightarrow.svg';
import { Trans } from "react-i18next";

export default function ButtonChat(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      className="faqchat"
      gap="8px"
      direction="column"
      width="196px"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      border="1px SOLID rgba(226,229,241,1)"
      borderRadius="8px"
      padding="23px 23px 23px 23px"
      {...rest}
      {...getOverrideProps(overrides, "ButtonChat")}
    >
      <Flex
        gap="16px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "title")}
      >
        <View
          width="28px"
          height="28px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          overflow="hidden"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "icon")}
        >
          <View
            width="28px"
            height="26.25px"
            display="flex"
            gap="unset"
            alignItems="center"
            justifyContent="center"
            {...getOverrideProps(overrides, "Frame 48095375")}
          >
            <Chat />
            <View
              padding="0px 0px 0px 0px"
              width="28px"
              height="26.25px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="0%"
              bottom="0%"
              left="100%"
              right="-100%"
              transformOrigin="top left"
              transform="rotate(180deg)"
              {...getOverrideProps(overrides, "Group7342499")}
            >
              <View
                padding="0px 0px 0px 0px"
                width="28px"
                height="26.25px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0%"
                bottom="0%"
                left="0%"
                right="0%"
                {...getOverrideProps(overrides, "Group7342500")}
              >
              </View>
            </View>
          </View>
        </View>
        <Text
          fontFamily="Lato"
          fontSize="14px"
          fontWeight="400"
          color="rgba(86,89,115,1)"
          lineHeight="22.399999618530273px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          marginTop="-8px"
          children="Don't like phone calls?"
          {...getOverrideProps(overrides, "txt")}
        ><Trans i18nKey="faq.chat"></Trans></Text>
        <a target="_blank" href="https://wa.me/+491621739274" style={{ color: "#22C55E", marginTop: "-7px" }} className="faqlink greenlink">Contact Us <Arrow /> </a>
      </Flex>
      <Flex
        gap="8px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        borderRadius="6px"
        padding="11px 0px 11px 0px"
        {...getOverrideProps(overrides, "btn")}
      ></Flex>
    </Flex>
  );
}
