/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import { Text, View, useBreakpointValue } from "@aws-amplify/ui-react";
import ButtonChat from "./ButtonChat";
import ButtonCall from "./ButtonCall";
import { Trans } from "react-i18next";
export default function FaqContact(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      overrides: {
        "Still have unanswered questions and need to\u00A0get in\u00A0touch?":
          {},
        ButtonChat: {},
        ButtonCall: {},
        FaqContact: {},
      },
      variantValues: { breakpoint: "large" },
    },
    {
      overrides: {
        "Still have unanswered questions and need to\u00A0get in\u00A0touch?": {
          textAlign: "center",
          width: "300px",
          bottom: "77.17%",
          left: "5.88%",
          right: "5.88%",
        },
        ButtonChat: {
          width: "160px",
          height: "166px",
          top: "34.65%",
          left: "52.94%",
        },
        ButtonCall: {
          width: "160px",
          height: "166px",
          top: "34.65%",
          right: "52.94%",
        },
        FaqContact: { width: "340px", height: "254px" },
      },
      variantValues: { breakpoint: "small" },
    },
    {
      overrides: {
        "Still have unanswered questions and need to\u00A0get in\u00A0touch?": {
          textAlign: "center",
          width: "494px",
          top: "9.84%",
          bottom: "78.74%",
          left: "-7.71%",
          right: "-7.71%",
        },
        ButtonChat: {
          width: "201.41px",
          height: "166px",
          top: "34.65%",
          left: "52.94%",
        },
        ButtonCall: {
          width: "201.41px",
          height: "166px",
          top: "34.65%",
          right: "52.94%",
        },
        FaqContact: { width: "428px", height: "254px" },
      },
      variantValues: { breakpoint: "medium" },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "small",
    small: "small",
    medium: "medium",
    large: "large",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <View
      width="416px"
      height="290px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      {...rest}
      {...getOverrideProps(overrides, "FaqContact")}
    >
      <Text
      className="faqanswer"
        fontFamily="Lato"
        fontSize="18px"
        fontWeight="400"
        color="rgba(86,89,115,1)"
        lineHeight="28.80000114440918px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="272px"
        height="unset"
        gap="unset"
        alignItems="unset"
        position="absolute"
        top="0%"
        bottom="80%"
        left="0%"
        right="34.62%"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Still have unanswered questions and need to get in touch?"
        {...getOverrideProps(
          overrides,
          "Still have unanswered questions and need to\u00A0get in\u00A0touch?"
        )}
      ><Trans i18nKey="faq.subtitle"></Trans></Text>
      <div className="buttonsfaq">
      <ButtonChat
        display="flex"
        gap="8px"
        direction="column"
        width="196px"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        position="absolute"
        top="42.76%"
        bottom="0%"
        left="52.88%"
        right="0%"
        border="1px SOLID rgba(226,229,241,1)"
        borderRadius="8px"
        padding="23px 23px 23px 23px"
        {...getOverrideProps(overrides, "ButtonChat")}
      ></ButtonChat>
      <ButtonCall
        display="flex"
        gap="8px"
        direction="column"
        width="196px"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        position="absolute"
        top="42.76%"
        bottom="0%"
        left="0%"
        right="52.88%"
        border="1px SOLID rgba(226,229,241,1)"
        borderRadius="8px"
        padding="23px 23px 23px 23px"
        {...getOverrideProps(overrides, "ButtonCall")}
      ></ButtonCall>
      </div>
    </View>
  );
}
