import React from 'react'
import NumberCard from '../../atoms/NumberCard/NumberCard'
import styles from "./Steps.module.scss";
import { useTranslation } from 'react-i18next';

export default function Steps({data}) {
  const {t} = useTranslation();
  return (
    <div className={styles.container}>
        <h1>{t('companytabs.tabtitle')}</h1>
        <div className={styles.stepsHolder}>
         {data.map((item)=>{
            return(
                <NumberCard key={item.id}  number={item.id} title={item.title} desc={item.desc} />
            )
         })}
        </div>
      
    </div>
  )
}
