import { React, useEffect, useState } from "react";
import { DataStore } from "@aws-amplify/datastore";
import { Auth, API, graphqlOperation } from "aws-amplify";
import { useAuthenticator } from "@aws-amplify/ui-react";
import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { CompanyBankAccount, Waitlist } from "../../models";
import DashboardNavbar from "../CompanyDashboard/DashboardNavbar";
import "./Profile.scss";
import dayjs from "dayjs";
import profilePic from "../../assets/images/profile.png";
// import editProfile from "../../assets/icons/editProfile.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "../Button";
import Input from "../Input";
import Modal from "../Modal";
import { BankAccountValidation } from "../../util/Validation";
import Loader from "../Loader";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useTranslation } from "react-i18next";

const Profile = () => {
  const [bankAccount, setBankAccount] = useState({
    companyName: "",
    ust: "",
    tax: "",
    firstName: "",
    lastName: "",
    IBAN: "",
    bic: "",
    companyAddress: "",
    gender: "",
    birthDate: dayjs(""),
    address: "",
    companyAddress2: "",
    companyZip: "",
    companyCity: "",
    address2: "",
    zip: "",
    city: "",
  });

  const { t } = useTranslation();
  const [userProfile, setUserProfile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedAcc, setSelectedAcc] = useState(null);
  const [accountList, setAccountList] = useState([]);
  const [switchEdit, setSwitchEdit] = useState(false);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [showModal, setShowModal] = useState(true);
  const [companyID, setCompanyID] = useState(true);
  const [errors, setErrors] = useState({});
  const [processing, setProcessing] = useState(false);
  const [isDateValid, setIsDateValid] = useState(false);
  const [dateError, setDateError] = useState("");
  const [cleanDate, setCleanDate] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [loggedUser, setLoggedUser] = useState({});

  // const [addAccount, setAddAccount] = useState("FirstState");
  const displayAccount = accountList?.filter((acc) => acc.IBAN === selectedAcc);

  const investorCondition =
    loggedUser?.attributes?.locale?.includes("investor");

  useEffect(() => {
    setInterval(() => {
      setLoading(true);
    }, 2000);
  }, []);

  const IBANexists = accountList.find((acc) =>
    acc.IBAN === bankAccount.IBAN ? true : false
  );
  const IBANlength =
    // bankAccount.IBAN.length >= 1 && bankAccount.IBAN.length < 22 ? true : false;
    bankAccount.IBAN.length >= 1 && bankAccount.IBAN.length !== 22
      ? true
      : false;

  const { user } = useAuthenticator((context) => [context.user]);
  console.log("user", user);
  // const companyID = user.attributes.email;
  console.log(`companyID: ${JSON.stringify(companyID)}`);
  const ID = bankAccount.id;
  console.log("ID", ID);
  useEffect(() => {
    const fetchData = async () => {
      const user = await Auth.currentAuthenticatedUser();
      const companyID = user.attributes.email;
      console.log(`companyID: ${JSON.stringify(companyID)}`);
      setCompanyID(companyID);
      setLoggedUser(user);
      console.log(`Getting Profile for ${companyID}`);
      const matches = await API.graphql(
        graphqlOperation(queries.listWaitlists, {
          filter: { email: { eq: companyID } },
        })
      );
      const profile = matches.data.listWaitlists.items[0];

      // const profile = await DataStore.query(Waitlist, (w) =>
      //   w.email.eq(companyID)
      // );
      console.log("Getting Profile:", profile);
      setUserProfile(profile);
      // const accounts = await DataStore.query(CompanyBankAccount);
      let filter = {
        email: {
          eq: companyID, // filter priority = 1
        },
      };
      const accounts = await API.graphql({
        query: queries.listCompanyBankAccounts,
        variables: { filter: filter },
        // authMode: "API_KEY",
      });
      const invoices = await API.graphql({
        query: queries.listInvoiceSummaries,
        variables: { filter: filter },
      });
      setInvoices(invoices.data.listInvoiceSummaries.items);
      console.log("accounts:", accounts);
      setAccountList(accounts.data.listCompanyBankAccounts.items);
    };
    fetchData();
  }, []);

  console.log("accountList-------------", accountList);
  console.log("errorssssss-------------", errors);
  console.log("bankAccount-------------", bankAccount);
  // console.log(
  //   "bankAccount-------------",
  //   (bankAccount.birthDate = new Date()).toLocaleDateString()
  // );

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors(BankAccountValidation(bankAccount, t));
    if (!isDateValid) {
      setDateError("Birth Date is required.");
    } else {
      setDateError("");
    }
    setIsSubmitClicked(true);
    try {
      if (
        bankAccount?.address &&
        bankAccount?.birthDate &&
        bankAccount?.companyAddress &&
        bankAccount?.companyName &&
        bankAccount?.firstName &&
        bankAccount?.gender &&
        bankAccount?.IBAN &&
        bankAccount?.IBAN.length === 22 &&
        bankAccount?.lastName &&
        bankAccount?.tax &&
        bankAccount?.ust &&
        bankAccount?.companyZip &&
        bankAccount?.companyCity &&
        bankAccount?.zip &&
        bankAccount?.city &&
        bankAccount?.ust.length === 9 &&
        bankAccount?.tax.length === 11 &&
        !IBANexists &&
        isDateValid
      ) {
        bankAccount.email = companyID;
        bankAccount.ust = "DE" + bankAccount.ust;
        bankAccount.companyAddress2 =
          bankAccount.companyAddress2 === "" ? "" : bankAccount.companyAddress2;
        bankAccount.address2 =
          bankAccount.address2 === "" ? "" : bankAccount.address2;
        console.log("Bank account:", {
          companyName: bankAccount.companyName,
          IBAN: bankAccount.IBAN,
        });
        // await DataStore.save(new CompanyBankAccount({companyName: bankAccount.companyName, IBAN: bankAccount.IBAN}));
        const newProfile = await API.graphql({
          query: mutations.createCompanyBankAccount,
          variables: { input: bankAccount },
        });
        console.log("newProfile-=-=-=-=", newProfile);
        console.log("Saved Successfully!");
        // BUG: Reset the DropDown
        setCleanDate(true);
        setAccountList((current) => [...current, bankAccount]);
        setSelectedAcc(bankAccount.IBAN);
        // setBankAccount({
        //   companyName: "",
        //   ust: "",
        //   tax: "",
        //   firstName: "",
        //   lastName: "",
        //   IBAN: "",
        //   bic: "",
        //   companyAddress: "",
        //   gender: "",
        //   birthDate: "",
        //   address: "",
        //   companyAddress2: "",
        //   companyZip: "",
        //   companyCity: "",
        //   address2: "",
        //   zip: "",
        //   city: "",
        // });
        // toast.success("Bank Account added successfully");
        // setInterval(() => {
        //   if (investorCondition) {
        //     window.location.href = "/investor-dashboard";
        //   } else {
        //     window.location.href = "/company-dashboard";
        //   }
        // }, 4000);
        setIsSubmitClicked(false);
      }
    } catch (err) {
      console.error("ERROR:", err);
      return toast.error(`${err.errors[0].message}`);
    }
  };

  const handleEdit = (e) => {
    e.preventDefault();
    setSwitchEdit(true);
    const newUst = bankAccount.ust.replace("DE", "");
    setBankAccount({ ...bankAccount, ust: newUst });
    setDateError("");
  };
  const handleAnotherAcc = (e) => {
    e.preventDefault();
    setSelectedAcc("NewAccount");
    setBankAccount({
      companyName: "",
      ust: "",
      tax: "",
      firstName: "",
      lastName: "",
      IBAN: "",
      bic: "",
      companyAddress: "",
      gender: "",
      birthDate: "",
      address: "",
      companyAddress2: "",
      companyZip: "",
      companyCity: "",
      address2: "",
      zip: "",
      city: "",
    });
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setSwitchEdit(false);
    bankAccount.ust = "DE" + bankAccount.ust;
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setErrors(BankAccountValidation(bankAccount, t));
    setIsSubmitClicked(true);
    try {
      if (
        bankAccount?.address &&
        bankAccount?.birthDate &&
        bankAccount?.companyAddress &&
        bankAccount?.companyName &&
        bankAccount?.firstName &&
        bankAccount?.gender &&
        bankAccount?.ust &&
        bankAccount?.companyZip &&
        bankAccount?.companyCity &&
        bankAccount?.zip &&
        bankAccount?.city &&
        bankAccount?.ust.length === 9 &&
        bankAccount?.tax &&
        bankAccount?.tax.length === 11 &&
        bankAccount?.IBAN &&
        bankAccount?.IBAN.length === 22 &&
        bankAccount?.lastName
      ) {
        bankAccount.email = companyID;
        bankAccount.ust = "DE" + bankAccount.ust;
        bankAccount.companyAddress2 =
          bankAccount.companyAddress2 === "" ? "" : bankAccount.companyAddress2;
        bankAccount.address2 =
          bankAccount.address2 === "" ? "" : bankAccount.address2;
        delete bankAccount.createdAt;
        delete bankAccount.updatedAt;
        delete bankAccount._deleted;
        delete bankAccount._lastChangedAt;
        const editedData = await API.graphql({
          query: mutations.updateCompanyBankAccount,
          variables: { input: bankAccount },
        });
        const edited = editedData.data.updateCompanyBankAccount;
        setBankAccount(edited);
        toast.success("Bank Account updated successfully");
        setProcessing(true);
        setInterval(() => {
          window.location.href = "/profile";
        }, 4000);
        setIsSubmitClicked(false);
      }
    } catch (err) {
      console.log("ERROR in Edit Bank Account", err);
      return toast.error(`${err.errors[0].message}`);
    }
  };

  useEffect(() => {
    if (showModal === true) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [showModal]);

  //FORMAT AMOUNT
  const invoicesPureAmount = invoices.map((invoice) =>
    invoice.amount.replaceAll("€", "")
  );
  const invoicesAmountFormated = invoicesPureAmount.map((invoice) =>
    invoice.replaceAll(".", "")
  );
  const invoiceAmountCents = invoicesAmountFormated.map((inv) => {
    const formated = inv.replaceAll(",", ".");
    const number = formated * 1;
    return number;
  });
  const totalAmount = invoiceAmountCents.reduce((a, v) => (a = a + v), 0);

  return (
    <>
      <ToastContainer />
      {loading ? (
        <div
          className="user-profile"
          style={
            showModal
              ? { height: "100vh", overflowX: "hidden" }
              : { height: "100%", overflowX: "hidden" }
          }
        >
          {showModal && accountList.length === 0 && (
            <Modal
              title={t("welcome.title")}
              description={
                investorCondition
                  ? `${t("welcome.invest")}`
                  : `${t("welcome.desc")}`
              }
              setShowModal={setShowModal}
            />
          )}
          <DashboardNavbar />
          <div className="innerContainer">
            <h2> {t("profile.title")}</h2>
            <div className="infoSection">
              <div className="profilePic">
                <img src={profilePic} alt="profilePic" />
              </div>
              <div className="userInfo">
                <div className="userName">
                  <p>
                    {userProfile?.firstName} {userProfile?.lastName}
                  </p>
                  <p>
                    {!investorCondition
                      ? `${t("profile.acc")}`
                      : "Investor Account"}
                  </p>
                </div>
                <div className="userData">
                  <div className="revenue">
                    <p>{`${totalAmount.toLocaleString("de-de")} €`}</p>
                    <p>
                      {investorCondition
                        ? "Total amount invested"
                        : `${t("profile.revenue")}`}
                    </p>
                  </div>
                  <div className="uploads">
                    <p>{invoices?.length}</p>
                    <p>
                      {investorCondition
                        ? "Number of invoices invested in"
                        : `${t("profile.number")}`}
                    </p>
                  </div>
                </div>
              </div>
              <div className="buttonContainer">
                {/* <Button extraClass="editButton" bgColor="white" color="gray">
              <img src={editProfile} alt="edit" />
              Edit Profile
            </Button> */}
              </div>
            </div>
          </div>
          <div className="fieldsContainer">
            <div className="companyInfo">
              <h3>INFORMATION</h3>
              <div className="infos">
                <p>{t("profile.name")}: </p>
                <p>{userProfile?.companyName}</p>
              </div>
              <div className="infos">
                <p>{t("profile.type")}: </p>
                <p>{userProfile?.businessType}</p>
              </div>
              <div className="infos">
                <p>{t("profile.mobile")}: </p>
                <p>{userProfile?.phoneNumber}</p>
              </div>
              <div className="infos">
                <p>Email:</p>
                <p>{userProfile?.email}</p>
              </div>
              {/* <div className="infos">
              <p>Website:</p>
              <p>www.auerandsons.com</p>
            </div> */}
              <div className="infos">
                <p>{t("profile.location")}: </p>
                <p>{userProfile?.country}</p>
              </div>
              {/* <div className="infos">
              <p>Languages:</p>
              <p>English, German, Spanish</p>
            </div> */}
              {/* <div className="infos">
              <p>In Business Since:</p>
              <p>2003</p>
            </div> */}
            </div>
            <div className="otherInfo">
              <h3>{t("profile.bank")}</h3>
              {/* {addAccount === "FirstState" && (
              <div
                className="addAccountContainer"
                onClick={() => setAddAccount("NewAccount")}
              >
                <img src={file} alt="addAccount" />
                <p>Click here to add a new Banking Account</p>
              </div>
            )} */}
              {/* {addAccount === "NewAccount" && ( */}
              <div className="fieldGroup">
                <div className="fields">
                  {!switchEdit && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <select
                        className="selector"
                        name="sector"
                        id="sector"
                        value={selectedAcc}
                        onChange={(e) => {
                          setSelectedAcc(e.target.value);
                          setBankAccount(
                            accountList?.find(
                              (acc) => acc.IBAN === e.target.value
                            )
                          );
                        }}
                      >
                        <option value="" hidden>
                          {t("profile.select")}...
                        </option>
                        {accountList.map((acc) => (
                          <option value={acc?.IBAN} key={acc?.IBAN}>
                            {acc?.IBAN &&
                              acc?.IBAN?.match(/.{1,4}/g)
                                .join(" ")
                                .toUpperCase()}
                          </option>
                        ))}
                      </select>
                      {selectedAcc === null && (
                        <p style={{ margin: "5px -10px 0 10px" }}>
                          {t("profile.or")}
                        </p>
                      )}
                    </div>
                  )}
                  {selectedAcc === "NewAccount" || switchEdit ? (
                    <>
                      <p> {t("profile.acctitle")}</p>
                      <Input
                        label={t("recipient.company")}
                        extraClass="inputField"
                        placeholder={t("recipient.company")}
                        value={bankAccount.companyName}
                        maxLength={160}
                        onChange={(e) => {
                          setBankAccount({
                            ...bankAccount,
                            companyName: e.target.value,
                          });
                        }}
                        error={
                          !bankAccount.companyName.trim()
                            ? errors?.companyName
                            : null
                        }
                        // error={
                        //   !bankAccount.companyName.trim()
                        //     ? "Company Name is required."
                        //     : null
                        // }
                      />
                      <Input
                        label="UST-ID"
                        extraClass="inputField"
                        placeholder="UST-ID"
                        // value={
                        //   switchEdit
                        //     ? bankAccount.ust.substring(2, 11)
                        //     : bankAccount.ust
                        // }
                        value={
                          processing
                            ? bankAccount.ust.slice(2)
                            : bankAccount.ust
                        }
                        maxLength={9}
                        minLength={9}
                        // type="number"
                        id="inputFieldUst"
                        onChange={(e) => {
                          const re = /^[0-9\b]+$/;
                          if (
                            e.target.value === "" ||
                            re.test(e.target.value)
                          ) {
                            setBankAccount({
                              ...bankAccount,
                              ust: e.target.value,
                            });
                          }
                        }}
                        // error={
                        //   bankAccount.ust !== "" && bankAccount.ust.length === 9
                        //     ? null
                        //     : errors?.ust
                        // }
                        error={
                          isSubmitClicked && bankAccount.ust.length === 0
                            ? errors.ust
                            : bankAccount.ust.length >= 1 &&
                              bankAccount.ust.length < 9
                            ? "UST ID must contain 9 numbers"
                            : null
                        }
                      />
                      <Input
                        label={t("profile.tax")}
                        extraClass="inputField"
                        placeholder={t("profile.tax")}
                        maxLength={11}
                        minLength={11}
                        value={bankAccount.tax}
                        onChange={(e) => {
                          const re = /^[0-9\b]+$/;
                          if (
                            e.target.value === "" ||
                            re.test(e.target.value)
                          ) {
                            setBankAccount({
                              ...bankAccount,
                              tax: e.target.value,
                            });
                          }
                        }}
                        // error={
                        //   bankAccount.tax !== "" &&
                        //   bankAccount.tax.length === 11
                        //     ? null
                        //     : errors?.tax
                        // }
                        error={
                          isSubmitClicked && bankAccount.tax.length === 0
                            ? errors.tax
                            : bankAccount.tax.length >= 1 &&
                              bankAccount.tax.length < 11
                            ? "Tax Number must contain 11 numbers"
                            : null
                        }
                      />
                      <Input
                        label={t("profile.address")}
                        extraClass="inputField"
                        placeholder={t("profile.address")}
                        value={bankAccount.companyAddress}
                        maxLength={160}
                        onChange={(e) =>
                          setBankAccount({
                            ...bankAccount,
                            companyAddress: e.target.value,
                          })
                        }
                        // error={
                        //   bankAccount.companyAddress !== ""
                        //     ? null
                        //     : errors?.companyAddress
                        // }
                        error={
                          !bankAccount.companyAddress.trim()
                            ? errors.companyAddress
                            : null
                        }
                      />
                      <Input
                        label={t("recipient.address2")}
                        extraClass="inputField"
                        placeholder={t("profile.address2")}
                        value={bankAccount.companyAddress2}
                        maxLength={160}
                        onChange={(e) =>
                          setBankAccount({
                            ...bankAccount,
                            companyAddress2: e.target.value,
                          })
                        }
                      />
                      <Input
                        label={t("profile.czip")}
                        extraClass="inputField"
                        placeholder={t("profile.czip")}
                        value={bankAccount.companyZip}
                        maxLength={160}
                        onChange={(e) => {
                          const re = /^[0-9\b]+$/;
                          if (
                            e.target.value === "" ||
                            re.test(e.target.value)
                          ) {
                            setBankAccount({
                              ...bankAccount,
                              companyZip: e.target.value,
                            });
                          }
                        }}
                        error={
                          !bankAccount.companyZip.trim()
                            ? errors.companyZip
                            : null
                        }
                      />
                      <Input
                        label={t("profile.companycity")}
                        extraClass="inputField"
                        placeholder={t("profile.companycity")}
                        value={bankAccount.companyCity}
                        maxLength={160}
                        onChange={(e) =>
                          setBankAccount({
                            ...bankAccount,
                            companyCity: e.target.value,
                          })
                        }
                        error={
                          !bankAccount.companyCity.trim()
                            ? errors.companyCity
                            : null
                        }
                      />
                      <Input
                        label="IBAN"
                        extraClass="inputField"
                        placeholder="IBAN"
                        value={bankAccount.IBAN}
                        maxLength={30}
                        minLength={22}
                        onChange={(e) =>
                          setBankAccount({
                            ...bankAccount,
                            IBAN: e.target.value
                              .toUpperCase()
                              .replaceAll(" ", ""),
                          })
                        }
                        // error={
                        //   IBANexists
                        //     ? "IBAN already exists."
                        //     : bankAccount.IBAN !== "" &&
                        //       bankAccount.IBAN.length === 22
                        //     ? null
                        //     : errors?.IBAN
                        // }
                        error={
                          isSubmitClicked && bankAccount.IBAN.length === 0
                            ? errors.IBAN
                            : IBANexists && !switchEdit
                            ? "IBAN already exists."
                            : IBANexists && switchEdit
                            ? ""
                            : IBANlength
                            ? "IBAN must be 22 characters long."
                            : null
                        }
                      />
                    </>
                  ) : (
                    accountList.length !== 0 &&
                    selectedAcc === bankAccount?.IBAN && (
                      <div className="accInfo">
                        <div className="infoSection">
                          <div className="innerSection">
                            <span>{t("profile.acctitle")}</span>
                            <div className="infoField">
                              <div className="label">
                                {t("recipient.company")}
                              </div>
                              <p>{bankAccount?.companyName}</p>
                            </div>
                            <div className="infoField">
                              <div className="label">UST ID</div>
                              <p>{bankAccount?.ust}</p>
                            </div>
                            <div className="infoField">
                              <div className="label">{t("profile.tax")}</div>
                              <p>{bankAccount?.tax}</p>
                            </div>
                            <div className="infoField">
                              <div className="label">
                                {t("profile.address")}
                              </div>
                              <p>{bankAccount?.companyAddress}</p>
                            </div>
                            <div className="infoField">
                              <div className="label">
                                {t("profile.address")} 2
                              </div>
                              {bankAccount?.companyAddress2 ? (
                                <p>{bankAccount?.companyAddress2}</p>
                              ) : (
                                <p style={{ color: "white" }}>{"a"}</p>
                              )}
                            </div>
                            <div className="infoField">
                              <div className="label">{t("profile.czip")}</div>
                              <p>{bankAccount?.companyZip}</p>
                            </div>
                            <div className="infoField">
                              <div className="label">
                                {t("profile.companycity")}
                              </div>
                              <p>{bankAccount?.companyCity}</p>
                            </div>
                            <div className="infoField">
                              <div className="label">IBAN</div>
                              <p>
                                {bankAccount?.IBAN &&
                                  bankAccount?.IBAN?.match(/.{1,4}/g)
                                    .join(" ")
                                    .toUpperCase()}
                              </p>
                            </div>
                          </div>
                        </div>
                        <Button
                          bgColor="#FFBA08"
                          color="white"
                          children={t("profile.another")}
                          onClick={handleAnotherAcc}
                        />
                      </div>
                    )
                  )}
                </div>
                <div
                  className="fields"
                  style={
                    switchEdit
                      ? { marginTop: "0" }
                      : selectedAcc === "NewAccount"
                      ? { marginTop: "63px" }
                      : { marginTop: "45px" }
                  }
                >
                  {selectedAcc === null && (
                    <Button
                      bgColor="#FFBA08"
                      color="white"
                      children={t("profile.add")}
                      onClick={() => setSelectedAcc("NewAccount")}
                      extraClass="addAccount"
                    />
                  )}
                  {selectedAcc === "NewAccount" || switchEdit ? (
                    <>
                      <p>{t("profile.accowner")}</p>
                      <Input
                        label={t("profile.firstname")}
                        extraClass="inputField"
                        placeholder={t("profile.firstname")}
                        value={bankAccount.firstName}
                        maxLength={35}
                        onChange={(e) =>
                          setBankAccount({
                            ...bankAccount,
                            firstName: e.target.value,
                          })
                        }
                        // error={
                        //   bankAccount.firstName !== ""
                        //     ? null
                        //     : errors?.firstName
                        // }
                        error={
                          !bankAccount.firstName.trim()
                            ? errors.firstName
                            : null
                        }
                      />
                      <Input
                        label={t("profile.lastname")}
                        extraClass="inputField"
                        placeholder={t("profile.lastname")}
                        value={bankAccount.lastName}
                        maxLength={35}
                        onChange={(e) =>
                          setBankAccount({
                            ...bankAccount,
                            lastName: e.target.value,
                          })
                        }
                        // error={
                        //   bankAccount.lastName !== "" ? null : errors?.lastName
                        // }
                        error={
                          !bankAccount.lastName.trim() ? errors.lastName : null
                        }
                      />
                      <div className="genderselector">
                        <label>{t("profile.gender")}</label>
                        <select
                          className="selector"
                          name="sector"
                          id="sector"
                          value={bankAccount?.gender}
                          onChange={(e) =>
                            setBankAccount({
                              ...bankAccount,
                              gender: e.target.value,
                            })
                          }
                        >
                          <option value="" hidden>
                            {t("profile.gender")}
                          </option>
                          <option value="male">{t("profile.male")}</option>
                          <option value="female">{t("profile.female")}</option>
                          <option value="other">{t("profile.other")}</option>
                        </select>
                        <p className="gender-input-error">
                          {/* {bankAccount.gender !== "" ? null : errors?.gender} */}
                          {!bankAccount.gender.trim() ? errors.gender : null}
                        </p>
                      </div>
                      <div
                        className="profiledatepicker"
                        style={dateError ? {} : { marginBottom: "25px" }}
                      >
                        <label>{t("profile.birth")}</label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            renderInput={(props) => <TextField {...props} />}
                            value={
                              cleanDate
                                ? null
                                : dayjs(bankAccount.birthDate, "DD.MM.YYYY")
                            }
                            disableFuture
                            inputFormat="DD.MM.YYYY"
                            onChange={(newValue) => {
                              if (newValue && newValue.isValid()) {
                                setIsDateValid(true);
                                setBankAccount({
                                  ...bankAccount,
                                  birthDate: newValue.format("DD.MM.YYYY"),
                                });
                              }
                            }}
                          />
                        </LocalizationProvider>
                        <p
                          className="input-errordatee"
                          style={!dateError ? { marginBottom: "-5px" } : {}}
                        >
                          {isDateValid ? null : dateError}
                          {/* {!bankAccount?.birthDate?.trim()
                            ? errors.birthDate
                            : null} */}
                        </p>
                      </div>
                      <Input
                        label={t("profile.personal")}
                        extraClass="inputField"
                        placeholder={t("profile.personal")}
                        value={bankAccount.address}
                        maxLength={160}
                        onChange={(e) =>
                          setBankAccount({
                            ...bankAccount,
                            address: e.target.value,
                          })
                        }
                        // error={
                        //   bankAccount.address !== "" ? null : errors?.address
                        // }
                        error={
                          !bankAccount.address.trim() ? errors.address : null
                        }
                        // error={errors?.address}
                      />
                      <Input
                        label={t("profile.personal")}
                        extraClass="inputField"
                        placeholder={t("profile.personal2")}
                        value={bankAccount.address2}
                        maxLength={160}
                        onChange={(e) =>
                          setBankAccount({
                            ...bankAccount,
                            address2: e.target.value,
                          })
                        }
                      />
                      <Input
                        label={t("profile.zip")}
                        extraClass="inputField"
                        placeholder={t("profile.zip")}
                        value={bankAccount.zip}
                        maxLength={160}
                        onChange={(e) => {
                          const re = /^[0-9\b]+$/;
                          if (
                            e.target.value === "" ||
                            re.test(e.target.value)
                          ) {
                            setBankAccount({
                              ...bankAccount,
                              zip: e.target.value,
                            });
                          }
                        }}
                        error={!bankAccount.zip.trim() ? errors.zip : null}
                      />
                      <Input
                        label={t("profile.city")}
                        extraClass="inputField"
                        placeholder={t("profile.city")}
                        value={bankAccount.city}
                        maxLength={160}
                        onChange={(e) =>
                          setBankAccount({
                            ...bankAccount,
                            city: e.target.value,
                          })
                        }
                        error={!bankAccount.city.trim() ? errors.city : null}
                      />
                    </>
                  ) : (
                    accountList.length !== 0 &&
                    selectedAcc === bankAccount?.IBAN && (
                      <div className="accInfo" style={{ marginTop: "17px" }}>
                        <div className="infoSection">
                          <div className="innerSection">
                            <span>{t("profile.accowner")}</span>
                            <div className="infoField">
                              <div className="label">
                                {t("profile.firstname")}
                              </div>
                              <p>{bankAccount?.firstName}</p>
                            </div>
                            <div className="infoField">
                              <div className="label">
                                {t("profile.lastname")}
                              </div>
                              <p>{bankAccount?.lastName}</p>
                            </div>
                            <div className="infoField">
                              <div className="label">{t("profile.gender")}</div>
                              <p>{bankAccount?.gender}</p>
                            </div>
                            <div className="infoField">
                              <div className="label">{t("profile.birth")}</div>
                              <p>{bankAccount?.birthDate}</p>
                            </div>
                            <div className="infoField">
                              <div className="label">
                                {t("profile.personal")}
                              </div>
                              <p>{bankAccount?.address}</p>
                            </div>
                            <div className="infoField">
                              <div className="label">
                                {t("profile.personal2")}
                              </div>
                              {bankAccount?.address2 ? (
                                <p>{bankAccount?.address2}</p>
                              ) : (
                                <p style={{ color: "white" }}>{"a"}</p>
                              )}
                            </div>
                            <div className="infoField">
                              <div className="label">{t("profile.zip")}</div>
                              <p>{bankAccount?.zip}</p>
                            </div>
                            <div className="infoField">
                              <div className="label">{t("profile.city")}</div>
                              <p>{bankAccount?.city}</p>
                            </div>
                          </div>
                        </div>
                        <Button
                          bgColor="#618BE8"
                          color="white"
                          children={t("profile.edit")}
                          onClick={handleEdit}
                        />
                      </div>
                    )
                  )}
                </div>
              </div>
              {/* )}*/}
              {selectedAcc === "NewAccount" && !switchEdit ? (
                <Button
                  bgColor="#22C55E"
                  color="white"
                  children={t("recipient.confirm")}
                  onClick={handleSubmit}
                  extraClass="confirmButton"
                />
              ) : (
                switchEdit && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <Button
                      bgColor="#22C55E"
                      color="white"
                      children="Save &nbsp;&nbsp;&#10003;"
                      onClick={handleSave}
                      extraClass="afterEditButton"
                    />
                    <Button
                      bgColor="transparent"
                      color="#22C55E"
                      children={t("recipient.cancel")}
                      onClick={handleCancel}
                      border="1px solid #22C55E"
                      extraClass="afterEditButton"
                      processing={processing}
                    />
                  </div>
                )
              )}
            </div>
            {/* <div className="otherInfo">
          <h3>CUSTOMERS</h3>
          <div className="fields">
          <Button bgColor="#618BE8" color="white" extraClass="addButton">
          Add Customer &nbsp;
          <img src={addIcon} alt="add" />
          </Button>
          <Input extraClass="inputField" placeholder="Name" />
          <Input extraClass="inputField" placeholder="IBAN" />
          <Input extraClass="inputField" placeholder="BIC" />
          <Input extraClass="inputField" placeholder="Address" />
          </div>
        </div> */}
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default Profile;
