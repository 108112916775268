import React, { useState, useEffect } from "react";
import ValueCard from "../../atoms/ValueCard/ValueCard";
import styles from "./PaymentCards.module.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function PaymentCards(props) {
  const [slidesToShow, setSlidesToShow] = useState(3);
  const [slidesToScroll, setSlidesToScroll] = useState(3);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 991) {
        setSlidesToShow(1);
        setSlidesToScroll(1);
      } else {
        setSlidesToShow(3);
        setSlidesToScroll(3);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow,
    slidesToScroll,
  };

  return (
    <div className={styles.container}>
      <h3>Transparent Pricing for You</h3>
      <p>Choose the financing conditions that best suit you.</p>
      <span>{`Funding amount: ${props.invoiceSummary.amount}`}</span>
      <div className={styles.cards}>
        <Slider {...settings}>
          <ValueCard
            days={60}
            premium={0.0003}
            extraClass="secondCard"
            selectedPayment={props.selectedPayment}
            setSelectedPayment={props.setSelectedPayment}
            setIsPaymentSelected={props.setIsPaymentSelected}
            invoiceAmount={props.invoiceSummary.amount}
          />
          <ValueCard
            days={90}
            premium={0.0004}
            extraClass="secondCard"
            selectedPayment={props.selectedPayment}
            setSelectedPayment={props.setSelectedPayment}
            setIsPaymentSelected={props.setIsPaymentSelected}
            invoiceAmount={props.invoiceSummary.amount}
          />
          <ValueCard
            days={180}
            premium={0}
            extraClass="secondCard"
            selectedPayment={props.selectedPayment}
            setSelectedPayment={props.setSelectedPayment}
            setIsPaymentSelected={props.setIsPaymentSelected}
            invoiceAmount={props.invoiceSummary.amount}
          />
        </Slider>
      </div>
    </div>
  );
}
