import React from 'react';
import MemberCard from '../../atoms/MemberCard/MemberCard';
import styles from "./Team.module.scss";
import { members } from '../../constants/data';
import Button from '../../atoms/Button/Button';
import { useNavigate } from 'react-router-dom';

export default function Team() {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <h1>Our talented team.</h1>
        <Button bgColor="#618BE8" onClick={() => navigate("/jobs")}>Join our team</Button>
        </div>
        <div className={styles.members}>
        {members.map((item)=>{
            return(
                <MemberCard name={item.name} position={item.position} backgroundImage={item.member} toLinkedin={item.toLinkedin} toFacebook={item.toFacebook} toTwitter={item.toTwitter}  />
            )
        })}
        </div>
    </div>
  )
}
