import React from "react";
import SingleGroup from "./SingleGroup";
import { sliceLongString } from "../../../util/helper";

export default function PaymentList({ ...rest }) {
  let mainPdf = [
    {
      value: sliceLongString(rest?.invoiceSummary?.doc, 30),
    },
  ];
  const allPdf = [
    ...mainPdf,
    ...rest.pdfFiles.map((item) => ({ value: sliceLongString(item.name, 30) })),
  ];

  let currencyFormat = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
  });

  return (
    <>
      <SingleGroup
        title="BANK ACCOUNT"
        list={[
          { value: rest?.displayBank?.companyName },
          {
            value:
              rest?.displayBank?.firstName + " " + rest?.displayBank?.lastName,
          },
          { value: rest?.displayBank?.IBAN?.match(/.{1,4}/g).join(" ") },
        ]}
      />
      <SingleGroup
        title="INVOICE ISSUER"
        list={[
          {
            value: rest?.displayAccount?.company
              ? sliceLongString(rest?.displayAccount?.company, 20)
              : sliceLongString(
                  rest?.displayAccount?.firstName +
                    " " +
                    rest?.displayAccount?.lastName,
                  20
                ),
          },
          {
            value: rest?.displayAccount?.address + " " + rest?.displayBank?.zip,
          },
          { value: rest?.displayAccount?.country },
        ]}
      />
      <SingleGroup title="FILES UPLOADED" list={allPdf} />
      <SingleGroup
        title="INVOICE SUMMARY"
        options={true}
        list={[
          {
            value: "Invoice Number",
          },
          {
            value: "Invoice Date",
          },
          {
            value: "Payment Due",
          },
          {
            value: "Amount Due (EUR)",
          },
        ]}
        optionsList={[
          {
            title: rest?.invoiceSummary?.invoiceNumber.toUpperCase(),
          },
          {
            title: rest?.invoiceSummary?.invoiceDate,
          },
          {
            title: rest?.invoiceSummary?.paymentDate,
          },
          {
            title: rest?.invoiceSummary?.amount,
          },
        ]}
      />
      <SingleGroup
        title="PRICING"
        options={true}
        extra={true}
        list={[
          {
            value: "Runtime",
          },
          {
            value: "Monthly Repayment",
          },
          {
            value: "Monthly Interest",
          },
          {
            value: "Monthly Rate",
          },
        ]}
        optionsList={[
          {
            title: rest?.selectedPayment?.days,
          },
          {
            title: currencyFormat.format(
              rest?.selectedPayment?.monthlyRepayment
            ),
          },
          {
            title: currencyFormat.format(
              rest?.selectedPayment?.monthlyInterest
            ),
          },
          {
            title: currencyFormat.format(rest?.selectedPayment?.monthlyRate),
          },
        ]}
      />
    </>
  );
}
