import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const UserSettings = (props) => {
  const [user, setUser] = useState();
  const {t}  = useTranslation();
  useEffect(() => {
    const fetchUser = async () => {
      const thisUser = await Auth.currentAuthenticatedUser();
      setUser(thisUser);
      console.log(thisUser);
    };
    fetchUser();
  }, []);
  const signOut = async () => {
    try {
      await Auth.signOut();
      window.location.href = window.location.origin + "/";
      localStorage.removeItem("logState");
    } catch (err) {
      console.log("err in SIGNOUT", err);
    }
  };
  return (
    <div className="settings-content">
      {user?.attributes?.locale?.includes("investor") ? (
        <Link to="/investor">
          <a>My Profile</a>
        </Link>
      ) : (
        <Link to="/profile">
          <a>{t('dashboard.bankacc')}</a>
        </Link>
      )}
      <button onClick={signOut}>Log Out</button>
    </div>
  );
};

export default UserSettings;
