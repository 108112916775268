import React, { useState } from 'react'
import styles from "./FaqSection.module.scss";
import FAQ from '../../atoms/FAQ/FAQ'
import InfoCard from '../../atoms/InfoCard/InfoCard';
import {ReactComponent as Phone} from "../../../assets/icons/phone.svg";
import {ReactComponent as Chat} from "../../../assets/icons/chatIcon.svg";
import { useTranslation } from 'react-i18next';

export default function FaqSection({data}) {
  const [isMobile] = useState(window.innerWidth <= 991 ? true : false);
  const {t} = useTranslation();

    const cards = [
        {
            id: 1,
            icon: <Phone />,
            title: t('faq.phone'),
            desc: t('banner.contact')
        },
        {
            id: 2,
            icon: <Chat />,
            title: t('faq.chat'),
            desc: t('banner.contact')
        },
    ]
  return (
    <div className={styles.container}>
        <div className={styles.faqInfo}>
           <h1>{t('faq.title')} <br/> {t('faq.secondline')}</h1>
           <p>{t('faq.subtitle')}</p>
           <div className={styles.cards}>
                {cards.map((item)=> {
                    return(
                        <InfoCard extraClass="faqCard" key={item.id} icon={item.icon} title={item.title} desc={item.desc} />
                    )
                })}
           </div>
        </div>
        <div className={styles.faq}>
      <FAQ data={data} />
      {isMobile ? (
        <>
         <div className={styles.cards}>
                {cards.map((item)=> {
                    return(
                        <InfoCard extraClass="faqCard" key={item.id} icon={item.icon} title={item.title} desc={item.desc} />
                    )
                })}
           </div></>
      ): (
        <></>
      )}
      </div>
    </div>
  )
}
