import React, { useState, useEffect } from "react";
import "./CompanyDashboard.scss";
import Button from "../Button";
import DashboardNavbar from "./DashboardNavbar";
import * as XLSX from "xlsx";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Export } from "../../assets/icons/export.svg";
import { ReactComponent as Arrow } from "../../assets/icons/rightarrow.svg";
import { ReactComponent as Support } from "../../assets/icons/support.svg";
import Loader from "../Loader";
import { API, Auth, Storage } from "aws-amplify";
import * as queries from "../../graphql/queries";
import { Tab, Tabs, Box } from "@mui/material";
import { sliceLongString } from "../../util/helper";
import { useTranslation } from "react-i18next";

export default function CompanyDashboard() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [identifier, setIdentifier] = useState(null);
  const [invoices, setInvoices] = useState(null);
  const [order, setOrder] = useState("ASC");
  const [loading, setLoading] = useState(false);
  const [toggleTabs, setToggleTabs] = useState(1);

  const companyInvoices = invoices?.filter(
    (invoice) => invoice?.invoiceType === "company"
  );
  const supplierInvoices = invoices?.filter(
    (invoice) => invoice?.invoiceType === "supplier"
  );
  console.log("companyInvoices----", companyInvoices);
  console.log("supplierInvoices----", supplierInvoices);

  const toggleTheTab = (index) => {
    setToggleTabs(index);
  };

  const invoicesWithDateFormat = invoices?.map((invoice) => {
    return { ...invoice, invoiceDate: new Date(invoice.invoiceDate) };
  });

  useEffect(() => {
    setInterval(() => {
      setLoading(true);
    }, 2000);
  }, []);

  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...invoices].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setInvoices(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...invoices].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      );
      setInvoices(sorted);
      setOrder("ASC");
    }
  };

  // const sortingDate = (dates) => {
  //   dates.sort((date1, date2) => date1 - date2);
  // };

  console.log("DATESSSSS", invoicesWithDateFormat);

  const sortNumbers = (col) => {
    if (order === "ASC") {
      const sorted = [...invoices].sort(
        (a, b) => a[col].toLowerCase() - b[col].toLowerCase()
      );
      setInvoices(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...invoices].sort(
        (a, b) => b[col].toLowerCase() - a[col].toLowerCase()
      );
      setInvoices(sorted);
      setOrder("ASC");
    }
  };

  const handleExport = () => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(invoices);

    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

    XLSX.writeFile(wb, "Invoices.xlsx");
  };

  useEffect(() => {
    async function fetchInvoices(callback) {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const identifier = user.attributes.email;
        setIdentifier(identifier);
        console.log("identifier=-=-=-=", identifier);
        let filter = {
          email: {
            eq: identifier, // filter priority = 1
          },
        };
        const res = await API.graphql({
          query: queries.listInvoiceSummaries,
          variables: { filter: filter },
        });
        // setInvoices(res.data.listInvoiceSummaries.items);
        console.log("running API.graphql", res);
        const invoices = res.data.listInvoiceSummaries.items;
        console.log("Invoices: ", invoices);

        const promises = invoices.map(async (invoice) => {
          // return { ...invoice, url: ''}
          let ret = { ...invoice, url: "" };

          if (invoice?.doc) {
            const newUrl = await getStorageUrl(invoice.doc);
            console.log("newUrl", newUrl);
            ret.url = newUrl;
          }
          return ret;
        });
        let newinvoices = await Promise.all(promises);
        // let newinvoices = invoices
        console.log("New Invoices: ", newinvoices);
        callback(newinvoices);
      } catch (err) {
        console.log("error fetchInvoices", err);
      }
    }

    async function getStorageUrl(key) {
      console.log("file key:", key);
      const url = await Storage.get(key);
      console.log("url: ", url);
      return url;
    }

    console.log("fetching Invoices");
    fetchInvoices((invoices) => setInvoices(invoices));
  }, []);

  const renderStatus = (status) => {
    switch (status) {
      case "LISTED":
        return (
          <span
            style={{
              backgroundColor: "#bddcfc",
              color: "#4C82F7",
            }}
          >
            {status}
          </span>
        );
      case "PEND":
        return (
          <span
            style={{
              backgroundColor: "#fcecc2",
              color: "#FFBA08",
            }}
          >
            {status}
          </span>
        );
      case "SOLD":
        return (
          <span
            style={{
              backgroundColor: "#cbf5db",
              color: "#22C55E",
            }}
          >
            {status}
          </span>
        );
      default:
        return <span>{status}</span>;
    }
  };

  return (
    <>
      {loading ? (
        <div className="companydashboard">
          <DashboardNavbar />
          <div className="containerHeader">
            <div className="dashboardButtons">
              <h2>{t("dashboard.company")}</h2>
              <div className="buttonsHolder">
                <Button
                  onClick={() => navigate("/supplier-upload-invoice")}
                  bgColor="#22C55E"
                  color="white"
                >
                  {t("dashboard.supload")} <Arrow />{" "}
                </Button>
                <Button
                  onClick={() => navigate("/upload-invoice")}
                  bgColor="#22C55E"
                  color="white"
                >
                  {t("dashboard.upload")} <Arrow />{" "}
                </Button>
                <Button
                  bgColor="#618BE8"
                  color="white"
                  onClick={() =>
                    window.open(
                      "https://api.whatsapp.com/send/?phone=%2B491621739274&text&type=phone_number&app_absent=0",
                      "_blank"
                    )
                  }
                >
                  {t("dashboard.support")} <Support />{" "}
                </Button>
              </div>
            </div>
            <div className="companytable">
              <div className="invoices">
                <h3>My Invoices</h3>
                {invoices && invoices.length !== 0 && (
                  <Button color="#618BE8" onClick={() => handleExport()}>
                    {t("dashboard.export")} <Export />{" "}
                  </Button>
                )}
              </div>
              <div className="bloc-tabs">
                <button
                  className={toggleTabs === 1 ? "tabs active-tabs" : "tabs"}
                  onClick={() => toggleTheTab(1)}
                >
                  {t("dashboard.all")}
                </button>
                <button
                  className={toggleTabs === 2 ? "tabs active-tabs" : "tabs"}
                  onClick={() => toggleTheTab(2)}
                >
                  {t("dashboard.cinvoices")}
                </button>
                <button
                  className={toggleTabs === 3 ? "tabs active-tabs" : "tabs"}
                  onClick={() => toggleTheTab(3)}
                >
                  {t("dashboard.sinvoices")}
                </button>
              </div>
              <div
                className={
                  toggleTabs === 1 ? "content  active-content" : "content"
                }
              >
                {invoices && invoices?.length === 0 ? (
                  <div style={{ textAlign: "center" }}>{t("dashboard.no")}</div>
                ) : (
                  <table>
                    <thead>
                      <th onClick={() => sorting("buyerCompany")}>
                        {t("dashboard.name")}
                      </th>
                      {/* <th onClick={() => sortNumbers("invoiceNumber")}>Invoice</th> */}
                      <th>{t("dashboard.inv")}</th>
                      <th onClick={() => sorting("invoiceDate")}>
                        {t("dashboard.date")}
                      </th>
                      <th onClick={() => sorting("status")}>
                        {t("dashboard.status")}
                      </th>
                      <th onClick={() => sortNumbers("amount")}>
                        {t("dashboard.amount")}
                      </th>
                      <th onClick={() => sortNumbers("bid")}>
                        {t("dashboard.bid")}
                      </th>
                      <th onClick={() => sortNumbers("savings")}>
                        {t("dashboard.savings")}
                      </th>
                      <th onClick={() => sorting("time")}>
                        {t("dashboard.time")}
                      </th>
                    </thead>
                    <tbody>
                      {invoices &&
                        invoices?.map((invoice) => (
                          <tr key={invoice.id}>
                            <td style={{ marginRight: "-20px" }}>
                              {sliceLongString(invoice.buyerCompany, 25)}
                            </td>
                            {invoice.url ? (
                              <td>
                                <a
                                  href={invoice.url}
                                  target="_blank"
                                  rel="noopener"
                                >
                                  {invoice.invoiceNumber.toUpperCase()}
                                </a>
                              </td>
                            ) : (
                              <td>{invoice.invoiceNumber}</td>
                            )}
                            {/* <td>{invoice.invoiceNumber}</td> */}
                            <td>{invoice.invoiceDate}</td>
                            <td>{renderStatus("LISTED")}</td>
                            <td>{invoice.amount}</td>
                            <td>{`-`}</td>
                            <td>{`-`}</td>
                            <td>{`FINISHED`}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}
              </div>
              <div
                className={
                  toggleTabs === 2 ? "content  active-content" : "content"
                }
              >
                {companyInvoices && companyInvoices?.length === 0 ? (
                  <div style={{ textAlign: "center" }}>{t("dashboard.no")}</div>
                ) : (
                  <table>
                    <thead>
                      <th onClick={() => sorting("buyerCompany")}>
                        {t("dashboard.name")}
                      </th>
                      {/* <th onClick={() => sortNumbers("invoiceNumber")}>Invoice</th> */}
                      <th>{t("dashboard.inv")}</th>
                      <th onClick={() => sorting("invoiceDate")}>
                        {t("dashboard.date")}
                      </th>
                      <th onClick={() => sorting("status")}>
                        {t("dashboard.status")}
                      </th>
                      <th onClick={() => sortNumbers("amount")}>
                        {t("dashboard.amount")}
                      </th>
                      <th onClick={() => sortNumbers("bid")}>
                        {t("dashboard.bid")}
                      </th>
                      <th onClick={() => sortNumbers("savings")}>
                        {t("dashboard.savings")}
                      </th>
                      <th onClick={() => sorting("time")}>
                        {t("dashboard.time")}
                      </th>
                    </thead>
                    <tbody>
                      {companyInvoices &&
                        companyInvoices?.map((invoice) => (
                          <tr key={invoice.id}>
                            <td style={{ marginRight: "-20px" }}>
                              {sliceLongString(invoice.buyerCompany, 25)}
                            </td>
                            {invoice.url ? (
                              <td>
                                <a
                                  href={invoice.url}
                                  target="_blank"
                                  rel="noopener"
                                >
                                  {invoice.invoiceNumber.toUpperCase()}
                                </a>
                              </td>
                            ) : (
                              <td>{invoice.invoiceNumber}</td>
                            )}
                            {/* <td>{invoice.invoiceNumber}</td> */}
                            <td>{invoice.invoiceDate}</td>
                            <td>{renderStatus("LISTED")}</td>
                            <td>{invoice.amount}</td>
                            <td>{`-`}</td>
                            <td>{`-`}</td>
                            <td>{`FINISHED`}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}
              </div>
              <div
                className={
                  toggleTabs === 3 ? "content  active-content" : "content"
                }
              >
                {supplierInvoices && supplierInvoices?.length === 0 ? (
                  <div style={{ textAlign: "center" }}>{t("dashboard.no")}</div>
                ) : (
                  <table>
                    <thead>
                      <th onClick={() => sorting("buyerCompany")}>
                        {t("dashboard.name")}
                      </th>
                      {/* <th onClick={() => sortNumbers("invoiceNumber")}>Invoice</th> */}
                      <th>Invoice</th>
                      <th onClick={() => sorting("invoiceDate")}>
                        {t("dashboard.date")}
                      </th>
                      <th onClick={() => sorting("status")}>
                        {t("dashboard.status")}
                      </th>
                      <th onClick={() => sortNumbers("amount")}>
                        {t("dashboard.amount")}
                      </th>
                      <th onClick={() => sortNumbers("bid")}>
                        {t("dashboard.bid")}
                      </th>
                      <th onClick={() => sortNumbers("savings")}>
                        {t("dashboard.savings")}
                      </th>
                      <th onClick={() => sorting("time")}>
                        {t("dashboard.time")}
                      </th>
                    </thead>
                    <tbody>
                      {supplierInvoices &&
                        supplierInvoices?.map((invoice) => (
                          <tr key={invoice.id}>
                            <td style={{ marginRight: "-20px" }}>
                              {sliceLongString(invoice.buyerCompany, 25)}
                            </td>
                            {invoice.url ? (
                              <td>
                                <a
                                  href={invoice.url}
                                  target="_blank"
                                  rel="noopener"
                                >
                                  {invoice.invoiceNumber.toUpperCase()}
                                </a>
                              </td>
                            ) : (
                              <td>{invoice.invoiceNumber}</td>
                            )}
                            {/* <td>{invoice.invoiceNumber}</td> */}
                            <td>{invoice.invoiceDate}</td>
                            <td>{renderStatus("LISTED")}</td>
                            <td>{invoice.amount}</td>
                            <td>{`-`}</td>
                            <td>{`-`}</td>
                            <td>{`FINISHED`}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
}
