import React from 'react';
import TextImage from '../../atoms/TextImage/TextImage';
import styles from "./DashboardSection.module.scss";
import img from "../../../assets/images/companydash.png";
import { useTranslation } from 'react-i18next';

export default function DashboardSection() {

  const {t} = useTranslation();

  return (
    <div>
      <TextImage extraClass="dashboardSection" image={img}>
        <div className={styles.content}>
            <h3>{t('possibilities.title')} <br/>{t('possibilities.span')}</h3>
            <ul>
                <li>{t('possibilities.item1')}</li>
                <li>{t('possibilities.item3')}</li>
                <li>{t('possibilities.item3')}</li>
                <li>{t('possibilities.item4')}</li>
                <li>{t('possibilities.item5')}</li>
            </ul>
        </div>
      </TextImage>
    </div>
  )
}
