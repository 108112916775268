import React from 'react'
import Button from '../Button/Button'
import styles from "./TextImage.module.scss";

export default function TextImage({extraClass , image, clickEvent, ...rest}) {
  return (
    <div className={`${styles.container} ${styles[extraClass]}`}>
        <div className={styles.content}>
      <div className={styles.children}>
        {rest?.children}
      </div>
      <div className={styles.imageHolder}>
        <img src={image} />
      </div>
      </div>
      {rest?.button && (
        <Button onClick={clickEvent}  bgColor={rest?.bgColor} color={rest?.color}>{rest?.text}</Button>
      )}
    </div>
  )
}
