import React from "react";
import DashboardLineChart from "../../DashboardLineChart";
import "./SecondSection.scss";
import { ReactComponent as Options } from "../../../assets/icons/dots.svg";
const SecondSection = (props) => {
  const data = [
    {
      month: "January",
      profit: 1245,
      invested: 3562,
      attrib1: 1000,
      attrib2: 7000,
    },
    {
      month: "February",
      profit: 4526,
      invested: 6532,
      attrib1: 2000,
      attrib2: 6000,
    },
    {
      month: "March",
      profit: 4238,
      invested: 9854,
      attrib1: 3000,
      attrib2: 5000,
    },
    {
      month: "April",
      profit: 4263,
      invested: 7523,
      attrib1: 4000,
      attrib2: 4000,
    },
    {
      month: "May",
      profit: 3287,
      invested: 6320,
      attrib1: 5000,
      attrib2: 3000,
    },
    {
      month: "June",
      profit: 8633,
      invested: 9855,
      attrib1: 6000,
      attrib2: 2000,
    },
    {
      month: "July",
      profit: 5210,
      invested: 7100,
      attrib1: 7000,
      attrib2: 1000,
    },
  ];

  const totalProfit = data?.reduce((a, v) => (a = a + v.profit), 0);
  const totalInvested = data?.reduce((a, v) => (a = a + v.invested), 0);
  const totalAttrib1 = data?.reduce((a, v) => (a = a + v.attrib1), 0);
  const totalAttrib2 = data?.reduce((a, v) => (a = a + v.attrib2), 0);

  const handleColor = (toolTipColor, dataKey) => {
    props.setColor(toolTipColor);
    props.setDataKey(dataKey);
  };

  return (
    <div className="chartwrapper">
      <div className="optionsHolder">
        <p>TOTAL SPENT</p>
        <Options />
      </div>
      <div className="chartInfos">
        <div className="compContainer">
          <p>Current Month</p>
          <div className="components">
            <div className="individualComp">
              <div className="value">
                <span id="bullet1">&bull;</span>
                <p>{`$${totalInvested}`}</p>
              </div>
              <p>Invested</p>
            </div>
            <div className="individualComp">
              <div className="value">
                <span id="bullet2">&bull;</span>
                <p>{`$${totalProfit}`}</p>
              </div>
              <p>Profit</p>
            </div>
          </div>
        </div>
        <div className="compContainer">
          <p>Previous Month</p>
          <div className="components">
            <div className="individualComp">
              <div className="value">
                <span id="bullet3">&bull;</span>
                <p>{`$${totalAttrib1}`}</p>
              </div>
              <p>Invested</p>
            </div>
            <div className="individualComp">
              <div className="value">
                <span id="bullet4">&bull;</span>
                <p>{`$${totalAttrib2}`}</p>
              </div>
              <p>Profit</p>
            </div>
          </div>
        </div>
      </div>
      <DashboardLineChart data={data} handleColor={handleColor} />
    </div>
  );
};

export default SecondSection;
