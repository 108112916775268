/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import {
  Icon,
  Text,
  View,
  useBreakpointValue,
  Image,
} from "@aws-amplify/ui-react";
import "./media-queries.scss";
import Navbar from "./Navbar";
import Button from "./Button";
import dashboard from "../assets/images/Dashboardd.png";
import { Trans } from "react-i18next";
import { useNavigate } from "react-router-dom";
export default function Header(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const navigate = useNavigate();
  const variants = [
    {
      overrides: {
        Background: {},
        light6612377: {
          paths: [
            {
              d: "M619.668 309.834C619.668 480.951 480.951 619.668 309.834 619.668C138.717 619.668 0 480.951 0 309.834C0 138.717 138.717 0 309.834 0C480.951 0 619.668 138.717 619.668 309.834Z",
              fill: "rgba(97,139,232,0.2025)",
              fillRule: "nonzero",
            },
          ],
        },
        light6612378: {
          paths: [
            {
              d: "M273.732 136.866C273.732 212.455 212.455 273.732 136.866 273.732C61.2771 273.732 0 212.455 0 136.866C0 61.2771 61.2771 0 136.866 0C212.455 0 273.732 61.2771 273.732 136.866Z",
              fill: "rgba(97,139,232,0.2025)",
              fillRule: "nonzero",
            },
          ],
        },
        light6612379: {
          paths: [
            {
              d: "M509.727 294.507C509.727 457.159 395.621 589.014 254.864 589.014C114.106 589.014 0 457.159 0 294.507C0 131.855 114.106 0 254.864 0C395.621 0 509.727 131.855 509.727 294.507Z",
              fill: "rgba(97,139,232,0.09)",
              fillRule: "nonzero",
            },
          ],
        },
        BackgroundLights: {},
        subtitle: {},
        title: {},
        content: {},
        JoinWaitlist: {},
        ContactUs: {},
        Dashboard: {},
        Header: {},
      },
      variantValues: { breakpoint: "large" },
    },
    {
      overrides: {
        Background: { width: "100%", height: "612px", bottom: "0%" },
        light6612377: {
          width: "273.73px",
          height: "273.73px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 307.04736328125,
            height: 307.04736328125,
          },
          paths: [
            {
              d: "M273.732 136.866C273.732 212.455 212.455 273.732 136.866 273.732C61.2771 273.732 0 212.455 0 136.866C0 61.2771 61.2771 0 136.866 0C212.455 0 273.732 61.2771 273.732 136.866Z",
              fill: "rgba(97,139,232,0.45)",
              fillRule: "nonzero",
            },
          ],
          top: "298.65px",
          left: "calc(50% - 136.87px - 364.68px)",
        },
        light6612378: {
          width: "509.73px",
          height: "589.01px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 696.44873046875,
            height: 739.32421875,
          },
          paths: [
            {
              d: "M509.727 294.507C509.727 457.159 395.621 589.014 254.864 589.014C114.106 589.014 0 457.159 0 294.507C0 131.855 114.106 0 254.864 0C395.621 0 509.727 131.855 509.727 294.507Z",
              fill: "rgba(97,139,232,0.3)",
              fillRule: "nonzero",
            },
          ],
          top: "577.22px",
          left: "calc(50% - 254.86px - 513.68px)",
          transform: "rotate(-22.52deg)",
        },
        light6612379: {
          width: "619.67px",
          height: "619.67px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 695.0859375,
            height: 695.0859375,
          },
          paths: [
            {
              d: "M619.668 309.834C619.668 480.951 480.951 619.668 309.834 619.668C138.717 619.668 0 480.951 0 309.834C0 138.717 138.717 0 309.834 0C480.951 0 619.668 138.717 619.668 309.834Z",
              fill: "rgba(97,139,232,0.45)",
              fillRule: "nonzero",
            },
          ],
          top: "80.69px",
          left: "calc(50% - 309.83px - -383.29px)",
          transform: "rotate(-7.48deg)",
        },
        BackgroundLights: {
          width: "1537.09px",
          height: "1121.32px",
          top: "-189px",
          left: "-328px",
        },
        subtitle: {
          width: "328px",
          top: "177px",
          left: "calc(50% - 164px - 26px)",
          children:
            "Unlock the full potential of your outstanding receivables for new projects and financial freedom. \nFaster, more efficient and flexible \nthan ever before.",
        },
        title: {
          fontSize: "40px",
          lineHeight: "52px",
          width: "380px",
          left: "calc(50% - 190px - 0px)",
        },
        content: {
          width: "380px",
          height: "322px",
          top: "20px",
          left: "36px",
        },
        JoinWaitlist: {
          width: "187px",
          top: "476px",
          left: "calc(50% - 93.5px - 62.5px)",
        },
        ContactUs: {
          width: "185px",
          top: "476px",
          left: "calc(50% - 92.5px - 256.5px)",
        },
        Dashboard: {
          width: "507.3px",
          height: "345px",
          top: "119px",
          left: "346px",
        },
        Header: { width: "100%", height: "612px" },
      },
      variantValues: { breakpoint: "medium" },
    },
    {
      overrides: {
        Background: { width: "100%", height: "780px", bottom: "0%" },
        light6612377: {
          width: "273.73px",
          height: "273.73px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 307.04736328125,
            height: 307.046875,
          },
          paths: [
            {
              d: "M273.732 136.866C273.732 212.455 212.455 273.732 136.866 273.732C61.2771 273.732 0 212.455 0 136.866C0 61.2771 61.2771 0 136.866 0C212.455 0 273.732 61.2771 273.732 136.866Z",
              fill: "rgba(97,139,232,0.45)",
              fillRule: "nonzero",
            },
          ],
          top: "299.65px",
          left: "calc(50% - 136.87px - 212.73px)",
        },
        light6612378: {
          width: "345.18px",
          height: "345.18px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 387.185546875,
            height: 387.185546875,
          },
          paths: [
            {
              d: "M345.175 172.588C345.175 267.905 267.905 345.175 172.588 345.175C77.2701 345.175 0 267.905 0 172.588C0 77.2701 77.2701 0 172.588 0C267.905 0 345.175 77.2701 345.175 172.588Z",
              fill: "rgba(97,139,232,0.45)",
              fillRule: "nonzero",
            },
          ],
          top: "44.95px",
          left: "calc(50% - 172.59px - -134.99px)",
        },
        light6612379: {
          width: "332.53px",
          height: "384.26px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 454.34619140625,
            height: 482.3173828125,
          },
          paths: [
            {
              d: "M332.534 192.129C332.534 298.239 258.093 384.259 166.267 384.259C74.4402 384.259 0 298.239 0 192.129C0 86.0192 74.4402 0 166.267 0C258.093 0 332.534 86.0192 332.534 192.129Z",
              fill: "rgba(97,139,232,0.3)",
              fillRule: "nonzero",
            },
          ],
          top: "697.36px",
          left: "calc(50% - 166.27px - 177.33px)",
        },
        BackgroundLights: {
          width: "699.19px",
          height: "1052.32px",
          top: "-118px",
          left: "-185px",
        },
        subtitle: {
          fontSize: "16px",
          lineHeight: "22.399999618530273px",
          textAlign: "center",
          width: "290px",
          top: "372px",
          left: "calc(50% - 145px - 0px)",
          children:
            "Unlock the full potential of your outstanding receivables for new projects and financial freedom. \nFaster, more efficient and flexible \nthan ever before.",
        },
        title: {
          fontSize: "32px",
          lineHeight: "41.599998474121094px",
          textAlign: "center",
          width: "310px",
          left: "calc(50% - 155px - 0px)",
        },
        content: {
          width: "310px",
          height: "484px",
          top: "102px",
          left: "25px",
        },
        JoinWaitlist: {
          width: "320px",
          top: "682px",
          left: "calc(50% - 160px - 0px)",
        },
        ContactUs: {
          width: "320px",
          top: "619px",
          left: "calc(50% - 160px - 0px)",
        },
        Dashboard: {
          width: "369.08px",
          height: "251px",
          top: "223px",
          left: "-4.24px",
        },
        Header: { width: "100%", height: "780px" },
      },
      variantValues: { breakpoint: "small" },
    },
  ];

  const breakpointHook = useBreakpointValue({
    base: "small",
    small: "small",
    medium: "medium",
    large: "large",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <View
      className="headerContainer"
      width="1920px"
      height="884px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      {...rest}
      {...getOverrideProps(overrides, "Header")}
    >
      <div style={{ zIndex: "2" }}>
        <View
          width="1217.52px"
          height="828px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="29px"
          z-index="9999"
          left="847px"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          {...getOverrideProps(overrides, "Dashboard")}
        >
          {" "}
          <img
            className="dashboardimg"
            style={{ zIndex: "9999", width: "100%", maxWidth: "100%" }}
            src={dashboard}
          />{" "}
        </View>
      </div>
      <div className="bannerHolder">
        <View
          className="bannerHolder"
          padding="0px 0px 0px 0px"
          width="616px"
          height="369px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="188px"
          left="312px"
          {...getOverrideProps(overrides, "content")}
        >
          <div className="descriptionBanner">
            <View
              className="descriptionBanner"
              fontFamily="Lato"
              fontSize="18px"
              fontWeight="400"
              color="rgba(43,54,119,1)"
              lineHeight="28.80000114440918px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="526px"
              height="unset"
              gap="unset"
              alignItems="unset"
              position="absolute"
              top="282px"
              left="calc(50% - 263px - 45px)"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
            >
              <Trans i18nKey="banner.description">
                <Text
                  children="Unlock the full potential of your outstanding receivables for new projects and financial freedom. &#xA;Faster, more efficient and flexible than ever before."
                  {...getOverrideProps(overrides, "subtitle")}
                ></Text>
              </Trans>
            </View>
          </div>
          <View
            className="bannerTitle"
            fontFamily="Lato"
            fontSize="64px"
            fontWeight="800"
            color="rgba(43,54,119,1)"
            lineHeight="83.19999694824219px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="616px"
            height="unset"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="0px"
            left="calc(50% - 308px - 0px)"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
          >
            <Trans i18nKey="banner.title">
              <Text
                children="The marketplace for your liquidity and financial freedom."
                {...getOverrideProps(overrides, "title")}
              ></Text>
            </Trans>
          </View>
        </View>
      </div>
      <View
        display="flex"
        gap="8px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        position="absolute"
        top="576px"
        left="280px"
        borderRadius="8px"
        padding="13px 32px 13px 32px"
        property1="L"
        property2="dark"
        {...getOverrideProps(overrides, "JoinWaitlist")}
      >
        <Button
          bgColor="rgba(43,54,119,1)"
          onClick={() => navigate("/company-signup")}
        >
          <Trans i18nKey="signup.titlesign1">Register as a company</Trans>
        </Button>
      </View>
      <View
        display="flex"
        gap="8px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        position="absolute"
        top="576px"
        left="560px"
        borderRadius="8px"
        padding="13px 32px 13px 32px"
        property1="L"
        property2="dark"
        {...getOverrideProps(overrides, "JoinWaitlist")}
      >
        <Button
          bgColor="rgba(43,54,119,1)"
          onClick={() => navigate("/investor-signup")}
        >
          <Trans i18nKey="signup.titlesign2">Register as an Investor</Trans>
        </Button>
      </View>
      {/* <View
        display="flex"
        gap="8px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        position="absolute"
        top="576px"
        left="calc(48% - 70px - 578px)"
        borderRadius="8px"
        padding="13px 32px 13px 32px"
        property1="L"
        property2="light"
        {...getOverrideProps(overrides, "ContactUs")}
      >
        {/* <Button onClick={() => navigate("/investor-signup")} bgColor="#618BE8">
         <Trans i18nKey="banner.btn1">
          Contact us
        </Trans>
          </Button> */}
      {/* </View> */}
    </View>
  );
}

// /***************************************************************************
//  * The contents of this file were generated with Amplify Studio.           *
//  * Please refrain from making any modifications to this file.              *
//  * Any changes to this file will be overwritten when running amplify pull. *
//  **************************************************************************/

// /* eslint-disable */
// import * as React from "react";
// import { getOverrideProps } from "@aws-amplify/ui-react/internal";
// import Navbar from "./Navbar";
// import { View } from "@aws-amplify/ui-react";
// export default function Header(props) {
//   const { overrides, ...rest } = props;
//   return (
//     <View
//       width="1923px"
//       height="884px"
//       display="block"
//       gap="unset"
//       alignItems="unset"
//       justifyContent="unset"
//       position="relative"
//       padding="0px 0px 0px 0px"
//       {...rest}
//       {...getOverrideProps(overrides, "Header")}
//     >
//       <HeaderBackground
//         width="1923px"
//         height="884px"
//         display="block"
//         gap="unset"
//         alignItems="unset"
//         justifyContent="unset"
//         overflow="hidden"
//         position="absolute"
//         top="0%"
//         bottom="0%"
//         left="0%"
//         right="0%"
//         padding="0px 0px 0px 0px"
//         {...getOverrideProps(overrides, "HeaderBackground")}
//       ></HeaderBackground>
//       <HeaderHeadline
//         width="616px"
//         height="440px"
//         display="block"
//         gap="unset"
//         alignItems="unset"
//         justifyContent="unset"
//         position="absolute"
//         top="21.27%"
//         bottom="28.96%"
//         left="16.28%"
//         right="51.69%"
//         padding="0px 0px 0px 0px"
//         {...getOverrideProps(overrides, "HeaderHeadline")}
//       ></HeaderHeadline>
//       <Navbar
//         width="1296px"
//         height="45px"
//         display="block"
//         gap="unset"
//         alignItems="unset"
//         justifyContent="unset"
//         overflow="hidden"
//         position="absolute"
//         top="2.26%"
//         bottom="93.67%"
//         left="16.38%"
//         right="16.22%"
//         padding="0px 0px 0px 0px"
//         {...getOverrideProps(overrides, "Navbar")}
//       ></Navbar>
//     </View>
//   );
// }
