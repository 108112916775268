import React, {useState, useEffect} from 'react';
import { ReactComponent as Upload } from "../../../assets/icons/upload.svg";
import {useDropzone} from 'react-dropzone';
import { useTranslation } from 'react-i18next';

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: "-50px !important"
  };
  
  const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 0,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
  };
  
  const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  };
  
  const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
  };

export default function Drag(props) {
    const { callback , clickEvent, files, setFiles} = props;
    // const [files, setFiles] = useState([]);
    const {getRootProps, getInputProps} = useDropzone({
      accept: {
        'application/pdf': ['.pdf']
      },
      onDrop: acceptedFiles => {
        setFiles(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));
        console.clear();
        console.log("UPLOADED MAIN FILE:" , acceptedFiles[0].name); // logs the file name to the console
    },
    
      maxFiles: 1
    });
    const { t } = useTranslation();
    const thumbs = files.map(file => (
      <div style={thumb} key={file.name}>
        <div style={thumbInner}>
          <iframe
          className='pdfframe'
            src={file.preview}
            style={img}
            // Revoke data uri after image is loaded
            onLoad={() => { URL.revokeObjectURL(file.preview) }}
          />
        </div>
      </div>
    ));
  
    useEffect(() => {
      // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
      return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, []);

    function handleFileUpload(files) {
      if (files.length === 0) {
        console.log("cannot continue")
      } else {
      }
    }
  return (
<>
    <section className="containerPdf">
      <div {...getRootProps({className: 'dropzone'})}>
        <input {...getInputProps()} />
        <p className='dragdesc'> <Upload /> {t('invoice.title')}</p>
        <div className='dashes'></div>
      </div>
      <aside style={thumbsContainer}>
        {thumbs}
      </aside>
   
</section>
</>
  )
}