import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import "./InvestorSignup.scss";
import Input from "../Input";
import Button from "../Button";
import { useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { ReactComponent as Arrow } from "../../assets/icons/leftarrow.svg";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import { ReactComponent as Eye } from "../../assets/icons/eye.svg";
import { ReactComponent as EyeClosed } from "../../assets/icons/eyeclosed.svg";
import { API, Auth } from "aws-amplify";
import { createInvestor } from "../../graphql/mutations";
import LoginPopup from "../Login/Login";

const InvestorSignup = () => {
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };
  const { t, i18n } = useTranslation();
  const [passwordShown, setPasswordShown] = useState(false);
  const [isVerifyCode, setIsVerifyCode] = React.useState(false);
  const [isSignupSuccessful, setIsSignupSuccessful] = React.useState(false);
  const [username, setUsername] = useState("");
  const [userExists, setUserExists] = useState("");
  const [submitted, isSubmitted] = useState(false);
  const [popup, setPopup] = useState(false);

  console.log("username------------", username);
  console.log("isVerifyCode------------", isVerifyCode);

  const togglePassword = (e) => {
    e.preventDefault();
    setPasswordShown(!passwordShown);
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      password: "",
      confirmPassword: "",
      code: "",
    },
    onSubmit: async (values) => {
      if (isVerifyCode) {
        console.log(`Values: ${values} Code: ${values.code}`);
        try {
          await Auth.confirmSignUp(username, values.code);
          const entry = {
            ...values,
            language: i18n.language,
          };
          delete entry["password"];
          delete entry["confirmPassword"];
          delete entry["code"];

          await API.graphql({
            query: createInvestor,
            variables: { input: entry },
            authMode: "AWS_IAM",
          });
          console.log("Saved Successfully!");

          navigate("/success");

          console.log("sign up confirmed!");
          setIsSignupSuccessful(true);
        } catch (error) {
          console.log("error confirming sign up", error);
          formik.setFieldError("code", t("signup.codeIncorrect"));
        }
      } else {
        //Submit form
        console.log(`Values: ${values} Email: ${values.email}`);
        const username = values.email;
        const password = values.password;
        try {
          const { user } = await Auth.signUp({
            username: username,
            password: password,
            attributes: {
              locale: i18n.language + "-investor",
            },
          });
          console.log(user);
          setUsername(username);
          setIsVerifyCode(true);
        } catch (error) {
          if (error.name === "UsernameExistsException") {
            setUserExists(
              "The email address entered already exists in out database - try logging in or entering a different email address."
            );
          }
          console.log("error signing up:", error);
          // setUserExists(`${error.message}`);
          formik.setSubmitting(false);
        }
      }
    },
    validate: (values) => {
      const errors = {};
      if (submitted) {
        if (!values.email) {
          errors.email = t("signup.emailRequired");
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
        ) {
          errors.email = t("signup.emailInvalid");
        }

        if (!values.firstName.trim()) {
          errors.firstName = t("signup.firstnameRequired");
        }
        if (!values.lastName.trim()) {
          errors.lastName = t("signup.lastnameRequired");
        }

        if (!values?.phone || !/^\+?\d{9,}$/.test(values?.phone)) {
          errors.phone = "Please enter a valid phone number";
        }
        const passwordRegex = /^.{6,}$/;
        if (!passwordRegex.test(values.password)) {
          errors.password = t("signup.passwordInvalid");
        }

        if (!values.confirmPassword) {
          errors.confirmPassword = t("signup.confirmPasswordRequired");
        }

        if (values.password !== values.confirmPassword) {
          errors.confirmPassword = t("signup.passwordConfirm");
        }
      }
      return errors;
    },
  });
  const navigate = useNavigate();
  const handleVerificationCode = (e) => {
    formik.setFieldValue("code", e.target.value.replaceAll(" ", ""));
  };

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        document.getElementById("loginBtnnn").click();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);
  return (
    <div className="signupInvestor">
      <div className="inputsPart">
        {!isVerifyCode &&
        <div className="navSignup">
          <Logo />
          <ul>
            <li onClick={() => changeLanguage("de")}>DE</li>
            <li>|</li>
            <li onClick={() => changeLanguage("en")}>EN</li>
          </ul>
        </div>
        }
        <button className="goHome" onClick={() => navigate("/")}>
          <Arrow /> Back to Main Page
        </button>
        {!isVerifyCode &&
        <h1>
          <Trans i18nKey="signup.investortitle"> </Trans>
          <br />
          <Trans i18nKey="signup.investorspan"> </Trans>
        </h1>
        }
        {isSignupSuccessful ? (
          <h3>Signup Successful</h3>
        ) : (
          <form onSubmit={formik.handleSubmit}>
            {!isVerifyCode && (
              <>
                <Input
                  placeholder={t("signup.firstname")}
                  extraClass="fullWidth"
                  label={t("signup.firstname")}
                  type="text"
                  name="firstName"
                  onChange={formik.handleChange}
                  value={formik.values.firstName}
                />
                {formik.errors.firstName && (
                  <div className="error">
                    <Trans i18nKey="signup.firstnameRequired">
                      {formik.errors.firstName}
                    </Trans>
                  </div>
                )}
                <br />
                <Input
                  placeholder={t("signup.lastname")}
                  extraClass="fullWidth"
                  label={t("signup.lastname")}
                  type="text"
                  name="lastName"
                  onChange={formik.handleChange}
                  value={formik.values.lastName}
                />
                {formik.errors.lastName && (
                  <div className="error">
                    <Trans i18nKey="signup.lastnameRequired">
                      {formik.errors.lastName}
                    </Trans>
                  </div>
                )}
                <br />
                <Input
                  placeholder={t("signup.email")}
                  extraClass="fullWidth"
                  label={t("signup.email")}
                  type="email"
                  name="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
                {formik.errors.email && (
                  <div className="error">
                    <Trans i18nKey="signup.emailRequired">
                      {formik.errors.email}
                    </Trans>
                  </div>
                )}
                <br />
                <Input
                  placeholder="Phone"
                  extraClass="fullWidth"
                  label="Phone"
                  type="text"
                  name="phone"
                  onChange={formik.handleChange}
                  value={formik.values.phone}
                />
                {formik.errors.phone && (
                  <div className="error">
                    <Trans i18nKey="signup.phoneRequired">
                      {formik.errors.phone}
                    </Trans>
                  </div>
                )}
                <br />
                <div className="investorInput">
                  <Input
                    placeholder={t("signup.password")}
                    extraClass="fullWidth"
                    label={t("signup.password")}
                    type={passwordShown ? "text" : "password"}
                    name="password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                  />
                  {formik.errors.password && (
                    <div className="error">
                      <Trans i18nKey="signup.passwordRequired">
                        {formik.errors.password}
                      </Trans>
                    </div>
                  )}
                  <button className="eyebtn" onClick={(e) => togglePassword(e)} tabIndex="-1">
                    {passwordShown ? <Eye /> : <EyeClosed />}
                  </button>
                  <br />
                </div>
                <div className="investorInput">
                  <Input
                    placeholder={t("signup.confirm")}
                    extraClass="fullWidth"
                    label={t("signup.confirm")}
                    type={passwordShown ? "text" : "password"}
                    name="confirmPassword"
                    onChange={formik.handleChange}
                    value={formik.values.confirmPassword}
                  />
                  <button className="eyebtn" onClick={(e) => togglePassword(e)}>
                    {passwordShown ? <Eye /> : <EyeClosed />}
                  </button>
                  {formik.errors.confirmPassword && (
                    <div className="error">
                      <Trans i18nKey="signup.passwordConfirm">
                        {formik.errors.confirmPassword}
                      </Trans>
                    </div>
                  )}
                  {userExists && <div className="error">{userExists}</div>}
                </div>
              </>
            )}
            <br />
            {isVerifyCode && (
              <div className="verifyInvestor">
              <div>
                <Input
                  title={t("signup.verify")}
                  placeholder={t("login.verify")}
                  extraClass="fullWidth"
                  label={t("login.verify")}
                  type="code"
                  name="code"
                  // onChange={formik.handleChange}
                  onChange={(e) => handleVerificationCode(e)}
                  value={formik.values.code}
                />
                {formik.errors.code && (
                  <div className="error">{formik.errors.code}</div>
                )}
                <br />
              </div>
              </div>
            )}
            <Button
              type="submit"
              bgColor="#2B3677"
              color="white"
              id="loginBtnnn"
              onClick={() => isSubmitted(true)}
            >
              {!isVerifyCode ?  `${t("companytabs.title1")}` :  `${t("login.verifybtn")}`}
            </Button>
            <p className="alreadyacc">{t("placeholder.acc")} <a onClick={() => setPopup(true)}>{t("placeholder.btn")}</a> </p>
          </form>
        )}
      </div>
      <div className="backgroundSignup"></div>
      {popup && (
        <div className="loginn">
          <div onClick={() => setPopup(false)} className="shades"></div>
          <LoginPopup to="/investor-signup" onClick={() => setPopup(false)} />
        </div>
      )}
    </div>
  );
};

export default InvestorSignup;
