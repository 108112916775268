import React from 'react'; 
import FactoryPage from '../organisms/FactoryPage';

export default function Investor() {
  return (
    <div>
      <FactoryPage />
    </div>
  )
}
