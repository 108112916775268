/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createNewsletter = /* GraphQL */ `
  mutation CreateNewsletter(
    $input: CreateNewsletterInput!
    $condition: ModelNewsletterConditionInput
  ) {
    createNewsletter(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
    }
  }
`;
export const updateNewsletter = /* GraphQL */ `
  mutation UpdateNewsletter(
    $input: UpdateNewsletterInput!
    $condition: ModelNewsletterConditionInput
  ) {
    updateNewsletter(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
    }
  }
`;
export const deleteNewsletter = /* GraphQL */ `
  mutation DeleteNewsletter(
    $input: DeleteNewsletterInput!
    $condition: ModelNewsletterConditionInput
  ) {
    deleteNewsletter(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
    }
  }
`;
export const createRequestConsultation = /* GraphQL */ `
  mutation CreateRequestConsultation(
    $input: CreateRequestConsultationInput!
    $condition: ModelRequestConsultationConditionInput
  ) {
    createRequestConsultation(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
    }
  }
`;
export const updateRequestConsultation = /* GraphQL */ `
  mutation UpdateRequestConsultation(
    $input: UpdateRequestConsultationInput!
    $condition: ModelRequestConsultationConditionInput
  ) {
    updateRequestConsultation(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
    }
  }
`;
export const deleteRequestConsultation = /* GraphQL */ `
  mutation DeleteRequestConsultation(
    $input: DeleteRequestConsultationInput!
    $condition: ModelRequestConsultationConditionInput
  ) {
    deleteRequestConsultation(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
    }
  }
`;
export const createCompanyBankAccount = /* GraphQL */ `
  mutation CreateCompanyBankAccount(
    $input: CreateCompanyBankAccountInput!
    $condition: ModelCompanyBankAccountConditionInput
  ) {
    createCompanyBankAccount(input: $input, condition: $condition) {
      id
      companyName
      IBAN
      ust
      tax
      firstName
      lastName
      bic
      companyAddress
      gender
      birthDate
      address
      companyAddress2
      companyZip
      companyCity
      address2
      zip
      city
      email
      createdAt
      updatedAt
    }
  }
`;
export const updateCompanyBankAccount = /* GraphQL */ `
  mutation UpdateCompanyBankAccount(
    $input: UpdateCompanyBankAccountInput!
    $condition: ModelCompanyBankAccountConditionInput
  ) {
    updateCompanyBankAccount(input: $input, condition: $condition) {
      id
      companyName
      IBAN
      ust
      tax
      firstName
      lastName
      bic
      companyAddress
      gender
      birthDate
      address
      companyAddress2
      companyZip
      companyCity
      address2
      zip
      city
      email
      createdAt
      updatedAt
    }
  }
`;
export const deleteCompanyBankAccount = /* GraphQL */ `
  mutation DeleteCompanyBankAccount(
    $input: DeleteCompanyBankAccountInput!
    $condition: ModelCompanyBankAccountConditionInput
  ) {
    deleteCompanyBankAccount(input: $input, condition: $condition) {
      id
      companyName
      IBAN
      ust
      tax
      firstName
      lastName
      bic
      companyAddress
      gender
      birthDate
      address
      companyAddress2
      companyZip
      companyCity
      address2
      zip
      city
      email
      createdAt
      updatedAt
    }
  }
`;
export const createInvoiceRecipient = /* GraphQL */ `
  mutation CreateInvoiceRecipient(
    $input: CreateInvoiceRecipientInput!
    $condition: ModelInvoiceRecipientConditionInput
  ) {
    createInvoiceRecipient(input: $input, condition: $condition) {
      id
      company
      country
      address
      address2
      zip
      city
      ustid
      firstName
      lastName
      contact
      mail
      phone
      recipient
      business
      client
      revenue
      delayed
      average
      email
      createdAt
      updatedAt
    }
  }
`;
export const updateInvoiceRecipient = /* GraphQL */ `
  mutation UpdateInvoiceRecipient(
    $input: UpdateInvoiceRecipientInput!
    $condition: ModelInvoiceRecipientConditionInput
  ) {
    updateInvoiceRecipient(input: $input, condition: $condition) {
      id
      company
      country
      address
      address2
      zip
      city
      ustid
      firstName
      lastName
      contact
      mail
      phone
      recipient
      business
      client
      revenue
      delayed
      average
      email
      createdAt
      updatedAt
    }
  }
`;
export const deleteInvoiceRecipient = /* GraphQL */ `
  mutation DeleteInvoiceRecipient(
    $input: DeleteInvoiceRecipientInput!
    $condition: ModelInvoiceRecipientConditionInput
  ) {
    deleteInvoiceRecipient(input: $input, condition: $condition) {
      id
      company
      country
      address
      address2
      zip
      city
      ustid
      firstName
      lastName
      contact
      mail
      phone
      recipient
      business
      client
      revenue
      delayed
      average
      email
      createdAt
      updatedAt
    }
  }
`;
export const createInvoiceSummary = /* GraphQL */ `
  mutation CreateInvoiceSummary(
    $input: CreateInvoiceSummaryInput!
    $condition: ModelInvoiceSummaryConditionInput
  ) {
    createInvoiceSummary(input: $input, condition: $condition) {
      id
      invoiceNumber
      buyerCompany
      invoiceDate
      paymentDate
      amount
      email
      doc
      invoiceType
      status
      days
      percent
      monthlyRepayment
      monthlyInterest
      monthlyRate
      createdAt
      updatedAt
    }
  }
`;
export const updateInvoiceSummary = /* GraphQL */ `
  mutation UpdateInvoiceSummary(
    $input: UpdateInvoiceSummaryInput!
    $condition: ModelInvoiceSummaryConditionInput
  ) {
    updateInvoiceSummary(input: $input, condition: $condition) {
      id
      invoiceNumber
      buyerCompany
      invoiceDate
      paymentDate
      amount
      email
      doc
      invoiceType
      status
      days
      percent
      monthlyRepayment
      monthlyInterest
      monthlyRate
      createdAt
      updatedAt
    }
  }
`;
export const deleteInvoiceSummary = /* GraphQL */ `
  mutation DeleteInvoiceSummary(
    $input: DeleteInvoiceSummaryInput!
    $condition: ModelInvoiceSummaryConditionInput
  ) {
    deleteInvoiceSummary(input: $input, condition: $condition) {
      id
      invoiceNumber
      buyerCompany
      invoiceDate
      paymentDate
      amount
      email
      doc
      invoiceType
      status
      days
      percent
      monthlyRepayment
      monthlyInterest
      monthlyRate
      createdAt
      updatedAt
    }
  }
`;
export const createWaitlist = /* GraphQL */ `
  mutation CreateWaitlist(
    $input: CreateWaitlistInput!
    $condition: ModelWaitlistConditionInput
  ) {
    createWaitlist(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      phoneNumber
      jobTitle
      companyName
      country
      businessType
      anyInfo
      language
      createdAt
      updatedAt
    }
  }
`;
export const updateWaitlist = /* GraphQL */ `
  mutation UpdateWaitlist(
    $input: UpdateWaitlistInput!
    $condition: ModelWaitlistConditionInput
  ) {
    updateWaitlist(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      phoneNumber
      jobTitle
      companyName
      country
      businessType
      anyInfo
      language
      createdAt
      updatedAt
    }
  }
`;
export const deleteWaitlist = /* GraphQL */ `
  mutation DeleteWaitlist(
    $input: DeleteWaitlistInput!
    $condition: ModelWaitlistConditionInput
  ) {
    deleteWaitlist(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      phoneNumber
      jobTitle
      companyName
      country
      businessType
      anyInfo
      language
      createdAt
      updatedAt
    }
  }
`;
export const createInvestor = /* GraphQL */ `
  mutation CreateInvestor(
    $input: CreateInvestorInput!
    $condition: ModelInvestorConditionInput
  ) {
    createInvestor(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      language
      phone
      country
      state
      city
      zip
      address
      address2
      createdAt
      updatedAt
    }
  }
`;
export const updateInvestor = /* GraphQL */ `
  mutation UpdateInvestor(
    $input: UpdateInvestorInput!
    $condition: ModelInvestorConditionInput
  ) {
    updateInvestor(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      language
      phone
      country
      state
      city
      zip
      address
      address2
      createdAt
      updatedAt
    }
  }
`;
export const deleteInvestor = /* GraphQL */ `
  mutation DeleteInvestor(
    $input: DeleteInvestorInput!
    $condition: ModelInvestorConditionInput
  ) {
    deleteInvestor(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      language
      phone
      country
      state
      city
      zip
      address
      address2
      createdAt
      updatedAt
    }
  }
`;
export const createInvoice = /* GraphQL */ `
  mutation CreateInvoice(
    $input: CreateInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    createInvoice(input: $input, condition: $condition) {
      id
      buyerName
      buyerIban
      buyerEmail
      brs
      sector
      revenue
      delayedPayment
      eai
      suppName
      suppLastName
      suppEmail
      invoiceNumber
      invoiceDate
      paymentDate
      amount
      discount
      doc
      createdAt
      updatedAt
    }
  }
`;
export const updateInvoice = /* GraphQL */ `
  mutation UpdateInvoice(
    $input: UpdateInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    updateInvoice(input: $input, condition: $condition) {
      id
      buyerName
      buyerIban
      buyerEmail
      brs
      sector
      revenue
      delayedPayment
      eai
      suppName
      suppLastName
      suppEmail
      invoiceNumber
      invoiceDate
      paymentDate
      amount
      discount
      doc
      createdAt
      updatedAt
    }
  }
`;
export const deleteInvoice = /* GraphQL */ `
  mutation DeleteInvoice(
    $input: DeleteInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    deleteInvoice(input: $input, condition: $condition) {
      id
      buyerName
      buyerIban
      buyerEmail
      brs
      sector
      revenue
      delayedPayment
      eai
      suppName
      suppLastName
      suppEmail
      invoiceNumber
      invoiceDate
      paymentDate
      amount
      discount
      doc
      createdAt
      updatedAt
    }
  }
`;
