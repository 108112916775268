import React from "react";
import styles from "./PaymentList.module.scss";
const SingleGroup = (props) => {
  return (
    <div className={styles.listHolder}>
      <div className={styles.container}>
        <h3>{props.title}</h3>
        <div className={styles.list}>
          <ul>
            {props?.list?.map((item, i) => {
              return <li key={i}>{item.value}</li>;
            })}
          </ul>
          {props.options && (
            <ul>
              {props.optionsList.map((item, i) => {
                return (
                  <li key={i}>
                    {item.title}
                    {props.extra ? i === 0 && ` days` : ""}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default SingleGroup;
