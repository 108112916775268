import React from 'react';
import styles from "./NumberCard.module.scss";
import { useTranslation } from 'react-i18next';

export default function NumberCard({...rest}) {
  const {t} = useTranslation();
  return (
    <div className={styles.container}>
      {rest?.number && (
      <div className={styles.number}>
        <h1>{rest?.number}</h1>
      </div>
      )}
      {rest?.circle && (
        <div className={styles.wrapper}>
        <div className={styles.circle}>
          <span></span>
          </div>
          </div>
      )}
      <h3 className={styles.title}>{t(rest?.title)}</h3>
      <p className={styles.desc}>{t(rest?.desc)}</p>
    </div>
  )
}
