/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Icon, Text, View } from "@aws-amplify/ui-react";
import { ReactComponent as Arrow } from '../assets/icons/rightarrow.svg';
import Button from './Button';
import { Trans } from "react-i18next";
export default function ButtonCall(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      className="faqcall"
      gap="8px"
      direction="column"
      width="196px"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      border="1px SOLID rgba(226,229,241,1)"
      borderRadius="8px"
      padding="23px 23px 23px 23px"
      {...rest}
      {...getOverrideProps(overrides, "ButtonCall")}
    >
      <Flex
        gap="16px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "title")}
      >
        <View
          width="28px"
          height="28px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          overflow="hidden"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "icon")}
        >
          <View
            padding="0px 0px 0px 0px"
            width="28px"
            height="28px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0%"
            bottom="0%"
            left="0%"
            right="0%"
            {...getOverrideProps(overrides, "Group7342508")}
          >
            <View
              padding="0px 0px 0px 0px"
              width="28px"
              height="28px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="0%"
              bottom="0%"
              left="0%"
              right="0%"
              {...getOverrideProps(overrides, "Group7342509")}
            >
              <Icon
                width="25.31px"
                height="25.31px"
                viewBox={{
                  minX: 0,
                  minY: 0,
                  width: 25.31103515625,
                  height: 25.3125,
                }}
                paths={[
                  {
                    d: "M21.2871 15.8343C20.3724 14.9312 19.2304 14.9312 18.3214 15.8343C17.6281 16.5219 16.9347 17.2094 16.253 17.9086C16.0666 18.1008 15.9092 18.1416 15.682 18.0134C15.2334 17.7687 14.7556 17.5706 14.3244 17.3026C12.3143 16.0383 10.6304 14.4127 9.13881 12.5831C8.39885 11.6742 7.74045 10.7012 7.28015 9.60577C7.18693 9.38436 7.20441 9.23869 7.38503 9.05807C8.07839 8.38802 8.75426 7.70049 9.43597 7.01296C10.3857 6.05741 10.3857 4.93871 9.43014 3.97734C8.88827 3.42964 8.34641 2.8936 7.80454 2.34591C7.24519 1.78656 6.69167 1.22139 6.1265 0.667868C5.21173 -0.223591 4.06973 -0.223591 3.16079 0.673694C2.46161 1.36123 1.79156 2.06623 1.08072 2.74211C0.422322 3.36555 0.09021 4.12883 0.0202916 5.02029C-0.0904125 6.47109 0.265006 7.84033 0.766088 9.1746C1.79156 11.9364 3.35307 14.3893 5.24669 16.6384C7.80454 19.6798 10.8576 22.0862 14.4293 23.8225C16.0374 24.6033 17.7038 25.2034 19.5159 25.3024C20.7627 25.3724 21.8465 25.0577 22.7146 24.0847C23.3089 23.4205 23.979 22.8145 24.6083 22.1794C25.5405 21.2355 25.5463 20.0935 24.6199 19.1613C23.5129 18.0484 22.4 16.9414 21.2871 15.8343Z",
                    fill: "rgba(43,54,119,1)",
                    fillRule: "nonzero",
                  },
                ]}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="9.6%"
                bottom="0%"
                left="0%"
                right="9.6%"
                {...getOverrideProps(overrides, "Vector7342510")}
              ></Icon>
              <Icon
                width="8.47px"
                height="8.29px"
                viewBox={{
                  minX: 0,
                  minY: 0,
                  width: 8.4658203125,
                  height: 8.28515625,
                }}
                paths={[
                  {
                    d: "M6.31596 8.28533L8.46595 7.91826C8.12801 5.94306 7.19577 4.15432 5.77992 2.73264C4.2825 1.23522 2.38888 0.291327 0.302979 0L0 2.16164C1.61395 2.38888 3.08224 3.11719 4.24171 4.27667C5.3371 5.37206 6.05377 6.75878 6.31596 8.28533Z",
                    fill: "rgba(43,54,119,1)",
                    fillRule: "nonzero",
                  },
                ]}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="19.98%"
                bottom="50.43%"
                left="49.5%"
                right="20.27%"
                {...getOverrideProps(overrides, "Vector7342511")}
              ></Icon>
              <Icon
                width="13.86px"
                height="13.51px"
                viewBox={{
                  minX: 0,
                  minY: 0,
                  width: 13.85546875,
                  height: 13.505859375,
                }}
                paths={[
                  {
                    d: "M9.39237 4.53304C6.91027 2.05094 3.76977 0.483602 0.30298 0L0 2.16164C2.99484 2.58115 5.71 3.93874 7.85417 6.07707C9.88763 8.11053 11.2219 10.68 11.7055 13.5059L13.8555 13.1388C13.2903 9.86432 11.7463 6.89279 9.39237 4.53304Z",
                    fill: "rgba(43,54,119,1)",
                    fillRule: "nonzero",
                  },
                ]}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0%"
                bottom="51.76%"
                left="50.51%"
                right="0%"
                {...getOverrideProps(overrides, "Vector7342512")}
              ></Icon>
            </View>
          </View>
        </View>
        <Text
          fontFamily="Lato"
          fontSize="14px"
          fontWeight="400"
          color="rgba(86,89,115,1)"
          lineHeight="22.399999618530273px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Still have  questions?"
          {...getOverrideProps(overrides, "txt")}
        ><Trans i18nKey="faq.phone"></Trans></Text>
        <a href="tel:+491621739274" style={{ color: "#2B3677" }} className="faqlink">Contact Us <Arrow /> </a>
      </Flex>
      <Flex
        gap="8px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        borderRadius="6px"
        padding="11px 0px 11px 0px"
        {...getOverrideProps(overrides, "btn")}
      ></Flex>
    </Flex>
  );
}
