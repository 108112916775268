import React from "react";
import "./MultiplePdf.scss";
import PicturesWall from "./PicturesWall";

class MultiplePdf extends React.Component {
  render() {
    function getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
      });
    }

    return (
      <div className="MainDiv">
        <div className="jumbotron text-center pt-5"></div>

        <div className="container">
          <PicturesWall props={this.props} getBase64={getBase64} />
        </div>
      </div>
    );
  }
}

export default MultiplePdf;
