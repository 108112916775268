import "./App.css";
import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";
import "@aws-amplify/ui-react/styles.css";
import "react-toastify/dist/ReactToastify.css";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Waitlist from "./pages/Waitlist";
// import NoPage from "./pages/NoPage";
import ThankYou from "./components/ThankYou";
import NoPage from "./ui/pages/404";
import Imprint from "./components/Imprint";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CompanyDashboard from "./components/CompanyDashboard/CompanyDashboard";
import UploadInvoice from "./components/CompanyDashboard/UploadInvoice/UploadInvoice";
import InvestorDashboard from "./components/InvestorDashboard/InvestorDashboard";
import SupplierUploadInvoice from "./components/CompanyDashboard/SupplierUploadInvoice/UploadInvoice";
import Profile from "./components/Profile/Profile";
import Investor from "./ui/pages/Investor";
import Factory from "./ui/pages/Factory";
import InvestorSignup from "./components/InvestorSignup/InvestorSignup";
import { Authenticator } from "@aws-amplify/ui-react";
import "react-datepicker/dist/react-datepicker.css";
// import Jobs from "./components/Jobs";
import ForCompanies from "./components/ForCompanies";
import { RequireAuth } from "./RequireAuth";
import Company from "./ui/pages/Company";
import Team from "./ui/pages/Team";
import Jobs from "./ui/pages/Jobs";
import AdminPanel from "./components/AdminPanel/AdminPanel";
import InvestorMarketplace from "./components/InvestorMarketplace/InvestorMarketplace";
import InvestorProfile from "./ui/pages/InvestorProfile";
import PrivacyPolicy from "./ui/pages/PrivacyPolicy";
import Terms from "./ui/pages/Terms";

Amplify.configure(awsconfig);

function App() {
  return (
    <>
      {/* <GoogleTagManager /> */}
      <Authenticator.Provider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index path="/" element={<Investor />} />
              <Route
                index
                path="/admin"
                element={
                  <RequireAuth role="admin">
                    <AdminPanel />
                  </RequireAuth>
                }
              />
              <Route index path="/invest" element={<Factory />} />
              <Route index path="/company" element={<Company />} />
              <Route index path="/company-v1" element={<Home />} />
              <Route index path="/team" element={<Team />} />
              <Route
                index
                path="/investor"
                element={
                  <RequireAuth role="investor">
                    <InvestorProfile />{" "}
                  </RequireAuth>
                }
              />
              <Route
                index
                path="/investor-signup"
                element={<InvestorSignup />}
              />
              <Route
                index
                path="/investor-dashboard"
                element={
                  <RequireAuth role="investor">
                    <InvestorDashboard />
                  </RequireAuth>
                }
              />
              <Route
                index
                path="/marketplace"
                element={
                  <RequireAuth role="investor">
                    <InvestorMarketplace />
                  </RequireAuth>
                }
              />
              <Route path="/company-signup" element={<Waitlist />} />
              <Route
                path="/profile"
                element={
                  <RequireAuth role="company">
                    <Profile />
                  </RequireAuth>
                }
              />
              <Route path="/jobs" element={<Jobs />} />
              {/* <Route path="*" element={<NoPage />} /> */}
              <Route path="/success" element={<ThankYou />} />
              <Route path="/for-companies" element={<ForCompanies />} />
              <Route path="/imprint" element={<Imprint />} />
              <Route path="/factoring" element={<Home />} />
              <Route path="/*" element={<NoPage />} />
              <Route path="/terms-conditions" element={<Terms />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route
                path="/company-dashboard"
                element={
                  <RequireAuth role="company">
                    <CompanyDashboard />
                  </RequireAuth>
                }
              />
              <Route
                path="/upload-invoice"
                element={
                  <RequireAuth role="company">
                    <UploadInvoice />
                  </RequireAuth>
                }
              />
            </Route>
            <Route
              path="/supplier-upload-invoice"
              element={
                <RequireAuth role="company">
                  <SupplierUploadInvoice />
                </RequireAuth>
              }
            />
          </Routes>
        </BrowserRouter>
      </Authenticator.Provider>
    </>
  );
}

export default App;
