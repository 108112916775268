/* eslint-disable */

/* Added checks as svg inside the view and change btn */

import * as React from "react";
import { ReactComponent as Check } from "../assets/icons/check.svg";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import {
  Flex,
  Icon,
  Text,
  View,
  useBreakpointValue,
} from "@aws-amplify/ui-react";
import Button from "./Button";
import { useNavigate } from "react-router-dom";
import { Trans } from "react-i18next";
export default function Possibilites(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const navigate = useNavigate();
  const variants = [
    {
      overrides: {
        826612523: {},
        826612527: {},
        826612544: {},
        826612548: {},
        826612565: {},
        826612569: {},
        826612586: {},
        826612590: {},
        826612607: {},
        826612611: {},
        826612628: {},
        826612632: {},
        826612652: {},
        826612654: {},
        bg6612509: {},
        "Card-box6612511": {},
        "Border-top6612514": {},
        "$6,518.186612517": {},
        Amount6612516: {},
        "$6,518.186612519": {},
        Amount6612518: {},
        "$6,518.186612521": {},
        Amount6612520: {},
        Quantity6612522: {},
        BG6612525: {},
        Quantity6612526: {},
        "Frame 26612524": {},
        "$79.496612529": {},
        Price6612528: {},
        "ASOS Ridley High Waist6612531": {},
        Products6612530: {},
        "icons / bx-link-external6612532": {},
        "icons / bx-link6612533": {},
        "Products-16612515": {},
        "Group 48095449": {},
        "Border-top6612535": {},
        "$6,518.186612538": {},
        Amount6612537: {},
        "$6,518.186612540": {},
        Amount6612539: {},
        "$6,518.186612542": {},
        Amount6612541: {},
        Quantity6612543: {},
        BG6612546: {},
        Quantity6612547: {},
        "Frame 26612545": {},
        "$79.496612550": {},
        Price6612549: {},
        "ASOS Ridley High Waist6612552": {},
        Products6612551: {},
        "icons / bx-link-external6612553": {},
        "icons / bx-link6612554": {},
        "Products-16612536": {},
        "Group 48095450": {},
        "Border-top6612556": {},
        "$6,518.186612559": {},
        Amount6612558: {},
        "$6,518.186612561": {},
        Amount6612560: {},
        "$6,518.186612563": {},
        Amount6612562: {},
        Quantity6612564: {},
        BG6612567: {},
        Quantity6612568: {},
        "Frame 26612566": {},
        "$79.496612571": {},
        Price6612570: {},
        "ASOS Ridley High Waist6612573": {},
        Products6612572: {},
        "icons / bx-link-external6612574": {},
        "icons / bx-link6612575": {},
        "Products-16612557": {},
        "Group 48095451": {},
        "Border-top6612577": {},
        "$6,518.186612580": {},
        Amount6612579: {},
        "$6,518.186612582": {},
        Amount6612581: {},
        "$6,518.186612584": {},
        Amount6612583: {},
        Quantity6612585: {},
        BG6612588: {},
        Quantity6612589: {},
        "Frame 26612587": {},
        "$79.496612592": {},
        Price6612591: {},
        "ASOS Ridley High Waist6612594": {},
        Products6612593: {},
        "icons / bx-link-external6612595": {},
        "icons / bx-link6612596": {},
        "Products-16612578": {},
        "Group 48095452": {},
        "Border-top6612598": {},
        "$6,518.186612601": {},
        Amount6612600: {},
        "$6,518.186612603": {},
        Amount6612602: {},
        "$6,518.186612605": {},
        Amount6612604: {},
        Quantity6612606: {},
        BG6612609: {},
        Quantity6612610: {},
        "Frame 26612608": {},
        "$79.496612613": {},
        Price6612612: {},
        "ASOS Ridley High Waist6612615": {},
        Products6612614: {},
        "icons / bx-link-external6612616": {},
        "icons / bx-link6612617": {},
        "Products-16612599": {},
        "Group 48095453": {},
        "Border-top6612619": {},
        "$6,518.186612622": {},
        Amount6612621: {},
        "$6,518.186612624": {},
        Amount6612623: {},
        "$6,518.186612626": {},
        Amount6612625: {},
        Quantity6612627: {},
        BG6612630: {},
        Quantity6612631: {},
        "Frame 26612629": {},
        "$79.496612634": {},
        Price6612633: {},
        "ASOS Ridley High Waist6612636": {},
        Products6612635: {},
        "icons / bx-link-external6612637": {},
        "icons / bx-link6612638": {},
        "Products-16612620": {},
        "Group 48095454": {},
        "Frame 3": {},
        "Top Selling Products6612639": {},
        "$6,518.186612644": {},
        Amount6612643: {},
        "$6,518.186612646": {},
        Amount6612645: {},
        "$6,518.186612648": {},
        Amount6612647: {},
        "$6,518.186612650": {},
        Amount6612649: {},
        Quantity6612651: {},
        Quantity6612653: {},
        "$79.496612656": {},
        Price6612655: {},
        "ASOS Ridley High Waist6612658": {},
        Products6612657: {},
        "Products-16612642": {},
        Header: {},
        "Frame 48095386": {},
        "Top Selling Products6612510": {},
        "Card-box6612660": {},
        "14 hours ago6612662": {},
        "Paul Burgess just purchased \u201CH6612663": {},
        "You sold an item6612664": {},
        border6612665: {},
        bg6612667: {
          paths: [
            {
              d: "M0 8.99471C0 4.02707 4.02707 0 8.99471 0C13.9624 0 17.9894 4.02707 17.9894 8.99471C17.9894 13.9624 13.9624 17.9894 8.99471 17.9894C4.02707 17.9894 0 13.9624 0 8.99471Z",
              fill: "rgba(68,186,220,0.063)",
              fillRule: "evenodd",
            },
          ],
        },
        Background6612669: {},
        "Shape path6612671": {},
        "Shape path6612672": {},
        Shape6612670: {},
        upload6612668: {},
        Icon6612666: {},
        "You sold an item6612661": {},
        "14 hours ago6612674": {},
        'Uploaded a photo "Error.jpg"': {},
        "Audrey Tobey6612676": {},
        border6612677: {},
        bg6612679: {
          paths: [
            {
              d: "M0 8.99471C0 4.02707 4.02707 0 8.99471 0C13.9624 0 17.9894 4.02707 17.9894 8.99471C17.9894 13.9624 13.9624 17.9894 8.99471 17.9894C4.02707 17.9894 0 13.9624 0 8.99471Z",
              fill: "rgba(54,136,252,0.063)",
              fillRule: "evenodd",
            },
          ],
        },
        Background6612681: {},
        "Shape path6612683": {},
        "Shape path6612684": {},
        Shape6612682: {},
        upload6612680: {},
        Icon6612678: {},
        "Audrey Tobey6612673": {},
        "2 hours ago": {},
        'Send you message "Are you ther': {},
        "Robert Delaney6612688": {},
        border6612689: {},
        bg6612691: {
          paths: [
            {
              d: "M0 8.99471C0 4.02707 4.02707 0 8.99471 0C13.9624 0 17.9894 4.02707 17.9894 8.99471C17.9894 13.9624 13.9624 17.9894 8.99471 17.9894C4.02707 17.9894 0 13.9624 0 8.99471Z",
              fill: "rgba(68,186,220,0.063)",
              fillRule: "evenodd",
            },
          ],
        },
        Background6612693: {},
        "Shape path6612695": {},
        "Shape path6612696": {},
        Shape6612694: {},
        microphone: {},
        Icon6612690: {},
        "Robert Delaney6612685": {},
        "30 minutes ago": {},
        "Dave Gamache added Admin Dashb": {},
        "Product on the Bootstrap Marke": {},
        border6612701: {},
        bg6612703: {
          paths: [
            {
              d: "M0 8.99471C0 4.02707 4.02707 0 8.99471 0C13.9624 0 17.9894 4.02707 17.9894 8.99471C17.9894 13.9624 13.9624 17.9894 8.99471 17.9894C4.02707 17.9894 0 13.9624 0 8.99471Z",
              fill: "rgba(54,136,252,0.063)",
              fillRule: "evenodd",
            },
          ],
        },
        Background6612705: {},
        Shape6612706: {},
        airplane: {},
        Icon6612702: {},
        "Product on the Bootstrap Market": {},
        "5 minutes ago": {},
        "Paul Burgess just purchased \u201CH6612709": {},
        "You sold an item6612710": {},
        border6612711: {},
        bg6612713: {
          paths: [
            {
              d: "M0 8.99471C0 4.02707 4.02707 0 8.99471 0C13.9624 0 17.9894 4.02707 17.9894 8.99471C17.9894 13.9624 13.9624 17.9894 8.99471 17.9894C4.02707 17.9894 0 13.9624 0 8.99471Z",
              fill: "rgba(68,186,220,0.063)",
              fillRule: "evenodd",
            },
          ],
        },
        Background6612715: {},
        "Shape path6612717": {},
        "Shape path6612718": {},
        Shape6612716: {},
        upload6612714: {},
        Icon6612712: {},
        "You sold an item6612707": {},
        Background6612720: {},
        "Shape path6612722": {},
        "Shape path6612723": {},
        "Shape path6612724": {},
        Shape6612721: {},
        "dots-vertical Copy": {},
        "Recent Activity6612725": {},
        "Shape path6612727": {},
        "Shape path6612728": {},
        "Shape path6612729": {},
        Shape6612726: {},
        "Recent Activity6612659": {},
        "icons / bx-check-circle6612734": {},
        "Purchase open receivables from companies without recourse": {},
        line6612733: {},
        "icons / bx-check-circle6612737": {},
        "Get credit information of companies": {},
        line6612736: {},
        "icons / bx-check-circle6612740": {},
        "Monitor your investmens with full transparency": {},
        line6612739: {},
        "icons / bx-check-circle6612743": {},
        "Regulatory compliance and assurance": {},
        line6612742: {},
        "icons / bx-check-circle6612746": {},
        "Access to a new attractive asset-class": {},
        line6612745: {},
        list: {},
        "Get possibilities for better Investments": {},
        text: {},
        Content: {},
        JoinWaitlist: {},
        Possibilites: {},
      },
      variantValues: { breakpoint: "large" },
    },
    {
      overrides: {
        826612523: {
          fontSize: "6.312079429626465px",
          lineHeight: "8.56639289855957px",
        },
        826612527: {
          fontSize: "6.312079429626465px",
          lineHeight: "8.56639289855957px",
        },
        826612544: {
          fontSize: "6.312079429626465px",
          lineHeight: "8.56639289855957px",
        },
        826612548: {
          fontSize: "6.312079429626465px",
          lineHeight: "8.56639289855957px",
        },
        826612565: {
          fontSize: "6.312079429626465px",
          lineHeight: "8.56639289855957px",
        },
        826612569: {
          fontSize: "6.312079429626465px",
          lineHeight: "8.56639289855957px",
        },
        826612586: {
          fontSize: "6.312079429626465px",
          lineHeight: "8.56639289855957px",
        },
        826612590: {
          fontSize: "6.312079429626465px",
          lineHeight: "8.56639289855957px",
        },
        826612607: {
          fontSize: "6.312079429626465px",
          lineHeight: "8.56639289855957px",
        },
        826612611: {
          fontSize: "6.312079429626465px",
          lineHeight: "8.56639289855957px",
        },
        826612628: {
          fontSize: "6.312079429626465px",
          lineHeight: "8.56639289855957px",
        },
        826612632: {
          fontSize: "6.312079429626465px",
          lineHeight: "8.56639289855957px",
        },
        826612652: {
          fontSize: "4.508627891540527px",
          lineHeight: "8.56639289855957px",
        },
        826612654: {
          fontSize: "4.508627891540527px",
          lineHeight: "8.56639289855957px",
        },
        bg6612509: { width: "100%", height: "899px", right: "-0.13%" },
        "Card-box6612511": {
          width: "351.67px",
          height: "219.57px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 351.6728515625,
            height: 219.5703125,
          },
          paths: [
            {
              d: "M0 12.9877C0 5.81477 5.81478 0 12.9877 0L338.685 0C345.858 0 351.673 5.81478 351.673 12.9877L351.673 206.583C351.673 213.755 345.858 219.57 338.685 219.57L12.9877 219.57C5.81478 219.57 0 213.755 0 206.583L0 12.9877Z",
              fill: "rgba(255,255,255,1)",
              fillRule: "evenodd",
            },
          ],
          left: "calc(50% - 175.84px - 0px)",
        },
        "Border-top6612514": {
          width: "330.03px",
          height: "0.45px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 330.03173828125,
            height: 0.45068359375,
          },
          paths: [
            {
              d: "M0 0L330.032 0L330.032 0.450863L0 0.450863L0 0Z",
              fill: "rgba(238,242,247,1)",
              fillRule: "evenodd",
            },
          ],
        },
        "$6,518.186612517": {
          fontSize: "6.312079429626465px",
          lineHeight: "8.56639289855957px",
        },
        Amount6612516: { width: "29px", height: "9px", left: "182.6px" },
        "$6,518.186612519": {
          fontSize: "6.312079429626465px",
          lineHeight: "8.56639289855957px",
        },
        Amount6612518: { width: "29px", height: "9px", left: "224.08px" },
        "$6,518.186612521": {
          fontSize: "6.312079429626465px",
          lineHeight: "8.56639289855957px",
        },
        Amount6612520: { width: "14px", height: "9px", left: "265.56px" },
        Quantity6612522: { width: "33px", height: "9px", left: "102.8px" },
        BG6612525: {
          width: "24.8px",
          height: "13.98px",
          top: "-2.7px",
          left: "-1.35px",
        },
        Quantity6612526: { width: "14px", height: "9px", left: "4.51px" },
        "Frame 26612524": {
          width: "22.09px",
          height: "8.57px",
          left: "147.88px",
          borderRadius: "2.2543139457702637px",
        },
        "$79.496612529": {
          fontSize: "6.312079429626465px",
          lineHeight: "8.56639289855957px",
        },
        Price6612528: { width: "30px", height: "9px", left: "57.71px" },
        "ASOS Ridley High Waist6612531": {
          fontSize: "6.312079429626465px",
          lineHeight: "8.56639289855957px",
        },
        Products6612530: { width: "40px", height: "18px" },
        "icons / bx-link-external6612532": {
          width: "7.1px",
          height: "7.1px",
          top: "0.9px",
          left: "303.99px",
        },
        "icons / bx-link6612533": {
          width: "7.1px",
          height: "7.1px",
          top: "0.9px",
          left: "292.16px",
        },
        "Products-16612515": {
          width: "311.09px",
          height: "18px",
          top: "7.67px",
        },
        "Group 48095449": { width: "330.03px", height: "25.67px" },
        "Border-top6612535": {
          width: "330.03px",
          height: "0.45px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 330.03173828125,
            height: 0.45068359375,
          },
          paths: [
            {
              d: "M0 0L330.032 0L330.032 0.450863L0 0.450863L0 0Z",
              fill: "rgba(238,242,247,1)",
              fillRule: "evenodd",
            },
          ],
        },
        "$6,518.186612538": {
          fontSize: "6.312079429626465px",
          lineHeight: "8.56639289855957px",
        },
        Amount6612537: { width: "29px", height: "9px", left: "182.6px" },
        "$6,518.186612540": {
          fontSize: "6.312079429626465px",
          lineHeight: "8.56639289855957px",
        },
        Amount6612539: { width: "29px", height: "9px", left: "224.08px" },
        "$6,518.186612542": {
          fontSize: "6.312079429626465px",
          lineHeight: "8.56639289855957px",
        },
        Amount6612541: { width: "14px", height: "9px", left: "265.56px" },
        Quantity6612543: { width: "33px", height: "9px", left: "102.8px" },
        BG6612546: {
          width: "24.8px",
          height: "13.98px",
          top: "-2.7px",
          left: "-1.35px",
        },
        Quantity6612547: { width: "18px", height: "9px", left: "2.71px" },
        "Frame 26612545": {
          width: "22.09px",
          height: "8.57px",
          left: "147.88px",
          borderRadius: "2.2543139457702637px",
        },
        "$79.496612550": {
          fontSize: "6.312079429626465px",
          lineHeight: "8.56639289855957px",
        },
        Price6612549: { width: "30px", height: "9px", left: "57.71px" },
        "ASOS Ridley High Waist6612552": {
          fontSize: "6.312079429626465px",
          lineHeight: "8.56639289855957px",
        },
        Products6612551: { width: "49px", height: "18px" },
        "icons / bx-link-external6612553": {
          width: "7.1px",
          height: "7.1px",
          top: "0.9px",
          left: "303.99px",
        },
        "icons / bx-link6612554": {
          width: "7.1px",
          height: "7.1px",
          top: "0.9px",
          left: "292.16px",
        },
        "Products-16612536": {
          width: "311.09px",
          height: "18px",
          top: "7.66px",
        },
        "Group 48095450": { width: "330.03px", height: "25.66px" },
        "Border-top6612556": {
          width: "330.03px",
          height: "0.45px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 330.03173828125,
            height: 0.45068359375,
          },
          paths: [
            {
              d: "M0 0L330.032 0L330.032 0.450863L0 0.450863L0 0Z",
              fill: "rgba(238,242,247,1)",
              fillRule: "evenodd",
            },
          ],
        },
        "$6,518.186612559": {
          fontSize: "6.312079429626465px",
          lineHeight: "8.56639289855957px",
        },
        Amount6612558: { width: "29px", height: "9px", left: "182.6px" },
        "$6,518.186612561": {
          fontSize: "6.312079429626465px",
          lineHeight: "8.56639289855957px",
        },
        Amount6612560: { width: "29px", height: "9px", left: "224.08px" },
        "$6,518.186612563": {
          fontSize: "6.312079429626465px",
          lineHeight: "8.56639289855957px",
        },
        Amount6612562: { width: "14px", height: "9px", left: "265.56px" },
        Quantity6612564: { width: "33px", height: "9px", left: "102.8px" },
        BG6612567: {
          width: "24.8px",
          height: "13.98px",
          top: "-2.71px",
          left: "-1.35px",
        },
        Quantity6612568: { width: "15px", height: "9px", left: "4.51px" },
        "Frame 26612566": {
          width: "22.09px",
          height: "8.57px",
          left: "147.88px",
          borderRadius: "2.2543139457702637px",
        },
        "$79.496612571": {
          fontSize: "6.312079429626465px",
          lineHeight: "8.56639289855957px",
        },
        Price6612570: { width: "30px", height: "9px", left: "57.71px" },
        "ASOS Ridley High Waist6612573": {
          fontSize: "6.312079429626465px",
          lineHeight: "8.56639289855957px",
        },
        Products6612572: { width: "40px", height: "18px" },
        "icons / bx-link-external6612574": {
          width: "7.1px",
          height: "7.1px",
          top: "0.9px",
          left: "303.99px",
        },
        "icons / bx-link6612575": {
          width: "7.1px",
          height: "7.1px",
          top: "0.9px",
          left: "292.16px",
        },
        "Products-16612557": {
          width: "311.09px",
          height: "18px",
          top: "7.66px",
        },
        "Group 48095451": { width: "330.03px", height: "25.66px" },
        "Border-top6612577": {
          width: "330.03px",
          height: "0.45px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 330.03173828125,
            height: 0.45068359375,
          },
          paths: [
            {
              d: "M0 0L330.032 0L330.032 0.450863L0 0.450863L0 0Z",
              fill: "rgba(238,242,247,1)",
              fillRule: "evenodd",
            },
          ],
        },
        "$6,518.186612580": {
          fontSize: "6.312079429626465px",
          lineHeight: "8.56639289855957px",
        },
        Amount6612579: { width: "29px", height: "9px", left: "182.6px" },
        "$6,518.186612582": {
          fontSize: "6.312079429626465px",
          lineHeight: "8.56639289855957px",
        },
        Amount6612581: { width: "29px", height: "9px", left: "224.08px" },
        "$6,518.186612584": {
          fontSize: "6.312079429626465px",
          lineHeight: "8.56639289855957px",
        },
        Amount6612583: { width: "14px", height: "9px", left: "265.56px" },
        Quantity6612585: { width: "33px", height: "9px", left: "102.8px" },
        BG6612588: {
          width: "24.8px",
          height: "13.98px",
          top: "-2.7px",
          left: "-1.35px",
        },
        Quantity6612589: { width: "15px", height: "9px", left: "4.51px" },
        "Frame 26612587": {
          width: "22.09px",
          height: "8.57px",
          left: "147.88px",
          borderRadius: "2.2543139457702637px",
        },
        "$79.496612592": {
          fontSize: "6.312079429626465px",
          lineHeight: "8.56639289855957px",
        },
        Price6612591: { width: "30px", height: "9px", left: "57.71px" },
        "ASOS Ridley High Waist6612594": {
          fontSize: "6.312079429626465px",
          lineHeight: "8.56639289855957px",
        },
        Products6612593: { width: "40px", height: "18px" },
        "icons / bx-link-external6612595": {
          width: "7.1px",
          height: "7.1px",
          top: "0.9px",
          left: "303.99px",
        },
        "icons / bx-link6612596": {
          width: "7.1px",
          height: "7.1px",
          top: "0.9px",
          left: "292.16px",
        },
        "Products-16612578": {
          width: "311.09px",
          height: "18px",
          top: "7.67px",
        },
        "Group 48095452": { width: "330.03px", height: "25.67px" },
        "Border-top6612598": {
          width: "330.03px",
          height: "0.45px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 330.03173828125,
            height: 0.45068359375,
          },
          paths: [
            {
              d: "M0 0L330.032 0L330.032 0.450863L0 0.450863L0 0Z",
              fill: "rgba(238,242,247,1)",
              fillRule: "evenodd",
            },
          ],
        },
        "$6,518.186612601": {
          fontSize: "6.312079429626465px",
          lineHeight: "8.56639289855957px",
        },
        Amount6612600: { width: "29px", height: "9px", left: "182.6px" },
        "$6,518.186612603": {
          fontSize: "6.312079429626465px",
          lineHeight: "8.56639289855957px",
        },
        Amount6612602: { width: "29px", height: "9px", left: "224.08px" },
        "$6,518.186612605": {
          fontSize: "6.312079429626465px",
          lineHeight: "8.56639289855957px",
        },
        Amount6612604: { width: "14px", height: "9px", left: "265.56px" },
        Quantity6612606: { width: "33px", height: "9px", left: "102.8px" },
        BG6612609: {
          width: "24.8px",
          height: "13.98px",
          top: "-2.7px",
          left: "-1.35px",
        },
        Quantity6612610: { width: "15px", height: "9px", left: "4.51px" },
        "Frame 26612608": {
          width: "22.09px",
          height: "8.57px",
          left: "147.88px",
          borderRadius: "2.2543139457702637px",
        },
        "$79.496612613": {
          fontSize: "6.312079429626465px",
          lineHeight: "8.56639289855957px",
        },
        Price6612612: { width: "30px", height: "9px", left: "57.71px" },
        "ASOS Ridley High Waist6612615": {
          fontSize: "6.312079429626465px",
          lineHeight: "8.56639289855957px",
        },
        Products6612614: { width: "40px", height: "18px" },
        "icons / bx-link-external6612616": {
          width: "7.1px",
          height: "7.1px",
          top: "0.9px",
          left: "304px",
        },
        "icons / bx-link6612617": {
          width: "7.1px",
          height: "7.1px",
          top: "0.9px",
          left: "292.16px",
        },
        "Products-16612599": {
          width: "311.1px",
          height: "18px",
          top: "7.67px",
        },
        "Group 48095453": { width: "330.03px", height: "25.67px" },
        "Border-top6612619": {
          width: "330.03px",
          height: "0.45px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 330.03173828125,
            height: 0.45068359375,
          },
          paths: [
            {
              d: "M0 0L330.032 0L330.032 0.450863L0 0.450863L0 0Z",
              fill: "rgba(238,242,247,1)",
              fillRule: "evenodd",
            },
          ],
        },
        "$6,518.186612622": {
          fontSize: "6.312079429626465px",
          lineHeight: "8.56639289855957px",
        },
        Amount6612621: { width: "29px", height: "9px", left: "182.6px" },
        "$6,518.186612624": {
          fontSize: "6.312079429626465px",
          lineHeight: "8.56639289855957px",
        },
        Amount6612623: { width: "29px", height: "9px", left: "224.08px" },
        "$6,518.186612626": {
          fontSize: "6.312079429626465px",
          lineHeight: "8.56639289855957px",
        },
        Amount6612625: { width: "14px", height: "9px", left: "265.56px" },
        Quantity6612627: { width: "33px", height: "9px", left: "102.8px" },
        BG6612630: {
          width: "24.8px",
          height: "13.98px",
          top: "-2.7px",
          left: "-1.35px",
        },
        Quantity6612631: { width: "15px", height: "9px", left: "4.51px" },
        "Frame 26612629": {
          width: "22.09px",
          height: "8.57px",
          left: "147.88px",
          borderRadius: "2.2543139457702637px",
        },
        "$79.496612634": {
          fontSize: "6.312079429626465px",
          lineHeight: "8.56639289855957px",
        },
        Price6612633: { width: "30px", height: "9px", left: "57.71px" },
        "ASOS Ridley High Waist6612636": {
          fontSize: "6.312079429626465px",
          lineHeight: "8.56639289855957px",
        },
        Products6612635: { width: "40px", height: "18px" },
        "icons / bx-link-external6612637": {
          width: "7.1px",
          height: "7.1px",
          top: "0.9px",
          left: "304px",
        },
        "icons / bx-link6612638": {
          width: "7.1px",
          height: "7.1px",
          top: "0.9px",
          left: "292.16px",
        },
        "Products-16612620": {
          width: "311.1px",
          height: "18px",
          top: "7.67px",
        },
        "Group 48095454": { width: "330.03px", height: "25.67px" },
        "Frame 3": {
          gap: "6.312079429626465px",
          width: "324.62px",
          height: "171.33px",
          top: "35.62px",
          left: "calc(50% - 162.31px - 2.71px)",
          borderRadius: "12.987665176391602px",
        },
        "Top Selling Products6612639": {
          fontSize: "6.492424488067627px",
          lineHeight: "9.017255783081055px",
          top: "9.21px",
          left: "calc(50% - 17px - 148.02px)",
        },
        "$6,518.186612644": {
          fontSize: "4.508627891540527px",
          lineHeight: "8.56639289855957px",
        },
        Amount6612643: { width: "26px", height: "9px", left: "186.66px" },
        "$6,518.186612646": {
          fontSize: "4.508627891540527px",
          lineHeight: "8.56639289855957px",
        },
        Amount6612645: { width: "34px", height: "9px", left: "224.53px" },
        "$6,518.186612648": {
          fontSize: "4.508627891540527px",
          lineHeight: "8.56639289855957px",
        },
        Amount6612647: { width: "14px", height: "9px", left: "267.81px" },
        "$6,518.186612650": {
          fontSize: "4.508627891540527px",
          lineHeight: "8.56639289855957px",
        },
        Amount6612649: { width: "25px", height: "9px", left: "289.91px" },
        Quantity6612651: { width: "27px", height: "9px", left: "107.76px" },
        Quantity6612653: { width: "21px", height: "9px", left: "151.04px" },
        "$79.496612656": {
          fontSize: "4.508627891540527px",
          lineHeight: "8.56639289855957px",
        },
        Price6612655: { width: "31px", height: "9px", left: "59.96px" },
        "ASOS Ridley High Waist6612658": {
          fontSize: "4.508627891540527px",
          lineHeight: "8.56639289855957px",
        },
        Products6612657: { width: "18px", height: "9px" },
        "Products-16612642": { width: "314.91px", height: "9px" },
        Header: { width: "314.91px", height: "9px", top: "5.86px" },
        "Frame 48095386": {
          width: "340.85px",
          height: "14.43px",
          top: "19.84px",
          left: "calc(50% - 170.43px - -5.41px)",
          borderRadius: "12.987665176391602px",
        },
        "Top Selling Products6612510": {
          width: "351.67px",
          height: "219.57px",
          top: "397px",
          left: "180px",
        },
        "Card-box6612660": {
          width: "199.01px",
          height: "256.4px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 199.0126953125,
            height: 256.39990234375,
          },
          paths: [
            {
              d: "M0 15.1662C0 6.79012 6.79012 0 15.1662 0L183.847 0C192.223 0 199.013 6.79012 199.013 15.1662L199.013 241.234C199.013 249.61 192.223 256.4 183.847 256.4L15.1662 256.4C6.79013 256.4 0 249.61 0 241.234L0 15.1662Z",
              fill: "rgba(255,255,255,1)",
              fillRule: "evenodd",
            },
          ],
          left: "calc(50% - 99.51px - 0px)",
        },
        "14 hours ago6612662": {
          fontSize: "6.317864418029785px",
          lineHeight: "8.423819541931152px",
          top: "31.59px",
          left: "calc(50% - 17px - 38.1px)",
        },
        "Paul Burgess just purchased \u201CH6612663": {
          fontSize: "6.317864418029785px",
          lineHeight: "8.423819541931152px",
          top: "13.69px",
          left: "calc(50% - 63px - -7.9px)",
        },
        "You sold an item6612664": {
          fontSize: "7.581437587738037px",
          lineHeight: "10.52977466583252px",
          left: "calc(50% - 49.5px - 5.6px)",
        },
        border6612665: {
          width: "1.05px",
          height: "31.59px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 1.052734375,
            height: 31.58935546875,
          },
          paths: [
            {
              d: "M0 0.52649C0 0.235718 0.235717 0 0.526489 0L0.526489 0C0.81726 0 1.05298 0.235717 1.05298 0.526489L1.05298 31.0628C1.05298 31.3536 0.81726 31.5893 0.526489 31.5893L0.526489 31.5893C0.235717 31.5893 0 31.3536 0 31.0628L0 0.52649Z",
              fill: "rgba(241,243,250,1)",
              fillRule: "evenodd",
            },
          ],
          top: "10.56px",
          left: "calc(50% - 0.53px - 65.63px)",
        },
        bg6612667: {
          width: "10.53px",
          height: "10.53px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 10.52978515625,
            height: 10.52978515625,
          },
          paths: [
            {
              d: "M0 5.26489C0 2.35717 2.35717 0 5.26489 0C8.1726 0 10.5298 2.35717 10.5298 5.26489C10.5298 8.1726 8.1726 10.5298 5.26489 10.5298C2.35717 10.5298 0 8.1726 0 5.26489Z",
              fill: "rgba(68,186,220,0.251)",
              fillRule: "evenodd",
            },
          ],
          left: "calc(50% - 5.26px - 0px)",
        },
        Background6612669: {
          width: "6.32px",
          height: "6.32px",
          left: "calc(50% - 3.16px - 0px)",
          borderRadius: "15.16616439819336px",
        },
        "Shape path6612671": {
          width: "3.69px",
          height: "3.42px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 3.685546875,
            height: 3.42236328125,
          },
          top: "-0.32px",
          left: "calc(50% - 1.84px - 1.32px)",
        },
        "Shape path6612672": {
          width: "3.69px",
          height: "0.53px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 3.685546875,
            height: 0.5263671875,
          },
          top: "3.63px",
          left: "calc(50% - 1.84px - 1.32px)",
        },
        Shape6612670: {
          width: "3.69px",
          height: "4.16px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 3.685546875,
            height: 4.15869140625,
          },
          top: "1.11px",
          left: "1.32px",
        },
        upload6612668: {
          width: "6.32px",
          height: "6.32px",
          top: "2.1px",
          left: "2.11px",
        },
        Icon6612666: { width: "10.53px", height: "10.53px", top: "0.03px" },
        "You sold an item6612661": {
          width: "141.79px",
          height: "42.15px",
          top: "201.62px",
          left: "12.64px",
        },
        "14 hours ago6612674": {
          fontSize: "6.317864418029785px",
          lineHeight: "8.423819541931152px",
          top: "22.11px",
          left: "calc(50% - 23.5px - 47.6px)",
        },
        'Uploaded a photo "Error.jpg"': {
          fontSize: "6.317864418029785px",
          lineHeight: "8.423819541931152px",
          top: "13.69px",
          left: "calc(50% - 79px - -7.9px)",
        },
        "Audrey Tobey6612676": {
          fontSize: "7.581437587738037px",
          lineHeight: "10.52977466583252px",
          left: "calc(50% - 70px - 1.1px)",
        },
        border6612677: {
          width: "1.05px",
          height: "27.9px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 1.052734375,
            height: 27.90380859375,
          },
          paths: [
            {
              d: "M0 0.526488C0 0.235717 0.235717 0 0.526489 0L0.526489 0C0.81726 0 1.05298 0.235717 1.05298 0.526489L1.05298 27.3774C1.05298 27.6682 0.81726 27.9039 0.526489 27.9039L0.526489 27.9039C0.235717 27.9039 0 27.6682 0 27.3774L0 0.526488Z",
              fill: "rgba(241,243,250,1)",
              fillRule: "evenodd",
            },
          ],
          top: "10.56px",
          left: "calc(50% - 0.53px - 81.63px)",
        },
        bg6612679: {
          width: "10.53px",
          height: "10.53px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 10.52978515625,
            height: 10.52978515625,
          },
          paths: [
            {
              d: "M0 5.26489C0 2.35717 2.35717 0 5.26489 0C8.1726 0 10.5298 2.35717 10.5298 5.26489C10.5298 8.1726 8.1726 10.5298 5.26489 10.5298C2.35717 10.5298 0 8.1726 0 5.26489Z",
              fill: "rgba(54,136,252,0.251)",
              fillRule: "evenodd",
            },
          ],
          left: "calc(50% - 5.26px - 0px)",
        },
        Background6612681: {
          width: "6.32px",
          height: "6.32px",
          left: "calc(50% - 3.16px - 0px)",
          borderRadius: "15.16616439819336px",
        },
        "Shape path6612683": {
          width: "3.69px",
          height: "3.42px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 3.685546875,
            height: 3.42236328125,
          },
          top: "-0.32px",
          left: "calc(50% - 1.84px - 1.32px)",
        },
        "Shape path6612684": {
          width: "3.69px",
          height: "0.53px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 3.685546875,
            height: 0.5263671875,
          },
          top: "3.63px",
          left: "calc(50% - 1.84px - 1.32px)",
        },
        Shape6612682: {
          width: "3.69px",
          height: "4.16px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 3.685546875,
            height: 4.15869140625,
          },
          top: "1.11px",
          left: "1.32px",
        },
        upload6612680: {
          width: "6.32px",
          height: "6.32px",
          top: "2.11px",
          left: "2.11px",
        },
        Icon6612678: { width: "10.53px", height: "10.53px", top: "0.03px" },
        "Audrey Tobey6612673": {
          width: "173.79px",
          height: "38.46px",
          top: "163.18px",
          left: "12.64px",
        },
        "2 hours ago": {
          fontSize: "6.317864418029785px",
          lineHeight: "8.423819541931152px",
          top: "22.11px",
          left: "calc(50% - 15px - 28.1px)",
        },
        'Send you message "Are you ther': {
          fontSize: "6.317864418029785px",
          lineHeight: "8.423819541931152px",
          top: "13.69px",
          left: "calc(50% - 51px - -7.9px)",
        },
        "Robert Delaney6612688": {
          fontSize: "7.581437587738037px",
          lineHeight: "10.52977466583252px",
          left: "calc(50% - 49.5px - -6.4px)",
        },
        border6612689: {
          width: "1.05px",
          height: "27.9px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 1.052734375,
            height: 27.90380859375,
          },
          paths: [
            {
              d: "M0 0.526488C0 0.235717 0.235717 0 0.526489 0L0.526489 0C0.81726 0 1.05298 0.235717 1.05298 0.526489L1.05298 27.3774C1.05298 27.6682 0.81726 27.9039 0.526489 27.9039L0.526489 27.9039C0.235717 27.9039 0 27.6682 0 27.3774L0 0.526488Z",
              fill: "rgba(241,243,250,1)",
              fillRule: "evenodd",
            },
          ],
          top: "10.56px",
          left: "calc(50% - 0.53px - 53.63px)",
        },
        bg6612691: {
          width: "10.53px",
          height: "10.53px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 10.52978515625,
            height: 10.52978515625,
          },
          paths: [
            {
              d: "M0 5.26489C0 2.35717 2.35717 0 5.26489 0C8.1726 0 10.5298 2.35717 10.5298 5.26489C10.5298 8.1726 8.1726 10.5298 5.26489 10.5298C2.35717 10.5298 0 8.1726 0 5.26489Z",
              fill: "rgba(68,186,220,0.251)",
              fillRule: "evenodd",
            },
          ],
          left: "calc(50% - 5.26px - 0px)",
        },
        Background6612693: {
          width: "6.32px",
          height: "6.32px",
          left: "calc(50% - 3.16px - 0px)",
          borderRadius: "15.16616439819336px",
        },
        "Shape path6612695": {
          width: "1.58px",
          height: "3.16px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 1.57958984375,
            height: 3.15869140625,
          },
          left: "calc(50% - 0.79px - 1.33px)",
        },
        "Shape path6612696": {
          width: "3.69px",
          height: "2.63px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 3.685546875,
            height: 2.63232421875,
          },
          top: "2.37px",
          left: "calc(50% - 1.84px - 1.33px)",
        },
        Shape6612694: {
          width: "3.67px",
          height: "5px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 3.6689453125,
            height: 5.0009765625,
          },
          top: "0.52px",
          left: "1.33px",
        },
        microphone: {
          width: "6.32px",
          height: "6.32px",
          top: "2.11px",
          left: "2.11px",
        },
        Icon6612690: { width: "10.53px", height: "10.53px", top: "0.03px" },
        "Robert Delaney6612685": {
          width: "117.79px",
          height: "38.46px",
          top: "124.75px",
          left: "12.64px",
        },
        "30 minutes ago": {
          fontSize: "6.317864418029785px",
          lineHeight: "8.423819541931152px",
          top: "22.11px",
          left: "calc(50% - 21.5px - 49.6px)",
        },
        "Dave Gamache added Admin Dashb": {
          fontSize: "6.317864418029785px",
          lineHeight: "8.423819541931152px",
          top: "13.69px",
          left: "calc(50% - 79px - -7.9px)",
        },
        "Product on the Bootstrap Marke": {
          fontSize: "7.581437587738037px",
          lineHeight: "10.52977466583252px",
          left: "calc(50% - 62px - 9.1px)",
        },
        border6612701: {
          width: "1.05px",
          height: "27.9px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 1.052734375,
            height: 27.90380859375,
          },
          paths: [
            {
              d: "M0 0.526488C0 0.235717 0.235717 0 0.526489 0L0.526489 0C0.81726 0 1.05298 0.235717 1.05298 0.526489L1.05298 27.3774C1.05298 27.6682 0.81726 27.9039 0.526489 27.9039L0.526489 27.9039C0.235717 27.9039 0 27.6682 0 27.3774L0 0.526488Z",
              fill: "rgba(241,243,250,1)",
              fillRule: "evenodd",
            },
          ],
          top: "10.56px",
          left: "calc(50% - 0.53px - 81.63px)",
        },
        bg6612703: {
          width: "10.53px",
          height: "10.53px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 10.52978515625,
            height: 10.52978515625,
          },
          paths: [
            {
              d: "M0 5.26489C0 2.35717 2.35717 0 5.26489 0C8.1726 0 10.5298 2.35717 10.5298 5.26489C10.5298 8.1726 8.1726 10.5298 5.26489 10.5298C2.35717 10.5298 0 8.1726 0 5.26489Z",
              fill: "rgba(54,136,252,0.251)",
              fillRule: "evenodd",
            },
          ],
          left: "calc(50% - 5.26px - 0px)",
        },
        Background6612705: {
          width: "6.32px",
          height: "6.32px",
          left: "calc(50% - 3.16px - 0px)",
          borderRadius: "15.16616439819336px",
        },
        Shape6612706: {
          width: "5px",
          height: "5.26px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 5.00146484375,
            height: 5.2646484375,
          },
          top: "0.53px",
          left: "calc(50% - 2.5px - 0.13px)",
        },
        airplane: {
          width: "6.32px",
          height: "6.32px",
          top: "2.11px",
          left: "2.11px",
        },
        Icon6612702: { width: "10.53px", height: "10.53px", top: "0.03px" },
        "Product on the Bootstrap Market": {
          width: "173.79px",
          height: "38.46px",
          top: "86.32px",
          left: "12.64px",
        },
        "5 minutes ago": {
          fontSize: "6.317864418029785px",
          lineHeight: "8.423819541931152px",
          top: "31.59px",
          left: "calc(50% - 19.5px - 35.6px)",
        },
        "Paul Burgess just purchased \u201CH6612709": {
          fontSize: "6.317864418029785px",
          lineHeight: "8.423819541931152px",
          top: "13.69px",
          left: "calc(50% - 63px - -7.9px)",
        },
        "You sold an item6612710": {
          fontSize: "7.581437587738037px",
          lineHeight: "10.52977466583252px",
          left: "calc(50% - 49.5px - 5.6px)",
        },
        border6612711: {
          width: "1.05px",
          height: "37.38px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 1.052734375,
            height: 37.380859375,
          },
          paths: [
            {
              d: "M0 0.526489C0 0.235717 0.235717 0 0.526489 0L0.526489 0C0.81726 0 1.05298 0.235717 1.05298 0.526489L1.05298 36.8542C1.05298 37.145 0.81726 37.3807 0.526489 37.3807L0.526489 37.3807C0.235717 37.3807 0 37.145 0 36.8542L0 0.526489Z",
              fill: "rgba(241,243,250,1)",
              fillRule: "evenodd",
            },
          ],
          top: "10.56px",
          left: "calc(50% - 0.53px - 65.63px)",
        },
        bg6612713: {
          width: "10.53px",
          height: "10.53px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 10.52978515625,
            height: 10.52978515625,
          },
          paths: [
            {
              d: "M0 5.26489C0 2.35717 2.35717 0 5.26489 0C8.1726 0 10.5298 2.35717 10.5298 5.26489C10.5298 8.1726 8.1726 10.5298 5.26489 10.5298C2.35717 10.5298 0 8.1726 0 5.26489Z",
              fill: "rgba(68,186,220,0.251)",
              fillRule: "evenodd",
            },
          ],
          left: "calc(50% - 5.26px - 0px)",
        },
        Background6612715: {
          width: "6.32px",
          height: "6.32px",
          left: "calc(50% - 3.16px - 0px)",
          borderRadius: "15.16616439819336px",
        },
        "Shape path6612717": {
          width: "3.69px",
          height: "3.42px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 3.685546875,
            height: 3.42236328125,
          },
          top: "-0.32px",
          left: "calc(50% - 1.84px - 1.32px)",
        },
        "Shape path6612718": {
          width: "3.69px",
          height: "0.53px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 3.685546875,
            height: 0.5263671875,
          },
          top: "3.63px",
          left: "calc(50% - 1.84px - 1.32px)",
        },
        Shape6612716: {
          width: "3.69px",
          height: "4.16px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 3.685546875,
            height: 4.15869140625,
          },
          top: "1.11px",
          left: "1.32px",
        },
        upload6612714: {
          width: "6.32px",
          height: "6.32px",
          top: "2.11px",
          left: "2.11px",
        },
        Icon6612712: { width: "10.53px", height: "10.53px", top: "0.03px" },
        "You sold an item6612707": {
          width: "141.79px",
          height: "47.94px",
          top: "38.41px",
          left: "12.64px",
        },
        Background6612720: {
          width: "10.53px",
          height: "10.53px",
          left: "calc(50% - 5.26px - 0px)",
          borderRadius: "15.16616439819336px",
        },
        "Shape path6612722": {
          width: "1.75px",
          height: "1.75px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 1.7548828125,
            height: 1.7548828125,
          },
          top: "5.26px",
          left: "calc(50% - 0.88px - 4.39px)",
        },
        "Shape path6612723": {
          width: "1.75px",
          height: "1.75px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 1.7548828125,
            height: 1.7548828125,
          },
          top: "2.63px",
          left: "calc(50% - 0.88px - 4.39px)",
        },
        "Shape path6612724": {
          width: "1.75px",
          height: "1.75px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 1.7548828125,
            height: 1.7548828125,
          },
          left: "calc(50% - 0.88px - 4.39px)",
        },
        Shape6612721: {
          width: "1.75px",
          height: "7.02px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 1.7548828125,
            height: 7.0185546875,
          },
          top: "1.76px",
          left: "4.39px",
        },
        "dots-vertical Copy": {
          width: "10.53px",
          height: "10.53px",
          top: "12.63px",
          left: "175.85px",
        },
        "Recent Activity6612725": {
          fontSize: "7.581437587738037px",
          lineHeight: "10.52977466583252px",
          top: "10.75px",
          left: "calc(50% - 32.5px - 54.37px)",
        },
        "Shape path6612727": {
          width: "1.75px",
          height: "1.75px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 1.7548828125,
            height: 1.7548828125,
          },
          top: "5.27px",
          left: "calc(50% - 0.88px - 98.63px)",
          transformOrigin: "top left",
          transform: "rotate(0deg)",
        },
        "Shape path6612728": {
          width: "1.75px",
          height: "1.75px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 1.7548828125,
            height: 1.7548828125,
          },
          top: "2.63px",
          left: "calc(50% - 0.88px - 98.63px)",
          transformOrigin: "top left",
          transform: "rotate(0deg)",
        },
        "Shape path6612729": {
          width: "1.75px",
          height: "1.75px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 1.7548828125,
            height: 1.7548828125,
          },
          left: "calc(50% - 0.88px - 98.63px)",
          transformOrigin: "top left",
          transform: "rotate(0deg)",
        },
        Shape6612726: {
          width: "1.75px",
          height: "7.02px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 7.02099609375,
            height: 1.7548828125,
          },
          top: "246.92px",
          left: "102.84px",
        },
        "Recent Activity6612659": {
          width: "199.01px",
          height: "256.4px",
          top: "492.41px",
          left: "395.99px",
        },
        "icons / bx-check-circle6612734": {},
        "Purchase open receivables from companies without recourse": {
          lineHeight: "22.399999618530273px",
          width: "453px",
        },
        line6612733: { width: "360px" },
        "icons / bx-check-circle6612737": {},
        "Get credit information of companies": {},
        line6612736: {},
        "icons / bx-check-circle6612740": {},
        "Monitor your investmens with full transparency": {
          lineHeight: "22.399999618530273px",
          width: "424px",
        },
        line6612739: {},
        "icons / bx-check-circle6612743": {},
        "Regulatory compliance and assurance": {},
        line6612742: {},
        "icons / bx-check-circle6612746": {},
        "Access to a new attractive asset-class": {},
        line6612745: {},
        list: { width: "379px", left: "calc(50% - 189.5px - 12.5px)" },
        "Get possibilities for better Investments": {
          fontSize: "30px",
          lineHeight: "39px",
          textAlign: "center",
          width: "378.58px",
          left: "calc(50% - 189.29px - -12.71px)",
        },
        text: { width: "404px", height: "284px" },
        Content: {
          width: "404px",
          height: "284px",
          top: "62px",
          left: "170px",
        },
        JoinWaitlist: { bottom: "51px", left: "calc(50% - 153.5px - 0.5px)" },
        Possibilites: { width: "100%", height: "899px" },
      },
      variantValues: { breakpoint: "medium" },
    },
    {
      overrides: {
        826612523: {
          fontSize: "4.603475570678711px",
          lineHeight: "6.247573375701904px",
        },
        826612527: {
          fontSize: "4.603475570678711px",
          lineHeight: "6.247573375701904px",
        },
        826612544: {
          fontSize: "4.603475570678711px",
          lineHeight: "6.247573375701904px",
        },
        826612548: {
          fontSize: "4.603475570678711px",
          lineHeight: "6.247573375701904px",
        },
        826612565: {
          fontSize: "4.603475570678711px",
          lineHeight: "6.247573375701904px",
        },
        826612569: {
          fontSize: "4.603475570678711px",
          lineHeight: "6.247573375701904px",
        },
        826612586: {
          fontSize: "4.603475570678711px",
          lineHeight: "6.247573375701904px",
        },
        826612590: {
          fontSize: "4.603475570678711px",
          lineHeight: "6.247573375701904px",
        },
        826612607: {
          fontSize: "4.603475570678711px",
          lineHeight: "6.247573375701904px",
        },
        826612611: {
          fontSize: "4.603475570678711px",
          lineHeight: "6.247573375701904px",
        },
        826612628: {
          fontSize: "4.603475570678711px",
          lineHeight: "6.247573375701904px",
        },
        826612632: {
          fontSize: "4.603475570678711px",
          lineHeight: "6.247573375701904px",
        },
        826612652: {
          fontSize: "3.288196563720703px",
          lineHeight: "6.247573375701904px",
        },
        826612654: {
          fontSize: "3.288196563720703px",
          lineHeight: "6.247573375701904px",
        },
        bg6612509: { width: "360.38px" },
        "Card-box6612511": {
          width: "256.48px",
          height: "160.14px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 256.4794921875,
            height: 160.13525390625,
          },
          paths: [
            {
              d: "M0 9.47206C0 4.24078 4.24079 0 9.47206 0L247.007 0C252.239 0 256.479 4.24079 256.479 9.47206L256.479 150.663C256.479 155.894 252.239 160.135 247.007 160.135L9.47205 160.135C4.24077 160.135 0 155.894 0 150.663L0 9.47206Z",
              fill: "rgba(255,255,255,1)",
              fillRule: "evenodd",
            },
          ],
          left: "calc(50% - 128.24px - 0px)",
        },
        "Border-top6612514": {
          width: "240.7px",
          height: "0.33px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 240.69580078125,
            height: 0.32861328125,
          },
          paths: [
            {
              d: "M0 0L240.696 0L240.696 0.32882L0 0.32882L0 0Z",
              fill: "rgba(238,242,247,1)",
              fillRule: "evenodd",
            },
          ],
        },
        "$6,518.186612517": {
          fontSize: "4.603475570678711px",
          lineHeight: "6.247573375701904px",
        },
        Amount6612516: { width: "21px", height: "7px", left: "133.17px" },
        "$6,518.186612519": {
          fontSize: "4.603475570678711px",
          lineHeight: "6.247573375701904px",
        },
        Amount6612518: { width: "21px", height: "7px", left: "163.42px" },
        "$6,518.186612521": {
          fontSize: "4.603475570678711px",
          lineHeight: "6.247573375701904px",
        },
        Amount6612520: { width: "11px", height: "7px", left: "193.67px" },
        Quantity6612522: { width: "24px", height: "7px", left: "74.97px" },
        BG6612525: {
          width: "18.09px",
          height: "10.19px",
          top: "-1.97px",
          left: "-0.99px",
        },
        Quantity6612526: { width: "10px", height: "7px", left: "3.29px" },
        "Frame 26612524": {
          width: "16.11px",
          height: "6.25px",
          left: "107.85px",
          borderRadius: "1.6440982818603516px",
        },
        "$79.496612529": {
          fontSize: "4.603475570678711px",
          lineHeight: "6.247573375701904px",
        },
        Price6612528: { width: "22px", height: "7px", left: "42.09px" },
        "ASOS Ridley High Waist6612531": {
          fontSize: "4.603475570678711px",
          lineHeight: "6.247573375701904px",
        },
        Products6612530: { width: "29px", height: "13px" },
        "icons / bx-link-external6612532": {
          width: "5.18px",
          height: "5.18px",
          top: "0.66px",
          left: "221.71px",
        },
        "icons / bx-link6612533": {
          width: "5.18px",
          height: "5.18px",
          top: "0.66px",
          left: "213.07px",
        },
        "Products-16612515": {
          width: "226.88px",
          height: "13px",
          top: "5.59px",
        },
        "Group 48095449": { width: "240.7px", height: "18.59px" },
        "Border-top6612535": {
          width: "240.7px",
          height: "0.33px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 240.69580078125,
            height: 0.32861328125,
          },
          paths: [
            {
              d: "M0 0L240.696 0L240.696 0.32882L0 0.32882L0 0Z",
              fill: "rgba(238,242,247,1)",
              fillRule: "evenodd",
            },
          ],
        },
        "$6,518.186612538": {
          fontSize: "4.603475570678711px",
          lineHeight: "6.247573375701904px",
        },
        Amount6612537: { width: "21px", height: "7px", left: "133.17px" },
        "$6,518.186612540": {
          fontSize: "4.603475570678711px",
          lineHeight: "6.247573375701904px",
        },
        Amount6612539: { width: "21px", height: "7px", left: "163.42px" },
        "$6,518.186612542": {
          fontSize: "4.603475570678711px",
          lineHeight: "6.247573375701904px",
        },
        Amount6612541: { width: "11px", height: "7px", left: "193.67px" },
        Quantity6612543: { width: "24px", height: "7px", left: "74.97px" },
        BG6612546: {
          width: "18.09px",
          height: "10.19px",
          top: "-1.97px",
          left: "-0.99px",
        },
        Quantity6612547: { width: "13px", height: "7px", left: "1.97px" },
        "Frame 26612545": {
          width: "16.11px",
          height: "6.25px",
          left: "107.85px",
          borderRadius: "1.6440982818603516px",
        },
        "$79.496612550": {
          fontSize: "4.603475570678711px",
          lineHeight: "6.247573375701904px",
        },
        Price6612549: { width: "22px", height: "7px", left: "42.09px" },
        "ASOS Ridley High Waist6612552": {
          fontSize: "4.603475570678711px",
          lineHeight: "6.247573375701904px",
        },
        Products6612551: { width: "36px", height: "13px" },
        "icons / bx-link-external6612553": {
          width: "5.18px",
          height: "5.18px",
          top: "0.66px",
          left: "221.71px",
        },
        "icons / bx-link6612554": {
          width: "5.18px",
          height: "5.18px",
          top: "0.66px",
          left: "213.08px",
        },
        "Products-16612536": {
          width: "226.89px",
          height: "13px",
          top: "5.59px",
        },
        "Group 48095450": { width: "240.7px", height: "18.59px" },
        "Border-top6612556": {
          width: "240.7px",
          height: "0.33px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 240.69580078125,
            height: 0.32861328125,
          },
          paths: [
            {
              d: "M0 0L240.696 0L240.696 0.32882L0 0.32882L0 0Z",
              fill: "rgba(238,242,247,1)",
              fillRule: "evenodd",
            },
          ],
        },
        "$6,518.186612559": {
          fontSize: "4.603475570678711px",
          lineHeight: "6.247573375701904px",
        },
        Amount6612558: { width: "21px", height: "7px", left: "133.17px" },
        "$6,518.186612561": {
          fontSize: "4.603475570678711px",
          lineHeight: "6.247573375701904px",
        },
        Amount6612560: { width: "21px", height: "7px", left: "163.42px" },
        "$6,518.186612563": {
          fontSize: "4.603475570678711px",
          lineHeight: "6.247573375701904px",
        },
        Amount6612562: { width: "11px", height: "7px", left: "193.67px" },
        Quantity6612564: { width: "24px", height: "7px", left: "74.97px" },
        BG6612567: {
          width: "18.09px",
          height: "10.19px",
          top: "-1.97px",
          left: "-0.99px",
        },
        Quantity6612568: { width: "11px", height: "7px", left: "3.29px" },
        "Frame 26612566": {
          width: "16.11px",
          height: "6.25px",
          left: "107.85px",
          borderRadius: "1.6440982818603516px",
        },
        "$79.496612571": {
          fontSize: "4.603475570678711px",
          lineHeight: "6.247573375701904px",
        },
        Price6612570: { width: "22px", height: "7px", left: "42.09px" },
        "ASOS Ridley High Waist6612573": {
          fontSize: "4.603475570678711px",
          lineHeight: "6.247573375701904px",
        },
        Products6612572: { width: "29px", height: "13px" },
        "icons / bx-link-external6612574": {
          width: "5.18px",
          height: "5.18px",
          top: "0.66px",
          left: "221.71px",
        },
        "icons / bx-link6612575": {
          width: "5.18px",
          height: "5.18px",
          top: "0.66px",
          left: "213.07px",
        },
        "Products-16612557": {
          width: "226.88px",
          height: "13px",
          top: "5.59px",
        },
        "Group 48095451": { width: "240.7px", height: "18.59px" },
        "Border-top6612577": {
          width: "240.7px",
          height: "0.33px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 240.69580078125,
            height: 0.32861328125,
          },
          paths: [
            {
              d: "M0 0L240.696 0L240.696 0.32882L0 0.32882L0 0Z",
              fill: "rgba(238,242,247,1)",
              fillRule: "evenodd",
            },
          ],
        },
        "$6,518.186612580": {
          fontSize: "4.603475570678711px",
          lineHeight: "6.247573375701904px",
        },
        Amount6612579: { width: "21px", height: "7px", left: "133.17px" },
        "$6,518.186612582": {
          fontSize: "4.603475570678711px",
          lineHeight: "6.247573375701904px",
        },
        Amount6612581: { width: "21px", height: "7px", left: "163.42px" },
        "$6,518.186612584": {
          fontSize: "4.603475570678711px",
          lineHeight: "6.247573375701904px",
        },
        Amount6612583: { width: "11px", height: "7px", left: "193.67px" },
        Quantity6612585: { width: "24px", height: "7px", left: "74.97px" },
        BG6612588: {
          width: "18.09px",
          height: "10.19px",
          top: "-1.97px",
          left: "-0.99px",
        },
        Quantity6612589: { width: "11px", height: "7px", left: "3.29px" },
        "Frame 26612587": {
          width: "16.11px",
          height: "6.25px",
          left: "107.85px",
          borderRadius: "1.6440982818603516px",
        },
        "$79.496612592": {
          fontSize: "4.603475570678711px",
          lineHeight: "6.247573375701904px",
        },
        Price6612591: { width: "22px", height: "7px", left: "42.09px" },
        "ASOS Ridley High Waist6612594": {
          fontSize: "4.603475570678711px",
          lineHeight: "6.247573375701904px",
        },
        Products6612593: { width: "30px", height: "13px" },
        "icons / bx-link-external6612595": {
          width: "5.18px",
          height: "5.18px",
          top: "0.66px",
          left: "221.71px",
        },
        "icons / bx-link6612596": {
          width: "5.18px",
          height: "5.18px",
          top: "0.66px",
          left: "213.07px",
        },
        "Products-16612578": {
          width: "226.88px",
          height: "13px",
          top: "5.59px",
        },
        "Group 48095452": { width: "240.7px", height: "18.59px" },
        "Border-top6612598": {
          width: "240.7px",
          height: "0.33px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 240.69580078125,
            height: 0.32861328125,
          },
          paths: [
            {
              d: "M0 0L240.696 0L240.696 0.32882L0 0.32882L0 0Z",
              fill: "rgba(238,242,247,1)",
              fillRule: "evenodd",
            },
          ],
        },
        "$6,518.186612601": {
          fontSize: "4.603475570678711px",
          lineHeight: "6.247573375701904px",
        },
        Amount6612600: { width: "21px", height: "7px", left: "133.17px" },
        "$6,518.186612603": {
          fontSize: "4.603475570678711px",
          lineHeight: "6.247573375701904px",
        },
        Amount6612602: { width: "21px", height: "7px", left: "163.42px" },
        "$6,518.186612605": {
          fontSize: "4.603475570678711px",
          lineHeight: "6.247573375701904px",
        },
        Amount6612604: { width: "11px", height: "7px", left: "193.67px" },
        Quantity6612606: { width: "24px", height: "7px", left: "74.97px" },
        BG6612609: {
          width: "18.09px",
          height: "10.19px",
          top: "-1.97px",
          left: "-0.99px",
        },
        Quantity6612610: { width: "11px", height: "7px", left: "3.29px" },
        "Frame 26612608": {
          width: "16.11px",
          height: "6.25px",
          left: "107.85px",
          borderRadius: "1.6440982818603516px",
        },
        "$79.496612613": {
          fontSize: "4.603475570678711px",
          lineHeight: "6.247573375701904px",
        },
        Price6612612: { width: "22px", height: "7px", left: "42.09px" },
        "ASOS Ridley High Waist6612615": {
          fontSize: "4.603475570678711px",
          lineHeight: "6.247573375701904px",
        },
        Products6612614: { width: "29px", height: "13px" },
        "icons / bx-link-external6612616": {
          width: "5.18px",
          height: "5.18px",
          top: "0.66px",
          left: "221.71px",
        },
        "icons / bx-link6612617": {
          width: "5.18px",
          height: "5.18px",
          top: "0.66px",
          left: "213.08px",
        },
        "Products-16612599": {
          width: "226.89px",
          height: "13px",
          top: "5.59px",
        },
        "Group 48095453": { width: "240.7px", height: "18.59px" },
        "Border-top6612619": {
          width: "240.7px",
          height: "0.33px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 240.69580078125,
            height: 0.32861328125,
          },
          paths: [
            {
              d: "M0 0L240.696 0L240.696 0.32882L0 0.32882L0 0Z",
              fill: "rgba(238,242,247,1)",
              fillRule: "evenodd",
            },
          ],
        },
        "$6,518.186612622": {
          fontSize: "4.603475570678711px",
          lineHeight: "6.247573375701904px",
        },
        Amount6612621: { width: "21px", height: "7px", left: "133.17px" },
        "$6,518.186612624": {
          fontSize: "4.603475570678711px",
          lineHeight: "6.247573375701904px",
        },
        Amount6612623: { width: "21px", height: "7px", left: "163.42px" },
        "$6,518.186612626": {
          fontSize: "4.603475570678711px",
          lineHeight: "6.247573375701904px",
        },
        Amount6612625: { width: "11px", height: "7px", left: "193.67px" },
        Quantity6612627: { width: "24px", height: "7px", left: "74.97px" },
        BG6612630: {
          width: "18.09px",
          height: "10.19px",
          top: "-1.97px",
          left: "-0.99px",
        },
        Quantity6612631: { width: "11px", height: "7px", left: "3.29px" },
        "Frame 26612629": {
          width: "16.11px",
          height: "6.25px",
          left: "107.85px",
          borderRadius: "1.6440982818603516px",
        },
        "$79.496612634": {
          fontSize: "4.603475570678711px",
          lineHeight: "6.247573375701904px",
        },
        Price6612633: { width: "22px", height: "7px", left: "42.09px" },
        "ASOS Ridley High Waist6612636": {
          fontSize: "4.603475570678711px",
          lineHeight: "6.247573375701904px",
        },
        Products6612635: { width: "29px", height: "13px" },
        "icons / bx-link-external6612637": {
          width: "5.18px",
          height: "5.18px",
          top: "0.66px",
          left: "221.71px",
        },
        "icons / bx-link6612638": {
          width: "5.18px",
          height: "5.18px",
          top: "0.66px",
          left: "213.07px",
        },
        "Products-16612620": {
          width: "226.89px",
          height: "13px",
          top: "5.59px",
        },
        "Group 48095454": { width: "240.7px", height: "18.59px" },
        "Frame 3": {
          gap: "4.603475570678711px",
          width: "236.75px",
          height: "124.95px",
          top: "25.98px",
          left: "calc(50% - 118.38px - 1.97px)",
          borderRadius: "9.472061157226562px",
        },
        "Top Selling Products6612639": {
          fontSize: "4.735003471374512px",
          lineHeight: "6.576393127441406px",
          top: "6.72px",
          left: "calc(50% - 12.5px - 107.85px)",
        },
        "$6,518.186612644": {
          fontSize: "3.288196563720703px",
          lineHeight: "6.247573375701904px",
        },
        Amount6612643: { width: "19px", height: "7px", left: "136.13px" },
        "$6,518.186612646": {
          fontSize: "3.288196563720703px",
          lineHeight: "6.247573375701904px",
        },
        Amount6612645: { width: "25px", height: "7px", left: "163.75px" },
        "$6,518.186612648": {
          fontSize: "3.288196563720703px",
          lineHeight: "6.247573375701904px",
        },
        Amount6612647: { width: "10px", height: "7px", left: "195.32px" },
        "$6,518.186612650": {
          fontSize: "3.288196563720703px",
          lineHeight: "6.247573375701904px",
        },
        Amount6612649: { width: "18px", height: "7px", left: "211.43px" },
        Quantity6612651: { width: "20px", height: "7px", left: "78.59px" },
        Quantity6612653: { width: "16px", height: "7px", left: "110.15px" },
        "$79.496612656": {
          fontSize: "3.288196563720703px",
          lineHeight: "6.247573375701904px",
        },
        Price6612655: { width: "23px", height: "7px", left: "43.73px" },
        "ASOS Ridley High Waist6612658": {
          fontSize: "3.288196563720703px",
          lineHeight: "6.247573375701904px",
        },
        Products6612657: { width: "14px", height: "7px" },
        "Products-16612642": { width: "229.43px", height: "7px" },
        Header: { width: "229.43px", height: "7px", top: "4.27px" },
        "Frame 48095386": {
          width: "248.59px",
          height: "10.52px",
          top: "14.47px",
          left: "calc(50% - 124.29px - -3.95px)",
          borderRadius: "9.472061157226562px",
        },
        "Top Selling Products6612510": {
          width: "256.48px",
          height: "160.14px",
          top: "420px",
          left: "29px",
        },
        "Card-box6612660": {
          width: "145.14px",
          height: "187px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 145.142578125,
            height: 186.99560546875,
          },
          paths: [
            {
              d: "M0 11.0609C0 4.95212 4.95212 0 11.0609 0L134.081 0C140.19 0 145.142 4.95212 145.142 11.0609L145.142 175.935C145.142 182.043 140.19 186.996 134.081 186.996L11.0609 186.996C4.9521 186.996 0 182.043 0 175.935L0 11.0609Z",
              fill: "rgba(255,255,255,1)",
              fillRule: "evenodd",
            },
          ],
          left: "calc(50% - 72.57px - 0px)",
        },
        "14 hours ago6612662": {
          fontSize: "4.607694625854492px",
          lineHeight: "6.1435933113098145px",
          top: "23.04px",
          left: "calc(50% - 12.5px - 27.74px)",
        },
        "Paul Burgess just purchased \u201CH6612663": {
          fontSize: "4.607694625854492px",
          lineHeight: "6.1435933113098145px",
          top: "9.98px",
          left: "calc(50% - 46px - -5.76px)",
        },
        "You sold an item6612664": {
          fontSize: "5.529233932495117px",
          lineHeight: "7.6794915199279785px",
          left: "calc(50% - 36px - 4.24px)",
        },
        border6612665: {
          width: "0.77px",
          height: "23.04px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 0.76806640625,
            height: 23.03857421875,
          },
          paths: [
            {
              d: "M0 0.383974C0 0.171911 0.171911 0 0.383975 0L0.383975 0C0.596038 0 0.767949 0.171911 0.767949 0.383974L0.767949 22.6545C0.767949 22.8666 0.596038 23.0385 0.383975 23.0385L0.383975 23.0385C0.171911 23.0385 0 22.8666 0 22.6545L0 0.383974Z",
              fill: "rgba(241,243,250,1)",
              fillRule: "evenodd",
            },
          ],
          top: "7.7px",
          left: "calc(50% - 0.38px - 47.92px)",
        },
        bg6612667: {
          width: "7.68px",
          height: "7.68px",
          viewBox: { minX: 0, minY: 0, width: 7.6796875, height: 7.6796875 },
          paths: [
            {
              d: "M0 3.83975C0 1.71911 1.71911 0 3.83975 0C5.96038 0 7.67949 1.71911 7.67949 3.83975C7.67949 5.96038 5.96038 7.67949 3.83975 7.67949C1.71911 7.67949 0 5.96038 0 3.83975Z",
              fill: "rgba(68,186,220,0.251)",
              fillRule: "evenodd",
            },
          ],
          left: "calc(50% - 3.84px - 0px)",
        },
        Background6612669: {
          width: "4.61px",
          height: "4.61px",
          left: "calc(50% - 2.3px - 0px)",
          borderRadius: "11.060866355895996px",
        },
        "Shape path6612671": {
          width: "2.69px",
          height: "2.5px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 2.68798828125,
            height: 2.49560546875,
          },
          top: "-0.23px",
          left: "calc(50% - 1.34px - 0.96px)",
        },
        "Shape path6612672": {
          width: "2.69px",
          height: "0.38px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 2.68798828125,
            height: 0.3837890625,
          },
          top: "2.65px",
          left: "calc(50% - 1.34px - 0.96px)",
        },
        Shape6612670: {
          width: "2.69px",
          height: "3.03px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 2.68798828125,
            height: 3.033203125,
          },
          top: "0.81px",
          left: "0.96px",
        },
        upload6612668: {
          width: "4.61px",
          height: "4.61px",
          top: "1.54px",
          left: "1.54px",
        },
        Icon6612666: { width: "7.68px", height: "7.68px", top: "0.02px" },
        "You sold an item6612661": {
          width: "103.52px",
          height: "30.74px",
          top: "147.04px",
          left: "9.22px",
        },
        "14 hours ago6612674": {
          fontSize: "4.607694625854492px",
          lineHeight: "6.1435933113098145px",
          top: "16.13px",
          left: "calc(50% - 17.5px - 34.24px)",
        },
        'Uploaded a photo "Error.jpg"': {
          fontSize: "4.607694625854492px",
          lineHeight: "6.1435933113098145px",
          top: "9.98px",
          left: "calc(50% - 57.5px - -5.76px)",
        },
        "Audrey Tobey6612676": {
          fontSize: "5.529233932495117px",
          lineHeight: "7.6794915199279785px",
          left: "calc(50% - 51px - 0.74px)",
        },
        border6612677: {
          width: "0.77px",
          height: "20.35px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 0.76806640625,
            height: 20.3505859375,
          },
          paths: [
            {
              d: "M0 0.383974C0 0.171911 0.171911 0 0.383975 0L0.383975 0C0.596038 0 0.767949 0.171911 0.767949 0.383974L0.767949 19.9667C0.767949 20.1787 0.596038 20.3507 0.383975 20.3507L0.383975 20.3507C0.171911 20.3507 0 20.1787 0 19.9667L0 0.383974Z",
              fill: "rgba(241,243,250,1)",
              fillRule: "evenodd",
            },
          ],
          top: "7.7px",
          left: "calc(50% - 0.38px - 59.42px)",
        },
        bg6612679: {
          width: "7.68px",
          height: "7.68px",
          viewBox: { minX: 0, minY: 0, width: 7.6796875, height: 7.6796875 },
          paths: [
            {
              d: "M0 3.83975C0 1.71911 1.71911 0 3.83975 0C5.96038 0 7.67949 1.71911 7.67949 3.83975C7.67949 5.96038 5.96038 7.67949 3.83975 7.67949C1.71911 7.67949 0 5.96038 0 3.83975Z",
              fill: "rgba(54,136,252,0.251)",
              fillRule: "evenodd",
            },
          ],
          left: "calc(50% - 3.84px - 0px)",
        },
        Background6612681: {
          width: "4.61px",
          height: "4.61px",
          left: "calc(50% - 2.3px - 0px)",
          borderRadius: "11.060866355895996px",
        },
        "Shape path6612683": {
          width: "2.69px",
          height: "2.5px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 2.68798828125,
            height: 2.49560546875,
          },
          top: "-0.23px",
          left: "calc(50% - 1.34px - 0.96px)",
        },
        "Shape path6612684": {
          width: "2.69px",
          height: "0.38px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 2.68798828125,
            height: 0.3837890625,
          },
          top: "2.65px",
          left: "calc(50% - 1.34px - 0.96px)",
        },
        Shape6612682: {
          width: "2.69px",
          height: "3.03px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 2.68798828125,
            height: 3.033203125,
          },
          top: "0.81px",
          left: "0.96px",
        },
        upload6612680: {
          width: "4.61px",
          height: "4.61px",
          top: "1.54px",
          left: "1.54px",
        },
        Icon6612678: { width: "7.68px", height: "7.68px", top: "0.02px" },
        "Audrey Tobey6612673": {
          width: "126.52px",
          height: "28.05px",
          top: "119.01px",
          left: "9.21px",
        },
        "2 hours ago": {
          fontSize: "4.607694625854492px",
          lineHeight: "6.1435933113098145px",
          top: "16.13px",
          left: "calc(50% - 11px - 20.24px)",
        },
        'Send you message "Are you ther': {
          fontSize: "4.607694625854492px",
          lineHeight: "6.1435933113098145px",
          top: "9.98px",
          left: "calc(50% - 37px - -5.76px)",
        },
        "Robert Delaney6612688": {
          fontSize: "5.529233932495117px",
          lineHeight: "7.6794915199279785px",
          left: "calc(50% - 36px - -4.76px)",
        },
        border6612689: {
          width: "0.77px",
          height: "20.35px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 0.76806640625,
            height: 20.3505859375,
          },
          paths: [
            {
              d: "M0 0.383974C0 0.171911 0.171911 0 0.383975 0L0.383975 0C0.596038 0 0.767949 0.171911 0.767949 0.383974L0.767949 19.9667C0.767949 20.1787 0.596038 20.3507 0.383975 20.3507L0.383975 20.3507C0.171911 20.3507 0 20.1787 0 19.9667L0 0.383974Z",
              fill: "rgba(241,243,250,1)",
              fillRule: "evenodd",
            },
          ],
          top: "7.7px",
          left: "calc(50% - 0.38px - 38.92px)",
        },
        bg6612691: {
          width: "7.68px",
          height: "7.68px",
          viewBox: { minX: 0, minY: 0, width: 7.6796875, height: 7.6796875 },
          paths: [
            {
              d: "M0 3.83975C0 1.71911 1.71911 0 3.83975 0C5.96038 0 7.67949 1.71911 7.67949 3.83975C7.67949 5.96038 5.96038 7.67949 3.83975 7.67949C1.71911 7.67949 0 5.96038 0 3.83975Z",
              fill: "rgba(68,186,220,0.251)",
              fillRule: "evenodd",
            },
          ],
          left: "calc(50% - 3.84px - 0px)",
        },
        Background6612693: {
          width: "4.61px",
          height: "4.61px",
          left: "calc(50% - 2.3px - 0px)",
          borderRadius: "11.060866355895996px",
        },
        "Shape path6612695": {
          width: "1.15px",
          height: "2.3px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 1.15185546875,
            height: 2.3037109375,
          },
          left: "calc(50% - 0.58px - 0.97px)",
        },
        "Shape path6612696": {
          width: "2.69px",
          height: "1.92px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 2.68798828125,
            height: 1.919921875,
          },
          top: "1.73px",
          left: "calc(50% - 1.34px - 0.97px)",
        },
        Shape6612694: {
          width: "2.68px",
          height: "3.65px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 2.67578125,
            height: 3.64794921875,
          },
          top: "0.38px",
          left: "0.97px",
        },
        microphone: {
          width: "4.61px",
          height: "4.61px",
          top: "1.53px",
          left: "1.54px",
        },
        Icon6612690: { width: "7.68px", height: "7.68px", top: "0.02px" },
        "Robert Delaney6612685": {
          width: "85.52px",
          height: "28.05px",
          top: "90.98px",
          left: "9.21px",
        },
        "30 minutes ago": {
          fontSize: "4.607694625854492px",
          lineHeight: "6.1435933113098145px",
          top: "16.13px",
          left: "calc(50% - 16px - 35.74px)",
        },
        "Dave Gamache added Admin Dashb": {
          fontSize: "4.607694625854492px",
          lineHeight: "6.1435933113098145px",
          top: "9.98px",
          left: "calc(50% - 57.5px - -5.76px)",
        },
        "Product on the Bootstrap Marke": {
          fontSize: "5.529233932495117px",
          lineHeight: "7.6794915199279785px",
          left: "calc(50% - 45.5px - 6.24px)",
        },
        border6612701: {
          width: "0.77px",
          height: "20.35px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 0.76806640625,
            height: 20.3505859375,
          },
          paths: [
            {
              d: "M0 0.383974C0 0.171911 0.171911 0 0.383975 0L0.383975 0C0.596038 0 0.767949 0.171911 0.767949 0.383974L0.767949 19.9667C0.767949 20.1787 0.596038 20.3507 0.383975 20.3507L0.383975 20.3507C0.171911 20.3507 0 20.1787 0 19.9667L0 0.383974Z",
              fill: "rgba(241,243,250,1)",
              fillRule: "evenodd",
            },
          ],
          top: "7.7px",
          left: "calc(50% - 0.38px - 59.42px)",
        },
        bg6612703: {
          width: "7.68px",
          height: "7.68px",
          viewBox: { minX: 0, minY: 0, width: 7.6796875, height: 7.6796875 },
          paths: [
            {
              d: "M0 3.83975C0 1.71911 1.71911 0 3.83975 0C5.96038 0 7.67949 1.71911 7.67949 3.83975C7.67949 5.96038 5.96038 7.67949 3.83975 7.67949C1.71911 7.67949 0 5.96038 0 3.83975Z",
              fill: "rgba(54,136,252,0.251)",
              fillRule: "evenodd",
            },
          ],
          left: "calc(50% - 3.84px - 0px)",
        },
        Background6612705: {
          width: "4.61px",
          height: "4.61px",
          left: "calc(50% - 2.3px - 0px)",
          borderRadius: "11.060866355895996px",
        },
        Shape6612706: {
          width: "3.65px",
          height: "3.84px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 3.64794921875,
            height: 3.83984375,
          },
          top: "0.38px",
          left: "calc(50% - 1.82px - 0.1px)",
        },
        airplane: {
          width: "4.61px",
          height: "4.61px",
          top: "1.54px",
          left: "1.54px",
        },
        Icon6612702: { width: "7.68px", height: "7.68px", top: "0.02px" },
        "Product on the Bootstrap Market": {
          width: "126.52px",
          height: "28.05px",
          top: "62.95px",
          left: "9.22px",
        },
        "5 minutes ago": {
          fontSize: "4.607694625854492px",
          lineHeight: "6.1435933113098145px",
          top: "23.04px",
          left: "calc(50% - 14.5px - 25.74px)",
        },
        "Paul Burgess just purchased \u201CH6612709": {
          fontSize: "4.607694625854492px",
          lineHeight: "6.1435933113098145px",
          top: "9.98px",
          left: "calc(50% - 46px - -5.76px)",
        },
        "You sold an item6612710": {
          fontSize: "5.529233932495117px",
          lineHeight: "7.6794915199279785px",
          left: "calc(50% - 36px - 4.24px)",
        },
        border6612711: {
          width: "0.77px",
          height: "27.26px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 0.76806640625,
            height: 27.26220703125,
          },
          paths: [
            {
              d: "M0 0.383973C0 0.17191 0.171911 0 0.383975 0L0.383975 0C0.596038 0 0.767949 0.171911 0.767949 0.383974L0.767949 26.8782C0.767949 27.0903 0.596038 27.2622 0.383975 27.2622L0.383975 27.2622C0.171911 27.2622 0 27.0903 0 26.8782L0 0.383973Z",
              fill: "rgba(241,243,250,1)",
              fillRule: "evenodd",
            },
          ],
          top: "7.7px",
          left: "calc(50% - 0.38px - 47.92px)",
        },
        bg6612713: {
          width: "7.68px",
          height: "7.68px",
          viewBox: { minX: 0, minY: 0, width: 7.6796875, height: 7.6796875 },
          paths: [
            {
              d: "M0 3.83975C0 1.71911 1.71911 0 3.83975 0C5.96038 0 7.67949 1.71911 7.67949 3.83975C7.67949 5.96038 5.96038 7.67949 3.83975 7.67949C1.71911 7.67949 0 5.96038 0 3.83975Z",
              fill: "rgba(68,186,220,0.251)",
              fillRule: "evenodd",
            },
          ],
          left: "calc(50% - 3.84px - 0px)",
        },
        Background6612715: {
          width: "4.61px",
          height: "4.61px",
          left: "calc(50% - 2.3px - 0px)",
          borderRadius: "11.060866355895996px",
        },
        "Shape path6612717": {
          width: "2.69px",
          height: "2.5px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 2.68798828125,
            height: 2.49560546875,
          },
          top: "-0.23px",
          left: "calc(50% - 1.34px - 0.96px)",
        },
        "Shape path6612718": {
          width: "2.69px",
          height: "0.38px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 2.68798828125,
            height: 0.3837890625,
          },
          top: "2.65px",
          left: "calc(50% - 1.34px - 0.96px)",
        },
        Shape6612716: {
          width: "2.69px",
          height: "3.03px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 2.68798828125,
            height: 3.033203125,
          },
          top: "0.81px",
          left: "0.96px",
        },
        upload6612714: {
          width: "4.61px",
          height: "4.61px",
          top: "1.53px",
          left: "1.54px",
        },
        Icon6612712: { width: "7.68px", height: "7.68px", top: "0.02px" },
        "You sold an item6612707": {
          width: "103.52px",
          height: "34.96px",
          top: "28.01px",
          left: "9.21px",
        },
        Background6612720: {
          width: "7.68px",
          height: "7.68px",
          left: "calc(50% - 3.84px - 0px)",
          borderRadius: "11.060866355895996px",
        },
        "Shape path6612722": {
          width: "1.28px",
          height: "1.28px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 1.27978515625,
            height: 1.27978515625,
          },
          top: "3.84px",
          left: "calc(50% - 0.64px - 3.2px)",
        },
        "Shape path6612723": {
          width: "1.28px",
          height: "1.28px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 1.27978515625,
            height: 1.27978515625,
          },
          top: "1.92px",
          left: "calc(50% - 0.64px - 3.2px)",
        },
        "Shape path6612724": {
          width: "1.28px",
          height: "1.28px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 1.27978515625,
            height: 1.27978515625,
          },
          left: "calc(50% - 0.64px - 3.2px)",
        },
        Shape6612721: {
          width: "1.28px",
          height: "5.12px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 1.27978515625,
            height: 5.1181640625,
          },
          top: "1.28px",
          left: "3.2px",
        },
        "dots-vertical Copy": {
          width: "7.68px",
          height: "7.68px",
          top: "9.21px",
          left: "128.25px",
        },
        "Recent Activity6612725": {
          fontSize: "5.529233932495117px",
          lineHeight: "7.6794915199279785px",
          top: "7.84px",
          left: "calc(50% - 23.5px - 39.86px)",
        },
        "Shape path6612727": {
          width: "1.28px",
          height: "1.28px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 1.27978515625,
            height: 1.27978515625,
          },
          top: "3.84px",
          left: "calc(50% - 0.64px - 71.93px)",
          transformOrigin: "top left",
          transform: "rotate(0deg)",
        },
        "Shape path6612728": {
          width: "1.28px",
          height: "1.28px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 1.27978515625,
            height: 1.27978515625,
          },
          top: "1.92px",
          left: "calc(50% - 0.64px - 71.93px)",
          transformOrigin: "top left",
          transform: "rotate(0deg)",
        },
        "Shape path6612729": {
          width: "1.28px",
          height: "1.28px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 1.27978515625,
            height: 1.27978515625,
          },
          left: "calc(50% - 0.64px - 71.93px)",
          transformOrigin: "top left",
          transform: "rotate(0deg)",
        },
        Shape6612726: {
          width: "1.28px",
          height: "5.12px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 5.12060546875,
            height: 1.27978515625,
          },
          top: "180.08px",
          left: "75px",
        },
        "Recent Activity6612659": {
          width: "145.14px",
          height: "187px",
          top: "489.58px",
          left: "186.52px",
        },
        "icons / bx-check-circle6612734": {},
        "Purchase open receivables from companies without recourse": {
          lineHeight: "22.399999618530273px",
          width: "243px",
        },
        line6612733: { width: "298px" },
        "icons / bx-check-circle6612737": {},
        "Get credit information of companies": {
          lineHeight: "22.399999618530273px",
          width: "263px",
        },
        line6612736: { width: "186px" },
        "icons / bx-check-circle6612740": {},
        "Monitor your investmens with full transparency": {
          lineHeight: "22.399999618530273px",
          width: "221px",
        },
        line6612739: { width: "239px" },
        "icons / bx-check-circle6612743": {},
        "Regulatory compliance and assurance": {
          lineHeight: "22.399999618530273px",
          width: "259px",
        },
        line6612742: { width: "287px" },
        "icons / bx-check-circle6612746": {},
        "Access to a new attractive asset-class": {
          lineHeight: "22.399999618530273px",
          width: "270px",
        },
        line6612745: { width: "287px" },
        list: {
          width: "287px",
          top: "89px",
          left: "calc(50% - 143.5px - 0px)",
        },
        "Get possibilities for better Investments": {
          fontSize: "20px",
          lineHeight: "26px",
          textAlign: "center",
          width: "188.29px",
          left: "calc(50% - 94.15px - -4.35px)",
        },
        text: { width: "287px", height: "313px" },
        Content: { width: "287px", height: "313px", top: "50px", left: "32px" },
        JoinWaitlist: {
          width: "320px",
          bottom: "24px",
          left: "calc(50% - 160px - 0px)",
        },
        Possibilites: { width: "360px" },
      },
      variantValues: { breakpoint: "small" },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "small",
    small: "small",
    medium: "medium",
    large: "large",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <View
      className="possibilitiesHolder"
      width="1920px"
      height="794px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      {...rest}
      {...getOverrideProps(overrides, "Possibilites")}
    >
      <View
        className="possibilitiesHolder"
        width="1922px"
        height="794px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="0px"
        left="0%"
        right="-0.1%"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(97,139,232,0.3)"
        {...getOverrideProps(overrides, "bg6612509")}
      ></View>
      <View
        padding="0px 0px 0px 0px"
        width="600.81px"
        height="375.12px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="96px"
        left="314px"
        {...getOverrideProps(overrides, "Top Selling Products6612510")}
      >
        <Icon
          width="600.81px"
          height="375.12px"
          viewBox={{
            minX: 0,
            minY: 0,
            width: 600.81005859375,
            height: 375.12109375,
          }}
          paths={[
            {
              d: "M0 22.1886C0 9.93415 9.93416 0 22.1886 0L578.621 0C590.876 0 600.81 9.93416 600.81 22.1886L600.81 352.932C600.81 365.187 590.876 375.121 578.621 375.121L22.1885 375.121C9.93409 375.121 0 365.187 0 352.932L0 22.1886Z",
              fill: "rgba(255,255,255,1)",
              fillRule: "evenodd",
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0px"
          left="calc(50% - 300.4px - 0px)"
          {...getOverrideProps(overrides, "Card-box6612511")}
        ></Icon>
        <Flex
          gap="10.783769607543945px"
          direction="column"
          width="554.59px"
          height="292.7px"
          justifyContent="flex-start"
          alignItems="flex-start"
          overflow="hidden"
          position="absolute"
          top="60.85px"
          left="calc(50% - 277.3px - 4.62px)"
          borderRadius="22.188566207885742px"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 3")}
        >
          <Flex
            padding="0px 0px 0px 0px"
            width="563.84px"
            height="44.1px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            {...getOverrideProps(overrides, "Group 48095449")}
          >
            <Icon
              width="563.84px"
              height="0.77px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 563.8369140625,
                height: 0.770263671875,
              }}
              paths={[
                {
                  d: "M0 0L563.837 0L563.837 0.770269L0 0.770269L0 0Z",
                  fill: "rgba(238,242,247,1)",
                  fillRule: "evenodd",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="0px"
              left="0px"
              {...getOverrideProps(overrides, "Border-top6612514")}
            ></Icon>
            <View
              padding="0px 0px 0px 0px"
              width="531.48px"
              height="31px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="13.1px"
              left="0px"
              {...getOverrideProps(overrides, "Products-16612515")}
            >
              <View
                padding="0px 0px 0px 0px"
                width="49px"
                height="15px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="311.96px"
                {...getOverrideProps(overrides, "Amount6612516")}
              >
                <Text
                  fontFamily="Lato"
                  fontSize="10.783769607543945px"
                  fontWeight="400"
                  color="rgba(145,156,167,1)"
                  lineHeight="14.635114669799805px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="0px"
                  left="0px"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="$6,518.18"
                  {...getOverrideProps(overrides, "$6,518.186612517")}
                ></Text>
              </View>
              <View
                padding="0px 0px 0px 0px"
                width="49px"
                height="15px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="382.82px"
                {...getOverrideProps(overrides, "Amount6612518")}
              >
                <Text
                  fontFamily="Lato"
                  fontSize="10.783769607543945px"
                  fontWeight="400"
                  color="rgba(145,156,167,1)"
                  lineHeight="14.635114669799805px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="0px"
                  left="0px"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="$6,218.18"
                  {...getOverrideProps(overrides, "$6,518.186612519")}
                ></Text>
              </View>
              <View
                padding="0px 0px 0px 0px"
                width="24px"
                height="15px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="453.69px"
                {...getOverrideProps(overrides, "Amount6612520")}
              >
                <Text
                  fontFamily="Lato"
                  fontSize="10.783769607543945px"
                  fontWeight="400"
                  color="rgba(34,197,94,1)"
                  lineHeight="14.635114669799805px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="0px"
                  left="0px"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="8.7%"
                  {...getOverrideProps(overrides, "$6,518.186612521")}
                ></Text>
              </View>
              <View
                padding="0px 0px 0px 0px"
                width="56px"
                height="15px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="175.62px"
                {...getOverrideProps(overrides, "Quantity6612522")}
              >
                <Text
                  fontFamily="Lato"
                  fontSize="10.783769607543945px"
                  fontWeight="400"
                  color="rgba(145,156,167,1)"
                  lineHeight="14.635114669799805px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="0px"
                  left="0px"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="01.11.2022"
                  {...getOverrideProps(overrides, "826612523")}
                ></Text>
              </View>
              <View
                width="37.74px"
                height="14.64px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                position="absolute"
                top="0px"
                left="252.65px"
                borderRadius="3.851346015930176px"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 26612524")}
              >
                <View
                  width="42.36px"
                  height="23.88px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="-4.62px"
                  left="-2.31px"
                  padding="0px 0px 0px 0px"
                  backgroundColor="rgba(239,68,68,1)"
                  opacity="0.15000000596046448"
                  {...getOverrideProps(overrides, "BG6612525")}
                ></View>
                <View
                  padding="0px 0px 0px 0px"
                  width="23px"
                  height="15px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="0px"
                  left="7.7px"
                  {...getOverrideProps(overrides, "Quantity6612526")}
                >
                  <Text
                    fontFamily="Lato"
                    fontSize="10.783769607543945px"
                    fontWeight="600"
                    color="rgba(239,68,68,1)"
                    lineHeight="14.635114669799805px"
                    textAlign="left"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    position="absolute"
                    top="0px"
                    left="0px"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="DUE"
                    {...getOverrideProps(overrides, "826612527")}
                  ></Text>
                </View>
              </View>
              <View
                padding="0px 0px 0px 0px"
                width="51px"
                height="15px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="98.59px"
                {...getOverrideProps(overrides, "Price6612528")}
              >
                <Text
                  fontFamily="Lato"
                  fontSize="10.783769607543945px"
                  fontWeight="400"
                  color="rgba(145,156,167,1)"
                  lineHeight="14.635114669799805px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="0px"
                  left="0px"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="RE220419"
                  {...getOverrideProps(overrides, "$79.496612529")}
                ></Text>
              </View>
              <View
                padding="0px 0px 0px 0px"
                width="68px"
                height="31px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="0px"
                {...getOverrideProps(overrides, "Products6612530")}
              >
                <Text
                  fontFamily="Lato"
                  fontSize="10.783769607543945px"
                  fontWeight="400"
                  color="rgba(145,156,167,1)"
                  lineHeight="14.635114669799805px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="0px"
                  left="0px"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Ra GmbH&#xA;27 September 2022"
                  {...getOverrideProps(
                    overrides,
                    "ASOS Ridley High Waist6612531"
                  )}
                ></Text>
              </View>
              <View
                width="12.13px"
                height="12.13px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                position="absolute"
                top="1.54px"
                left="519.35px"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(
                  overrides,
                  "icons / bx-link-external6612532"
                )}
              ></View>
              <View
                width="12.13px"
                height="12.13px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                position="absolute"
                top="1.54px"
                left="499.13px"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "icons / bx-link6612533")}
              ></View>
            </View>
          </Flex>
          <Flex
            padding="0px 0px 0px 0px"
            width="563.84px"
            height="44.09px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            {...getOverrideProps(overrides, "Group 48095450")}
          >
            <Icon
              width="563.84px"
              height="0.77px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 563.8369140625,
                height: 0.770263671875,
              }}
              paths={[
                {
                  d: "M0 0L563.837 0L563.837 0.770269L0 0.770269L0 0Z",
                  fill: "rgba(238,242,247,1)",
                  fillRule: "evenodd",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="0px"
              left="0px"
              {...getOverrideProps(overrides, "Border-top6612535")}
            ></Icon>
            <View
              padding="0px 0px 0px 0px"
              width="531.49px"
              height="31px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="13.09px"
              left="0px"
              {...getOverrideProps(overrides, "Products-16612536")}
            >
              <View
                padding="0px 0px 0px 0px"
                width="49px"
                height="15px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="311.96px"
                {...getOverrideProps(overrides, "Amount6612537")}
              >
                <Text
                  fontFamily="Lato"
                  fontSize="10.783769607543945px"
                  fontWeight="400"
                  color="rgba(145,156,167,1)"
                  lineHeight="14.635114669799805px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="0px"
                  left="0px"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="$6,518.18"
                  {...getOverrideProps(overrides, "$6,518.186612538")}
                ></Text>
              </View>
              <View
                padding="0px 0px 0px 0px"
                width="49px"
                height="15px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="382.82px"
                {...getOverrideProps(overrides, "Amount6612539")}
              >
                <Text
                  fontFamily="Lato"
                  fontSize="10.783769607543945px"
                  fontWeight="400"
                  color="rgba(145,156,167,1)"
                  lineHeight="14.635114669799805px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="0px"
                  left="0px"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="$6,218.18"
                  {...getOverrideProps(overrides, "$6,518.186612540")}
                ></Text>
              </View>
              <View
                padding="0px 0px 0px 0px"
                width="24px"
                height="15px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="453.69px"
                {...getOverrideProps(overrides, "Amount6612541")}
              >
                <Text
                  fontFamily="Lato"
                  fontSize="10.783769607543945px"
                  fontWeight="400"
                  color="rgba(34,197,94,1)"
                  lineHeight="14.635114669799805px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="0px"
                  left="0px"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="8.7%"
                  {...getOverrideProps(overrides, "$6,518.186612542")}
                ></Text>
              </View>
              <View
                padding="0px 0px 0px 0px"
                width="56px"
                height="15px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="175.62px"
                {...getOverrideProps(overrides, "Quantity6612543")}
              >
                <Text
                  fontFamily="Lato"
                  fontSize="10.783769607543945px"
                  fontWeight="400"
                  color="rgba(145,156,167,1)"
                  lineHeight="14.635114669799805px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="0px"
                  left="0px"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="01.11.2022"
                  {...getOverrideProps(overrides, "826612544")}
                ></Text>
              </View>
              <View
                width="37.74px"
                height="14.64px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                position="absolute"
                top="0px"
                left="252.65px"
                borderRadius="3.851346015930176px"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 26612545")}
              >
                <View
                  width="42.36px"
                  height="23.88px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="-4.62px"
                  left="-2.31px"
                  padding="0px 0px 0px 0px"
                  backgroundColor="rgba(255,186,8,1)"
                  opacity="0.15000000596046448"
                  {...getOverrideProps(overrides, "BG6612546")}
                ></View>
                <View
                  padding="0px 0px 0px 0px"
                  width="30px"
                  height="15px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="0px"
                  left="4.62px"
                  {...getOverrideProps(overrides, "Quantity6612547")}
                >
                  <Text
                    fontFamily="Lato"
                    fontSize="10.783769607543945px"
                    fontWeight="600"
                    color="rgba(255,186,8,1)"
                    lineHeight="14.635114669799805px"
                    textAlign="left"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    position="absolute"
                    top="0px"
                    left="0px"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="PEND"
                    {...getOverrideProps(overrides, "826612548")}
                  ></Text>
                </View>
              </View>
              <View
                padding="0px 0px 0px 0px"
                width="51px"
                height="15px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="98.59px"
                {...getOverrideProps(overrides, "Price6612549")}
              >
                <Text
                  fontFamily="Lato"
                  fontSize="10.783769607543945px"
                  fontWeight="400"
                  color="rgba(145,156,167,1)"
                  lineHeight="14.635114669799805px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="0px"
                  left="0px"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="RE220419"
                  {...getOverrideProps(overrides, "$79.496612550")}
                ></Text>
              </View>
              <View
                padding="0px 0px 0px 0px"
                width="84px"
                height="31px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="0px"
                {...getOverrideProps(overrides, "Products6612551")}
              >
                <Text
                  fontFamily="Lato"
                  fontSize="10.783769607543945px"
                  fontWeight="400"
                  color="rgba(145,156,167,1)"
                  lineHeight="14.635114669799805px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="0px"
                  left="0px"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Großmann GmbH&#xA;27 September 2022"
                  {...getOverrideProps(
                    overrides,
                    "ASOS Ridley High Waist6612552"
                  )}
                ></Text>
              </View>
              <View
                width="12.13px"
                height="12.13px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                position="absolute"
                top="1.54px"
                left="519.35px"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(
                  overrides,
                  "icons / bx-link-external6612553"
                )}
              ></View>
              <View
                width="12.13px"
                height="12.13px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                position="absolute"
                top="1.54px"
                left="499.13px"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "icons / bx-link6612554")}
              ></View>
            </View>
          </Flex>
          <Flex
            padding="0px 0px 0px 0px"
            width="563.84px"
            height="44.09px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            {...getOverrideProps(overrides, "Group 48095451")}
          >
            <Icon
              width="563.84px"
              height="0.77px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 563.8369140625,
                height: 0.770263671875,
              }}
              paths={[
                {
                  d: "M0 0L563.837 0L563.837 0.770269L0 0.770269L0 0Z",
                  fill: "rgba(238,242,247,1)",
                  fillRule: "evenodd",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="0px"
              left="0px"
              {...getOverrideProps(overrides, "Border-top6612556")}
            ></Icon>
            <View
              padding="0px 0px 0px 0px"
              width="531.49px"
              height="31px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="13.09px"
              left="0px"
              {...getOverrideProps(overrides, "Products-16612557")}
            >
              <View
                padding="0px 0px 0px 0px"
                width="49px"
                height="15px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="311.96px"
                {...getOverrideProps(overrides, "Amount6612558")}
              >
                <Text
                  fontFamily="Lato"
                  fontSize="10.783769607543945px"
                  fontWeight="400"
                  color="rgba(145,156,167,1)"
                  lineHeight="14.635114669799805px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="0px"
                  left="0px"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="$6,518.18"
                  {...getOverrideProps(overrides, "$6,518.186612559")}
                ></Text>
              </View>
              <View
                padding="0px 0px 0px 0px"
                width="49px"
                height="15px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="382.82px"
                {...getOverrideProps(overrides, "Amount6612560")}
              >
                <Text
                  fontFamily="Lato"
                  fontSize="10.783769607543945px"
                  fontWeight="400"
                  color="rgba(145,156,167,1)"
                  lineHeight="14.635114669799805px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="0px"
                  left="0px"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="$6,218.18"
                  {...getOverrideProps(overrides, "$6,518.186612561")}
                ></Text>
              </View>
              <View
                padding="0px 0px 0px 0px"
                width="24px"
                height="15px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="453.69px"
                {...getOverrideProps(overrides, "Amount6612562")}
              >
                <Text
                  fontFamily="Lato"
                  fontSize="10.783769607543945px"
                  fontWeight="400"
                  color="rgba(34,197,94,1)"
                  lineHeight="14.635114669799805px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="0px"
                  left="0px"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="8.7%"
                  {...getOverrideProps(overrides, "$6,518.186612563")}
                ></Text>
              </View>
              <View
                padding="0px 0px 0px 0px"
                width="56px"
                height="15px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="175.62px"
                {...getOverrideProps(overrides, "Quantity6612564")}
              >
                <Text
                  fontFamily="Lato"
                  fontSize="10.783769607543945px"
                  fontWeight="400"
                  color="rgba(145,156,167,1)"
                  lineHeight="14.635114669799805px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="0px"
                  left="0px"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="01.11.2022"
                  {...getOverrideProps(overrides, "826612565")}
                ></Text>
              </View>
              <View
                width="37.74px"
                height="14.64px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                position="absolute"
                top="0px"
                left="252.65px"
                borderRadius="3.851346015930176px"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 26612566")}
              >
                <View
                  width="42.36px"
                  height="23.88px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="-4.62px"
                  left="-2.31px"
                  padding="0px 0px 0px 0px"
                  backgroundColor="rgba(34,197,94,1)"
                  opacity="0.15000000596046448"
                  {...getOverrideProps(overrides, "BG6612567")}
                ></View>
                <View
                  padding="0px 0px 0px 0px"
                  width="25px"
                  height="15px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="0px"
                  left="7.7px"
                  {...getOverrideProps(overrides, "Quantity6612568")}
                >
                  <Text
                    fontFamily="Lato"
                    fontSize="10.783769607543945px"
                    fontWeight="600"
                    color="rgba(34,197,94,1)"
                    lineHeight="14.635114669799805px"
                    textAlign="left"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    position="absolute"
                    top="0px"
                    left="0px"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="PAID"
                    {...getOverrideProps(overrides, "826612569")}
                  ></Text>
                </View>
              </View>
              <View
                padding="0px 0px 0px 0px"
                width="51px"
                height="15px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="98.59px"
                {...getOverrideProps(overrides, "Price6612570")}
              >
                <Text
                  fontFamily="Lato"
                  fontSize="10.783769607543945px"
                  fontWeight="400"
                  color="rgba(145,156,167,1)"
                  lineHeight="14.635114669799805px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="0px"
                  left="0px"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="RE220419"
                  {...getOverrideProps(overrides, "$79.496612571")}
                ></Text>
              </View>
              <View
                padding="0px 0px 0px 0px"
                width="68px"
                height="31px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="0px"
                {...getOverrideProps(overrides, "Products6612572")}
              >
                <Text
                  fontFamily="Lato"
                  fontSize="10.783769607543945px"
                  fontWeight="400"
                  color="rgba(145,156,167,1)"
                  lineHeight="14.635114669799805px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="0px"
                  left="0px"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Hofman KG&#xA;27 September 2022"
                  {...getOverrideProps(
                    overrides,
                    "ASOS Ridley High Waist6612573"
                  )}
                ></Text>
              </View>
              <View
                width="12.13px"
                height="12.13px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                position="absolute"
                top="1.54px"
                left="519.35px"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(
                  overrides,
                  "icons / bx-link-external6612574"
                )}
              ></View>
              <View
                width="12.13px"
                height="12.13px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                position="absolute"
                top="1.54px"
                left="499.13px"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "icons / bx-link6612575")}
              ></View>
            </View>
          </Flex>
          <Flex
            padding="0px 0px 0px 0px"
            width="563.84px"
            height="44.1px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            {...getOverrideProps(overrides, "Group 48095452")}
          >
            <Icon
              width="563.84px"
              height="0.77px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 563.8369140625,
                height: 0.770263671875,
              }}
              paths={[
                {
                  d: "M0 0L563.837 0L563.837 0.770269L0 0.770269L0 0Z",
                  fill: "rgba(238,242,247,1)",
                  fillRule: "evenodd",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="0px"
              left="0px"
              {...getOverrideProps(overrides, "Border-top6612577")}
            ></Icon>
            <View
              padding="0px 0px 0px 0px"
              width="531.48px"
              height="31px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="13.1px"
              left="0px"
              {...getOverrideProps(overrides, "Products-16612578")}
            >
              <View
                padding="0px 0px 0px 0px"
                width="49px"
                height="15px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="311.96px"
                {...getOverrideProps(overrides, "Amount6612579")}
              >
                <Text
                  fontFamily="Lato"
                  fontSize="10.783769607543945px"
                  fontWeight="400"
                  color="rgba(145,156,167,1)"
                  lineHeight="14.635114669799805px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="0px"
                  left="0px"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="$6,518.18"
                  {...getOverrideProps(overrides, "$6,518.186612580")}
                ></Text>
              </View>
              <View
                padding="0px 0px 0px 0px"
                width="49px"
                height="15px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="382.82px"
                {...getOverrideProps(overrides, "Amount6612581")}
              >
                <Text
                  fontFamily="Lato"
                  fontSize="10.783769607543945px"
                  fontWeight="400"
                  color="rgba(145,156,167,1)"
                  lineHeight="14.635114669799805px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="0px"
                  left="0px"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="$6,218.18"
                  {...getOverrideProps(overrides, "$6,518.186612582")}
                ></Text>
              </View>
              <View
                padding="0px 0px 0px 0px"
                width="24px"
                height="15px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="453.69px"
                {...getOverrideProps(overrides, "Amount6612583")}
              >
                <Text
                  fontFamily="Lato"
                  fontSize="10.783769607543945px"
                  fontWeight="400"
                  color="rgba(34,197,94,1)"
                  lineHeight="14.635114669799805px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="0px"
                  left="0px"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="8.7%"
                  {...getOverrideProps(overrides, "$6,518.186612584")}
                ></Text>
              </View>
              <View
                padding="0px 0px 0px 0px"
                width="56px"
                height="15px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="175.62px"
                {...getOverrideProps(overrides, "Quantity6612585")}
              >
                <Text
                  fontFamily="Lato"
                  fontSize="10.783769607543945px"
                  fontWeight="400"
                  color="rgba(145,156,167,1)"
                  lineHeight="14.635114669799805px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="0px"
                  left="0px"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="01.11.2022"
                  {...getOverrideProps(overrides, "826612586")}
                ></Text>
              </View>
              <View
                width="37.74px"
                height="14.64px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                position="absolute"
                top="0px"
                left="252.65px"
                borderRadius="3.851346015930176px"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 26612587")}
              >
                <View
                  width="42.36px"
                  height="23.88px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="-4.62px"
                  left="-2.31px"
                  padding="0px 0px 0px 0px"
                  backgroundColor="rgba(34,197,94,1)"
                  opacity="0.15000000596046448"
                  {...getOverrideProps(overrides, "BG6612588")}
                ></View>
                <View
                  padding="0px 0px 0px 0px"
                  width="25px"
                  height="15px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="0px"
                  left="7.7px"
                  {...getOverrideProps(overrides, "Quantity6612589")}
                >
                  <Text
                    fontFamily="Lato"
                    fontSize="10.783769607543945px"
                    fontWeight="600"
                    color="rgba(34,197,94,1)"
                    lineHeight="14.635114669799805px"
                    textAlign="left"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    position="absolute"
                    top="0px"
                    left="0px"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="PAID"
                    {...getOverrideProps(overrides, "826612590")}
                  ></Text>
                </View>
              </View>
              <View
                padding="0px 0px 0px 0px"
                width="51px"
                height="15px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="98.6px"
                {...getOverrideProps(overrides, "Price6612591")}
              >
                <Text
                  fontFamily="Lato"
                  fontSize="10.783769607543945px"
                  fontWeight="400"
                  color="rgba(145,156,167,1)"
                  lineHeight="14.635114669799805px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="0px"
                  left="0px"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="RE220419"
                  {...getOverrideProps(overrides, "$79.496612592")}
                ></Text>
              </View>
              <View
                padding="0px 0px 0px 0px"
                width="69px"
                height="31px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="0px"
                {...getOverrideProps(overrides, "Products6612593")}
              >
                <Text
                  fontFamily="Lato"
                  fontSize="10.783769607543945px"
                  fontWeight="400"
                  color="rgba(145,156,167,1)"
                  lineHeight="14.635114669799805px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="0px"
                  left="0px"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Auer and Sons&#xA;27 September 2022"
                  {...getOverrideProps(
                    overrides,
                    "ASOS Ridley High Waist6612594"
                  )}
                ></Text>
              </View>
              <View
                width="12.13px"
                height="12.13px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                position="absolute"
                top="1.54px"
                left="519.35px"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(
                  overrides,
                  "icons / bx-link-external6612595"
                )}
              ></View>
              <View
                width="12.13px"
                height="12.13px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                position="absolute"
                top="1.54px"
                left="499.13px"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "icons / bx-link6612596")}
              ></View>
            </View>
          </Flex>
          <Flex
            padding="0px 0px 0px 0px"
            width="563.84px"
            height="44.1px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            {...getOverrideProps(overrides, "Group 48095453")}
          >
            <Icon
              width="563.84px"
              height="0.77px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 563.8369140625,
                height: 0.770263671875,
              }}
              paths={[
                {
                  d: "M0 0L563.837 0L563.837 0.770269L0 0.770269L0 0Z",
                  fill: "rgba(238,242,247,1)",
                  fillRule: "evenodd",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="0px"
              left="0px"
              {...getOverrideProps(overrides, "Border-top6612598")}
            ></Icon>
            <View
              padding="0px 0px 0px 0px"
              width="531.49px"
              height="31px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="13.1px"
              left="0px"
              {...getOverrideProps(overrides, "Products-16612599")}
            >
              <View
                padding="0px 0px 0px 0px"
                width="49px"
                height="15px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="311.96px"
                {...getOverrideProps(overrides, "Amount6612600")}
              >
                <Text
                  fontFamily="Lato"
                  fontSize="10.783769607543945px"
                  fontWeight="400"
                  color="rgba(145,156,167,1)"
                  lineHeight="14.635114669799805px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="0px"
                  left="0px"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="$6,518.18"
                  {...getOverrideProps(overrides, "$6,518.186612601")}
                ></Text>
              </View>
              <View
                padding="0px 0px 0px 0px"
                width="49px"
                height="15px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="382.82px"
                {...getOverrideProps(overrides, "Amount6612602")}
              >
                <Text
                  fontFamily="Lato"
                  fontSize="10.783769607543945px"
                  fontWeight="400"
                  color="rgba(145,156,167,1)"
                  lineHeight="14.635114669799805px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="0px"
                  left="0px"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="$6,218.18"
                  {...getOverrideProps(overrides, "$6,518.186612603")}
                ></Text>
              </View>
              <View
                padding="0px 0px 0px 0px"
                width="24px"
                height="15px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="453.69px"
                {...getOverrideProps(overrides, "Amount6612604")}
              >
                <Text
                  fontFamily="Lato"
                  fontSize="10.783769607543945px"
                  fontWeight="400"
                  color="rgba(34,197,94,1)"
                  lineHeight="14.635114669799805px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="0px"
                  left="0px"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="8.7%"
                  {...getOverrideProps(overrides, "$6,518.186612605")}
                ></Text>
              </View>
              <View
                padding="0px 0px 0px 0px"
                width="56px"
                height="15px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="175.62px"
                {...getOverrideProps(overrides, "Quantity6612606")}
              >
                <Text
                  fontFamily="Lato"
                  fontSize="10.783769607543945px"
                  fontWeight="400"
                  color="rgba(145,156,167,1)"
                  lineHeight="14.635114669799805px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="0px"
                  left="0px"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="01.11.2022"
                  {...getOverrideProps(overrides, "826612607")}
                ></Text>
              </View>
              <View
                width="37.74px"
                height="14.64px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                position="absolute"
                top="0px"
                left="252.65px"
                borderRadius="3.851346015930176px"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 26612608")}
              >
                <View
                  width="42.36px"
                  height="23.88px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="-4.62px"
                  left="-2.31px"
                  padding="0px 0px 0px 0px"
                  backgroundColor="rgba(34,197,94,1)"
                  opacity="0.15000000596046448"
                  {...getOverrideProps(overrides, "BG6612609")}
                ></View>
                <View
                  padding="0px 0px 0px 0px"
                  width="25px"
                  height="15px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="0px"
                  left="7.7px"
                  {...getOverrideProps(overrides, "Quantity6612610")}
                >
                  <Text
                    fontFamily="Lato"
                    fontSize="10.783769607543945px"
                    fontWeight="600"
                    color="rgba(34,197,94,1)"
                    lineHeight="14.635114669799805px"
                    textAlign="left"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    position="absolute"
                    top="0px"
                    left="0px"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="PAID"
                    {...getOverrideProps(overrides, "826612611")}
                  ></Text>
                </View>
              </View>
              <View
                padding="0px 0px 0px 0px"
                width="51px"
                height="15px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="98.6px"
                {...getOverrideProps(overrides, "Price6612612")}
              >
                <Text
                  fontFamily="Lato"
                  fontSize="10.783769607543945px"
                  fontWeight="400"
                  color="rgba(145,156,167,1)"
                  lineHeight="14.635114669799805px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="0px"
                  left="0px"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="RE220419"
                  {...getOverrideProps(overrides, "$79.496612613")}
                ></Text>
              </View>
              <View
                padding="0px 0px 0px 0px"
                width="68px"
                height="31px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="0px"
                {...getOverrideProps(overrides, "Products6612614")}
              >
                <Text
                  fontFamily="Lato"
                  fontSize="10.783769607543945px"
                  fontWeight="400"
                  color="rgba(145,156,167,1)"
                  lineHeight="14.635114669799805px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="0px"
                  left="0px"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Ra GmbH&#xA;27 September 2022"
                  {...getOverrideProps(
                    overrides,
                    "ASOS Ridley High Waist6612615"
                  )}
                ></Text>
              </View>
              <View
                width="12.13px"
                height="12.13px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                position="absolute"
                top="1.54px"
                left="519.36px"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(
                  overrides,
                  "icons / bx-link-external6612616"
                )}
              ></View>
              <View
                width="12.13px"
                height="12.13px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                position="absolute"
                top="1.54px"
                left="499.13px"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "icons / bx-link6612617")}
              ></View>
            </View>
          </Flex>
          <Flex
            padding="0px 0px 0px 0px"
            width="563.84px"
            height="44.09px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            {...getOverrideProps(overrides, "Group 48095454")}
          >
            <Icon
              width="563.84px"
              height="0.77px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 563.8369140625,
                height: 0.770263671875,
              }}
              paths={[
                {
                  d: "M0 0L563.837 0L563.837 0.770269L0 0.770269L0 0Z",
                  fill: "rgba(238,242,247,1)",
                  fillRule: "evenodd",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="0px"
              left="0px"
              {...getOverrideProps(overrides, "Border-top6612619")}
            ></Icon>
            <View
              padding="0px 0px 0px 0px"
              width="531.49px"
              height="31px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="13.09px"
              left="0px"
              {...getOverrideProps(overrides, "Products-16612620")}
            >
              <View
                padding="0px 0px 0px 0px"
                width="49px"
                height="15px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="311.96px"
                {...getOverrideProps(overrides, "Amount6612621")}
              >
                <Text
                  fontFamily="Lato"
                  fontSize="10.783769607543945px"
                  fontWeight="400"
                  color="rgba(145,156,167,1)"
                  lineHeight="14.635114669799805px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="0px"
                  left="0px"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="$6,518.18"
                  {...getOverrideProps(overrides, "$6,518.186612622")}
                ></Text>
              </View>
              <View
                padding="0px 0px 0px 0px"
                width="49px"
                height="15px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="382.82px"
                {...getOverrideProps(overrides, "Amount6612623")}
              >
                <Text
                  fontFamily="Lato"
                  fontSize="10.783769607543945px"
                  fontWeight="400"
                  color="rgba(145,156,167,1)"
                  lineHeight="14.635114669799805px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="0px"
                  left="0px"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="$6,218.18"
                  {...getOverrideProps(overrides, "$6,518.186612624")}
                ></Text>
              </View>
              <View
                padding="0px 0px 0px 0px"
                width="24px"
                height="15px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="453.69px"
                {...getOverrideProps(overrides, "Amount6612625")}
              >
                <Text
                  fontFamily="Lato"
                  fontSize="10.783769607543945px"
                  fontWeight="400"
                  color="rgba(34,197,94,1)"
                  lineHeight="14.635114669799805px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="0px"
                  left="0px"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="8.7%"
                  {...getOverrideProps(overrides, "$6,518.186612626")}
                ></Text>
              </View>
              <View
                padding="0px 0px 0px 0px"
                width="56px"
                height="15px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="175.62px"
                {...getOverrideProps(overrides, "Quantity6612627")}
              >
                <Text
                  fontFamily="Lato"
                  fontSize="10.783769607543945px"
                  fontWeight="400"
                  color="rgba(145,156,167,1)"
                  lineHeight="14.635114669799805px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="0px"
                  left="0px"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="01.11.2022"
                  {...getOverrideProps(overrides, "826612628")}
                ></Text>
              </View>
              <View
                width="37.74px"
                height="14.64px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                position="absolute"
                top="0px"
                left="252.65px"
                borderRadius="3.851346015930176px"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 26612629")}
              >
                <View
                  width="42.36px"
                  height="23.88px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="-4.62px"
                  left="-2.31px"
                  padding="0px 0px 0px 0px"
                  backgroundColor="rgba(34,197,94,1)"
                  opacity="0.15000000596046448"
                  {...getOverrideProps(overrides, "BG6612630")}
                ></View>
                <View
                  padding="0px 0px 0px 0px"
                  width="25px"
                  height="15px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="0px"
                  left="7.7px"
                  {...getOverrideProps(overrides, "Quantity6612631")}
                >
                  <Text
                    fontFamily="Lato"
                    fontSize="10.783769607543945px"
                    fontWeight="600"
                    color="rgba(34,197,94,1)"
                    lineHeight="14.635114669799805px"
                    textAlign="left"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    position="absolute"
                    top="0px"
                    left="0px"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="PAID"
                    {...getOverrideProps(overrides, "826612632")}
                  ></Text>
                </View>
              </View>
              <View
                padding="0px 0px 0px 0px"
                width="51px"
                height="15px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="98.6px"
                {...getOverrideProps(overrides, "Price6612633")}
              >
                <Text
                  fontFamily="Lato"
                  fontSize="10.783769607543945px"
                  fontWeight="400"
                  color="rgba(145,156,167,1)"
                  lineHeight="14.635114669799805px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="0px"
                  left="0px"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="RE220419"
                  {...getOverrideProps(overrides, "$79.496612634")}
                ></Text>
              </View>
              <View
                padding="0px 0px 0px 0px"
                width="68px"
                height="31px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="0px"
                {...getOverrideProps(overrides, "Products6612635")}
              >
                <Text
                  fontFamily="Lato"
                  fontSize="10.783769607543945px"
                  fontWeight="400"
                  color="rgba(145,156,167,1)"
                  lineHeight="14.635114669799805px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="0px"
                  left="0px"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Ra GmbH&#xA;27 September 2022"
                  {...getOverrideProps(
                    overrides,
                    "ASOS Ridley High Waist6612636"
                  )}
                ></Text>
              </View>
              <View
                width="12.13px"
                height="12.13px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                position="absolute"
                top="1.54px"
                left="519.36px"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(
                  overrides,
                  "icons / bx-link-external6612637"
                )}
              ></View>
              <View
                width="12.13px"
                height="12.13px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                position="absolute"
                top="1.54px"
                left="499.13px"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "icons / bx-link6612638")}
              ></View>
            </View>
          </Flex>
        </Flex>
        <Text
          fontFamily="Lato"
          fontSize="11.091876983642578px"
          fontWeight="700"
          color="rgba(43,54,119,1)"
          textTransform="uppercase"
          lineHeight="15.405384063720703px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          position="absolute"
          top="15.73px"
          left="calc(50% - 29px - 252.92px)"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="HOLDINGS"
          {...getOverrideProps(overrides, "Top Selling Products6612639")}
        ></Text>
        <View
          width="582.32px"
          height="24.65px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          overflow="hidden"
          position="absolute"
          top="33.89px"
          left="calc(50% - 291.16px - -9.24px)"
          borderRadius="22.188566207885742px"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(255,255,255,1)"
          opacity="0.5"
          {...getOverrideProps(overrides, "Frame 48095386")}
        >
          <View
            padding="0px 0px 0px 0px"
            width="537.28px"
            height="15px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="10.01px"
            left="0px"
            {...getOverrideProps(overrides, "Header")}
          >
            <View
              padding="0px 0px 0px 0px"
              width="537.28px"
              height="15px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="0px"
              left="0px"
              {...getOverrideProps(overrides, "Products-16612642")}
            >
              <View
                padding="0px 0px 0px 0px"
                width="43px"
                height="15px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="318.89px"
                {...getOverrideProps(overrides, "Amount6612643")}
              >
                <Text
                  fontFamily="Lato"
                  fontSize="7.702692031860352px"
                  fontWeight="700"
                  color="rgba(97,139,232,1)"
                  lineHeight="14.635114669799805px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="0px"
                  left="0px"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="AMOUNT"
                  {...getOverrideProps(overrides, "$6,518.186612644")}
                ></Text>
              </View>
              <View
                padding="0px 0px 0px 0px"
                width="58px"
                height="15px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="383.59px"
                {...getOverrideProps(overrides, "Amount6612645")}
              >
                <Text
                  fontFamily="Lato"
                  fontSize="7.702692031860352px"
                  fontWeight="700"
                  color="rgba(97,139,232,1)"
                  lineHeight="14.635114669799805px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="0px"
                  left="0px"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="DISCOUNTED"
                  {...getOverrideProps(overrides, "$6,518.186612646")}
                ></Text>
              </View>
              <View
                padding="0px 0px 0px 0px"
                width="23px"
                height="15px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="457.54px"
                {...getOverrideProps(overrides, "Amount6612647")}
              >
                <Text
                  fontFamily="Lato"
                  fontSize="7.702692031860352px"
                  fontWeight="700"
                  color="rgba(97,139,232,1)"
                  lineHeight="14.635114669799805px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="0px"
                  left="0px"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="APY"
                  {...getOverrideProps(overrides, "$6,518.186612648")}
                ></Text>
              </View>
              <View
                padding="0px 0px 0px 0px"
                width="42px"
                height="15px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="495.28px"
                {...getOverrideProps(overrides, "Amount6612649")}
              >
                <Text
                  fontFamily="Lato"
                  fontSize="7.702692031860352px"
                  fontWeight="700"
                  color="rgba(97,139,232,1)"
                  lineHeight="14.635114669799805px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="0px"
                  left="0px"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="CONTRACT"
                  {...getOverrideProps(overrides, "$6,518.186612650")}
                ></Text>
              </View>
              <View
                padding="0px 0px 0px 0px"
                width="46px"
                height="15px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="184.09px"
                {...getOverrideProps(overrides, "Quantity6612651")}
              >
                <Text
                  fontFamily="Lato"
                  fontSize="7.702692031860352px"
                  fontWeight="700"
                  color="rgba(97,139,232,1)"
                  lineHeight="14.635114669799805px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="0px"
                  left="0px"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="DUE DATE"
                  {...getOverrideProps(overrides, "826612652")}
                ></Text>
              </View>
              <View
                padding="0px 0px 0px 0px"
                width="36px"
                height="15px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="258.04px"
                {...getOverrideProps(overrides, "Quantity6612653")}
              >
                <Text
                  fontFamily="Lato"
                  fontSize="7.702692031860352px"
                  fontWeight="700"
                  color="rgba(97,139,232,1)"
                  lineHeight="14.635114669799805px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="0px"
                  left="0px"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="STATUS"
                  {...getOverrideProps(overrides, "826612654")}
                ></Text>
              </View>
              <View
                padding="0px 0px 0px 0px"
                width="52px"
                height="15px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="102.45px"
                {...getOverrideProps(overrides, "Price6612655")}
              >
                <Text
                  fontFamily="Lato"
                  fontSize="7.702692031860352px"
                  fontWeight="700"
                  color="rgba(97,139,232,1)"
                  lineHeight="14.635114669799805px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="0px"
                  left="0px"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="INVOICE NR"
                  {...getOverrideProps(overrides, "$79.496612656")}
                ></Text>
              </View>
              <View
                padding="0px 0px 0px 0px"
                width="31px"
                height="15px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="0px"
                {...getOverrideProps(overrides, "Products6612657")}
              >
                <Text
                  fontFamily="Lato"
                  fontSize="7.702692031860352px"
                  fontWeight="700"
                  color="rgba(97,139,232,1)"
                  lineHeight="14.635114669799805px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="0px"
                  left="0px"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="NAME   "
                  {...getOverrideProps(
                    overrides,
                    "ASOS Ridley High Waist6612658"
                  )}
                ></Text>
              </View>
            </View>
          </View>
        </View>
      </View>
      <View
        padding="0px 0px 0px 0px"
        width="340px"
        height="438.04px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="259px"
        left="683px"
        {...getOverrideProps(overrides, "Recent Activity6612659")}
      >
        <Icon
          width="340px"
          height="438.04px"
          viewBox={{ minX: 0, minY: 0, width: 340, height: 438.04248046875 }}
          paths={[
            {
              d: "M0 25.9104C0 11.6005 11.6005 0 25.9104 0L314.09 0C328.4 0 340 11.6005 340 25.9104L340 412.132C340 426.442 328.4 438.042 314.09 438.042L25.9104 438.042C11.6005 438.042 0 426.442 0 412.132L0 25.9104Z",
              fill: "rgba(255,255,255,1)",
              fillRule: "evenodd",
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0px"
          left="calc(50% - 170px - 0px)"
          {...getOverrideProps(overrides, "Card-box6612660")}
        ></Icon>
        <View
          padding="0px 0px 0px 0px"
          width="241.98px"
          height="72px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="344.45px"
          left="21.59px"
          {...getOverrideProps(overrides, "You sold an item6612661")}
        >
          <Text
            fontFamily="Lato"
            fontSize="10.793652534484863px"
            fontWeight="400"
            color="rgba(152,166,173,1)"
            lineHeight="14.3915376663208px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="53.97px"
            left="calc(50% - 28.5px - 65.51px)"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="14 days ago"
            {...getOverrideProps(overrides, "14 hours ago6612662")}
          ></Text>
          <Text
            fontFamily="Lato"
            fontSize="10.793652534484863px"
            fontWeight="400"
            color="rgba(145,156,167,1)"
            lineHeight="14.3915376663208px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="23.39px"
            left="calc(50% - 107.5px - -13.49px)"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="You just purchased a Receivable of Ra GmbH &#xA;“Ra RE220425”"
            {...getOverrideProps(
              overrides,
              "Paul Burgess just purchased \u201CH6612663"
            )}
          ></Text>
          <Text
            fontFamily="Lato"
            fontSize="12.952383995056152px"
            fontWeight="700"
            color="rgba(68,186,220,1)"
            lineHeight="17.989421844482422px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="0px"
            left="calc(50% - 84.5px - 9.51px)"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="You bought a new Receivable"
            {...getOverrideProps(overrides, "You sold an item6612664")}
          ></Text>
          <Icon
            width="1.8px"
            height="53.97px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.798828125,
              height: 53.96826171875,
            }}
            paths={[
              {
                d: "M0 0.89947C0 0.402706 0.402707 0 0.899471 0L0.899471 0C1.39624 0 1.79894 0.402707 1.79894 0.899471L1.79894 53.0688C1.79894 53.5655 1.39624 53.9683 0.899471 53.9683L0.899471 53.9683C0.402707 53.9683 0 53.5655 0 53.0688L0 0.89947Z",
                fill: "rgba(241,243,250,1)",
                fillRule: "evenodd",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="18.03px"
            left="calc(50% - 0.9px - 112px)"
            {...getOverrideProps(overrides, "border6612665")}
          ></Icon>
          <View
            padding="0px 0px 0px 0px"
            width="17.99px"
            height="17.99px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0.04px"
            left="0px"
            {...getOverrideProps(overrides, "Icon6612666")}
          >
            <Icon
              width="17.99px"
              height="17.99px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 17.9892578125,
                height: 17.989501953125,
              }}
              paths={[
                {
                  d: "M0 8.99471C0 4.02707 4.02707 0 8.99471 0C13.9624 0 17.9894 4.02707 17.9894 8.99471C17.9894 13.9624 13.9624 17.9894 8.99471 17.9894C4.02707 17.9894 0 13.9624 0 8.99471Z",
                  fill: "rgba(68,186,220,0.251)",
                  fillRule: "evenodd",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="0px"
              left="calc(50% - 8.99px - 0px)"
              {...getOverrideProps(overrides, "bg6612667")}
            ></Icon>
            <View
              padding="0px 0px 0px 0px"
              width="10.79px"
              height="10.79px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="3.6px"
              left="3.6px"
              {...getOverrideProps(overrides, "upload6612668")}
            >
              <View
                width="10.79px"
                height="10.79px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="calc(50% - 5.4px - 0px)"
                borderRadius="25.91038703918457px"
                padding="0px 0px 0px 0px"
                backgroundColor="rgba(0,0,0,1)"
                {...getOverrideProps(overrides, "Background6612669")}
              ></View>
              <Icon
                width="6.3px"
                height="7.11px"
                viewBox={{
                  minX: 0,
                  minY: 0,
                  width: 6.29638671875,
                  height: 7.105224609375,
                }}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="1.89px"
                left="2.25px"
                {...getOverrideProps(overrides, "Shape6612670")}
              >
                <Icon
                  width="6.3px"
                  height="5.85px"
                  viewBox={{
                    minX: 0,
                    minY: 0,
                    width: 6.29638671875,
                    height: 5.8466796875,
                  }}
                  paths={[]}
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="-0.54px"
                  left="calc(50% - 3.15px - 2.25px)"
                  {...getOverrideProps(overrides, "Shape path6612671")}
                ></Icon>
                <Icon
                  width="6.3px"
                  height="0.9px"
                  viewBox={{
                    minX: 0,
                    minY: 0,
                    width: 6.29638671875,
                    height: 0.8994140625,
                  }}
                  paths={[]}
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="6.21px"
                  left="calc(50% - 3.15px - 2.25px)"
                  {...getOverrideProps(overrides, "Shape path6612672")}
                ></Icon>
              </Icon>
            </View>
          </View>
        </View>
        <View
          padding="0px 0px 0px 0px"
          width="295.98px"
          height="65.71px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="278.79px"
          left="21.59px"
          {...getOverrideProps(overrides, "Audrey Tobey6612673")}
        >
          <Text
            fontFamily="Lato"
            fontSize="10.793652534484863px"
            fontWeight="400"
            color="rgba(152,166,173,1)"
            lineHeight="14.3915376663208px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="37.78px"
            left="calc(50% - 40px - 81.01px)"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="3 days hours ago"
            {...getOverrideProps(overrides, "14 hours ago6612674")}
          ></Text>
          <Text
            fontFamily="Lato"
            fontSize="10.793652534484863px"
            fontWeight="400"
            color="rgba(145,156,167,1)"
            lineHeight="14.3915376663208px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="23.39px"
            left="calc(50% - 134.5px - -13.49px)"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="A new investment opportunity from one of your contacts "
            {...getOverrideProps(overrides, 'Uploaded a photo "Error.jpg"')}
          ></Text>
          <Text
            fontFamily="Lato"
            fontSize="12.952383995056152px"
            fontWeight="700"
            color="rgba(54,136,252,1)"
            lineHeight="17.989421844482422px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="0px"
            left="calc(50% - 119.5px - 1.51px)"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Auer and Sons uploaded a new receivable"
            {...getOverrideProps(overrides, "Audrey Tobey6612676")}
          ></Text>
          <Icon
            width="1.8px"
            height="47.67px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.798828125,
              height: 47.671875,
            }}
            paths={[
              {
                d: "M0 0.89947C0 0.402706 0.402707 0 0.899471 0L0.899471 0C1.39624 0 1.79894 0.402707 1.79894 0.899471L1.79894 46.7725C1.79894 47.2693 1.39624 47.672 0.899471 47.672L0.899471 47.672C0.402707 47.672 0 47.2693 0 46.7725L0 0.89947Z",
                fill: "rgba(241,243,250,1)",
                fillRule: "evenodd",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="18.03px"
            left="calc(50% - 0.9px - 139px)"
            {...getOverrideProps(overrides, "border6612677")}
          ></Icon>
          <View
            padding="0px 0px 0px 0px"
            width="17.99px"
            height="17.99px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0.04px"
            left="0px"
            {...getOverrideProps(overrides, "Icon6612678")}
          >
            <Icon
              width="17.99px"
              height="17.99px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 17.9892578125,
                height: 17.989501953125,
              }}
              paths={[
                {
                  d: "M0 8.99471C0 4.02707 4.02707 0 8.99471 0C13.9624 0 17.9894 4.02707 17.9894 8.99471C17.9894 13.9624 13.9624 17.9894 8.99471 17.9894C4.02707 17.9894 0 13.9624 0 8.99471Z",
                  fill: "rgba(54,136,252,0.251)",
                  fillRule: "evenodd",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="0px"
              left="calc(50% - 8.99px - 0px)"
              {...getOverrideProps(overrides, "bg6612679")}
            ></Icon>
            <View
              padding="0px 0px 0px 0px"
              width="10.79px"
              height="10.79px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="3.6px"
              left="3.6px"
              {...getOverrideProps(overrides, "upload6612680")}
            >
              <View
                width="10.79px"
                height="10.79px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="calc(50% - 5.4px - 0px)"
                borderRadius="25.91038703918457px"
                padding="0px 0px 0px 0px"
                backgroundColor="rgba(0,0,0,1)"
                {...getOverrideProps(overrides, "Background6612681")}
              ></View>
              <Icon
                width="6.3px"
                height="7.11px"
                viewBox={{
                  minX: 0,
                  minY: 0,
                  width: 6.29638671875,
                  height: 7.105224609375,
                }}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="1.89px"
                left="2.25px"
                {...getOverrideProps(overrides, "Shape6612682")}
              >
                <Icon
                  width="6.3px"
                  height="5.85px"
                  viewBox={{
                    minX: 0,
                    minY: 0,
                    width: 6.29638671875,
                    height: 5.8466796875,
                  }}
                  paths={[]}
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="-0.54px"
                  left="calc(50% - 3.15px - 2.25px)"
                  {...getOverrideProps(overrides, "Shape path6612683")}
                ></Icon>
                <Icon
                  width="6.3px"
                  height="0.9px"
                  viewBox={{
                    minX: 0,
                    minY: 0,
                    width: 6.29638671875,
                    height: 0.8994140625,
                  }}
                  paths={[]}
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="6.21px"
                  left="calc(50% - 3.15px - 2.25px)"
                  {...getOverrideProps(overrides, "Shape path6612684")}
                ></Icon>
              </Icon>
            </View>
          </View>
        </View>
        <View
          padding="0px 0px 0px 0px"
          width="200.98px"
          height="65.71px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="213.13px"
          left="21.59px"
          {...getOverrideProps(overrides, "Robert Delaney6612685")}
        >
          <Text
            fontFamily="Lato"
            fontSize="10.793652534484863px"
            fontWeight="400"
            color="rgba(152,166,173,1)"
            lineHeight="14.3915376663208px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="37.78px"
            left="calc(50% - 25.5px - 48.01px)"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="2 days ago"
            {...getOverrideProps(overrides, "2 hours ago")}
          ></Text>
          <Text
            fontFamily="Lato"
            fontSize="10.793652534484863px"
            fontWeight="400"
            color="rgba(145,156,167,1)"
            lineHeight="14.3915376663208px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="23.39px"
            left="calc(50% - 87px - -13.49px)"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="You just purchased a “Ra RE220425”"
            {...getOverrideProps(overrides, 'Send you message "Are you ther')}
          ></Text>
          <Text
            fontFamily="Lato"
            fontSize="12.952383995056152px"
            fontWeight="700"
            color="rgba(68,186,220,1)"
            lineHeight="17.989421844482422px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="0px"
            left="calc(50% - 84.5px - -10.99px)"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="You bought a new Receivable"
            {...getOverrideProps(overrides, "Robert Delaney6612688")}
          ></Text>
          <Icon
            width="1.8px"
            height="47.67px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.798828125,
              height: 47.671875,
            }}
            paths={[
              {
                d: "M0 0.89947C0 0.402706 0.402707 0 0.899471 0L0.899471 0C1.39624 0 1.79894 0.402707 1.79894 0.899471L1.79894 46.7725C1.79894 47.2693 1.39624 47.672 0.899471 47.672L0.899471 47.672C0.402707 47.672 0 47.2693 0 46.7725L0 0.89947Z",
                fill: "rgba(241,243,250,1)",
                fillRule: "evenodd",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="18.03px"
            left="calc(50% - 0.9px - 91.5px)"
            {...getOverrideProps(overrides, "border6612689")}
          ></Icon>
          <View
            padding="0px 0px 0px 0px"
            width="17.99px"
            height="17.99px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0.05px"
            left="0px"
            {...getOverrideProps(overrides, "Icon6612690")}
          >
            <Icon
              width="17.99px"
              height="17.99px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 17.9892578125,
                height: 17.989501953125,
              }}
              paths={[
                {
                  d: "M0 8.99471C0 4.02707 4.02707 0 8.99471 0C13.9624 0 17.9894 4.02707 17.9894 8.99471C17.9894 13.9624 13.9624 17.9894 8.99471 17.9894C4.02707 17.9894 0 13.9624 0 8.99471Z",
                  fill: "rgba(68,186,220,0.251)",
                  fillRule: "evenodd",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="0px"
              left="calc(50% - 8.99px - 0px)"
              {...getOverrideProps(overrides, "bg6612691")}
            ></Icon>
            <View
              padding="0px 0px 0px 0px"
              width="10.79px"
              height="10.79px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="3.6px"
              left="3.6px"
              {...getOverrideProps(overrides, "microphone")}
            >
              <View
                width="10.79px"
                height="10.79px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="calc(50% - 5.4px - 0px)"
                borderRadius="25.91038703918457px"
                padding="0px 0px 0px 0px"
                backgroundColor="rgba(0,0,0,1)"
                {...getOverrideProps(overrides, "Background6612693")}
              ></View>
              <Icon
                width="6.27px"
                height="8.55px"
                viewBox={{
                  minX: 0,
                  minY: 0,
                  width: 6.2685546875,
                  height: 8.5458984375,
                }}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0.9px"
                left="2.28px"
                {...getOverrideProps(overrides, "Shape6612694")}
              >
                <Icon
                  width="2.7px"
                  height="5.4px"
                  viewBox={{
                    minX: 0,
                    minY: 0,
                    width: 2.6982421875,
                    height: 5.396728515625,
                  }}
                  paths={[]}
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="0px"
                  left="calc(50% - 1.35px - 2.28px)"
                  {...getOverrideProps(overrides, "Shape path6612695")}
                ></Icon>
                <Icon
                  width="6.3px"
                  height="4.5px"
                  viewBox={{
                    minX: 0,
                    minY: 0,
                    width: 6.29638671875,
                    height: 4.497314453125,
                  }}
                  paths={[]}
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="4.05px"
                  left="calc(50% - 3.15px - 2.28px)"
                  {...getOverrideProps(overrides, "Shape path6612696")}
                ></Icon>
              </Icon>
            </View>
          </View>
        </View>
        <View
          padding="0px 0px 0px 0px"
          width="295.98px"
          height="65.71px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="147.47px"
          left="21.59px"
          {...getOverrideProps(overrides, "Product on the Bootstrap Market")}
        >
          <Text
            fontFamily="Lato"
            fontSize="10.793652534484863px"
            fontWeight="400"
            color="rgba(152,166,173,1)"
            lineHeight="14.3915376663208px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="37.78px"
            left="calc(50% - 36.5px - 84.51px)"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="30 minutes ago"
            {...getOverrideProps(overrides, "30 minutes ago")}
          ></Text>
          <Text
            fontFamily="Lato"
            fontSize="10.793652534484863px"
            fontWeight="400"
            color="rgba(145,156,167,1)"
            lineHeight="14.3915376663208px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="23.39px"
            left="calc(50% - 134.5px - -13.49px)"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="A new investment opportunity from one of your contacts "
            {...getOverrideProps(overrides, "Dave Gamache added Admin Dashb")}
          ></Text>
          <Text
            fontFamily="Lato"
            fontSize="12.952383995056152px"
            fontWeight="700"
            color="rgba(54,136,252,1)"
            lineHeight="17.989421844482422px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="0px"
            left="calc(50% - 105.5px - 15.51px)"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Ra GmbH uploaded a new receivable"
            {...getOverrideProps(overrides, "Product on the Bootstrap Marke")}
          ></Text>
          <Icon
            width="1.8px"
            height="47.67px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.798828125,
              height: 47.671875,
            }}
            paths={[
              {
                d: "M0 0.89947C0 0.402706 0.402707 0 0.899471 0L0.899471 0C1.39624 0 1.79894 0.402707 1.79894 0.899471L1.79894 46.7725C1.79894 47.2693 1.39624 47.672 0.899471 47.672L0.899471 47.672C0.402707 47.672 0 47.2693 0 46.7725L0 0.89947Z",
                fill: "rgba(241,243,250,1)",
                fillRule: "evenodd",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="18.03px"
            left="calc(50% - 0.9px - 139px)"
            {...getOverrideProps(overrides, "border6612701")}
          ></Icon>
          <View
            padding="0px 0px 0px 0px"
            width="17.99px"
            height="17.99px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0.05px"
            left="0px"
            {...getOverrideProps(overrides, "Icon6612702")}
          >
            <Icon
              width="17.99px"
              height="17.99px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 17.9892578125,
                height: 17.989501953125,
              }}
              paths={[
                {
                  d: "M0 8.99471C0 4.02707 4.02707 0 8.99471 0C13.9624 0 17.9894 4.02707 17.9894 8.99471C17.9894 13.9624 13.9624 17.9894 8.99471 17.9894C4.02707 17.9894 0 13.9624 0 8.99471Z",
                  fill: "rgba(54,136,252,0.251)",
                  fillRule: "evenodd",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="0px"
              left="calc(50% - 8.99px - 0px)"
              {...getOverrideProps(overrides, "bg6612703")}
            ></Icon>
            <View
              padding="0px 0px 0px 0px"
              width="10.79px"
              height="10.79px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="3.6px"
              left="3.6px"
              {...getOverrideProps(overrides, "airplane")}
            >
              <View
                width="10.79px"
                height="10.79px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="calc(50% - 5.4px - 0px)"
                borderRadius="25.91038703918457px"
                padding="0px 0px 0px 0px"
                backgroundColor="rgba(0,0,0,1)"
                {...getOverrideProps(overrides, "Background6612705")}
              ></View>
              <Icon
                width="8.54px"
                height="8.99px"
                viewBox={{
                  minX: 0,
                  minY: 0,
                  width: 8.544921875,
                  height: 8.99462890625,
                }}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0.9px"
                left="calc(50% - 4.27px - 0.22px)"
                {...getOverrideProps(overrides, "Shape6612706")}
              ></Icon>
            </View>
          </View>
        </View>
        <View
          padding="0px 0px 0px 0px"
          width="241.98px"
          height="81.9px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="65.62px"
          left="21.59px"
          {...getOverrideProps(overrides, "You sold an item6612707")}
        >
          <Text
            fontFamily="Lato"
            fontSize="10.793652534484863px"
            fontWeight="400"
            color="rgba(152,166,173,1)"
            lineHeight="14.3915376663208px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="53.97px"
            left="calc(50% - 33.5px - 60.51px)"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="5 minutes ago"
            {...getOverrideProps(overrides, "5 minutes ago")}
          ></Text>
          <Text
            fontFamily="Lato"
            fontSize="10.793652534484863px"
            fontWeight="400"
            color="rgba(145,156,167,1)"
            lineHeight="14.3915376663208px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="23.39px"
            left="calc(50% - 107.5px - -13.49px)"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="You just purchased a Receivable of Ra GmbH &#xA;“Ra RE220425”"
            {...getOverrideProps(
              overrides,
              "Paul Burgess just purchased \u201CH6612709"
            )}
          ></Text>
          <Text
            fontFamily="Lato"
            fontSize="12.952383995056152px"
            fontWeight="700"
            color="rgba(68,186,220,1)"
            lineHeight="17.989421844482422px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="0px"
            left="calc(50% - 84.5px - 9.51px)"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="You bought a new Receivable"
            {...getOverrideProps(overrides, "You sold an item6612710")}
          ></Text>
          <Icon
            width="1.8px"
            height="63.86px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.798828125,
              height: 63.862548828125,
            }}
            paths={[
              {
                d: "M0 0.899472C0 0.402707 0.402707 0 0.899471 0L0.899471 0C1.39624 0 1.79894 0.402707 1.79894 0.899471L1.79894 62.963C1.79894 63.4597 1.39624 63.8624 0.899471 63.8624L0.899471 63.8624C0.402707 63.8624 0 63.4597 0 62.963L0 0.899472Z",
                fill: "rgba(241,243,250,1)",
                fillRule: "evenodd",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="18.03px"
            left="calc(50% - 0.9px - 112px)"
            {...getOverrideProps(overrides, "border6612711")}
          ></Icon>
          <View
            padding="0px 0px 0px 0px"
            width="17.99px"
            height="17.99px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0.05px"
            left="0px"
            {...getOverrideProps(overrides, "Icon6612712")}
          >
            <Icon
              width="17.99px"
              height="17.99px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 17.9892578125,
                height: 17.989501953125,
              }}
              paths={[
                {
                  d: "M0 8.99471C0 4.02707 4.02707 0 8.99471 0C13.9624 0 17.9894 4.02707 17.9894 8.99471C17.9894 13.9624 13.9624 17.9894 8.99471 17.9894C4.02707 17.9894 0 13.9624 0 8.99471Z",
                  fill: "rgba(68,186,220,0.251)",
                  fillRule: "evenodd",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="0px"
              left="calc(50% - 8.99px - 0px)"
              {...getOverrideProps(overrides, "bg6612713")}
            ></Icon>
            <View
              padding="0px 0px 0px 0px"
              width="10.79px"
              height="10.79px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="3.6px"
              left="3.6px"
              {...getOverrideProps(overrides, "upload6612714")}
            >
              <View
                width="10.79px"
                height="10.79px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="calc(50% - 5.4px - 0px)"
                borderRadius="25.91038703918457px"
                padding="0px 0px 0px 0px"
                backgroundColor="rgba(0,0,0,1)"
                {...getOverrideProps(overrides, "Background6612715")}
              ></View>
              <Icon
                width="6.3px"
                height="7.11px"
                viewBox={{
                  minX: 0,
                  minY: 0,
                  width: 6.29638671875,
                  height: 7.105224609375,
                }}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="1.89px"
                left="2.25px"
                {...getOverrideProps(overrides, "Shape6612716")}
              >
                <Icon
                  width="6.3px"
                  height="5.85px"
                  viewBox={{
                    minX: 0,
                    minY: 0,
                    width: 6.29638671875,
                    height: 5.8466796875,
                  }}
                  paths={[]}
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="-0.54px"
                  left="calc(50% - 3.15px - 2.25px)"
                  {...getOverrideProps(overrides, "Shape path6612717")}
                ></Icon>
                <Icon
                  width="6.3px"
                  height="0.9px"
                  viewBox={{
                    minX: 0,
                    minY: 0,
                    width: 6.29638671875,
                    height: 0.8994140625,
                  }}
                  paths={[]}
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="6.21px"
                  left="calc(50% - 3.15px - 2.25px)"
                  {...getOverrideProps(overrides, "Shape path6612718")}
                ></Icon>
              </Icon>
            </View>
          </View>
        </View>
        <View
          padding="0px 0px 0px 0px"
          width="17.99px"
          height="17.99px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="21.59px"
          left="300.42px"
          {...getOverrideProps(overrides, "dots-vertical Copy")}
        >
          <View
            width="17.99px"
            height="17.99px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0px"
            left="calc(50% - 8.99px - 0px)"
            borderRadius="25.91038703918457px"
            padding="0px 0px 0px 0px"
            backgroundColor="rgba(0,0,0,1)"
            {...getOverrideProps(overrides, "Background6612720")}
          ></View>
          <Icon
            width="3px"
            height="11.99px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 2.998046875,
              height: 11.990966796875,
            }}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="3px"
            left="7.5px"
            {...getOverrideProps(overrides, "Shape6612721")}
          >
            <Icon
              width="3px"
              height="3px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 2.998046875,
                height: 2.998291015625,
              }}
              paths={[]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="8.99px"
              left="calc(50% - 1.5px - 7.5px)"
              {...getOverrideProps(overrides, "Shape path6612722")}
            ></Icon>
            <Icon
              width="3px"
              height="3px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 2.998046875,
                height: 2.998291015625,
              }}
              paths={[]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="4.5px"
              left="calc(50% - 1.5px - 7.5px)"
              {...getOverrideProps(overrides, "Shape path6612723")}
            ></Icon>
            <Icon
              width="3px"
              height="3px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 2.998046875,
                height: 2.998291015625,
              }}
              paths={[]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="0px"
              left="calc(50% - 1.5px - 7.5px)"
              {...getOverrideProps(overrides, "Shape path6612724")}
            ></Icon>
          </Icon>
        </View>
        <Text
          fontFamily="Lato"
          fontSize="12.952383995056152px"
          fontWeight="700"
          color="rgba(43,54,119,1)"
          textTransform="uppercase"
          lineHeight="17.989421844482422px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          position="absolute"
          top="18.37px"
          left="calc(50% - 55px - 93.41px)"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Recent Activity"
          {...getOverrideProps(overrides, "Recent Activity6612725")}
        ></Text>
        <Icon
          width="3px"
          height="11.99px"
          viewBox={{
            minX: 0,
            minY: 0,
            width: 11.9951171875,
            height: 2.998291015625,
          }}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="421.85px"
          left="175.7px"
          transformOrigin="top left"
          transform="rotate(90deg)"
          {...getOverrideProps(overrides, "Shape6612726")}
        >
          <Icon
            width="3px"
            height="3px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 2.998046875,
              height: 2.998291015625,
            }}
            paths={[]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="9px"
            left="calc(50% - 1.5px - 168.5px)"
            {...getOverrideProps(overrides, "Shape path6612727")}
          ></Icon>
          <Icon
            width="3px"
            height="3px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 2.998046875,
              height: 2.998291015625,
            }}
            paths={[]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="4.5px"
            left="calc(50% - 1.5px - 168.5px)"
            {...getOverrideProps(overrides, "Shape path6612728")}
          ></Icon>
          <Icon
            width="3px"
            height="3px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 2.998046875,
              height: 2.998291015625,
            }}
            paths={[]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0px"
            left="calc(50% - 1.5px - 168.5px)"
            {...getOverrideProps(overrides, "Shape path6612729")}
          ></Icon>
        </Icon>
      </View>
      <View
        padding="0px 0px 0px 0px"
        width="453px"
        height="292px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="233px"
        left="1171.75px"
        {...getOverrideProps(overrides, "Content")}
      >
        <View
          padding="0px 0px 0px 0px"
          width="453px"
          height="292px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0px"
          left="0px"
          {...getOverrideProps(overrides, "text")}
        >
          <Flex
            gap="12px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            position="absolute"
            top="114px"
            left="calc(50% - 226.5px - 0px)"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "list")}
          >
            <Flex
              gap="8px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "line6612733")}
            >
              <View
                width="20px"
                height="20px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(
                  overrides,
                  "icons / bx-check-circle6612734"
                )}
              >
                <Check />
              </View>
              <View
                className= "listitems"
                fontFamily="Lato"
                fontSize="16px"
                fontWeight="400"
                color="rgba(86,89,115,1)"
                lineHeight="25.600000381469727px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
              >
                <Trans i18nKey="possibilities.item1" >
                  <Text
                    children="Purchase open receivables from companies without recourse"
                    {...getOverrideProps(
                      overrides,
                      "Purchase open receivables from companies without recourse"
                    )}
                  ></Text>
                </Trans>
              </View>
            </Flex>
            <Flex
              gap="8px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "line6612736")}
            >
              <View
                width="20px"
                height="20px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(
                  overrides,
                  "icons / bx-check-circle6612737"
                )}
              >
                <Check />
              </View>
              <View
                className= "listitems"
                fontFamily="Lato"
                fontSize="16px"
                fontWeight="400"
                color="rgba(86,89,115,1)"
                lineHeight="25.600000381469727px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
              >
                <Trans i18nKey="possibilities.item2" >
                  <Text
                    children="Get credit information of companies"
                    {...getOverrideProps(
                      overrides,
                      "Get credit information of companies"
                    )}
                  ></Text>
                </Trans>
              </View>
            </Flex>
            <Flex
              gap="8px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "line6612739")}
            >
              <View
                width="20px"
                height="20px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(
                  overrides,
                  "icons / bx-check-circle6612740"
                )}
              >
                <Check />
              </View>

              <View
                className= "listitems"
                fontFamily="Lato"
                fontSize="16px"
                fontWeight="400"
                color="rgba(86,89,115,1)"
                lineHeight="25.600000381469727px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
              >
                <Trans i18nKey="possibilities.item3" >
                  <Text
                    children="Monitor your investmens with full transparency"
                    {...getOverrideProps(
                      overrides,
                      "Monitor your investmens with full transparency"
                    )}
                  ></Text>
                </Trans>
              </View>
            </Flex>
            <Flex
              gap="8px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "line6612742")}
            >
              <View
                width="20px"
                height="20px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(
                  overrides,
                  "icons / bx-check-circle6612743"
                )}
              >
                <Check />
              </View>
              <View
                className= "listitems"
                fontFamily="Lato"
                fontSize="16px"
                fontWeight="400"
                color="rgba(86,89,115,1)"
                lineHeight="25.600000381469727px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
              >
                <Trans i18nKey="possibilities.item4" >
                  <Text
                    children="Regulatory compliance and assurance"
                    {...getOverrideProps(
                      overrides,
                      "Regulatory compliance and assurance"
                    )}
                  ></Text>
                </Trans>
              </View>
            </Flex>
            <Flex
              gap="8px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "line6612745")}
            >
              <View
                width="20px"
                height="20px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(
                  overrides,
                  "icons / bx-check-circle6612746"
                )}
              >
                <Check />
              </View>
              <View
                className= "listitems"
                fontFamily="Lato"
                fontSize="16px"
                fontWeight="400"
                color="rgba(86,89,115,1)"
                lineHeight="25.600000381469727px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
              >
                <Trans i18nKey="possibilities.item5" >
                  <Text
                    children="Access to a new attractive asset-class"
                    {...getOverrideProps(
                      overrides,
                      "Access to a new attractive asset-class"
                    )}
                  ></Text>
                </Trans>
              </View>
            </Flex>
          </Flex>
          <View
          className="possibilitiesTitle"
            fontFamily="Lato"
            fontSize="32px"
            fontWeight="800"
            color="rgba(11,15,25,1)"
            lineHeight="41.599998474121094px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="449px"
            height="unset"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="0px"
            left="calc(50% - 224.5px - -0.5px)"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
          >
            <Trans i18nKey="possibilities.title" >
              <Text
                children="Get possibilities for better Investments"
                {...getOverrideProps(
                  overrides,
                  "Get possibilities for better Investments"
                )}
              ></Text>
            </Trans>
          </View>
        </View>
      </View>
      <View
        display="flex"
        borderRadius="6px"
        gap="8px"
        direction="row"
        width="307px"
        height="unset"
        justifyContent="center"
        alignItems="center"
        position="absolute"
        bottom="185px"
        left="calc(50% - 153.5px - -368.5px)"
        backgroundColor="rgba(43,54,119,1)"
        property1="M"
        property2="dark"
        {...getOverrideProps(overrides, "JoinWaitlist")}
      >
        <Button
          extraClass="waitlist"
          color="white"
          onClick={() => navigate("/company-signup")}
        >
          <Trans i18nKey="banner.btn2" >
          Join Waitlist
          </Trans>
        </Button>
      </View>
    </View>
  );
}
