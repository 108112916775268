import React, {useState} from 'react'
import Button from './Button';
import { ReactComponent as Icon } from "../assets/icons/checkicon.svg";
import FinbaLogo from "../ui-components/FinbaLogo";
import { useNavigate } from "react-router-dom";
import { Trans } from 'react-i18next';
import LoginPopup from './Login/Login';
import { useTranslation } from 'react-i18next';

export default function ThankYou() {

  const navigate = useNavigate();
  const [popup, setPopup] = useState(false);
  const {t} = useTranslation();

    return (
        <div className='submitContainer'>
            <div className='submitContent'>
                <Icon/>
                <h1>{t('success.title')}</h1>
                <FinbaLogo />
                <p>{t('success.desc')}</p>
                    <div className="buttonsPart">
                <Button onClick={() => navigate("/")} bgColor="#2B3677" color="white"><Trans i18nKey="success.btn">Back To Main Page</Trans></Button>
                <Button onClick={() => setPopup(true)} bgColor="#2B3677" color="white">Log In</Button>
                </div>
            </div>
            <div className='backSubmit'>
            </div>
            {popup && (
        <div className="loginn">
          <div onClick={() => setPopup(false)} className="shades"></div>
          <LoginPopup to="/company-signup" onClick={() => setPopup(false)} />
        </div>
      )}
        </div>
    )
}

