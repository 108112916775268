import React, { useState } from 'react';
import styles from "./PaymentCard.module.scss";
import Button from "../Button/Button"
import { ReactComponent as Edit } from "../../../assets/icons/editicon.svg";
import { ReactComponent as Delete } from "../../../assets/icons/remove.svg";
import { ReactComponent as Remove } from "../../../assets/icons/delete.svg";
import Bank from "../../../assets/icons/bank-symbol.png";


export default function PaymentCard({ icon, number, primary, date, onDelete, index, year, cvv, iban }) {
  const [deleteModal, setDeleteModal] = useState(false);

  const handleDeleteClick = () => {
    setDeleteModal(true);
  };

  const handleDelete = () => {
    onDelete(index);
    setDeleteModal(false);
  };
  return (
    <>
      <div className={styles.card}>
        <div className={styles.firstPart}>
          <img src={Bank} alt="bank-symbol" />
          <div className={styles.date}>
            <p>{iban}
              {primary &&
                <span className={styles.primary}>Primary</span>
              }
            </p>
            <div className={styles.dateNumber}>
            </div>
          </div>
        </div>
        <div className={styles.secondPart}>
          <Button color="#33354D" extraClass="editbtn"><Edit /> Edit</Button>
          <Button color="#EF4444" extraClass="deletebutton" onClick={handleDeleteClick}><Delete /> Delete</Button>
        </div>
      </div>
      {deleteModal &&
        <div className={styles.deleteModal}>
          <div className={styles.shades}></div>
          <div className={styles.modal}>
            <Remove />
            <p>Are you sure you want to delete this payment card?</p>
            <div className={styles.btns}>
              <Button color="#2B3677" onClick={() => setDeleteModal(false)}>No</Button>
              <Button color="#ff000096" onClick={handleDelete}>Yes</Button>
            </div>
          </div>
        </div>
      }
    </>
  )
}
