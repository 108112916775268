import React, { useEffect, useState } from "react";
import "./UploadInvoice.scss";
import DashboardNavbar from "../DashboardNavbar";
import Input from "../../Input";
import Button from "../../Button";
import { ReactComponent as Logo } from "../../../assets/icons/logo.svg";
import { ReactComponent as Info } from "../../../assets/icons/info.svg";
import Drag from "./Drag";
import MultiplePdf from "../../MultiplePdf/MultiplePdf";
import { Invoice } from "../../../models";
import { DataStore } from "@aws-amplify/datastore";
import { UploadInvoiceValidation } from "../../../util/Validation";
import RecipientPopup from "./RecipientPopup";
import Dropdown from "../../Dropdown";
import { API, Auth, Storage } from "aws-amplify";
import * as mutations from "../../../graphql/mutations";
import * as queries from "../../../graphql/queries";
import { InvoiceRecipient } from "../../../models";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../Loader";
import dayjs, { Dayjs } from "dayjs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useNavigate } from "react-router-dom";
import MaskedInput from "react-text-mask";
import { createNumberMask } from "text-mask-addons";
import SelectBank from "../../SelectBank";
import SelectRecipient from "../../SelectRecipient";
import { sliceLongString } from "../../../util/helper";
import { useTranslation } from "react-i18next";

export default function UploadInvoice() {
  const [files, setFiles] = useState([]);
  const { t } = useTranslation();
  function handleFileUpload(files) {
    console.log("HandleFileUpload ...", files);
    if (files.length === 0) {
      console.log("cannot continue");
    } else {
      setFiles(files);
      handleFiles(files);
      setIndex(1);
    }
  }
  const initialInvoice = {
    invoiceNumber: "",
    invoiceDate: "",
    paymentDate: "",
    amount: "",
    doc: "",
  };
  const [recipientFields, setRecipientFields] = useState({
    company: "",
    country: "",
    address: "",
    address2: "",
    zip: "",
    city: "",
    ustid: "",
    contact: "",
    mail: "",
    phone: "",
    recipient: "",
    business: "",
    client: "",
    revenue: "",
    delayed: "",
    average: "",
    firstName: "",
    lastName: "",
  });
  const [url, setUrl] = useState("");
  const [pdfFiles, setPdfFiles] = useState([]);
  const [buttonBgColor, setButtonBgColor] = useState("#B4B7C9");
  const [errors, setErrors] = useState({});
  const [fields, setFields] = useState(initialInvoice);
  const [popup, setPopup] = useState(true);
  const [infoPopup, setInfoPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recipientPopup, setRecipientPopup] = useState(false);
  const [invoiceFile, setInvoiceFile] = useState(null);
  const [index, setIndex] = useState(0);
  const [accountList, setAccountList] = useState([]);
  const [bankAccountList, setBankAccountList] = useState([]);
  const [selectedAcc, setSelectedAcc] = useState(null);
  const [selectedBank, setSelectedBank] = useState(null);
  const [identifier, setIdentifier] = useState(null);
  const [discard, setDiscard] = useState(false);
  const [isClicked, setClicked] = useState(true);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [cleanDate, setCleanDate] = useState(false);
  const [isSupplier, setIsSupplier] = useState(false);
  const [aftreAddedRecipient, setAfterAddedRecipient] = useState();
  const [isRecipientAdded, setIsRecipientAdded] = useState(false);
  const [isInvoiceDateValid, setIsInvoiceDateValid] = useState(false);
  const [isPaymentDateValid, setIsPaymentDateValid] = useState(false);
  const [successInvoice, setSuccessInvoice] = useState(false);
  const [nonFormatDate, setNonFormatDate] = useState({
    inv: dayjs(""),
    pay: dayjs(""),
  });
  const [dateError, setDateError] = useState({ invErr: "", payErr: "" });
  const [dropdownError, setDropdownError] = useState({
    bank: "",
    recipient: "",
  });
  const [areFieldsEmpty, setAreFieldsEmpty] = useState(false);
  console.log("isInvoiceDateValid--------", isInvoiceDateValid);
  console.log("isPaymentDateValid--------", isPaymentDateValid);
  console.log("dateError--------", dateError);
  console.log("nonFormatDate--------", nonFormatDate);
  console.log("successInvoice--------", successInvoice);

  const navigate = useNavigate();

  const displayAccount =
    isRecipientAdded && aftreAddedRecipient
      ? aftreAddedRecipient
      : accountList?.find(
          (acc) =>
            acc.company === selectedAcc ||
            acc.firstName + " " + acc.lastName === selectedAcc
        );
  const displayBank = bankAccountList?.find((acc) => acc.IBAN === selectedBank);

  const fullName = displayAccount?.firstName + " " + displayAccount?.lastName;
  useEffect(() => {
    const allFieldsFilled = Object.values(fields).every((field) => field);

    if (allFieldsFilled) {
      setButtonBgColor("#22c55e");
    } else {
      setButtonBgColor("#B4B7C9");
    }
  }, [fields]);

  useEffect(() => {
    setInterval(() => {
      setLoading(true);
    }, 2000);
  }, []);

  /////FORMATED CURRENCY INPUT/////
  const defaultMaskOptions = {
    prefix: "",
    suffix: "€",
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ".",
    allowDecimal: true,
    decimalSymbol: ",",
    decimalLimit: 2,
    integerLimit: 7,
    allowNegative: false,
    allowLeadingZeroes: false,
  };

  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
  });
  /////FORMATED CURRENCY INPUT/////

  console.log("fields-=-==-=-=", fields);
  console.log("selectedAcc-=-==-=-=", selectedAcc);
  console.log("selectedBank-=-==-=-=", selectedBank);
  console.log("displayAccount-=-==-=-=", displayAccount);
  console.log("displayBank-=-==-=-=", displayBank);

  function getFilenameAndExtension(pathfilename) {
    var filenameextension = pathfilename.replace(/^.*[\\\/]/, "");
    var filename = filenameextension.substring(
      0,
      filenameextension.lastIndexOf(".")
    );
    var ext = filenameextension.split(".").pop();

    return [filename, ext];
  }

  useEffect(() => {
    const fetchData = async () => {
      const user = await Auth.currentAuthenticatedUser();
      const identifier = user.attributes.email;
      setIdentifier(identifier);
      let filter = {
        email: {
          eq: identifier, // filter priority = 1
        },
      };
      const recipient = await API.graphql({
        query: queries.listInvoiceRecipients,
        variables: { filter: filter },
      });
      setAccountList(recipient.data.listInvoiceRecipients.items);
      const accounts = await API.graphql({
        query: queries.listCompanyBankAccounts,
        variables: { filter: filter },
      });
      console.log("accounts:", accounts);
      if (accounts.data.listCompanyBankAccounts.items.length === 0) {
        navigate("/profile");
      }
      setBankAccountList(accounts.data.listCompanyBankAccounts.items);
      setIsSupplier(false);
    };
    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors(UploadInvoiceValidation(fields, t));
    setDropdownError({
      bank: `${t("errors.bank")}`,
      recipient: `${t("errors.recipient")}`,
    });
    setDateError({
      invErr: `${t("errors.date")}`,
      payErr: `${t("errors.payment")}`,
    });
    // if (!selectedBank) {
    //   setDropdownError({
    //     ...dropdownError,
    //     bank: "You must select a bank account first.",
    //   });
    // }
    // if (!selectedAcc) {
    //   setDropdownError({
    //     ...dropdownError,
    //     recipient: "You must select a recipient first.",
    //   });
    // }
    setIsSubmitClicked(true);
    // const allFieldsFilled = Object.values(fields).every((field) => field);
    if (
      // allFieldsFilled &&
      fields?.invoiceNumber &&
      fields?.invoiceDate &&
      fields?.paymentDate &&
      fields?.amount &&
      isInvoiceDateValid &&
      isPaymentDateValid &&
      nonFormatDate.pay >= nonFormatDate.inv &&
      displayAccount &&
      displayBank
    ) {
      setCleanDate(true);
      // setFields({ ...fields, amount: "", invoiceNumber: "" });
      setInfoPopup(true);
      setIsSubmitClicked(false);
    }

    // try {
    //   if (!fields.doc) return;

    //   const [filename, ext] = getFilenameAndExtension(fields.doc);
    //   const fileKey = `${filename}__${new Date()
    //     .toISOString()
    //     .replace(/:/g, "")}.${ext}`;
    //   const inv = {
    //     ...fields,
    //     doc: fileKey,
    //   };
    //   console.log("inv: ", inv);

    //   // await Storage.put(fileKey, invoiceFile, {
    //   //   contentType: "application/pdf",
    //   // });
    //   // // await DataStore.save(new Invoice(inv));
    //   // // await API.graphql(graphqlOperation(mutations.createInvoiceSummary, { input: inv }));
    //   // const invoice = await API.graphql({
    //   //   query: mutations.createInvoiceSummary,
    //   //   variables: { input: inv },
    //   // });
    //   // console.log("Invoice created");
    //   // setFields(initialInvoice);
    //   // console.log("initial state: ", initialState);
    //   // navigate("/client");

    //   // alert("Thank you! Your invoice has been uploaded successfully");
    // } catch (err) {
    //   console.log("error creating invoice:", err);
    // }
  };

  const handleAddRecipient = (e) => {
    e.preventDefault();
    setRecipientFields({
      company: "",
      country: "",
      address: "",
      address2: "",
      zip: "",
      city: "",
      ustid: "",
      contact: "",
      mail: "",
      phone: "",
      recipient: "",
      business: "",
      client: "",
      revenue: "",
      delayed: "",
      average: "",
      firstName: "",
      lastName: "",
    });
    setIsSupplier(false);
    setRecipientPopup(true);
  };

  console.log("accountList=-=-=-=-=", accountList);

  function handleFiles(files) {
    console.log("Files to add", files);
    handleFile(files[0]);
  }
  function handleFile(file) {
    console.log("Single File to add", file);
    setInvoiceFile(file);
    setFields((prevState) => ({ ...prevState, doc: file.name }));
    console.log("file > fields-=-==-=-=", fields);
  }

  function handleChange(event) {
    const { name, value } = event.target;
    setFields((prevState) => ({ ...prevState, [name]: value }));
  }

  console.log("fields=-=-=-", fields);

  const onChange = (e) => {
    const files = e.target.files;
    console.log(files);
    files.length > 0 && setUrl(URL.createObjectURL(files[0]));
    files.length > 0 && handleFile(files[0]);
  };

  const [dragActive, setDragActive] = React.useState(false);
  // ref
  const inputRef = React.useRef(null);

  console.log("dragActive", dragActive);
  console.log("inputRef", inputRef);

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files[0]);
    }
  };

  // triggers when file is selected with click
  const handleChange1 = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files[0]);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };

  const addInvoiceSummary = async (e) => {
    e.preventDefault();
    try {
      fields.email = identifier;
      fields.invoiceType = "company";
      fields.buyerCompany = displayAccount?.company
        ? displayAccount?.company
        : displayAccount?.firstName + " " + displayAccount?.lastName;
      const [filename, ext] = getFilenameAndExtension(fields.doc);
      const fileKey = `${filename}__${new Date()
        .toISOString()
        .replace(/:/g, "")}.${ext}`;
      const inv = {
        ...fields,
        doc: fileKey,
        status: "pending",
      };
      console.log("inv: ", inv);
      await Storage.put(fileKey, invoiceFile, {
        contentType: "application/pdf",
      });
      const invoice = await API.graphql({
        query: mutations.createInvoiceSummary,
        variables: { input: inv },
      });
      setSuccessInvoice(true);
      console.log("Invoice summary SUCCESS=-=-=-");
      toast.success("Added successfully!");
      setInfoPopup(false);
      setInterval(() => {
        window.location.href = "/company-dashboard";
      }, 4000);
    } catch (err) {
      console.log("ERROR NE INVOICE SUMMARY", err);
      // return toast.error(`${err.errors[0].message}`);
    }
  };
  return (
    <div>
      <ToastContainer />
      {loading ? (
        <>
          <DashboardNavbar />
          <div className="company-invoiceContainer">
            {popup && (
              <div className="company-wrap">
                <div
                  onClick={() => setPopup(false)}
                  className="company-invoiceshade"
                ></div>
                <div className="company-invoicepopup">
                  <div className="company-popupInfo">
                    <Logo className="company-logoPopup" />
                    <div className="company-moreInfo">
                      <p className="company-descInfo"> {t("invoice.popup")}</p>
                      <p>
                        {t("invoice.account")}:{" "}
                        <span>Finba Technologies GmbH</span>{" "}
                      </p>
                      <p>
                        IBAN: <span>DE08100101233739872842</span>
                      </p>
                      <p>
                        Bank: <span>OLINDA DEUTSCHLAND</span>
                      </p>
                      <p>
                        BIC: <span>QNTODEB2XXX</span>
                      </p>
                      <Button
                        onClick={() => setPopup(false)}
                        bgColor="#2B3677"
                        color="white"
                      >
                        {t("invoice.next")}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {infoPopup && (
              <div className="company-wrap">
                <div
                  onClick={() => setPopup(false)}
                  className="company-invoiceshade"
                ></div>
                <div className="company-invoicepopup">
                  <div className="company-popupInfo informationpopup">
                    <Logo className="company-logoPopup" />
                    <div className="company-moreInfo">
                      <p className="company-descInfo">
                        Are you sure all entered informations correct?
                        <br /> <br />
                        Please ensure all informations are correct before taking
                        the next step.
                      </p>
                      <p>
                        {t("recipient.number")}
                        <span>{fields.invoiceNumber.toUpperCase()}</span>{" "}
                      </p>
                      <p>
                        Recipient{" "}
                        <span>
                          {sliceLongString(displayAccount?.company, 10)}
                          {sliceLongString(fullName, 10)}
                        </span>
                      </p>
                      <p>
                        {t("recipient.payment")}{" "}
                        <span>{fields.paymentDate}</span>
                      </p>
                      <div className="btns">
                        <Button
                          onClick={() => setInfoPopup(false)}
                          bgColor="#2B3677"
                          color="white"
                        >
                          {t("recipient.back")}
                        </Button>
                        <Button
                          onClick={addInvoiceSummary}
                          bgColor="#22C55E"
                          color="white"
                        >
                          {t("invoice.next")}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {discard && (
              <div className="discardpopup">
                <div className="popupshade"></div>
                <div className="discardchanges">
                  <div className="popupchanges">
                    <div className="popupHeader">
                      <h3>Discard changes</h3>
                      <span onClick={() => setDiscard(false)}>X</span>
                    </div>
                    <h4>Changes will not be saved. Do you want to proceed?</h4>
                    <div className="discardbuttons">
                      <Button
                        bgColor="#B4B7C9"
                        color="white"
                        onClick={() => {
                          setDiscard(false);
                          setRecipientPopup(false);
                        }}
                      >
                        Discard
                      </Button>
                      <Button
                        onClick={() => setDiscard(false)}
                        color="white"
                        bgColor="#2B3677"
                      >
                        No
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {recipientPopup && (
              <div className="addrecipient">
                <div
                  // onClick={() => setRecipientPopup(false)}
                  className="popupshade"
                ></div>
                <RecipientPopup
                  clickEvent={() =>
                    areFieldsEmpty ? setRecipientPopup(false) : setDiscard(true)
                  }
                  fields={recipientFields}
                  setFields={setRecipientFields}
                  setAccountList={setAccountList}
                  setAreFieldsEmpty={setAreFieldsEmpty}
                  ID={identifier}
                  setRecipientPopup={setRecipientPopup}
                  accountList={accountList}
                  isClicked={isClicked}
                  setClicked={setClicked}
                  isSupplier={isSupplier}
                  setIsRecipientAdded={setIsRecipientAdded}
                  setAfterAddedRecipient={setAfterAddedRecipient}
                />
              </div>
            )}
            <div
              className={
                index === 0 ? "invoiceContent multipleFiles" : "invoiceContent"
              }
            >
              <div
                className="pdfHolder"
                onDragEnter={handleDrag}
                onSubmit={(e) => e.preventDefault()}
              >
                <Drag {...{ setFiles, files }} callback={handleFiles} />
              </div>
              {index === 1 && (
                <>
                  <div className="fields-container">
                    <div className="top-fields">
                      <p> {t("invoice.bankacc")}</p>
                      <SelectBank
                        selectedBank={selectedBank}
                        setSelectedBank={setSelectedBank}
                        bankAccountList={bankAccountList}
                        displayBank={displayBank}
                        dropdownError={dropdownError}
                      />
                      {selectedBank && (
                        <div className="bankAccInfo">
                          <p>{displayBank?.companyName}</p>
                          <p>
                            {displayBank?.firstName +
                              " " +
                              displayBank?.lastName}
                          </p>
                          <p>
                            {displayBank?.IBAN &&
                              displayBank?.IBAN?.match(/.{1,4}/g)
                                .join(" ")
                                .toUpperCase()}
                          </p>
                        </div>
                      )}
                      {/* <Input
                    extraClass="invoice"
                    label=""
                    placeholder="Buyer Company Name*"
                    type="text"
                    name="buyerName"
                    value={fields?.buyerName}
                    onChange={handleChange}
                    error={errors?.buyerName}
                  />
                  <Input
                    extraClass="invoice"
                    label=""
                    placeholder="Buyer IBAN*"
                    type="text"
                    name="buyerIban"
                    value={fields?.buyerIban}
                    onChange={handleChange}
                    error={errors?.buyerIban}
                  />
                  <Input
                    extraClass="invoice"
                    label=""
                    placeholder="Buyer Mail Address*"
                    type="text"
                    name="buyerEmail"
                    value={fields?.buyerEmail}
                    onChange={handleChange}
                    error={errors?.buyerEmail}
                  /> */}
                    </div>
                    <div className="bottom-fields">
                      <p> {t("invoice.additional")}</p>
                      <>
                        <div className="company-recipient">
                          <div className="company-recipientdata">
                            <p>{t("recipient.business")}</p>
                            <span>{displayAccount?.business}</span>
                          </div>
                          <div className="infoicon">
                            <Info />
                            <p className="tooltip">
                              How long have you been doing business <br /> with
                              the invoice recipient? (in months and years)
                            </p>
                          </div>
                        </div>
                        <div className="company-recipient">
                          <div className="company-recipientdata">
                            <p>{t("recipient.client")}</p>
                            <span>{displayAccount?.client}</span>
                          </div>
                          <div className="infoicon">
                            <Info />
                            <p className="tooltip nospace">
                              Industry and business field of the invoice
                              recipient.
                            </p>
                          </div>
                        </div>
                        <div className="company-recipient">
                          <div className="company-recipientdata">
                            <p>{t("recipient.percent")}</p>
                            <span>{displayAccount?.revenue}</span>
                          </div>
                          <div className="infoicon">
                            <Info />
                            <p className="tooltip">
                              Percentage of the invoice amount to total <br />{" "}
                              revenue of your company.
                            </p>
                          </div>
                        </div>
                        <div className="company-recipient">
                          <div className="company-recipientdata">
                            <p>{t("recipient.delayed")}</p>
                            <span>{displayAccount?.delayed}</span>
                          </div>
                          <div className="infoicon">
                            <Info />
                            <p className="tooltip">
                              If this customer has a history of late payments,{" "}
                              <br /> describe how often and why this happened.
                            </p>
                          </div>
                        </div>
                        <div className="company-recipient">
                          <div className="company-recipientdata">
                            <p>{t("recipient.invoices")}</p>
                            <span>{displayAccount?.average}</span>
                          </div>
                          <div className="infoicon">
                            <Info />
                            <p className="tooltip">
                              Best estimate of how many invoices you issue{" "}
                              <br /> to this customer per year on average.
                            </p>
                          </div>
                        </div>
                      </>
                    </div>
                    <Button
                      extraClass="subButton"
                      bgColor="#2B3677"
                      color="white"
                      // onClick={(e) => handleSubmit(e)}
                      onClick={() => setIndex(0)}
                      children={<>{t("recipient.back")}</>}
                    />
                  </div>
                  <div className="fields-container">
                    <div className="top-fields">
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <p>{t("invoice.recipient")}</p>
                        <button
                          className="add-recipient-button"
                          onClick={handleAddRecipient}
                        >
                          {t("invoice.addrecipient")}
                        </button>
                      </div>
                      {/* <Dropdown
                    list={accountList}
                    accountList={accountList}
                    setSelectedAcc={setSelectedAcc}
                  /> */}
                      {/* <select
                        className="selector"
                        name="sector"
                        id="sector"
                        value={selectedAcc}
                        onChange={(e) => {
                          setSelectedAcc(e.target.value);
                          setRecipientFields(
                            accountList?.find((acc) =>
                              acc.company
                                ? acc.company === e.target.value
                                : acc.firstName === e.target.value
                            )
                          );
                        }}
                      >
                        <option value="" hidden>
                          Select Recipient...
                        </option>
                        {accountList.map((acc) => (
                          <option
                            value={
                              acc?.company
                                ? acc?.company
                                : acc?.firstName + " " + acc?.lastName
                            }
                            key={
                              acc?.company
                                ? acc?.company
                                : acc?.firstName + " " + acc?.lastName
                            }
                          >
                            {acc?.company
                              ? acc?.company
                              : acc?.firstName + " " + acc?.lastName}
                          </option>
                        ))}
                      </select>
                      <p className="dropdown-error">
                        {displayAccount.length === 0
                          ? dropdownError.recipient
                          : null}
                      </p> */}
                      <SelectRecipient
                        selectedAcc={selectedAcc}
                        setSelectedAcc={setSelectedAcc}
                        setRecipientFields={setRecipientFields}
                        accountList={accountList}
                        displayAccount={displayAccount}
                        dropdownError={dropdownError}
                        isSupplier={isSupplier}
                        aftreAddedRecipient={aftreAddedRecipient}
                        isRecipientAdded={isRecipientAdded}
                        setIsRecipientAdded={setIsRecipientAdded}
                      />
                      {/* <Input
                    extraClass="invoice"
                    label=""
                    placeholder="Supplier First Name*"
                    type="text"
                    name="suppName"
                    value={fields?.suppName}
                    onChange={handleChange}
                    error={errors?.suppName}
                  />
                  <Input
                    extraClass="invoice"
                    label=""
                    placeholder="Supplier Last Name*"
                    type="text"
                    name="suppLastName"
                    value={fields?.suppLastName}
                    onChange={handleChange}
                    error={errors?.suppLastName}
                  />
                  <Input
                    extraClass="invoice"
                    label=""
                    placeholder="Supplier Mail Address*"
                    type="text"
                    name="suppEmail"
                    value={fields?.suppEmail}
                    onChange={handleChange}
                    error={errors?.suppEmail}
                  /> */}
                    </div>
                    <div className="bottom-fields">
                      <p>{t("invoice.summary")}</p>
                      <Input
                        label={t("recipient.number")}
                        extraClass="invoice"
                        placeholder={t("recipient.number")}
                        type="text"
                        name="invoiceNumber"
                        value={fields?.invoiceNumber.toUpperCase()}
                        onChange={handleChange}
                        error={
                          !fields.invoiceNumber.trim()
                            ? errors?.invoiceNumber
                            : null
                        }
                      />

                      {/* <Input
                    extraClass="invoice"
                    placeholder="Invoice Date"
                    type="date"
                    name="invoiceDate"
                    value={fields?.invoiceDate}
                    onChange={handleChange}
                    error={errors?.invoiceDate}
                  /> */}

                      <label>{t("recipient.date")}</label>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          renderInput={(props) => <TextField {...props} />}
                          value={dayjs(fields.invoiceDate, "DD.MM.YYYY")}
                          // value={
                          //   cleanDate
                          //     ? null
                          //     : dayjs(fields.invoiceDate, "DD.MM.YYYY")
                          // }
                          disableFuture
                          inputFormat="DD.MM.YYYY"
                          onChange={(newValue) => {
                            if (newValue && newValue.isValid()) {
                              setIsInvoiceDateValid(true);
                              setNonFormatDate({
                                ...nonFormatDate,
                                inv: newValue,
                              });
                              setFields({
                                ...fields,
                                invoiceDate: newValue.format("DD.MM.YYYY"),
                              });
                            }
                          }}
                        />
                      </LocalizationProvider>
                      <p className="input-errordate">
                        {isInvoiceDateValid ? null : dateError.invErr}
                      </p>

                      <label>{t("recipient.payment")}</label>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          renderInput={(props) => <TextField {...props} />}
                          value={dayjs(fields.paymentDate, "DD.MM.YYYY")}
                          // value={
                          //   cleanDate
                          //     ? null
                          //     : dayjs(fields.paymentDate, "DD.MM.YYYY")
                          // }
                          inputFormat="DD.MM.YYYY"
                          onChange={(newValue) => {
                            if (newValue && newValue.isValid()) {
                              setIsPaymentDateValid(true);
                              setNonFormatDate({
                                ...nonFormatDate,
                                pay: newValue,
                              });
                              setFields({
                                ...fields,
                                paymentDate: newValue.format("DD.MM.YYYY"),
                              });
                            }
                          }}
                        />
                      </LocalizationProvider>
                      <p className="input-errordate">
                        {!isPaymentDateValid
                          ? dateError.payErr
                          : nonFormatDate.pay < nonFormatDate.inv
                          ? `${t("errors.after")}`
                          : null}
                      </p>
                      {/* <input
                className="dateField"
                placeholder="Invoice Date"
                type="text"
                name="invoiceDate"
                value={fields?.invoiceDate}
                onChange={handleChange}
                onfocus="(this.type='date')"
              /> */}
                      {/* <Input
                    extraClass="invoice"
                    placeholder="Payment Due"
                    type="date"
                    name="paymentDate"
                    value={fields?.paymentDate}
                    onChange={handleChange}
                    error={errors?.paymentDate}
                  /> */}
                      {/* <input
                  className="dateField"
                  placeholder="Payment Due"
                  type="text"
                  name="paymentDate"
                  value={fields?.paymentDate}
                  onChange={handleChange}
                  onfocus="(this.type='date')"
                /> */}
                      {/* <Input
                        extraClass="invoice"
                        label="Amount Due"
                        placeholder="Amount Due (EUR)"
                        type="text"
                        name="amount"
                        value={fields?.amount}
                        onChange={(e) => {
                          const re = /^[0-9\b]+$/;
                          if (
                            e.target.value === "" ||
                            re.test(e.target.value)
                          ) {
                            setFields({
                              ...fields,
                              amount: e.target.value,
                            });
                          }
                        }}
                        error={!fields.amount.trim() ? errors?.amount : null}
                      /> */}
                      <p className="amountLabel">{t("recipient.amount")}</p>
                      <MaskedInput
                        mask={currencyMask}
                        className="amountInput"
                        value={fields?.amount}
                        placeholder={t("recipient.due")}
                        onChange={(e) =>
                          setFields({
                            ...fields,
                            amount: e.target.value,
                          })
                        }
                      />
                      <p className="input-amountError">
                        {fields.amount === "" ? errors?.amount : null}
                      </p>
                    </div>
                    <Button
                      extraClass="subButton"
                      bgColor={buttonBgColor}
                      color="white"
                      onClick={handleSubmit}
                      children={<>{t("invoice.next")}</>}
                      processing={successInvoice}
                    />
                  </div>
                </>
              )}
              {index === 0 && (
                <div className="multipleinvoices">
                  <MultiplePdf pdfFiles={pdfFiles} setPdfFiles={setPdfFiles} />
                  <button
                    className={
                      files.length > 0 ? "green-button" : "disabled-button"
                    }
                    onClick={() => handleFileUpload(files)}
                  >
                    {t("invoice.next")}
                  </button>
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
}
