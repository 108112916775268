/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Icon, View } from "@aws-amplify/ui-react";
export default function FinbaLogo(props) {
  const { overrides, ...rest } = props;
  return (
    <View
      width="121px"
      height="24px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      {...rest}
      {...getOverrideProps(overrides, "FinbaLogo")}
    >
      <View
        width="70px"
        height="23px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        overflow="hidden"
        position="absolute"
        top="0%"
        bottom="4.17%"
        left="42.15%"
        right="0%"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Text")}
      >
        <Icon
          width="11.04px"
          height="22.69px"
          viewBox={{ minX: 0, minY: 0, width: 11.0419921875, height: 22.6875 }}
          paths={[
            {
              d: "M0 7.36111L0 6.75C0 2.66667 2.69471 0 6.78872 0C8.34735 0 9.75359 0.1875 11.0421 0.645833L10.3701 3.375C9.35873 3.09722 8.13953 2.88194 6.78872 2.88194C4.64819 2.88194 3.42207 4.29167 3.42207 6.74306L3.42207 7.35417L7.64077 7.35417L7.64077 10.1111L3.429 10.1111L3.429 22.6875L0 22.6875L0 7.36111Z",
              fill: "rgba(43,54,119,1)",
              fillRule: "nonzero",
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0%"
          bottom="1.36%"
          left="0%"
          right="84.23%"
          {...getOverrideProps(overrides, "Vector6612286")}
        ></Icon>
        <Icon
          width="3.43px"
          height="15.33px"
          viewBox={{
            minX: 0,
            minY: 0,
            width: 3.428955078125,
            height: 15.3330078125,
          }}
          paths={[
            {
              d: "M3.429 0L0 0L0 15.3333L3.429 15.3333L3.429 0Z",
              fill: "rgba(43,54,119,1)",
              fillRule: "nonzero",
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="32%"
          bottom="1.33%"
          left="15.05%"
          right="80.05%"
          {...getOverrideProps(overrides, "Vector6612287")}
        ></Icon>
        <Icon
          width="14.51px"
          height="15.64px"
          viewBox={{
            minX: 0,
            minY: 0,
            width: 14.505615234375,
            height: 15.638671875,
          }}
          paths={[
            {
              d: "M0 0.305556L3.429 0.305556L3.429 2.17361L3.61603 2.23611C4.71747 0.916667 6.55319 0 8.97081 0C11.998 0 14.5057 2.18056 14.5057 6.4375L14.5057 15.6389L11.0767 15.6389L11.0767 6.5625C11.0767 3.98611 9.58041 2.88194 7.74468 2.88194C5.90896 2.88194 4.37803 3.55556 3.429 4.96528L3.429 15.6389L0 15.6389L0 0.305556L0 0.305556Z",
              fill: "rgba(43,54,119,1)",
              fillRule: "nonzero",
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="30.68%"
          bottom="1.33%"
          left="25.78%"
          right="53.5%"
          {...getOverrideProps(overrides, "Vector6612288")}
        ></Icon>
        <Icon
          width="15.7px"
          height="22.69px"
          viewBox={{
            minX: 0,
            minY: 0,
            width: 15.697265625,
            height: 22.6943359375,
          }}
          paths={[
            {
              d: "M3.11727 21.0069L2.66007 22.3889L0 22.3889L0 0L3.429 0L3.429 8.61806L3.61603 8.68056C4.59278 7.54861 6.24839 6.75 8.32657 6.75C12.4275 6.75 15.6972 10.2778 15.6972 14.7222C15.6972 19.1667 12.3028 22.6944 8.01484 22.6944C5.78426 22.6944 4.34339 22.0833 3.11727 21.0069L3.11727 21.0069ZM3.42207 11.8958L3.42207 18.7361C4.43345 19.4444 5.86739 19.8125 7.58535 19.8125C10.1554 19.8125 12.2682 17.6042 12.2682 14.7222C12.2682 11.8403 10.1554 9.63194 7.58535 9.63194C5.87432 9.63194 4.30876 10.2778 3.42207 11.9028L3.42207 11.8958Z",
              fill: "rgba(43,54,119,1)",
              fillRule: "nonzero",
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="1.33%"
          bottom="0%"
          left="51.97%"
          right="25.6%"
          {...getOverrideProps(overrides, "Vector6612289")}
        ></Icon>
        <Icon
          width="15.73px"
          height="15.94px"
          viewBox={{
            minX: 0,
            minY: 0,
            width: 15.73193359375,
            height: 15.9443359375,
          }}
          paths={[
            {
              d: "M12.1504 1.44444L12.3028 1.38195L12.3028 0.305556L15.7318 0.305556L15.7318 15.6389L12.3028 15.6389L12.3028 14.0764L12.1158 14.0139C11.139 15.1458 9.45571 15.9444 7.37061 15.9444C3.26967 15.9444 0 12.4167 0 7.97222C0 3.52778 3.39436 0 7.68234 0C9.73281 0 11.139 0.583333 12.1504 1.44444L12.1504 1.44444ZM12.3028 10.7361L12.3028 4.17361C11.2637 3.375 9.79515 2.88194 8.11182 2.88194C5.54181 2.88194 3.429 5.09028 3.429 7.97222C3.429 10.8542 5.54181 13.0625 8.11182 13.0625C9.82286 13.0625 11.4161 12.4167 12.3028 10.7292L12.3028 10.7361Z",
              fill: "rgba(43,54,119,1)",
              fillRule: "nonzero",
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="30.65%"
          bottom="0.03%"
          left="77.54%"
          right="-0.01%"
          {...getOverrideProps(overrides, "Vector6612290")}
        ></Icon>
      </View>
      <View
        width="41px"
        height="24px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="0%"
        bottom="0%"
        left="0%"
        right="66.12%"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Icon")}
      >
        <Icon
          width="41px"
          height="24px"
          viewBox={{ minX: 0, minY: 0, width: 41.00048828125, height: 24 }}
          paths={[
            {
              d: "M40.6273 0L16.6762 0C14.4934 0 12.4857 1.16185 11.449 3.02528L0.0384764 23.5464C-0.0755597 23.7509 0.0776403 24 0.317231 24L24.3627 24C26.5455 24 28.5533 22.8382 29.5899 20.9747L40.9555 0.532886C41.0892 0.291579 40.9095 0 40.6273 0Z",
              fill: "rgba(43,54,119,1)",
              fillRule: "nonzero",
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0%"
          bottom="0%"
          left="0%"
          right="0%"
          {...getOverrideProps(overrides, "Vector6612292")}
        ></Icon>
        <Icon
          width="14.97px"
          height="5.04px"
          viewBox={{ minX: 0, minY: 0, width: 14.96875, height: 5.0380859375 }}
          paths={[
            {
              d: "M14.9687 0.0011174L4.31148 0.0011174C3.41417 0.0011174 2.58712 0.474794 2.15517 1.23782L0 5.0384L10.6434 5.0384C11.5384 5.0384 12.3631 4.56696 12.7962 3.80729L14.9687 0L14.9687 0.0011174Z",
              fill: "rgba(97,139,232,1)",
              fillRule: "nonzero",
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="40.46%"
          bottom="38.54%"
          left="27.05%"
          right="36.44%"
          {...getOverrideProps(overrides, "Vector6612293")}
        ></Icon>
        <Icon
          width="20.13px"
          height="5.04px"
          viewBox={{
            minX: 0,
            minY: 0,
            width: 20.1279296875,
            height: 5.037109375,
          }}
          paths={[
            {
              d: "M20.1268 0L4.30573 0C3.40726 0 2.58021 0.474794 2.14826 1.23893L0 5.03729L15.8142 5.03729C16.7115 5.03729 17.5374 4.56361 17.9705 3.8017L20.1279 0L20.1268 0Z",
              fill: "rgba(97,139,232,1)",
              fillRule: "nonzero",
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="10.42%"
          bottom="68.59%"
          left="37.04%"
          right="13.87%"
          {...getOverrideProps(overrides, "Vector6612294")}
        ></Icon>
        <Icon
          width="9.82px"
          height="5.04px"
          viewBox={{
            minX: 0,
            minY: 0,
            width: 9.818603515625,
            height: 5.037109375,
          }}
          paths={[
            {
              d: "M9.81747 0L4.323 0C3.42799 0 2.60325 0.471443 2.17014 1.23111L0 5.03728L5.50483 5.03728C6.40215 5.03728 7.22804 4.56361 7.66115 3.8017L9.81862 0L9.81747 0Z",
              fill: "rgba(97,139,232,1)",
              fillRule: "nonzero",
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="70.27%"
          bottom="8.74%"
          left="17.06%"
          right="58.99%"
          {...getOverrideProps(overrides, "Vector6612295")}
        ></Icon>
      </View>
    </View>
  );
}
