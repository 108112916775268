import React from 'react';
import Header from '../molecules/Header/Header';
import Footer from '../molecules/Footer/Footer';
import { useTranslation } from 'react-i18next';
import TextSection from '../molecules/TextSection/TextSection';
import TalkSection from "../molecules/TalkSection/TalkSection";
import JobsCards from '../molecules/JobsCards/JobsCards';
import Jobs from "../../components/Jobs"

export default function JobsPage() {

  const { t } = useTranslation();

  return (
    <div>
      <Header extraClass="jobsHeader" signupPath="/investor-signup"/>
      <TextSection title={t('investBanner.jobtitle')} description={t('investBanner.jobdesc')} extraClass="jobsBanner" />
      <JobsCards />
      <Jobs />
      <TalkSection />
     <Footer />
    </div>
  )
}
