import React from "react";
import {
  ResponsiveContainer,
  CartesianGrid,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  // Legend,
} from "recharts";
const DashboardLineChart = (props) => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <AreaChart width={939} height={280} data={props?.data}>
        {/* <Legend /> */}
        <defs>
          <linearGradient id="colorLabel1" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#0dc9a0" stopOpacity={0.2} />
            <stop offset="100%" stopColor="#0dc9a0" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorLabel2" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#0693da" stopOpacity={0.2} />
            <stop offset="100%" stopColor="#0693da" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorLabel3" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#ec7600" stopOpacity={0.2} />
            <stop offset="100%" stopColor="#ec7600" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="month"
          tick={{ fontSize: 13, fill: "#000000", opacity: 0.5 }}
        />
        <YAxis
          tick={{ fontSize: 13, fill: "#000000", opacity: 0.5 }}
          tickFormatter={(tick) => {
            return `${tick}k`;
          }}
        />
        <CartesianGrid
          stroke="#ced1d2"
          strokeWidth={0.25}
          horizontal={true}
          vertical={false}
        />
        <Tooltip />

        <Area
          type="monotone"
          dataKey="profit"
          stroke="#0dc9a0"
          fillOpacity={1}
          fill="url(#colorLabel1)"
          activeDot={{
            onMouseOver: () => props?.handleColor("#0dc9a0", "profit"),
            // onMouseLeave: () => handleColor("", ""),
          }}
        />
        <Area
          type="monotone"
          dataKey="invested"
          stroke="#0693da"
          fillOpacity={1}
          fill="url(#colorLabel2)"
          activeDot={{
            onMouseOver: () => props?.handleColor("#0693da", "invested"),
            // onMouseLeave: () => handleColor("", ""),
          }}
        />
        <Area
          type="monotone"
          dataKey="attrib1"
          stroke="#ec7600"
          fillOpacity={1}
          fill="url(#colorLabel3)"
          activeDot={{
            onMouseOver: () => props?.handleColor("#ec7600", "attrib1"),
            // onMouseLeave: () => handleColor("", ""),
          }}
        />
        <Area
          type="monotone"
          dataKey="attrib2"
          stroke="#8e00ec"
          fillOpacity={1}
          fill="url(#colorLabel3)"
          activeDot={{
            onMouseOver: () => props?.handleColor("#8e00ec", "attrib2"),
            // onMouseLeave: () => handleColor("", ""),
          }}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default DashboardLineChart;
