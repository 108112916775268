import React, { useState, useEffect } from "react";
import styles from "./Header.module.scss";
import LoginPopup from "../../../components/Login/Login";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../../../assets/icons/finbalogo.svg";
import { ReactComponent as Arrow } from "../../../assets/icons/faqarrow.svg";
import { ReactComponent as Login } from "../../../assets/icons/login.svg";
import { ReactComponent as Menu } from "../../../assets/icons/menu.svg";
import { ReactComponent as Close } from "../../../assets/icons/close.svg";
import { ReactComponent as Icon1 } from "../../../assets/icons/icon1.svg";
import { ReactComponent as Icon2 } from "../../../assets/icons/icon2.svg";
import { ReactComponent as Icon3 } from "../../../assets/icons/icon3.svg";
import { ReactComponent as Icon4 } from "../../../assets/icons/icon4.svg";
import SideMenu from "./SideMenu";
import Button from "../../atoms/Button/Button";
import { useTranslation } from "react-i18next";

export default function Header({ extraClass, extraClassMobile, signupPath }) {
  const [isOpen, setIsOpen] = useState(false);
  const [popup, setPopup] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [signup, setSignup] = useState(false);
  const [about, setAbout] = useState(false);
  const { t, i18n } = useTranslation();
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };
  const location = useLocation();
  useEffect(() => {
    if (location?.state?.login) {
      setPopup(location?.state?.login);
    } else {
      setPopup(false);
    }
  }, []);
  const navigate = useNavigate();

  const cards = [
    {
      id: 1,
      icon: <Icon1 />,
      title: t("dropdown.title1"),
      desc: t("dropdown.desc1"),
      path: "/company",
    },
    {
      id: 2,
      icon: <Icon2 />,
      title: t("dropdown.title2"),
      desc: t("dropdown.desc2"),
      path: "/company",
    },
    {
      id: 3,
      icon: <Icon3 />,
      title: t("dropdown.title3"),
      desc: t("dropdown.desc3"),
    },
    {
      id: 4,
      icon: <Icon4 />,
      title: t("dropdown.title4"),
      desc: t("dropdown.desc4"),
    },
  ];
  const [currentPath, setCurrentPath] = useState(window.location.pathname);

  useEffect(() => {
    setCurrentPath(window.location.pathname);
  }, [window.location.pathname]);
  return (
    <>
      <div className={`${styles.header} ${styles[extraClass]}`}>
        <div className={styles.menu}>
          <ul className={`${styles.links} ${styles.menu}`}>
            <li className={styles.logo} onClick={() => navigate("/")}>
              <Logo />
            </li>
            <li onClick={() => navigate("/invest")}>{t("navbar.item1")}</li>
            <div className={styles.navPopup}>
              <li
                onClick={() => {
                  setDropdown(!dropdown);
                  setAbout(false);
                  setSignup(false);
                }}
                className={
                  dropdown == true
                    ? `${styles.linkHolder} ${styles.linkActive}`
                    : `${styles.linkHolder}`
                }
              >
                {t("navbar.item2")} <Arrow />{" "}
              </li>
              {dropdown && (
                <div className={styles.navDropdown}>
                  <div
                    className={styles.shade}
                    onClick={() => setDropdown(false)}
                  ></div>
                  <p>{t("dropdown.title")}</p>
                  <div className={styles.cardsHolder}>
                    {cards.map((item) => {
                      return (
                        <div
                          onClick={() => navigate(item.path)}
                          key={item.id}
                          className={
                            currentPath === item.path
                              ? `${styles.card} ${styles.active}`
                              : `${styles.card}`
                          }
                        >
                          <h1>{item.icon}</h1>
                          <h3>{item.title}</h3>
                          <p>{item.desc}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
            <div className={`${styles.authDropdown} ${styles.aboutDropdown}`}>
              <li
                className={about == true ? `${styles.linkActive}` : ""}
                onClick={() => {
                  setAbout(!about);
                  setDropdown(false);
                  setSignup(false);
                }}
              >
                {t("navbar.item3")} <Arrow />
              </li>
              {about && (
                <div className={styles.signupDropdown}>
                  <div
                    className={styles.shade}
                    onClick={() => setAbout(false)}
                  ></div>
                  <ul>
                    <li>{t("navbar.story")}</li>
                    <li onClick={() => navigate("/team")}>
                      {t("navbar.team")}
                    </li>
                    <li>{t("navbar.investors")}</li>
                    <li onClick={() => navigate("/jobs")}>
                      {t("navbar.jobs")}
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </ul>
          <ul className={`${styles.links} ${styles.rightLinks}`}>
            <li onClick={() => changeLanguage("de")}>DE</li>
            <li>|</li>
            <li onClick={() => changeLanguage("en")}>EN</li>
            <div className={styles.authDropdown}>
              <li className={signup == true ? `${styles.btnActive}` : ""}>
                <Button
                  onClick={() => {
                    setSignup(!signup);
                    setDropdown(false);
                    setAbout(false);
                  }}
                  bgColor="#618BE8"
                  color="white"
                >
                  {" "}
                  {t("mission.btn")} <Arrow />{" "}
                </Button>
              </li>

              {signup && (
                <div className={styles.signupDropdown}>
                  <div
                    className={styles.shade}
                    onClick={() => setSignup(false)}
                  ></div>
                  <ul>
                    <li onClick={() => navigate("/investor-signup")}>
                      {t("navbar.investor")}
                    </li>
                    <li onClick={() => navigate("/company-signup")}>
                      {t("navbar.company")}
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <li className={styles.login}>
              <Button
                onClick={() => setPopup(true)}
                bgColor="#618BE8"
                color="white"
                id="headerLogin"
              >
                {t("navbar.login")}{" "}
              </Button>
            </li>
          </ul>
        </div>
        <div className={styles.mobile}>
          <Logo style={{ cursor: "pointer" }} onClick={() => navigate("/")} />
          <>
            <ul
              className={`${styles.links} ${styles.rightLinks}  ${styles.mobileLinks}`}
            >
              <li onClick={() => changeLanguage("de")}>DE</li>
              <li>|</li>
              <li onClick={() => changeLanguage("en")}>EN</li>
              <button
                className={styles.menuButton}
                onClick={() => setIsOpen(!isOpen)}
              >
                {isOpen ? <Close /> : <Menu />}
              </button>
            </ul>
          </>
          {isOpen && (
            <SideMenu
              handleClose={() => setIsOpen(false)}
              extraClass={extraClassMobile}
              closeMenu={() => setIsOpen(false)}
            />
          )}
        </div>
      </div>
      {popup && (
        <div className="loginn">
          <div onClick={() => setPopup(false)} className="shades"></div>
          <LoginPopup to={signupPath} onClick={() => setPopup(false)} />
        </div>
      )}
    </>
  );
}
