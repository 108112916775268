import React from 'react'; 
import InvestorPage from '../organisms/InvestorPage';

export default function Investor() {
  return (
    <div>
      <InvestorPage />
    </div>
  )
}
