import React from 'react'
import TrustedBy from '../../components/TrustedBy'
import CompanySection from '../molecules/CompanySection/CompanySection'
import DashboardSection from '../molecules/DashboardSection/DashboardSection'
import FactoryBanner from '../molecules/FactoryBanner/FactoryBanner'
import FaqSection from '../molecules/FaqSection/FaqSection'
import Footer from '../molecules/Footer/Footer'
import Header from '../molecules/Header/Header'
import RequestCard from '../molecules/RequestCard/RequestCard'
import TextSection from '../molecules/TextSection/TextSection'
import { faqData, stepsData } from '../constants/data'
import Steps from '../molecules/Steps/Steps'
import { useTranslation } from 'react-i18next'


export default function FactoryPage() {

  const {t} = useTranslation();
 
  return (
    <div>
      <Header signupPath="/investor-signup" extraClassMobile="factorymobile" extraClass="factoryheader" />
      <TextSection button title={t('investBanner.title')} description={t('investBanner.description')} />
      <FactoryBanner />
      <TrustedBy />
      <CompanySection />
      <DashboardSection />
      <Steps data={stepsData}/>
      <RequestCard />
      <FaqSection data={faqData} />
      <Footer />
    </div>
  )
}
