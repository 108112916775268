import React from 'react';
import TextImage from '../../atoms/TextImage/TextImage';
import styles from "./InfoSection.module.scss";
import img from "../../../assets/images/company-section.png";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function InfoSection() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    return (
        <div className={styles.container}>
            <TextImage clickEvent={() => navigate("/company-signup")} button text={t('mission.btn')}bgColor="#2B3677" extraClass="infoSection" image={img}>
                <div className={styles.content}>
                    <h3>{t('company.subtitle')} <br/>
                    {t('company.span')}</h3>
                    <ul>
                        <li>{t('company.item1')}<span>{t('company.span1')}</span></li>
                        <li>{t('company.item2')} <span>{t('company.span2')}</span></li>
                        <li><span>{t('company.span3')}</span> {t('company.item3')}</li>
                        <li><span>{t('company.span4')}</span>{t('company.item4')}</li>
                        <li><span>{t('company.span5')}</span> {t('company.item5')}</li>
                        <li><span>{t('company.span6')}</span> {t('company.item6')}</li>
                    </ul>
                </div>
            </TextImage>
        </div>
    )
}
