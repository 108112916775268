import React from 'react';
import Banner from '../molecules/Banner/Banner';
import Dashboard from "../../assets/icons/Hero_Shot.svg";
import Mission from '../molecules/Mission/Mission';
import missionImg from '../../assets/images/CTA.png';
import Cards from '../molecules/Cards/Cards';
import RequestCard from '../molecules/RequestCard/RequestCard';
import Invest from '../molecules/Invest/Invest';
import Header from '../molecules/Header/Header';
import Footer from '../molecules/Footer/Footer';
import { useTranslation } from 'react-i18next';


export default function InvestorPage() {

  const { t } = useTranslation();

  return (
    <div>
      <Header signupPath="/investor-signup"/>
     <Banner path="/investor-signup" scroll image={Dashboard} title={t('investorBanner.title')} description={t('investorBanner.desc')} button={t('investorBanner.button')} bgColor="#618BE8" color="white" />
     <Invest />
     <Mission image={missionImg} />
     <Cards />
     <RequestCard />
     <Footer />
    </div>
  )
}
