import React from 'react';
import styles from "./InfoCard.module.scss";
import { useTranslation } from 'react-i18next';

export default function InfoCard({ extraClass, ...rest }) {

    const {t} = useTranslation();

    return (
        <div className={`${styles.container} ${styles[extraClass]}`}>
            <div className={styles.icon}>
            {rest?.icon}
            </div>
            <div className={styles.content}>
            <h3>{t(rest?.title)}</h3>   
            <p>{t(rest?.desc)}</p>
            </div>
        </div>
    )
}
