import React from "react";
import "./styles.scss";

const Button = ({
  bgColor,
  color,
  children,
  onClick,
  extraClass,
  border,
  type,
  processing,
  onKeyDown,
  id,
}) => {
  return (
    <button
      id={id}
      target="_blank"
      className={`button ${extraClass}`}
      style={{ backgroundColor: bgColor, color, border: border }}
      onClick={onClick}
      type={type || "button"}
      disabled={processing}
      onKeyDown={onKeyDown}
    >
      {children}
    </button>
  );
};
export default Button;
