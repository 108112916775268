import React, { useEffect, useState } from "react";
import "./UploadInvoice.scss";
import { useFormik } from "formik";
import Input from "../../Input";
import Button from "../../Button";
import { ReactComponent as Close } from "../../../assets/icons/close.svg";
import { ReactComponent as Tick } from "../../../assets/icons/tick.svg";
import { ReactComponent as Info } from "../../../assets/icons/info.svg";
import { API } from "aws-amplify";
import * as mutations from "../../../graphql/mutations";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function RecipientPopup(props) {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: props.fields,
    onSubmit: async (values) => {
      try {
        values.email = props.ID;
        values.address2 = values.address2 ? values.address2 : "";
        values.ustid = "DE" + values.ustid;
        const recipient = await API.graphql({
          query: mutations.createInvoiceRecipient,
          variables: { input: values },
        });
        props.setRecipientPopup(false);
        // toast.success("Recipient addedd successfully");
        toast.success(
          `${
            props.isSupplier
              ? `${t("modal.issuer")}`
              : `${t("modal.recipient")}`
          }`
        );
        props.setIsRecipientAdded(true);
        props.setAccountList((current) => [...current, values]);
        props.setAfterAddedRecipient(values);
      } catch (err) {
        console.log("err IN POPUP", err);
        return toast.error(`${err.errors[0].message}`);
      }
    },
    validate: (values) => {
      const errors = {};
      if (isSubmitted) {
        if (props.isClicked) {
          if (!values.company.trim()) {
            errors.company = `${t("errors.company")}`;
          }
        }
        if (!props.isClicked) {
          if (!values.firstName.trim()) {
            errors.firstName = `${t("errors.firstname")}`;
          }
          if (!values.lastName.trim()) {
            errors.lastName = `${t("errors.lastname")}`;
          }
        }
        if (!values.country.trim()) {
          errors.country = `${t("errors.country")}`;
        }
        if (!values.address.trim()) {
          errors.address = `${t("errors.address")}`;
        }
        if (!values.zip.trim()) {
          errors.zip = `${t("errors.zip")}`;
        }
        if (!values.city.trim()) {
          errors.city = `${t("errors.city")}`;
        }
        if (!values.ustid) {
          errors.ustid = `${t("errors.ust")}`;
        } else if (values.ustid.length !== 9) {
          errors.ustid = "UST ID must contain 9 numbers";
        }
        if (!values.contact.trim()) {
          errors.contact = `${t("errors.contact")}`;
        }
        if (!values.mail.trim()) {
          errors.mail = `${t("errors.mail")}`;
        }
        if (!values.phone.trim()) {
          errors.phone = `${t("errors.phone")}`;
        }
        if (!props.isSupplier) {
          if (!values.business) {
            errors.business = `${t("errors.business")}`;
          }
          if (!values.client) {
            errors.client = `${t("errors.client")}`;
          }
          if (!values.revenue) {
            errors.revenue = `${t("errors.revenue")}`;
          }
          if (!values.delayed) {
            errors.delayed = `${t("errors.delayed")}`;
          }
          if (!values.average) {
            errors.average = `${t("errors.average")}`;
          }
        }
      }
      return errors;
    },
  });
  useEffect(() => {
    props.isSupplier
      ? !formik.values.address &&
        !formik.values.zip &&
        !formik.values.city &&
        !formik.values.company &&
        !formik.values.contact &&
        !formik.values.country &&
        !formik.values.firstName &&
        !formik.values.lastName &&
        !formik.values.mail &&
        !formik.values.phone &&
        !formik.values.ustid
        ? props.setAreFieldsEmpty(true)
        : props.setAreFieldsEmpty(false)
      : !formik.values.address &&
        !formik.values.zip &&
        !formik.values.city &&
        !formik.values.company &&
        !formik.values.contact &&
        !formik.values.country &&
        !formik.values.firstName &&
        !formik.values.lastName &&
        !formik.values.mail &&
        !formik.values.phone &&
        !formik.values.ustid &&
        !formik.values.business &&
        !formik.values.client &&
        !formik.values.revenue &&
        !formik.values.delayed &&
        !formik.values.average
      ? props.setAreFieldsEmpty(true)
      : props.setAreFieldsEmpty(false);
  }, [formik.values]);
  return (
    <div className="recipientPopup">
      <form onSubmit={formik.handleSubmit}>
        <div
          className={
            props.isSupplier ? "popupInformation" : "company-popupInformation"
          }
        >
          <div className="recipientInfo">
            <div className="topfield">
              <p className="recipienttitle">
                {props.isSupplier
                  ? `${t("recipient.addissuer")}`
                  : `${t("recipient.title")}`}
              </p>
              {props.isSupplier && (
                <div className="buyerinfo" style={{ marginTop: "-20px" }}>
                  <div className="topfield">
                    <div class="recipientSwitch">
                      <div id="toggle-btn">
                        <input
                          onChange={() => props.setClicked(!props.isClicked)}
                          type="checkbox"
                          id="checkbox-input"
                        />
                        <label
                          htmlFor="checkbox-input"
                          class="round-slider-container"
                        >
                          <div
                            className={
                              props.isClicked === true
                                ? "forinvestors activetab"
                                : "forinvestors"
                            }
                          >
                            {t("recipient.subtitle")}
                          </div>
                          <div
                            className={
                              props.isClicked === false
                                ? "forcompanies activetab"
                                : "forcompanies"
                            }
                          >
                            {t("recipient.private")}
                          </div>
                          <div class="round-slider"></div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div
              className={props.isSupplier ? "allfields" : "company-allfields"}
            >
              {props.isClicked ? (
                <div className="field">
                  <p className="fieldtitle">{t("recipient.subtitle")}</p>
                  <Input
                    label={t("recipient.company")}
                    placeholder={t("recipient.company")}
                    extraClass={
                      props.isSupplier
                        ? "recipientInput"
                        : "company-recipientInput"
                    }
                    name="company"
                    onChange={formik.handleChange}
                    value={formik.values.company}
                  />
                  {formik.errors.company && (
                    <div className="error">{formik.errors.company}</div>
                  )}
                </div>
              ) : (
                <>
                  <p className="fieldtitle">{t("recipient.private")}</p>
                  <div className="company-field company-rowfields">
                    <div className="company-columnInput">
                      <Input
                        label={t("recipient.firstname")}
                        placeholder={t("recipient.firstname")}
                        extraClass={
                          props.isSupplier
                            ? "recipientInput"
                            : "company-recipientInput"
                        }
                        name="firstName"
                        onChange={formik.handleChange}
                        value={formik.values.firstName}
                      />
                      {formik.errors.firstName && (
                        <div className="error">{formik.errors.firstName}</div>
                      )}
                    </div>
                    <div
                      className="company-columnInput"
                      style={props.isSupplier ? { margin: "20px 0" } : {}}
                    >
                      <Input
                        label={t("recipient.lastname")}
                        placeholder={t("recipient.lastname")}
                        extraClass={
                          props.isSupplier
                            ? "recipientInput"
                            : "company-recipientInput"
                        }
                        name="lastName"
                        onChange={formik.handleChange}
                        value={formik.values.lastName}
                      />
                      {formik.errors.lastName && (
                        <div className="error">{formik.errors.lastName}</div>
                      )}
                    </div>
                  </div>
                </>
              )}
              <div className="field">
                <Input
                  label={t("recipient.country")}
                  placeholder={t("recipient.country")}
                  extraClass={
                    props.isSupplier
                      ? "recipientInput"
                      : "company-recipientInput"
                  }
                  name="country"
                  onChange={formik.handleChange}
                  value={formik.values.country}
                />
                {formik.errors.country && (
                  <div className="error">{formik.errors.country}</div>
                )}
              </div>
              <div className="field">
                <Input
                  label={t("recipient.address")}
                  placeholder={t("recipient.address")}
                  extraClass={
                    props.isSupplier
                      ? "recipientInput"
                      : "company-recipientInput"
                  }
                  name="address"
                  onChange={formik.handleChange}
                  value={formik.values.address}
                />
                {formik.errors.address && (
                  <div className="error">{formik.errors.address}</div>
                )}
              </div>
              <div className="field">
                <Input
                  label={t("recipient.address2")}
                  placeholder={t("recipient.address2")}
                  extraClass={
                    props.isSupplier
                      ? "recipientInput"
                      : "company-recipientInput"
                  }
                  name="address2"
                  onChange={formik.handleChange}
                  value={formik.values.address2}
                />
              </div>
              <div className="field">
                <Input
                  label={t("recipient.zip")}
                  placeholder={t("recipient.zip")}
                  extraClass={
                    props.isSupplier
                      ? "recipientInput"
                      : "company-recipientInput"
                  }
                  name="zip"
                  onChange={(e) => {
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      formik.setFieldValue("zip", e.target.value);
                    }
                  }}
                  value={formik.values.zip}
                />
                {formik.errors.zip && (
                  <div className="error">{formik.errors.zip}</div>
                )}
              </div>
              <div className="field">
                <Input
                  label={t("recipient.city")}
                  placeholder={t("recipient.city")}
                  extraClass={
                    props.isSupplier
                      ? "recipientInput"
                      : "company-recipientInput"
                  }
                  name="city"
                  onChange={formik.handleChange}
                  value={formik.values.city}
                />
                {formik.errors.city && (
                  <div className="error">{formik.errors.city}</div>
                )}
              </div>
              <div className="field">
                <Input
                  label="UST-ID"
                  placeholder="UST-ID"
                  id="inputFieldUst"
                  extraClass={
                    props.isSupplier
                      ? "recipientInput"
                      : "company-recipientInput"
                  }
                  name="ustid"
                  maxLength={9}
                  minLength={9}
                  onChange={(e) => {
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      formik.setFieldValue("ustid", e.target.value);
                    }
                  }}
                  value={formik.values.ustid}
                />
                {formik.errors.ustid && (
                  <div className="error">{formik.errors.ustid}</div>
                )}
              </div>
              <div className="field">
                <Input
                  label={t("recipient.name")}
                  placeholder={t("recipient.name")}
                  extraClass={
                    props.isSupplier
                      ? "recipientInput"
                      : "company-recipientInput"
                  }
                  name="contact"
                  onChange={formik.handleChange}
                  value={formik.values.contact}
                />
                {formik.errors.contact && (
                  <div className="error">{formik.errors.contact}</div>
                )}
              </div>
              <div className="field">
                <Input
                  label={t("recipient.mail")}
                  placeholder={t("recipient.mail")}
                  extraClass={
                    props.isSupplier
                      ? "recipientInput"
                      : "company-recipientInput"
                  }
                  name="mail"
                  onChange={formik.handleChange}
                  value={formik.values.mail}
                />
                {formik.errors.mail && (
                  <div className="error">{formik.errors.mail}</div>
                )}
              </div>
              <div className="field">
                <Input
                  label={t("recipient.phone")}
                  placeholder={t("recipient.phone")}
                  extraClass={
                    props.isSupplier
                      ? "recipientInput"
                      : "company-recipientInput"
                  }
                  name="phone"
                  onChange={formik.handleChange}
                  value={formik.values.phone}
                />
                {formik.errors.phone && (
                  <div className="error">{formik.errors.phone}</div>
                )}
              </div>
            </div>
          </div>
          {props.isSupplier && (
            <>
              <div
                className="field"
                style={props.isSupplier ? { margin: "-50px 0" } : {}}
              >
                <Button
                  onClick={props.clickEvent}
                  bgColor="#B4B7C9"
                  color="white"
                >
                  {t("recipient.cancel")}
                </Button>
              </div>
              <div
                className="field"
                style={props.isSupplier ? { margin: "-50px 0" } : {}}
              >
                <Button
                  onClick={() => setIsSubmitted(true)}
                  type="submit"
                  bgColor="#22C55E"
                  color="white"
                >
                  {t("recipient.confirm")} <Tick />
                </Button>
              </div>
            </>
          )}
          {!props.isSupplier && (
            <div className="company-buyerinfo">
              <div className="topfield">
                <div class="recipientSwitch">
                  <div id="toggle-btn">
                    <input
                      onChange={() => props.setClicked(!props.isClicked)}
                      type="checkbox"
                      id="checkbox-input"
                    />
                    <label
                      htmlFor="checkbox-input"
                      class="round-slider-container"
                    >
                      <div
                        className={
                          props.isClicked === true
                            ? "forinvestors activetab"
                            : "forinvestors"
                        }
                      >
                        {t("recipient.subtitle")}
                      </div>
                      <div
                        className={
                          props.isClicked === false
                            ? "forcompanies activetab"
                            : "forcompanies"
                        }
                      >
                        {t("recipient.private")}
                      </div>
                      <div class="round-slider"></div>
                    </label>
                  </div>
                </div>
              </div>
              <div className="field">
                <p className="fieldtitle">{t("recipient.additional")}</p>
                <div className="fieldinfo">
                  <Input
                    label={t("recipient.business")}
                    placeholder={t("recipient.business")}
                    extraClass="company-recipientInput"
                    name="business"
                    onChange={formik.handleChange}
                    value={formik.values.business}
                  />
                  <div className="infoicon">
                    <Info />
                    <p className="tooltip">
                      How long have you been doing business <br /> with the
                      invoice recipient? (in months and years)
                    </p>
                  </div>
                </div>
                {formik.errors.business && (
                  <div className="error">{formik.errors.business}</div>
                )}
              </div>
              <div className="field">
                <div className="fieldinfo">
                  <Input
                    label={t("recipient.client")}
                    placeholder={t("recipient.client")}
                    extraClass="company-recipientInput"
                    name="client"
                    onChange={formik.handleChange}
                    value={formik.values.client}
                  />
                  <div className="infoicon">
                    <Info />
                    <p className="tooltip nospace">
                      Industry and business field of the invoice recipient.
                    </p>
                  </div>
                </div>
                {formik.errors.client && (
                  <div className="error">{formik.errors.client}</div>
                )}
              </div>
              <div className="field">
                <div className="fieldinfo">
                  <Input
                    label={t("recipient.percent")}
                    placeholder={t("recipient.percent")}
                    extraClass="company-recipientInput"
                    name="revenue"
                    onChange={formik.handleChange}
                    value={formik.values.revenue}
                  />
                  <div className="infoicon">
                    <Info />
                    <p className="tooltip">
                      Percentage of the invoice amount to total revenue <br />{" "}
                      of your company.
                    </p>
                  </div>
                </div>
                {formik.errors.revenue && (
                  <div className="error">{formik.errors.revenue}</div>
                )}
              </div>
              <div className="field">
                <div className="fieldinfo">
                  <Input
                    label={t("recipient.delayed")}
                    placeholder={t("recipient.delayed")}
                    extraClass="company-recipientInput"
                    name="delayed"
                    onChange={formik.handleChange}
                    value={formik.values.delayed}
                  />
                  <div className="infoicon">
                    <Info />
                    <p className="tooltip">
                      If this customer has a history of late payments,
                      <br /> describe how often and why this happened.“
                    </p>
                  </div>
                </div>
                {formik.errors.delayed && (
                  <div className="error">{formik.errors.delayed}</div>
                )}
              </div>
              <div className="field">
                <div className="fieldinfo">
                  <Input
                    label={t("recipient.invoices")}
                    placeholder={t("recipient.invoices")}
                    extraClass="company-recipientInput"
                    name="average"
                    onChange={formik.handleChange}
                    value={formik.values.average}
                  />
                  <div className="infoicon">
                    <Info />
                    <p className="tooltip">
                      Best estimate of how many invoices you <br />
                      issue to this customer per year on average.
                    </p>
                  </div>
                </div>
                {formik.errors.average && (
                  <div className="error">{formik.errors.average}</div>
                )}
              </div>
              <div className="company-field" style={{ marginTop: "20px" }}>
                <Button
                  onClick={props.clickEvent}
                  bgColor="#B4B7C9"
                  color="white"
                >
                  {t("recipient.cancel")}
                </Button>
              </div>
              <div className="company-field">
                <Button
                  onClick={() => setIsSubmitted(true)}
                  type="submit"
                  bgColor="#22C55E"
                  color="white"
                >
                  {t("recipient.confirm")} <Tick />
                </Button>
              </div>
            </div>
          )}
        </div>
      </form>
    </div>
  );
}
