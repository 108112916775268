import React from "react";
import { sliceLongString } from "../util/helper";
import { useTranslation } from "react-i18next";
const SelectRecipient = (props) => {
  const { t } = useTranslation();
  const onlyRecipients = props.accountList.filter(
    (acc) =>
      acc.business && acc.average && acc.client && acc.delayed && acc.revenue
  );
  const onlySuppliers = props.accountList.filter(
    (acc) =>
      !acc.business &&
      !acc.average &&
      !acc.client &&
      !acc.delayed &&
      !acc.revenue
  );
  return (
    <>
      <select
        className="selector"
        name="sector"
        id="sector"
        value={
          props.isRecipientAdded
            ? props.aftreAddedRecipient.company
              ? props.aftreAddedRecipient.company
              : props.aftreAddedRecipient.firstName +
                " " +
                props.aftreAddedRecipient.lastName
            : props.selectedAcc
        }
        onChange={(e) => {
          props.setIsRecipientAdded(false);
          props.setSelectedAcc(e.target.value);
          props.setRecipientFields(
            props.accountList?.find((acc) =>
              acc.company
                ? acc.company === e.target.value
                : acc.firstName === e.target.value
            )
          );
        }}
      >
        <option value="" hidden>
          {props.isSupplier ? `${t('profile.selecti')}...` :  `${t('profile.selectr')}...`}
        </option>
        {props.isSupplier
          ? onlySuppliers.map((acc) => {
              const fullName = acc?.firstName + " " + acc?.lastName;
              return (
                <option
                  value={
                    acc?.company
                      ? acc?.company
                      : acc?.firstName + " " + acc?.lastName
                  }
                  key={
                    acc?.company
                      ? acc?.company
                      : acc?.firstName + " " + acc?.lastName
                  }
                >
                  {sliceLongString(acc?.company, 20)}
                  {sliceLongString(fullName, 30)}
                </option>
              );
            })
          : onlyRecipients.map((acc) => {
              const fullName = acc?.firstName + " " + acc?.lastName;
              return (
                <option
                  value={
                    acc?.company
                      ? acc?.company
                      : acc?.firstName + " " + acc?.lastName
                  }
                  key={
                    acc?.company
                      ? acc?.company
                      : acc?.firstName + " " + acc?.lastName
                  }
                >
                  {sliceLongString(acc?.company, 20)}
                  {sliceLongString(fullName, 30)}
                </option>
              );
            })}
      </select>
      <p className="dropdown-error">
        {!props.displayAccount ? props.dropdownError.recipient : null}
      </p>
    </>
  );
};

export default SelectRecipient;
