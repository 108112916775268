import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FinbaLogo from "../../ui-components/FinbaLogo";
import { ReactComponent as Dashboard } from "../../assets/icons/dashboard.svg";
import { ReactComponent as Invoice } from "../../assets/icons/invoice.svg";
import profile from "../../assets/images/user-img.png";
import UserSettings from "../UserSettings";
import { Auth, API, graphqlOperation } from "aws-amplify";
import * as queries from "../../graphql/queries";
import { Trans, useTranslation } from "react-i18next";
import { sliceLongString } from "../../util/helper";

export default function DashboardNavbar() {
  const [showDropdown, setShowDropdown] = useState(false);
  const [user, setUser] = useState();
  const [identifier, setIdentifier] = useState(true);
  const [expand, setExpand] = useState(false);
  const [loggedUser, setLoggedUser] = useState();
  const { t, i18n } = useTranslation();
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  console.log("profile", user);
  console.log("loggggggggg", loggedUser);

  const signOut = async () => {
    try {
      await Auth.signOut();
      window.location.href = "/";
      localStorage.removeItem("logState");
    } catch (err) {
      console.log("err in SIGNOUT", err);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const thisUser = await Auth.currentAuthenticatedUser();
      let identifier = thisUser.attributes.email;
      setIdentifier(identifier);
      const matches = await API.graphql(
        graphqlOperation(queries.listWaitlists, {
          filter: { email: { eq: identifier } },
        })
      );
      const investorMatches = await API.graphql(
        graphqlOperation(queries.listInvestors, {
          filter: { email: { eq: identifier } },
        })
      );
      const profile = thisUser.attributes.locale.includes("investor")
        ? investorMatches.data.listInvestors.items[0]
        : matches.data.listWaitlists.items[0];
      setUser(profile);
      setLoggedUser(thisUser);
    };
    fetchUserData();
  }, []);

  const investorCondition = loggedUser?.attributes?.locale.includes("investor");
  console.log("investorCondition", investorCondition);
  return (
    <div className="dashContainer" style={expand ? { padding: 0 } : {}}>
      <div className={expand ? "dashboardnav-expand" : "dashboardnav"}>
        <div className="dashLinks">
          <Link to={investorCondition ? "/investor-dashboard" : "/"}>
            <div
              className="headerLogo"
              style={expand ? { display: "none" } : {}}
            >
              <FinbaLogo />
            </div>
          </Link>
          <Link
            to={
              investorCondition ? "/investor-dashboard" : "/company-dashboard"
            }
          >
            <Dashboard /> {t("dashboard.dash")}
          </Link>
          {investorCondition && (
            <Link to="/marketplace">
              <Invoice /> Marketplace{" "}
            </Link>
          )}
          {!investorCondition && (
            <>
              <Link to="/upload-invoice">
                <Invoice /> {t("dashboard.dashinvoice")}
              </Link>
              <Link to="/supplier-upload-invoice">
                <Invoice /> {t("dashboard.supload")}
              </Link>
            </>
          )}
          {expand && (
            <>
              <Link to="/profile">{t("dashboard.bankacc")} </Link>
              <Link to="#" onClick={signOut}>
                Log out
              </Link>
            </>
          )}
        </div>
        <div className="profilelinks">
          <div className="profileinfo" style={{ cursor: "pointer" }}>
            <ul className="langlist">
              <li onClick={() => changeLanguage("de")}>DE</li>
              <li>|</li>
              <li onClick={() => changeLanguage("en")}>EN</li>
            </ul>
            <div
              style={{ display: "flex", alignItems: "center" }}
              onClick={() => (expand ? "" : setShowDropdown(!showDropdown))}
            >
              <img
                width={35}
                height={35}
                style={{ objectFit: "contain" }}
                src={profile}
              />
              <div className="infouser" style={{ userSelect: "none" }}>
                <h3>{user?.firstName + " " + user?.lastName}</h3>
                <p>{sliceLongString(user?.companyName, 20)}</p>
              </div>
            </div>
          </div>
          {showDropdown && <UserSettings expand={expand} />}
        </div>
        <div
          className={expand ? "hamburgerX" : "hamburger"}
          onClick={() => setExpand(!expand)}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  );
}
