import React from "react";
import Button from "../Button/Button";
import styles from "./ValueCard.module.scss";
import accounting from "accounting";

export default function ValueCard({ days, invoiceAmount, premium, ...rest }) {
  let currencyFormat = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
  });

  const RATE_12MONTH = 0.1233;
  const MONTH = 30;
  const YEAR = 360;

  //amount
  const amount = accounting.unformat(invoiceAmount, ",");

  //rate
  const rate = (1 + RATE_12MONTH) ** (days / YEAR) - 1 + premium;

  //interest
  const interest = (rate * amount * MONTH) / days;

  //repayment
  const repayment = (amount / days) * MONTH;

  //total repayment
  const total = interest + repayment;

  return (
    <div
      className={`${styles.card} ${
        styles[rest.selectedPayment.days === days ? rest?.extraClass : null]
      }`}
    >
      <h1>{days} days</h1>
      <h2>{(rate * 100).toFixed(2)}%</h2>
      <div className={styles.payment}>
        <ul>
          <li>Monthly Repayment:</li>
          <li>Monthly Interest:</li>
          <b>
            <li>Monthly Rate:</li>
          </b>
        </ul>
        <ul>
          <li>{currencyFormat.format(repayment)}</li>
          <li>{currencyFormat.format(interest)}</li>
          <b>
            <li>{currencyFormat.format(total)}</li>
          </b>
        </ul>
      </div>
      <Button
        extraClass="selectbtn"
        bgColor="#E2E5F1"
        color="#2B3677"
        onClick={() => {
          rest.setSelectedPayment({
            days: days,
            percent: rate,
            monthlyRepayment: repayment,
            monthlyInterest: interest,
            monthlyRate: total,
          });
          rest.setIsPaymentSelected(true);
        }}
      >
        Select
      </Button>
    </div>
  );
}
