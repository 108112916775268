/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import { Flex, Icon, Text, View } from "@aws-amplify/ui-react";
import { Trans } from "react-i18next";
export default function BenefitsV2(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        divider3: {},
        divider2: {},
        divider1: {},
        "Rectangle 220010662919": {},
        Vector10662923: {},
        Vector10662924: {},
        Discover10662921: {},
        "Discover partners": {},
        "We have a large volume of companies and investors in our marketplace. Evaluate and choose your partners flexibly.":
          {},
        text10662925: {},
        Content10662920: {},
        Discover10662918: {},
        "Rectangle 220010662898": {},
        Vector10662903: {},
        Vector10662904: {},
        "Rectangle 2199": {},
        "Rectangle 220010662906": {},
        Fast10662900: {},
        "Fast and digital": {},
        "Overcome time-consuming complex processes and finalize contracts faster and simpler than ever before.":
          {},
        text10662907: {},
        Content10662899: {},
        Fast10662897: {},
        "Rectangle 220010662876": {},
        Vector10662882: {},
        Vector10662883: {},
        Track10662878: {},
        "Track your portfolio live": {},
        "Your dashboard gives you a transparent overview, allows you to analyze your performance and view contracts and partners transparently.":
          {},
        text10662884: {},
        Content10662877: {},
        Track10662875: {},
        "Rectangle 220010662929": {},
        Vector10662936: {},
        Vector10662937: {},
        Vector10662938: {},
        Vector10662939: {},
        Vector10662940: {},
        Vector10662941: {},
        Vector10662942: {},
        Legally10662931: {},
        "Legally compliant": {},
        "We ensure legally compliant contracts and support you in the event of payment defaults.":
          {},
        text10662943: {},
        Content10662930: {},
        Legally10662928: {},
        "Rectangle 220010662911": {},
        Vector10662914: {},
        "Less Expensive": {},
        "Less expensive": {},
        "We do not work with factoring companies, but connect companies directly with investors through our marketplace.":
          {},
        text10662915: {},
        Content10662912: {},
        LessExpensive: {},
        "Rectangle 220010662888": {},
        Vector10662892: {},
        Vector10662893: {},
        Security10662890: {},
        "Data Security": {},
        "Through our innovative decentralized technology, we ensure security and privacy of your data at all times.":
          {},
        text10662894: {},
        Content10662889: {},
        Security10662887: {},
        Content10662871: {},
        subtitle: {},
        "One platform \u2014 many solutions.": {},
        BenefitsV2: {},
      },
      variantValues: { property1: "large" },
    },
    {
      overrides: {
        divider3: {
          display: "none",
          top: "0.06%",
          bottom: "99.94%",
          left: "199.54%",
          right: "-223.33%",
        },
        divider2: {
          display: "none",
          top: "4.15%",
          bottom: "95.85%",
          left: "49.88%",
          right: "-73.67%",
        },
        divider1: {
          display: "none",
          top: "16.67%",
          bottom: "83.33%",
          left: "299.31%",
          right: "-498.61%",
        },
        "Rectangle 220010662919": {},
        Vector10662923: {
          viewBox: {
            minX: 0,
            minY: 0,
            width: 14.842041015625,
            height: 14.842041015625,
          },
        },
        Vector10662924: {},
        Discover10662921: {},
        "Discover partners": {},
        "We have a large volume of companies and investors in our marketplace. Evaluate and choose your partners flexibly.":
          {},
        text10662925: {},
        Content10662920: {},
        Discover10662918: { bottom: "83.33%", left: "0.23%", right: "0.23%" },
        "Rectangle 220010662898": {},
        Vector10662903: {},
        Vector10662904: {
          viewBox: {
            minX: 0,
            minY: 0,
            width: 7.681396484375,
            height: 7.681396484375,
          },
        },
        "Rectangle 2199": {},
        "Rectangle 220010662906": {},
        Fast10662900: {},
        "Fast and digital": {},
        "Overcome time-consuming complex processes and finalize contracts faster and simpler than ever before.":
          {},
        text10662907: {},
        Content10662899: {},
        Fast10662897: {
          top: "16.67%",
          bottom: "66.67%",
          left: "0.23%",
          right: "0.23%",
        },
        "Rectangle 220010662876": {},
        Vector10662882: {},
        Vector10662883: {},
        Track10662878: {},
        "Track your portfolio live": {},
        "Your dashboard gives you a transparent overview, allows you to analyze your performance and view contracts and partners transparently.":
          {},
        text10662884: {},
        Content10662877: {},
        Track10662875: { top: "33.33%", left: "0.23%", right: "0.23%" },
        "Rectangle 220010662929": {},
        Vector10662936: {},
        Vector10662937: {},
        Vector10662938: {},
        Vector10662939: {},
        Vector10662940: {},
        Vector10662941: {},
        Vector10662942: {},
        Legally10662931: {},
        "Legally compliant": {},
        "We ensure legally compliant contracts and support you in the event of payment defaults.":
          {},
        text10662943: {},
        Content10662930: {},
        Legally10662928: { bottom: "33.33%", left: "0.23%", right: "0.23%" },
        "Rectangle 220010662911": {},
        Vector10662914: {},
        "Less Expensive": {},
        "Less expensive": {},
        "We do not work with factoring companies, but connect companies directly with investors through our marketplace.":
          {},
        text10662915: {},
        Content10662912: {},
        LessExpensive: {
          top: "66.67%",
          bottom: "16.67%",
          left: "0.23%",
          right: "0.23%",
        },
        "Rectangle 220010662888": {},
        Vector10662892: {},
        Vector10662893: {
          viewBox: {
            minX: 0,
            minY: 0,
            width: 14.6044921875,
            height: 26.149169921875,
          },
        },
        Security10662890: {},
        "Data Security": {},
        "Through our innovative decentralized technology, we ensure security and privacy of your data at all times.":
          {},
        text10662894: {},
        Content10662889: {},
        Security10662887: { top: "83.33%", left: "0.23%", right: "0.23%" },
        Content10662871: {
          width: "433px",
          height: "1614px",
          top: "243px",
          left: "44px",
        },
        subtitle: {
          width: "242px",
          height: "45px",
          top: "161px",
          left: "calc(50% - 121px - 0px)",
        },
        "One platform \u2014 many solutions.": {
          width: "376px",
          height: "188px",
          top: "37px",
          left: "calc(50% - 188px - 0px)",
        },
        BenefitsV2: { width: "520px", height: "1953px" },
      },
      variantValues: { property1: "small" },
    },
    {
      overrides: {
        divider3: {
          width: "724px",
          viewBox: { minX: 0, minY: 0, width: 724, height: 1 },
          paths: [
            {
              d: "M0 0.5L724 0.5L724 -0.5L0 -0.5L0 0.5Z",
              stroke: "rgba(226,229,241,1)",
              fillRule: "nonzero",
              strokeWidth: 1,
            },
          ],
          top: "66.67%",
          bottom: "33.33%",
          left: "97.01%",
          right: "-91.04%",
          transform: "rotate(-180deg)",
        },
        divider2: {
          width: "724px",
          viewBox: { minX: 0, minY: 0, width: 724, height: 1 },
          paths: [
            {
              d: "M0 0L724 0L724 -1L0 -1L0 0Z",
              stroke: "rgba(226,229,241,1)",
              fillRule: "nonzero",
              strokeWidth: 1,
            },
          ],
          top: "33.33%",
          bottom: "66.67%",
          left: "97.01%",
          right: "-91.04%",
          transform: "rotate(180deg)",
        },
        divider1: {
          width: "806px",
          viewBox: { minX: 0, minY: 0, width: 1, height: 806 },
          paths: [
            {
              d: "M0 0L806 0L806 -1L0 -1L0 0Z",
              stroke: "rgba(226,229,241,1)",
              fillRule: "nonzero",
              strokeWidth: 1,
            },
          ],
          top: "99.38%",
          bottom: "0.62%",
          left: "50%",
          right: "-54.68%",
          transform: "rotate(-90deg)",
        },
        "Rectangle 220010662919": { width: "409.72px" },
        Vector10662923: {
          viewBox: {
            minX: 0,
            minY: 0,
            width: 14.842041015625,
            height: 14.8419189453125,
          },
        },
        Vector10662924: {},
        Discover10662921: {},
        "Discover partners": {},
        "We have a large volume of companies and investors in our marketplace. Evaluate and choose your partners flexibly.":
          {},
        text10662925: { width: "302px" },
        Content10662920: { left: "calc(50% - 151px - 0.86px)" },
        Discover10662918: {
          width: "409.72px",
          top: "0.25%",
          bottom: "66.42%",
          left: "0%",
          right: "46.79%",
        },
        "Rectangle 220010662898": { width: "409.72px" },
        Vector10662903: {},
        Vector10662904: {
          viewBox: {
            minX: 0,
            minY: 0,
            width: 7.681396484375,
            height: 7.6812744140625,
          },
        },
        "Rectangle 2199": {},
        "Rectangle 220010662906": {},
        Fast10662900: {},
        "Fast and digital": {},
        "Overcome time-consuming complex processes and finalize contracts faster and simpler than ever before.":
          { width: "278px" },
        text10662907: {},
        Content10662899: { left: "calc(50% - 176px - 0.86px)" },
        Fast10662897: {
          width: "409.72px",
          bottom: "66.67%",
          left: "46.79%",
          right: "0%",
        },
        "Rectangle 220010662876": { width: "409.72px" },
        Vector10662882: {},
        Vector10662883: {},
        Track10662878: {},
        "Track your portfolio live": {},
        "Your dashboard gives you a transparent overview, allows you to analyze your performance and view contracts and partners transparently.":
          {},
        text10662884: {},
        Content10662877: { left: "calc(50% - 176px - 0.86px)" },
        Track10662875: {
          width: "409.72px",
          top: "33.33%",
          bottom: "33.33%",
          left: "0%",
          right: "46.79%",
        },
        "Rectangle 220010662929": { width: "409.72px" },
        Vector10662936: {},
        Vector10662937: {},
        Vector10662938: {},
        Vector10662939: {},
        Vector10662940: {
          viewBox: {
            minX: 0,
            minY: 0,
            width: 10.15625,
            height: 7.9783935546875,
          },
        },
        Vector10662941: {},
        Vector10662942: {},
        Legally10662931: {},
        "Legally compliant": {},
        "We ensure legally compliant contracts and support you in the event of payment defaults.":
          {},
        text10662943: { width: "311px" },
        Content10662930: {
          top: "calc(50% - 102px - 0.5px)",
          left: "calc(50% - 155.5px - 0.86px)",
        },
        Legally10662928: {
          width: "409.72px",
          top: "33.33%",
          bottom: "33.33%",
          left: "46.79%",
          right: "0%",
        },
        "Rectangle 220010662911": { width: "409.72px" },
        Vector10662914: {},
        "Less Expensive": {},
        "Less expensive": {},
        "We do not work with factoring companies, but connect companies directly with investors through our marketplace.":
          {},
        text10662915: { width: "310px" },
        Content10662912: { left: "calc(50% - 155px - 0.86px)" },
        LessExpensive: {
          width: "409.72px",
          top: "66.67%",
          left: "0%",
          right: "46.79%",
        },
        "Rectangle 220010662888": { width: "409.72px" },
        Vector10662892: {},
        Vector10662893: {
          viewBox: {
            minX: 0,
            minY: 0,
            width: 14.6044921875,
            height: 26.149169921875,
          },
        },
        Security10662890: {},
        "Data Security": {},
        "Through our innovative decentralized technology, we ensure security and privacy of your data at all times.":
          {},
        text10662894: { width: "309px" },
        Content10662889: { left: "calc(50% - 154.5px - 0.86px)" },
        Security10662887: {
          width: "409.72px",
          top: "66.67%",
          left: "46.79%",
          right: "0%",
        },
        Content10662871: { width: "770px", height: "807px", top: "132px" },
        subtitle: {
          width: "468px",
          height: "45px",
          left: "calc(50% - 234px - 9px)",
        },
        "One platform \u2014 many solutions.": {
          width: "674px",
          height: "188px",
          left: "calc(50% - 337px - 9px)",
        },
        BenefitsV2: { width: "770px", height: "939px" },
      },
      variantValues: { property1: "medium" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <View
      width="1296px"
      height="728px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      {...rest}
      {...getOverrideProps(overrides, "BenefitsV2")}
    >
      <View
        width="1296px"
        height="538px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="165px"
        left="0px"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Content10662871")}
      >
        <Icon
          width="536px"
          height="0px"
          viewBox={{ minX: 0, minY: 0, width: 1, height: 536 }}
          paths={[
            {
              d: "M0 0.5L536 0.5L536 -0.5L0 -0.5L0 0.5Z",
              stroke: "rgba(226,229,241,1)",
              fillRule: "nonzero",
              strokeWidth: 1,
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0.19%"
          bottom="99.81%"
          left="66.67%"
          right="-8.02%"
          transformOrigin="top left"
          transform="rotate(90deg)"
          {...getOverrideProps(overrides, "divider3")}
        ></Icon>
        <Icon
          width="536px"
          height="0px"
          viewBox={{ minX: 0, minY: 0, width: 1, height: 536 }}
          paths={[
            {
              d: "M0 0L536 0L536 -1L0 -1L0 0Z",
              stroke: "rgba(226,229,241,1)",
              fillRule: "nonzero",
              strokeWidth: 1,
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0.19%"
          bottom="99.81%"
          left="33.33%"
          right="25.31%"
          transformOrigin="top left"
          transform="rotate(90deg)"
          {...getOverrideProps(overrides, "divider2")}
        ></Icon>
        <Icon
          width="1296px"
          height="0px"
          viewBox={{ minX: 0, minY: 0, width: 1296, height: 1 }}
          paths={[
            {
              d: "M0 0L1296 0L1296 -1L0 -1L0 0Z",
              stroke: "rgba(226,229,241,1)",
              fillRule: "nonzero",
              strokeWidth: 1,
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="50%"
          bottom="50%"
          left="100%"
          right="-100%"
          transformOrigin="top left"
          transform="rotate(180deg)"
          {...getOverrideProps(overrides, "divider1")}
        ></Icon>
        <View
        className="cardBack"
          width="431px"
          height="269px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0%"
          bottom="50%"
          left="0.15%"
          right="66.59%"
          boxShadow="0px 4px 10px rgba(0, 0, 0, 0.15000000596046448)"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Discover10662918")}
        >
          <View
            width="431px"
            height="269px"
            display="none"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0%"
            bottom="0%"
            left="0%"
            right="0%"
            padding="0px 0px 0px 0px"
            backgroundColor="rgba(97,139,232,0.3)"
            {...getOverrideProps(overrides, "Rectangle 220010662919")}
          ></View>
          <Flex
            gap="14px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            position="absolute"
            top="calc(50% - 102px - 0.5px)"
            left="calc(50% - 176px - 0.5px)"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Content10662920")}
          >
            <View
              width="72px"
              height="72px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              borderRadius="8px"
              padding="0px 0px 0px 0px"
              backgroundColor="rgba(243,246,255,1)"
              {...getOverrideProps(overrides, "Discover10662921")}
            >
              <Icon
                width="14.84px"
                height="14.84px"
                viewBox={{
                  minX: 0,
                  minY: 0,
                  width: 14.842041015625,
                  height: 14.841796875,
                }}
                paths={[
                  {
                    d: "M13.2794 8.98316C14.1425 8.98316 14.8419 8.28369 14.8419 7.42096C14.8419 6.55792 14.1425 5.85846 13.2794 5.85846L8.98316 5.85846L8.98316 1.56219C8.98316 0.699463 8.2837 0 7.42096 0C6.55793 0 5.85846 0.699463 5.85846 1.56219L5.85846 5.85846L1.56219 5.85846C0.699462 5.85846 0 6.55792 0 7.42096C0 8.28369 0.699462 8.98316 1.56219 8.98316L5.85846 8.98316L5.85846 13.2794C5.85846 14.1425 6.55793 14.8419 7.42096 14.8419C8.2837 14.8419 8.98316 14.1425 8.98316 13.2794L8.98316 8.98316L13.2794 8.98316Z",
                    fill: "rgba(226,74,63,1)",
                    fillRule: "nonzero",
                  },
                ]}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="57.16%"
                bottom="22.23%"
                left="57.16%"
                right="22.22%"
                {...getOverrideProps(overrides, "Vector10662923")}
              ></Icon>
              <Icon
                width="40px"
                height="40px"
                viewBox={{ minX: 0, minY: 0, width: 40, height: 39.9951171875 }}
                paths={[
                  {
                    d: "M23.1612 26.2186C22.1831 25.5362 21.1379 24.9802 20.0448 24.5554C21.4989 23.2337 22.4636 21.3821 22.6314 19.3094C24.8366 17.1607 27.7273 15.9827 30.826 15.9827C33.2451 15.9827 35.5678 16.7111 37.5436 18.089C38.2516 18.5828 39.2251 18.4094 39.7188 17.7014C40.2123 16.9937 40.039 16.0199 39.3313 15.5264C38.3532 14.8441 37.3076 14.2883 36.2145 13.8632C37.8191 12.4048 38.828 10.3018 38.828 7.96784C38.828 3.57422 35.2538 0 30.8602 0C26.4669 0 22.8927 3.57422 22.8927 7.96784C22.8927 10.2924 23.8933 12.3877 25.487 13.8455C25.2688 13.9294 25.0518 14.0183 24.8369 14.1129C23.8592 14.5432 22.94 15.0723 22.0852 15.6937C20.9063 12.7649 18.0361 10.6921 14.6905 10.6921C10.2971 10.6921 6.72261 14.2664 6.72261 18.6597C6.72261 20.9778 7.71779 23.0673 9.30288 24.5245C5.0649 26.1124 1.62862 29.5227 0.22969 33.8721C-0.234178 35.3143 0.0114888 36.8439 0.903823 38.0686C1.79616 39.2929 3.17677 39.9951 4.69167 39.9951L18.2839 39.9951C19.1466 39.9951 19.8461 39.2957 19.8461 38.4326C19.8461 37.5699 19.1466 36.8704 18.2839 36.8704L4.69167 36.8704C4.1866 36.8704 3.7264 36.6364 3.42885 36.228C3.1313 35.8197 3.04952 35.3098 3.20393 34.8288C4.74812 30.0278 9.4576 26.6748 14.6563 26.6748C17.0751 26.6748 19.3981 27.4033 21.3738 28.7811C22.0815 29.2749 23.0553 29.1013 23.5491 28.3936C24.0426 27.6859 23.8692 26.712 23.1612 26.2186ZM30.8602 3.1247C33.5308 3.1247 35.7033 5.29724 35.7033 7.96784C35.7033 10.6381 33.5308 12.811 30.8602 12.811C28.1899 12.811 26.0171 10.6381 26.0171 7.96784C26.0171 5.29724 28.1899 3.1247 30.8602 3.1247ZM14.6905 13.8165C17.361 13.8165 19.5336 15.9894 19.5336 18.6597C19.5336 21.3303 17.361 23.5028 14.6905 23.5028C12.0199 23.5028 9.84731 21.3303 9.84731 18.6597C9.84731 15.9894 12.0199 13.8165 14.6905 13.8165Z",
                    fill: "rgba(43,54,119,1)",
                    fillRule: "nonzero",
                  },
                ]}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="22.22%"
                bottom="22.23%"
                left="22.22%"
                right="22.22%"
                {...getOverrideProps(overrides, "Vector10662924")}
              ></Icon>
            </View>
            <Flex
              gap="12px"
              direction="column"
              width="352px"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "text10662925")}
            >
              <Text
                fontFamily="Lato"
                fontSize="20px"
                fontWeight="800"
                color="rgba(11,15,25,1)"
                lineHeight="28px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Discover partners"
                {...getOverrideProps(overrides, "Discover partners")}
              ><Trans i18nKey="benefits.title1">Discover partners</Trans></Text>
              <Text
                fontFamily="Lato"
                fontSize="16px"
                fontWeight="400"
                color="rgba(86,89,115,1)"
                lineHeight="25.600000381469727px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="We have a large volume of companies and investors in our marketplace. Evaluate and choose your partners flexibly."
                {...getOverrideProps(
                  overrides,
                  "We have a large volume of companies and investors in our marketplace. Evaluate and choose your partners flexibly."
                )}
              ><Trans i18nKey="benefits.description1"></Trans></Text>
            </Flex>
          </Flex>
        </View>
        <View
        className="cardBack"
          width="431px"
          height="269px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0%"
          bottom="50%"
          left="33.41%"
          right="33.33%"
          boxShadow="0px 4px 10px rgba(0, 0, 0, 0.15000000596046448)"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Fast10662897")}
        >
          <View
            width="431px"
            height="269px"
            display="none"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0%"
            bottom="0%"
            left="0%"
            right="0%"
            padding="0px 0px 0px 0px"
            backgroundColor="rgba(97,139,232,0.3)"
            {...getOverrideProps(overrides, "Rectangle 220010662898")}
          ></View>
          <Flex
            gap="14px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            position="absolute"
            top="calc(50% - 102px - 0.5px)"
            left="calc(50% - 176px - 0.5px)"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Content10662899")}
          >
            <View
              width="72px"
              height="72px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              borderRadius="8px"
              padding="0px 0px 0px 0px"
              backgroundColor="rgba(243,246,255,1)"
              {...getOverrideProps(overrides, "Fast10662900")}
            >
              <Icon
                width="36px"
                height="36px"
                viewBox={{ minX: 0, minY: 0, width: 36, height: 36 }}
                paths={[
                  {
                    d: "M18 0C8.07525 0 0 8.07525 0 18C0 27.9248 8.07525 36 18 36C27.9248 36 36 27.9248 36 18C36 8.07525 27.9248 0 18 0ZM18 31.5C10.5548 31.5 4.5 25.4453 4.5 18C4.5 10.5548 10.5548 4.5 18 4.5C25.4453 4.5 31.5 10.5548 31.5 18C31.5 25.4453 25.4453 31.5 18 31.5Z",
                    fill: "rgba(43,54,119,1)",
                    fillRule: "nonzero",
                  },
                ]}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="28.13%"
                bottom="21.88%"
                left="25%"
                right="25%"
                {...getOverrideProps(overrides, "Vector10662903")}
              ></Icon>
              <Icon
                width="6.36px"
                height="4.5px"
                viewBox={{
                  minX: 0,
                  minY: 0,
                  width: 7.681396484375,
                  height: 7.68115234375,
                }}
                paths={[
                  {
                    d: "M0 0L6.363 0L6.363 4.5L0 4.5L0 0Z",
                    fill: "rgba(226,74,63,1)",
                    fillRule: "nonzero",
                  },
                ]}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="36.58%"
                bottom="57.17%"
                left="72.79%"
                right="18.37%"
                transformOrigin="top left"
                transform="rotate(-135deg)"
                {...getOverrideProps(overrides, "Vector10662904")}
              ></Icon>
              <View
                width="14.73px"
                height="4.91px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="19.32%"
                bottom="73.86%"
                left="39.77%"
                right="39.77%"
                padding="0px 0px 0px 0px"
                backgroundColor="rgba(226,74,63,1)"
                {...getOverrideProps(overrides, "Rectangle 2199")}
              ></View>
              <View
                width="11.05px"
                height="4.91px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="39.77%"
                bottom="53.41%"
                left="53.41%"
                right="31.25%"
                transformOrigin="top left"
                transform="rotate(90deg)"
                padding="0px 0px 0px 0px"
                backgroundColor="rgba(43,54,119,1)"
                {...getOverrideProps(overrides, "Rectangle 220010662906")}
              ></View>
            </View>
            <Flex
              gap="12px"
              direction="column"
              width="352px"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "text10662907")}
            >
              <Text
                fontFamily="Lato"
                fontSize="20px"
                fontWeight="800"
                color="rgba(11,15,25,1)"
                lineHeight="28px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Fast and digital"
                {...getOverrideProps(overrides, "Fast and digital")}
              ><Trans i18nKey="benefits.title2"></Trans></Text>
              <Text
                fontFamily="Lato"
                fontSize="16px"
                fontWeight="400"
                color="rgba(86,89,115,1)"
                lineHeight="25.600000381469727px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Overcome time-consuming complex processes and finalize contracts faster and simpler than ever before."
                {...getOverrideProps(
                  overrides,
                  "Overcome time-consuming complex processes and finalize contracts faster and simpler than ever before."
                )}
              ><Trans i18nKey="benefits.description2"></Trans></Text>
            </Flex>
          </Flex>
        </View>
        <View
        className="cardBack"
          width="431px"
          height="269px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0%"
          bottom="50%"
          left="66.67%"
          right="0.08%"
          boxShadow="0px 4px 10px rgba(0, 0, 0, 0.15000000596046448)"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Track10662875")}
        >
          <View
            width="431px"
            height="269px"
            display="none"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0%"
            bottom="0%"
            left="0%"
            right="0%"
            padding="0px 0px 0px 0px"
            backgroundColor="rgba(97,139,232,0.3)"
            {...getOverrideProps(overrides, "Rectangle 220010662876")}
          ></View>
          <Flex
            gap="14px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            position="absolute"
            top="calc(50% - 102px - 0.5px)"
            left="calc(50% - 176px - 0.5px)"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Content10662877")}
          >
            <View
              width="72px"
              height="72px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              borderRadius="8px"
              padding="0px 0px 0px 0px"
              backgroundColor="rgba(243,246,255,1)"
              {...getOverrideProps(overrides, "Track10662878")}
            >
              <Icon
                width="27.19px"
                height="14.69px"
                viewBox={{ minX: 0, minY: 0, width: 27.1875, height: 14.6875 }}
                paths={[
                  {
                    d: "M22.1142 0C22.1124 0 22.1106 0 22.1088 0C21.6924 0 21.2931 0.16625 20.9996 0.461953L12.0351 9.49641L8.67978 6.09086C8.38853 5.79531 7.99173 5.62773 7.57689 5.625C7.57345 5.625 7.57017 5.625 7.56673 5.625C7.15548 5.625 6.76072 5.78719 6.46806 6.07648L0.463843 12.014C-0.149751 12.6208 -0.155298 13.6101 0.451499 14.2237C0.757124 14.5327 1.15962 14.6876 1.56259 14.6875C1.95947 14.6875 2.35666 14.5371 2.66119 14.236L7.55236 9.39914L10.9182 12.8153C11.2113 13.1127 11.611 13.2805 12.0285 13.2812C12.0295 13.2812 12.0303 13.2812 12.0313 13.2812C12.4478 13.2812 12.847 13.1149 13.1404 12.8192L22.101 3.78867L24.5081 6.24898C25.1119 6.86586 26.101 6.87648 26.7178 6.27305C27.3345 5.66953 27.3453 4.68031 26.7419 4.06344L23.2256 0.469687C22.9329 0.170703 22.5326 0.00148437 22.1142 0Z",
                    fill: "rgba(226,74,63,1)",
                    fillRule: "nonzero",
                  },
                ]}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="35.57%"
                bottom="44.03%"
                left="32.51%"
                right="29.73%"
                {...getOverrideProps(overrides, "Vector10662882")}
              ></Icon>
              <Icon
                width="40px"
                height="40px"
                viewBox={{ minX: 0, minY: 0, width: 40, height: 40 }}
                paths={[
                  {
                    d: "M38.4375 18.9062C39.3005 18.9062 40 18.2067 40 17.3438L40 4.76562C40 3.90266 39.3005 3.20312 38.4375 3.20312L21.5625 3.20312L21.5625 1.5625C21.5625 0.699531 20.863 0 20 0C19.137 0 18.4375 0.699531 18.4375 1.5625L18.4375 3.20312L1.5625 3.20312C0.699531 3.20312 0 3.90266 0 4.76562L0 25.2344C0 28.6806 2.80375 31.4844 6.25 31.4844L18.4375 31.4844L18.4375 36.875L13.75 36.875C12.887 36.875 12.1875 37.5745 12.1875 38.4375C12.1875 39.3005 12.887 40 13.75 40L26.1719 40C27.0348 40 27.7344 39.3005 27.7344 38.4375C27.7344 37.5745 27.0348 36.875 26.1719 36.875L21.5625 36.875L21.5625 31.4844L33.75 31.4844C37.1963 31.4844 40 28.6806 40 25.2344C40 24.3714 39.3005 23.6719 38.4375 23.6719C37.5745 23.6719 36.875 24.3714 36.875 25.2344C36.875 26.9575 35.4731 28.3594 33.75 28.3594L6.25 28.3594C4.52688 28.3594 3.125 26.9575 3.125 25.2344L3.125 6.32812L36.875 6.32812L36.875 17.3438C36.875 18.2067 37.5745 18.9062 38.4375 18.9062Z",
                    fill: "rgba(43,54,119,1)",
                    fillRule: "nonzero",
                  },
                ]}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="22.22%"
                bottom="22.22%"
                left="23.61%"
                right="20.83%"
                {...getOverrideProps(overrides, "Vector10662883")}
              ></Icon>
            </View>
            <Flex
              gap="12px"
              direction="column"
              width="352px"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "text10662884")}
            >
              <Text
                fontFamily="Lato"
                fontSize="20px"
                fontWeight="800"
                color="rgba(11,15,25,1)"
                lineHeight="28px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Track your portfolio live"
                {...getOverrideProps(overrides, "Track your portfolio live")}
              ><Trans i18nKey="benefits.title3"></Trans></Text>
              <Text
                fontFamily="Lato"
                fontSize="16px"
                fontWeight="400"
                color="rgba(86,89,115,1)"
                lineHeight="25.600000381469727px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Your dashboard gives you a transparent overview, allows you to analyze your performance and view contracts and partners transparently."
                {...getOverrideProps(
                  overrides,
                  "Your dashboard gives you a transparent overview, allows you to analyze your performance and view contracts and partners transparently."
                )}
              ><Trans i18nKey="benefits.description3"></Trans></Text>
            </Flex>
          </Flex>
        </View>
        <View
        className="cardBack"
          width="431px"
          height="269px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="50%"
          bottom="0%"
          left="0.15%"
          right="66.59%"
          boxShadow="0px 4px 10px rgba(0, 0, 0, 0.15000000596046448)"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Legally10662928")}
        >
          <View
            width="431px"
            height="269px"
            display="none"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0%"
            bottom="0%"
            left="0%"
            right="0%"
            padding="0px 0px 0px 0px"
            backgroundColor="rgba(97,139,232,0.3)"
            {...getOverrideProps(overrides, "Rectangle 220010662929")}
          ></View>
          <Flex
            gap="14px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            position="absolute"
            top="calc(50% - 89px - 0.5px)"
            left="calc(50% - 176px - 0.5px)"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Content10662930")}
          >
            <View
              width="72px"
              height="72px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              borderRadius="8px"
              padding="0px 0px 0px 0px"
              backgroundColor="rgba(243,246,255,1)"
              {...getOverrideProps(overrides, "Legally10662931")}
            >
              <Icon
                width="7.73px"
                height="3.13px"
                viewBox={{ minX: 0, minY: 0, width: 7.734375, height: 3.125 }}
                paths={[
                  {
                    d: "M6.17188 0L1.5625 0C0.699531 0 0 0.699531 0 1.5625C0 2.42547 0.699531 3.125 1.5625 3.125L6.17188 3.125C7.03484 3.125 7.73438 2.42547 7.73438 1.5625C7.73438 0.699531 7.03484 0 6.17188 0Z",
                    fill: "rgba(43,54,119,1)",
                    fillRule: "nonzero",
                  },
                ]}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="62.7%"
                bottom="32.96%"
                left="52.61%"
                right="36.65%"
                {...getOverrideProps(overrides, "Vector10662936")}
              ></Icon>
              <Icon
                width="7.73px"
                height="3.13px"
                viewBox={{ minX: 0, minY: 0, width: 7.734375, height: 3.125 }}
                paths={[
                  {
                    d: "M6.17188 0L1.5625 0C0.699531 0 0 0.699531 0 1.5625C0 2.42547 0.699531 3.125 1.5625 3.125L6.17188 3.125C7.03484 3.125 7.73438 2.42547 7.73438 1.5625C7.73438 0.699531 7.03484 0 6.17188 0Z",
                    fill: "rgba(43,54,119,1)",
                    fillRule: "nonzero",
                  },
                ]}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="49.57%"
                bottom="46.09%"
                left="52.61%"
                right="36.65%"
                {...getOverrideProps(overrides, "Vector10662937")}
              ></Icon>
              <Icon
                width="31.71px"
                height="40px"
                viewBox={{ minX: 0, minY: 0, width: 31.7099609375, height: 40 }}
                paths={[
                  {
                    d: "M30.1476 27.5C31.0105 27.5 31.7101 26.8005 31.7101 25.9375L31.7101 6.25C31.7101 2.80375 28.9063 0 25.4601 0L6.25 0C2.80375 0 0 2.80375 0 6.25L0 33.75C0 37.1963 2.80375 40 6.25 40L25.46 40C28.9063 40 31.71 37.1963 31.7101 33.75C31.7101 32.887 31.0105 32.1875 30.1476 32.1875C29.2846 32.1875 28.5851 32.887 28.5851 33.75C28.5851 35.4731 27.1832 36.875 25.4601 36.875L6.25 36.875C4.52688 36.875 3.125 35.4731 3.125 33.75L3.125 6.25C3.125 4.52688 4.52688 3.125 6.25 3.125L25.4601 3.125C27.1832 3.125 28.5851 4.52688 28.5851 6.25L28.5851 25.9375C28.5851 26.8005 29.2846 27.5 30.1476 27.5Z",
                    fill: "rgba(43,54,119,1)",
                    fillRule: "nonzero",
                  },
                ]}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="22.22%"
                bottom="22.22%"
                left="27.98%"
                right="27.98%"
                {...getOverrideProps(overrides, "Vector10662938")}
              ></Icon>
              <Icon
                width="10.16px"
                height="7.98px"
                viewBox={{
                  minX: 0,
                  minY: 0,
                  width: 10.15625,
                  height: 7.978515625,
                }}
                paths={[
                  {
                    d: "M9.58891 0.357945C8.92376 -0.191586 7.93883 -0.0979923 7.38922 0.56732L4.01383 4.65271L2.68195 3.28498C2.08 2.66677 1.0907 2.65357 0.472415 3.2556C-0.145789 3.85763 -0.158992 4.84685 0.443041 5.46513L2.11914 7.18646C2.13296 7.20068 2.14703 7.21459 2.1614 7.22826C2.66789 7.71037 3.3439 7.97849 4.0393 7.97849C4.09898 7.97834 4.15906 7.97638 4.21906 7.97232C4.97258 7.92209 5.67625 7.5574 6.1518 6.97123L9.79829 2.55771C10.348 1.8924 10.2542 0.907555 9.58891 0.357945Z",
                    fill: "rgba(226,74,63,1)",
                    fillRule: "nonzero",
                  },
                ]}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="30.36%"
                bottom="58.56%"
                left="36.66%"
                right="49.23%"
                {...getOverrideProps(overrides, "Vector10662939")}
              ></Icon>
              <Icon
                width="10.16px"
                height="7.98px"
                viewBox={{
                  minX: 0,
                  minY: 0,
                  width: 10.15625,
                  height: 7.978515625,
                }}
                paths={[
                  {
                    d: "M9.5889 0.35792C8.92374 -0.191611 7.93882 -0.0979396 7.38921 0.567294L4.01288 4.65377L2.67889 3.29143C2.07506 2.67487 1.08584 2.66456 0.469276 3.2683C-0.147287 3.87205 -0.157599 4.86135 0.446073 5.47791L2.12217 7.18955C2.13498 7.2026 2.14803 7.21549 2.16131 7.22814C2.66779 7.71025 3.34389 7.97845 4.03928 7.97845C4.09905 7.9783 4.15905 7.97634 4.21905 7.97236C4.97249 7.92212 5.67616 7.55744 6.15178 6.97127L9.79827 2.55768C10.348 1.89237 10.2542 0.907528 9.5889 0.35792Z",
                    fill: "rgba(226,74,63,1)",
                    fillRule: "nonzero",
                  },
                ]}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="56.82%"
                bottom="32.1%"
                left="36.66%"
                right="49.23%"
                {...getOverrideProps(overrides, "Vector10662940")}
              ></Icon>
              <Icon
                width="7.73px"
                height="3.13px"
                viewBox={{ minX: 0, minY: 0, width: 7.734375, height: 3.125 }}
                paths={[
                  {
                    d: "M6.17188 0L1.5625 0C0.699531 0 0 0.699531 0 1.5625C0 2.42547 0.699531 3.125 1.5625 3.125L6.17188 3.125C7.03484 3.125 7.73438 2.42547 7.73438 1.5625C7.73438 0.699531 7.03484 0 6.17188 0Z",
                    fill: "rgba(43,54,119,1)",
                    fillRule: "nonzero",
                  },
                ]}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="36.22%"
                bottom="59.44%"
                left="52.61%"
                right="36.65%"
                {...getOverrideProps(overrides, "Vector10662941")}
              ></Icon>
              <Icon
                width="10.16px"
                height="7.98px"
                viewBox={{
                  minX: 0,
                  minY: 0,
                  width: 10.15625,
                  height: 7.978515625,
                }}
                paths={[
                  {
                    d: "M9.5889 0.357951C8.92374 -0.191657 7.93882 -0.0979065 7.38921 0.567327L4.01288 4.6538L2.67889 3.29146C2.07506 2.6749 1.08584 2.66459 0.469276 3.26834C-0.147287 3.87209 -0.157599 4.86138 0.446073 5.47794L2.12217 7.18958C2.13498 7.20263 2.14803 7.21552 2.16131 7.22817C2.66779 7.71028 3.34389 7.97849 4.03928 7.97849C4.09905 7.97833 4.15905 7.97638 4.21905 7.97239C4.97249 7.92216 5.67616 7.55747 6.15178 6.9713L9.79827 2.55771C10.348 1.8924 10.2542 0.90756 9.5889 0.357951Z",
                    fill: "rgba(226,74,63,1)",
                    fillRule: "nonzero",
                  },
                ]}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="43.7%"
                bottom="45.22%"
                left="36.66%"
                right="49.23%"
                {...getOverrideProps(overrides, "Vector10662942")}
              ></Icon>
            </View>
            <Flex
              gap="12px"
              direction="column"
              width="352px"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "text10662943")}
            >
              <Text
                fontFamily="Lato"
                fontSize="20px"
                fontWeight="800"
                color="rgba(11,15,25,1)"
                lineHeight="28px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Legally compliant"
                {...getOverrideProps(overrides, "Legally compliant")}
              ><Trans i18nKey="benefits.title4"></Trans></Text>
              <Text
                fontFamily="Lato"
                fontSize="16px"
                fontWeight="400"
                color="rgba(86,89,115,1)"
                lineHeight="25.600000381469727px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="We ensure legally compliant contracts and support you in the event of payment defaults."
                {...getOverrideProps(
                  overrides,
                  "We ensure legally compliant contracts and support you in the event of payment defaults."
                )}
              ><Trans i18nKey="benefits.description4"></Trans></Text>
            </Flex>
          </Flex>
        </View>
        <View
        className="cardBack"
          width="431px"
          height="269px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="50%"
          bottom="0%"
          left="33.41%"
          right="33.33%"
          boxShadow="0px 4px 10px rgba(0, 0, 0, 0.15000000596046448)"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "LessExpensive")}
        >
          <View
            width="431px"
            height="269px"
            display="none"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0%"
            bottom="0%"
            left="0%"
            right="0%"
            padding="0px 0px 0px 0px"
            backgroundColor="rgba(97,139,232,0.3)"
            {...getOverrideProps(overrides, "Rectangle 220010662911")}
          ></View>
          <Flex
            gap="14px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            position="absolute"
            top="calc(50% - 102px - 0.5px)"
            left="calc(50% - 176px - 0.5px)"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Content10662912")}
          >
            <View
              width="72px"
              height="72px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              borderRadius="8px"
              padding="0px 0px 0px 0px"
              backgroundColor="rgba(243,246,255,1)"
              {...getOverrideProps(overrides, "Less Expensive")}
            >
              <Icon
                width="32px"
                height="40px"
                viewBox={{ minX: 0, minY: 0, width: 32, height: 40 }}
                paths={[
                  {
                    d: "M16 40C23.952 40 32 37.252 32 32L32 24L32 16L32 8C32 2.748 23.952 0 16 0C8.048 0 0 2.748 0 8L0 16L0 24L0 32C0 37.252 8.048 40 16 40ZM16 36C8.556 36 4 33.41 4 32L4 29.464C7.082 31.14 11.554 32 16 32C20.446 32 24.918 31.14 28 29.464L28 32C28 33.41 23.444 36 16 36ZM16 4C23.444 4 28 6.59 28 8C28 9.41 23.444 12 16 12C8.556 12 4 9.41 4 8C4 6.59 8.556 4 16 4ZM4 13.464C7.082 15.14 11.554 16 16 16C20.446 16 24.918 15.14 28 13.464L28 16C28 17.41 23.444 20 16 20C8.556 20 4 17.41 4 16L4 13.464ZM4 21.464C7.082 23.14 11.554 24 16 24C20.446 24 24.918 23.14 28 21.464L28 24C28 25.41 23.444 28 16 28C8.556 28 4 25.41 4 24L4 21.464Z",
                    fill: "rgba(43,54,119,1)",
                    fillRule: "nonzero",
                  },
                ]}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="22.22%"
                bottom="22.22%"
                left="27.78%"
                right="27.78%"
                {...getOverrideProps(overrides, "Vector10662914")}
              ></Icon>
            </View>
            <Flex
              gap="12px"
              direction="column"
              width="352px"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "text10662915")}
            >
              <Text
                fontFamily="Lato"
                fontSize="20px"
                fontWeight="800"
                color="rgba(11,15,25,1)"
                lineHeight="28px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Less expensive"
                {...getOverrideProps(overrides, "Less expensive")}
              ><Trans i18nKey="benefits.title5"></Trans></Text>
              <Text
                fontFamily="Lato"
                fontSize="16px"
                fontWeight="400"
                color="rgba(86,89,115,1)"
                lineHeight="25.600000381469727px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="We do not work with factoring companies, but connect companies directly with investors through our marketplace."
                {...getOverrideProps(
                  overrides,
                  "We do not work with factoring companies, but connect companies directly with investors through our marketplace."
                )}
              ><Trans i18nKey="benefits.description5"></Trans></Text>
            </Flex>
          </Flex>
        </View>
        <View
        className="cardBack"
          width="431px"
          height="269px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="50%"
          bottom="0%"
          left="66.67%"
          right="0.08%"
          boxShadow="0px 4px 10px rgba(0, 0, 0, 0.15000000596046448)"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Security10662887")}
        >
          <View
            width="431px"
            height="269px"
            display="none"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0%"
            bottom="0%"
            left="0%"
            right="0%"
            padding="0px 0px 0px 0px"
            backgroundColor="rgba(97,139,232,0.3)"
            {...getOverrideProps(overrides, "Rectangle 220010662888")}
          ></View>
          <Flex
            gap="14px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            position="absolute"
            top="calc(50% - 102px - 0.5px)"
            left="calc(50% - 176px - 0.5px)"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Content10662889")}
          >
            <View
              width="72px"
              height="72px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              borderRadius="8px"
              padding="0px 0px 0px 0px"
              backgroundColor="rgba(243,246,255,1)"
              {...getOverrideProps(overrides, "Security10662890")}
            >
              <Icon
                width="40px"
                height="40px"
                viewBox={{
                  minX: 0,
                  minY: 0,
                  width: 39.99853515625,
                  height: 40,
                }}
                paths={[
                  {
                    d: "M19.9232 40C19.6836 40 19.4443 39.9451 19.2243 39.8352C19.0415 39.7437 14.6964 37.5406 10.1502 32.5989C7.48542 29.7028 5.2967 26.4551 3.64448 22.9465C1.58088 18.5641 0.356209 13.7662 0.00373083 8.68591C-0.0249557 8.27148 0.112679 7.86285 0.385811 7.55005C0.659248 7.23724 1.0456 7.0459 1.46033 7.01874C4.32197 6.83075 6.56379 4.4342 6.56379 1.5625C6.56379 0.699463 7.26325 0 8.12629 0L31.8763 0C32.739 0 33.4388 0.699463 33.4388 1.5625C33.4388 4.43237 35.6791 6.82892 38.5392 7.01874C38.9603 7.04651 39.3522 7.24365 39.6256 7.565C39.8994 7.88635 40.0312 8.30475 39.9915 8.72498C39.4572 14.3658 37.8995 19.6417 35.3617 24.4064C34.9558 25.1682 34.0095 25.4565 33.248 25.051C32.4863 24.6454 32.1979 23.699 32.6035 22.9373C34.7294 18.9462 36.1109 14.5578 36.7185 9.87366C33.519 9.03015 31.0612 6.38428 30.4569 3.125L9.54566 3.125C8.93897 6.39832 6.46308 9.05243 3.24378 9.88403C4.06501 17.7667 7.12897 24.6588 12.3627 30.3882C15.4957 33.8174 18.5673 35.8408 19.9143 36.6415C21.3883 35.7449 24.9531 33.3356 28.4235 29.158C28.975 28.494 29.9598 28.403 30.6238 28.9545C31.2876 29.5059 31.3788 30.491 30.8274 31.1548C25.8884 37.1002 20.8472 39.7198 20.6348 39.8285C20.4114 39.9429 20.1673 40 19.9232 40Z",
                    fill: "rgba(43,54,119,1)",
                    fillRule: "nonzero",
                  },
                ]}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="22.22%"
                bottom="22.22%"
                left="22.22%"
                right="22.23%"
                {...getOverrideProps(overrides, "Vector10662892")}
              ></Icon>
              <Icon
                width="14.6px"
                height="26.15px"
                viewBox={{
                  minX: 0,
                  minY: 0,
                  width: 14.6044921875,
                  height: 26.1494140625,
                }}
                paths={[
                  {
                    d: "M14.2884 25.5288C14.8087 24.84 14.6723 23.8604 13.9841 23.3401C12.6331 22.3192 11.3547 21.1617 10.184 19.9001C6.78042 16.2307 4.47115 11.9195 3.31118 7.07266C3.93618 6.58987 4.52426 6.05643 5.06716 5.47903C5.96743 4.52109 6.73617 3.45145 7.35201 2.2991C7.75851 1.53799 7.47103 0.591339 6.70992 0.18454C5.94881 -0.221954 5.00216 0.0655217 4.59567 0.82663C4.10952 1.73605 3.50222 2.58109 2.79055 3.33853C2.16311 4.00595 1.45999 4.5983 0.701318 5.0997C0.169092 5.45126 -0.0961061 6.09122 0.0317625 6.71652C1.22134 12.5335 3.86599 17.6842 7.89309 22.0254C9.18764 23.4209 10.603 24.7024 12.0999 25.8333C12.3819 26.0464 12.7127 26.1492 13.0408 26.1492C13.5138 26.1492 13.9813 25.9353 14.2884 25.5288Z",
                    fill: "rgba(226,74,63,1)",
                    fillRule: "nonzero",
                  },
                ]}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="30.58%"
                bottom="33.1%"
                left="31.73%"
                right="47.99%"
                {...getOverrideProps(overrides, "Vector10662893")}
              ></Icon>
            </View>
            <Flex
              gap="12px"
              direction="column"
              width="352px"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "text10662894")}
            >
              <Text
                fontFamily="Lato"
                fontSize="20px"
                fontWeight="800"
                color="rgba(11,15,25,1)"
                lineHeight="28px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Data Security"
                {...getOverrideProps(overrides, "Data Security")}
              ><Trans i18nKey="benefits.title6"></Trans></Text>
              <Text
                fontFamily="Lato"
                fontSize="16px"
                fontWeight="400"
                color="rgba(86,89,115,1)"
                lineHeight="25.600000381469727px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Through our innovative decentralized technology, we ensure security and privacy of your data at all times."
                {...getOverrideProps(
                  overrides,
                  "Through our innovative decentralized technology, we ensure security and privacy of your data at all times."
                )}
              ><Trans i18nKey="benefits.description6"></Trans></Text>
            </Flex>
          </Flex>
        </View>
      </View>
      <View
             fontFamily="Lato"
             fontSize="18px"
             fontWeight="400"
             color="rgba(147,151,173,1)"
             lineHeight="28.80000114440918px"
             textAlign="center"
             display="block"
             direction="column"
             justifyContent="unset"
             width="636px"
             height="unset"
             gap="unset"
             alignItems="unset"
             position="absolute"
             top="76px"
             left="calc(50% - 318px - 0px)"
             padding="0px 0px 0px 0px"
             whiteSpace="pre-wrap">
              <Trans i18nKey="benefits.description">
      <Text
        children="Innovative technology supported infrastructure."
        {...getOverrideProps(overrides, "subtitle")}
      ></Text>
      </Trans>
      </View>
      <View
              fontFamily="Lato"
              fontSize="40px"
              fontWeight="800"
              color="rgba(11,15,25,1)"
              lineHeight="52px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              width="636px"
              height="unset"
              gap="unset"
              alignItems="unset"
              position="absolute"
              top="0px"
              left="calc(50% - 318px - 0px)"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap">
                <Trans i18nKey="benefits.title">
      <Text
        children="One platform — many solutions."
        {...getOverrideProps(overrides, "One platform \u2014 many solutions.")}
      ></Text>
      </Trans>
      </View>
    </View>
  );
}
