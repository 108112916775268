import React from 'react';
import "./FirstSection.scss";
export default function InvestorCard({ title, icon,extraClass, ...rest }) {
    return (
        <div className='investorbox'>
            <div className='firstrow'>
                <p className='boxtitle'>{title}</p>
                {icon}
            </div>
            <h2 className='amount'>{rest?.amount}</h2>
            <p className={`time ${extraClass}`}>{rest?.arrow}<span className='percent'>{rest?.percent}%</span>{rest?.time}</p>
        </div>
    )
}
