import React from "react";
import PaymentList from "../../atoms/PaymentList/PaymentList";
import styles from "./Summary.module.scss";

export default function Summary(props) {
  return (
    <div className={styles.container}>
      <div className={styles.paymentlist}>
        <PaymentList
          displayAccount={props.displayAccount}
          displayBank={props.displayBank}
          invoiceSummary={props.invoiceSummary}
          selectedPayment={props.selectedPayment}
          pdfFiles={props.pdfFiles}
        />
      </div>
    </div>
  );
}
