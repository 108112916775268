import React, { useEffect, useState } from "react";
import "./AdminPanel.scss";
import logo from "../../assets/icons/mainLogo.png";
import { API, Auth, Storage } from "aws-amplify";
import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";
import { sliceLongString } from "../../util/helper";
import Button from "../Button";

const AdminPanel = () => {
  const [showInvoices, setShowInvoices] = useState(false);
  const [invoices, setInvoices] = useState();

  const signOut = async () => {
    try {
      await Auth.signOut();
      window.location.href = window.location.origin + "/";
      localStorage.removeItem("logState");
    } catch (err) {
      console.log("err in SIGNOUT", err);
    }
  };

  useEffect(() => {
    async function fetchInvoices(callback) {
      try {
        //   const user = await Auth.currentAuthenticatedUser();
        //   const identifier = user.attributes.email;
        //   setIdentifier(identifier);
        //   console.log("identifier=-=-=-=", identifier);
        //   let filter = {
        //     email: {
        //       eq: identifier, // filter priority = 1
        //     },
        //   };
        const res = await API.graphql({
          query: queries.listInvoiceSummaries,
          // variables: { filter: filter },
        });

        // setInvoices(res.data.listInvoiceSummaries.items);
        const invoices = res.data.listInvoiceSummaries.items;

        const promises = invoices.map(async (invoice) => {
          // return { ...invoice, url: ''}
          let ret = { ...invoice, url: "" };

          if (invoice?.doc) {
            const newUrl = await getStorageUrl(invoice.doc);
            ret.url = newUrl;
          }
          return ret;
        });
        let newinvoices = await Promise.all(promises);
        callback(newinvoices);
      } catch (err) {
        console.log("error fetchInvoices", err);
      }
      async function getStorageUrl(key) {
        const url = await Storage.get(key);
        return url;
      }
    }
    fetchInvoices((invoices) => setInvoices(invoices));
  }, []);

  const handleEdit = async (obj) => {
    const {
      url,
      updatedAt,
      createdAt,
      days,
      percent,
      monthlyRepayment,
      monthlyInterest,
      monthlyRate,
      ...newInvoice
    } = obj;
    const editedData = await API.graphql({
      query: mutations.updateInvoiceSummary,
      variables: { input: { ...newInvoice, status: "approved" } },
    });
    if (editedData.data.updateInvoiceSummary.status === "approved") {
      const updatedInvoices = invoices.map((inv) => {
        if (inv.id === obj.id) {
          return { ...inv, status: "approved" };
        } else {
          return inv;
        }
      });
      setInvoices(updatedInvoices);
    }
  };

  return (
    <div className="adminCont">
      <div className="navBar">
        <img src={logo} alt="logo" />
        <p className="link" onClick={() => setShowInvoices(true)}>
          Invoices
        </p>
      </div>
      <div className="content">
        <p>Welcome Admin</p>
        <button className="logoutBtn" onClick={signOut}>
          Log out
        </button>
        {showInvoices && (
          <div className="invoices">
            <div className="header">
              <p>Name</p>
              <p>Invoice</p>
              <p>Type</p>
              <p>Email</p>
              <p>Date</p>
              <p>Payment Date</p>
              <p>Amount</p>
            </div>
            <div className="data">
              {invoices &&
                invoices.map((invoice) => (
                  <>
                    <div className="singleData">
                      <p>{sliceLongString(invoice.buyerCompany, 10)}</p>
                      {invoice.url ? (
                        <a href={invoice.url} target="_blank" rel="noopener">
                          {sliceLongString(
                            invoice.invoiceNumber.toUpperCase(),
                            9
                          )}
                        </a>
                      ) : (
                        <p>{invoice.invoiceNumber}</p>
                      )}
                      <p>{invoice.invoiceType}</p>
                      <p>{invoice.email}</p>
                      <p>{invoice.invoiceDate}</p>
                      <p>{invoice.paymentDate}</p>
                      <p>{invoice.amount}</p>
                      {invoice?.status === "approved" ? (
                        <p className="approved">APPROVED</p>
                      ) : (
                        <button
                          onClick={() => {
                            handleEdit(invoice);
                          }}
                          className="approveBtn"
                        >
                          Approve
                        </button>
                      )}
                    </div>
                  </>
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminPanel;
