import { useFormik } from "formik";
import * as Yup from "yup";
import React from "react";
import { API } from "aws-amplify";
import * as mutations from "../../../graphql/mutations";
import styles from "./Footer.module.scss";
import { ReactComponent as Logo } from "../../../assets/icons/bluelogo.svg";
import { ReactComponent as Linkedin } from "../../../assets/icons/linkedin.svg";
import { ReactComponent as Youtube } from "../../../assets/icons/youtube.svg";
import Button from "../../atoms/Button/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const array1 = [
    {
      id: 1,
      title: t("navbar.item1"),
      path: "/invest",
    },
    {
      id: 2,
      title: t("navbar.item2"),
      path: "/company",
    },
    {
      id: 3,
      title: t("navbar.item3"),
      path: "/team",
    },
  ];
  const array2 = [
    {
      id: 1,
      title: t("navbar.jobs"),
      path: "/jobs",
    },
    {
      id: 2,
      title: t("footer.title4"),
    },
    {
      id: 3,
      title: t("footer.jobs2"),
    },
    {
      id: 5,
      title: t("footer.title2"),
      path: "/imprint",
    },
  ];
  const [newsletterSuccess, setNewsletterSuccess] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is Required"),
    }),
    onSubmit: async (values) => {
      const entry = { ...values };

      try {
        console.log("Entry:", entry);
        await API.graphql({
          query: mutations.createNewsletter,
          variables: { input: entry },
          authMode: "AWS_IAM",
        });
        console.log("Saved Request Successfully!");
        setNewsletterSuccess(true);
        formik.resetForm();
      } catch (err) {
        console.error("ERROR:", err);
      }
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  return (
    <div className={styles.footer}>
      <div>
        <Logo
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/");
            window.scrollTo(0, 0);
          }}
        />
        <p>{t("banner.title")}</p>
        <div className={styles.inputHolder}>
          <form onSubmit={formik.handleSubmit}>
            <div className={styles.subscribe}>
              <div className={styles.inputs}>
                <input
                  id="email"
                  name="email"
                  type="email"
                  placeholder={t("request.placeholder")}
                  {...formik.getFieldProps("email")}
                />

                {formik.touched.email && formik.errors.email ? (
                  <div className={styles.error}>{formik.errors.email}</div>
                ) : null}
              </div>
              {newsletterSuccess ? <div>Thank you</div> : null}
              <Button bgColor="#2B3677" color="white" type="submit">
                Subscribe
              </Button>
            </div>
          </form>
        </div>
      </div>
      <ul>
        {array1.map((item) => {
          return (
            <li
              key={item.id}
              onClick={() => {
                navigate(item?.path);
                window.scrollTo(0, 0);
              }}
            >
              {item.title}
            </li>
          );
        })}
      </ul>
      <ul>
        {array2.map((item) => {
          return (
            <li
              key={item.id}
              onClick={() => {
                navigate(item?.path);
                window.scrollTo(0, 0);
              }}
            >
              {item.title}
            </li>
          );
        })}
        <div className={styles.icons}>
          <a target="_blank" href="https://www.linkedin.com/company/finba-io/">
            <Linkedin />
          </a>
          <a target="_blank" href="https://www.youtube.com/@finba-io">
            <Youtube />
          </a>
        </div>
      </ul>
      <div>
        <span>{t("footer.title3")}</span>
        <p className={styles.address}>
          Finba GmbH Königstraße 28 22767 Hamburg Deutschland
        </p>
        <a href="mailto:hello@finba.io">hello@finba.io</a>
      </div>
    </div>
  );
}
