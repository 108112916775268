import React from "react";
import logo from "../assets/icons/mainLogo.png";
import Button from "./Button";
import { useTranslation } from "react-i18next";

const Modal = (props) => {

  const { t } = useTranslation();

  return (
    <div className="modalBackground">
      <div className="modalBody">
        <div className="closeButton">
          <span></span>
        </div>
        <div className="modalData">
          <img src={logo} alt="logo" />
          <p className="title">{props.title}</p>
          <p className="description">{props.description}</p>
          <Button
            bgColor="#2B3677"
            color="white"
            children={t('welcome.continue')}
            onClick={() => props.setShowModal(false)}
          />
        </div>
      </div>
    </div>
  );
};

export default Modal;
