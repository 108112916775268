import React from 'react';
import Button from '../../atoms/Button/Button';
import styles from "./TextSection.module.scss";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function TextSection({title, description, button, extraClass}) {
    const {t} = useTranslation();
    const navigate = useNavigate();
    return (
        <div className={`${styles.container} ${styles[extraClass]}`}>
            <div className={styles.content}>
            <h1>{title}</h1>
            <div>
                <p>{description}</p>
                {button &&
                <Button onClick={() => navigate("/investor-signup")} bgColor="#2B3677">{t('mission.btn')}</Button>
            }
            </div>
            </div>
        </div>
    )
}
