

export const UploadInvoiceValidation = (fields, t) => {

  let errors = {};

  if (!fields?.buyerName?.trim()) {
    errors.buyerName = `${t("errors.buyer")}`;
  }
  if (!fields?.buyerIban?.trim()) {
    errors.buyerIban = `${t("errors.ibanbuyer")}`;
  }
  if (!fields?.buyerEmail?.trim()) {
    errors.buyerEmail = `${t("errors.emailbuyer")}`;
  }
  if (!fields?.brs?.trim()) {
    errors.brs = `${t("errors.businesssince")}`;
  }
  if (fields?.sector && !fields?.sector?.trim()) {
    errors.sector = `${t("errors.option")}`;
  }
  if (!fields?.revenue?.trim()) {
    errors.revenue = `${t("errors.percentage")}`;
  } else if (fields?.revenue < 0) {
    errors.revenue = `${t("errors.positive")}`;
  } else if (fields?.revenue > 100) {
    errors.revenue = `${t("errors.equal")}`;
  }
  if (!fields?.delayedPayment?.trim()) {
    errors.delayedPayment = `${t("errors.delayed")}`;
  } else if (fields?.delayedPayment < 0) {
    errors.delayedPayment = `${t("errors.delayedp")}`;
  }
  if (!fields?.eai?.trim()) {
    errors.eai = `${t("errors.averageyear")}`;
  } else if (fields?.eai < 0) {
    errors.eai = `${t("errors.averagep")}`;
  }
  if (!fields?.suppName?.trim()) {
    errors.suppName = `${t("errors.sfirst")}`;
  }
  if (!fields?.suppLastName?.trim()) {
    errors.suppLastName = `${t("errors.slast")}`;
  }
  if (!fields?.suppEmail?.trim()) {
    errors.suppEmail = `${t("errors.semail")}`;
  }
  if (!fields?.invoiceNumber?.trim()) {
    errors.invoiceNumber = `${t("errors.invoice")}`;
  }
  if (fields?.amount === "") {
    errors.amount = `${t("errors.amount")}`;
  }

  return errors;
};

export const BankAccountValidation = (fields, t) => {
  let errors = {};
  if (!fields?.companyName?.trim()) {
    errors.companyName = `${t("errors.company")}`;
  }
  if (!fields?.ust?.trim() || fields?.ust?.length < 9) {
    errors.ust = `${t("errors.ustcontain")}`;
  }
  if (!fields?.tax?.trim() || fields?.tax?.length < 11) {
    errors.tax = `${t("errors.taxcontain")}`;
  }
  if (!fields?.firstName?.trim()) {
    errors.firstName = `${t("errors.firstname")}`;
  }
  if (!fields?.lastName?.trim()) {
    errors.lastName = `${t("errors.lastname")}`;
  }
  if (!fields?.IBAN?.trim() || fields?.IBAN?.length < 22) {
    errors.IBAN = `${t("errors.ibancontain")}`;
  }
  //  else if (fields?.IBAN?.length < 20) {
  //   errors.IBAN = "IBAN must be 20 characters long.";
  // }
  if (!fields?.companyAddress?.trim()) {
    errors.companyAddress = `${t("errors.address")}`;
  }
  if (!fields?.companyZip?.trim()) {
    errors.companyZip = `${t("errors.czip")}`;
  }
  if (!fields?.companyCity?.trim()) {
    errors.companyCity = `${t("errors.companycity")}`;
  }
  if (!fields?.gender?.trim()) {
    errors.gender = `${t("errors.genderselect")}`;
  }
  // if (!fields?.birthDate?.trim()) {
  //   errors.birthDate = "Birth Date is required.";
  // }
  if (!fields?.address?.trim()) {
    errors.address =`${t("errors.email")}`;
  }
  if (!fields?.zip?.trim()) {
    errors.zip = `${t("errors.zip")}`;
  }
  if (!fields?.city?.trim()) {
    errors.city = `${t("errors.city")}`;
  }
  return errors;
};
