import React from 'react';
import Investor from '../molecules/Investor/Investor';

export default function InvestorProfilePage() {
  return (
    <div>
      <Investor />
    </div>
  )
}
