import React, { useEffect, useState } from "react";
import InvestorCard from "./InvestorCard";
import { cards, balance } from "../../../constants/data";
import profile from "../../../assets/images/user-img.png";
import { API, Auth, graphqlOperation } from "aws-amplify";
import * as queries from "../../../graphql/queries";

export default function FirstSection() {
  const [user, setUser] = useState();
  const [filterId, setFilterId] = useState();
  const [userProfile, setUserProfile] = useState();
  useEffect(() => {
    const fetchData = async () => {
      const currentUser = await Auth.currentAuthenticatedUser();
      setUser(currentUser);
      setFilterId(currentUser.attributes.email);
      let id = currentUser && currentUser.attributes.email;
      let filter = { email: { eq: id } };
      const matched = await API.graphql(
        graphqlOperation(queries.listInvestors, {
          filter: filter,
        })
      );
      const profile = matched.data.listInvestors.items[0];
      setUserProfile(profile);
    };
    fetchData();
  }, []);
  console.log("user", user);
  console.log("filterId", filterId);
  console.log("userProfile", userProfile);

  return (
    <div className="firstsection">
      <div className="investorcards">
        {cards.map((item) => {
          return (
            <InvestorCard
              key={item.id}
              title={item.title}
              icon={item.icon}
              amount={item.amount}
              time={item.time}
              percent={item.percent}
              arrow={item.arrow}
              extraClass={item.extraClass}
            />
          );
        })}
      </div>
      <div className="profileHolder">
        <div className="investorinfo">
          <div className="information">
            <span>Connected</span>
            <p>Welcome</p>
            <h2>
              {userProfile &&
                userProfile?.firstName + " " + userProfile?.lastName}
            </h2>
          </div>
          <div className="profileinfo">
            <img src={profile} />
            <div className="infouser">
              <h3>Invest Wallet</h3>
              <p>0xd....23462</p>
            </div>
          </div>
        </div>
        <p className="balanceTitle">Total Balance</p>
        {balance.map((item) => {
          return (
            <div key={item.id} className="balanceItem">
              {item.icon}
              <p>{item.name}:</p>
              <p className="balanceAmount">{item.amount}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}
