import React from "react";

const ProgressBar = (props) => {
  const progressContainer = {
    height: 5,
    width: "100%",
    backgroundColor: "#e0e0de",
    borderRadius: 50,
    margin: 50,
  };

  const progressFiller = {
    height: "100%",
    width: `${props?.completed}%`,
    backgroundColor: props?.color,
    borderRadius: "inherit",
    textAlign: "right",
  };

  return (
    <div className="element">
      <p className="state">{props?.state}</p>
      <div className="countryBar">
        <div style={progressContainer}>
          <div style={progressFiller}></div>
        </div>
        <p className="indicator">{props?.value}k</p>
      </div>
    </div>
  );
};

export default ProgressBar;
