import React from 'react';
import styles from "./Mission.module.scss";
import Button from '../../atoms/Button/Button';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Mission({image}) {

  const { t } = useTranslation();

  const navigate = useNavigate();

    return (
        <div className={styles.mission}
            style={{
                backgroundImage: `url(${image})`
            }}>
            <div className={styles.content}>
                <span>{t('mission.desc')}</span>
                <h2>{t('mission.title')}</h2>
                <ul>
                    <li>{t('mission.list1')}</li>
                    <li>{t('mission.list2')}</li>
                    <li>{t('mission.list3')}</li>
                </ul>
                <Button onClick={() => navigate("/investor-signup")}  bgColor="#618BE8" color="white">{t('mission.btn')}</Button>
            </div>

        </div>
    )
}
