import React, { useRef } from 'react'
import Header from '../molecules/Header/Header'
import Footer from '../molecules/Footer/Footer'
import Steps from '../molecules/Steps/Steps'
import RequestCard from '../molecules/RequestCard/RequestCard'
import FaqSection from '../molecules/FaqSection/FaqSection'
import { faqData, solutionCards, companySteps } from '../constants/data'
import Dashboard from "../../assets/images/companyBanner.png";
import Banner from '../molecules/Banner/Banner'
import TrustedBy from '../../components/TrustedBy'
import InfoSection from '../molecules/InfoSection/InfoSection'
import Solutions from '../molecules/Solutions/Solutions'
import { useTranslation } from 'react-i18next'

export default function CompanyPage() {

  const { t } = useTranslation();

  const handleClick = () => {
    const footer = document.getElementById("footer");
    footer.scrollIntoView({ behavior: 'smooth' });
  }
  return (
    <div>
      <Header signupPath="/company-signup" extraClassMobile="factorymobile" extraClass="factoryheader" />
      <Banner path="/company-signup" path1={handleClick} button2 bgColor1="#2B3677" button1={t('banner.contact')} extraClass="companyBanner" image={Dashboard} title={t('banner.title')} description={t('banner.description')}  button={t('banner.start')} bgColor="#618BE8" color="white" />
      <TrustedBy />
      <Solutions cards={solutionCards}  />
      <InfoSection />
      <Steps data={companySteps} />
      <RequestCard />
      <FaqSection data={faqData} />
      <div id="footer">
      <Footer   />
      </div>
    </div>
  )
}
