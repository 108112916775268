import { ReactComponent as Accounts } from "../assets/icons/accounts.svg";
import { ReactComponent as GreenArrow } from "../assets/icons/greenarrow.svg";
import { ReactComponent as Carts } from "../assets/icons/carts.svg";
import { ReactComponent as Money } from "../assets/icons/money.svg";
import { ReactComponent as Value } from "../assets/icons/value.svg";
import { ReactComponent as Icon1 } from "../assets/icons/matic.svg";
import { ReactComponent as Icon2 } from "../assets/icons/usdc.svg";
import { ReactComponent as Icon3 } from "../assets/icons/jeur.svg";

export const cards = [
  {
    id: 1,
    title: "Total Spent",
    amount: "$36,254",
    percent: " 5.27",
    time: "Since last month",
    icon: <Accounts />,
    arrow: <GreenArrow />,
  },
  {
    id: 2,
    title: "Open Receivables",
    amount: "53",
    percent: " 1.08",
    time: "Since last month",
    icon: <Carts />,
    arrow: <GreenArrow />,
    extraClass: "red",
  },
  {
    id: 3,
    title: "Revenue",
    amount: "$6,254",
    percent: " 7.00",
    time: "Since last month",
    icon: <Money />,
    arrow: <GreenArrow />,
  },
  {
    id: 4,
    title: "Growth",
    amount: "+ 30.56%",
    percent: "4.87",
    time: "Since last month",
    icon: <Value />,
    arrow: <GreenArrow />,
    extraClass: "red",
  },
];
export const marketplaceCards = [
  {
    id: 1,
    title: "Monthly Profit",
    amount: "€6,254",
    percent: " 7.00",
    time: "Since last month",
    icon: <Money />,
    arrow: <GreenArrow />,
  },
  {
    id: 2,
    title: "Open Receivables",
    amount: "53",
    percent: " 1.08",
    time: "Since last month",
    icon: <Carts />,
    arrow: <GreenArrow />,
    extraClass: "red",
  },
];

export const balance = [
  {
    id: 1,
    icon: <Icon2 />,
    name: "USDC Balance",
    amount: "$1243,23.00",
  },
  {
    id: 2,
    icon: <Icon3 />,
    name: "LEUR Balance",
    amount: "€5235,54.00",
  },
];
export const marketplaceBalance = [
  // {
  //   id: 1,
  //   icon: <Icon1 />,
  //   name: "0 MATIC",
  //   amount: "€0.00 EUR",
  // },
  {
    id: 2,
    icon: <Icon2 />,
    name: "0 USDC",
    amount: "€0.00 EUR",
  },
  {
    id: 3,
    icon: <Icon3 />,
    name: "5,254.18 LEUR",
    amount: "€5,254.18 EUR",
  },
];

export const marketplaceTableData = [
  {
    id: 1,
    name: "Ra GmbH",
    apy: 4.3,
    openVolume: 6518.18,
    totalVolume: 11439.19,
    risk: "LOW",
    date: "01.11.2022",
    type: "RBF",
    timeRemaining: "2h 24m 30s",
  },
  {
    id: 2,
    name: "Ra GmbH",
    apy: 4.3,
    openVolume: 6518.18,
    totalVolume: 11439.19,
    risk: "MEDIUM",
    date: "01.11.2022",
    type: "TRF",
    timeRemaining: "2h 24m 30s",
  },
  {
    id: 3,
    name: "Ra GmbH",
    apy: 4.3,
    openVolume: 6518.18,
    totalVolume: 11439.19,
    risk: "LOW",
    date: "01.11.2022",
    type: "RBF",
    timeRemaining: "2h 24m 30s",
  },
  {
    id: 4,
    name: "Ra GmbH",
    apy: 4.3,
    openVolume: 6518.18,
    totalVolume: 11439.19,
    risk: "HIGH",
    date: "01.11.2022",
    type: "RBF",
    timeRemaining: "2h 24m 30s",
  },
  {
    id: 5,
    name: "Ra GmbH",
    apy: 4.3,
    openVolume: 6518.18,
    totalVolume: 11439.19,
    risk: "HIGH",
    date: "01.11.2022",
    type: "TRF",
    timeRemaining: "2h 24m 30s",
  },
  {
    id: 6,
    name: "Ra GmbH",
    apy: 4.3,
    openVolume: 6518.18,
    totalVolume: 11439.19,
    risk: "MEDIUM",
    date: "01.11.2022",
    type: "RBF",
    timeRemaining: "2h 24m 30s",
  },
  {
    id: 7,
    name: "Ra GmbH",
    apy: 4.3,
    openVolume: 6518.18,
    totalVolume: 11439.19,
    risk: "MEDIUM",
    date: "01.11.2022",
    type: "RBF",
    timeRemaining: "2h 24m 30s",
  },
  {
    id: 8,
    name: "Ra GmbH",
    apy: 4.3,
    openVolume: 6518.18,
    totalVolume: 11439.19,
    risk: "MEDIUM",
    date: "01.11.2022",
    type: "RBF",
    timeRemaining: "2h 24m 30s",
  },
  {
    id: 9,
    name: "Ra GmbH",
    apy: 4.3,
    openVolume: 6518.18,
    totalVolume: 11439.19,
    risk: "MEDIUM",
    date: "01.11.2022",
    type: "RBF",
    timeRemaining: "2h 24m 30s",
  },
  {
    id: 10,
    name: "Ra GmbH",
    apy: 4.3,
    openVolume: 6518.18,
    totalVolume: 11439.19,
    risk: "HIGH",
    date: "01.11.2022",
    type: "RBF",
    timeRemaining: "2h 24m 30s",
  },
  {
    id: 11,
    name: "Ra GmbH",
    apy: 4.3,
    openVolume: 6518.18,
    totalVolume: 11439.19,
    risk: "HIGH",
    date: "01.11.2022",
    type: "RBF",
    timeRemaining: "2h 24m 30s",
  },
];
