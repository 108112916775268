import React from 'react'; 
import styles from "./MemberCard.module.scss";
import { ReactComponent as Facebook } from "../../../assets/icons/facebook.svg";
import { ReactComponent as Linkedin } from "../../../assets/icons/linkedin.svg";
import { ReactComponent as Twitter } from "../../../assets/icons/twitter.svg";

export default function MemberCard({backgroundImage,...rest }) {
  return (
    <div className={styles.container}>
      <div className={styles.image} 
      style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className={styles.content}>
            <div className={styles.socials}>
                <a target='_blank' href={rest?.toLinkedin}><Linkedin/></a>
            </div>
        </div>
      </div>
      <p>{rest?.name}</p>
      <span>{rest?.position}</span>
      <div>
      </div>
    </div>
  )
}
