import React from "react";
import { useTranslation } from "react-i18next";

const SelectBank = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <select
        className="selector"
        name="sector"
        id="sector"
        value={props.selectedBank}
        onChange={(e) => {
          props.setSelectedBank(e.target.value);
        }}
      >
        <option value="Bankkonto 1 " hidden>
        {t('profile.select')}...
        </option>
        {props.bankAccountList.map((option) => (
          <option value={option.IBAN}>
            {option?.IBAN &&
              option?.IBAN?.match(/.{1,4}/g)
                .join(" ")
                .toUpperCase()}
          </option>
        ))}
      </select>
      <p className="dropdown-error">
        {!props.displayBank ? props.dropdownError.bank : null}
      </p>
    </>
  );
};

export default SelectBank;
