import React from 'react';
import TrustedBy from '../../components/TrustedBy';
import Footer from '../molecules/Footer/Footer';
import Header from '../molecules/Header/Header';
import Reviews from '../molecules/Reviews/Reviews';
import TalkSection from '../molecules/TalkSection/TalkSection';
import Team from '../molecules/Team/Team';
import TeamBanner from '../molecules/TeamBanner/TeamBanner';
import TeamHistory from '../molecules/TeamHistory/TeamHistory';

export default function TeamPage() {
  return (
    <div>
      <Header signupPath="/investor-signup"/>
      <TeamBanner />
      <TeamHistory />
      <Team />
      <Reviews />
      <TrustedBy />
      <TalkSection />
      <Footer />
    </div>
  )
}
