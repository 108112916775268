import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useEffect } from "react";

export function RequireAuth(props) {
  console.log("propssssss", props);
  const navigate = useNavigate();

  async function checkAuthState() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const userRoles = user.attributes.locale.includes("investor")
        ? ["investor"]
        : user.attributes.locale.includes("admin")
        ? ["admin"]
        : ["company"];
      console.log("Authenticated user");
      if (props.role !== "all" && !userRoles.includes(props.role)) {
        navigate("/404");
      }
    } catch (err) {
      console.log("ERROR", err);
      navigate("/", { state: { login: true } });
    }
  }
  useEffect(() => {
    console.log("Checking auth state");
    checkAuthState();
  });

  return props.children;
}
