import React from "react";
import "./MultiplePdf.scss";
import { Upload, Modal } from "antd";
import { ReactComponent as Cloud } from "../../assets/icons/upload.svg";
import { withTranslation } from "react-i18next";

class PicturesWall extends React.Component {
  state = {
    previewVisible: false,
    previewImage: "",
    previewTitle: "",
    fileList: [],
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async (file) => {
    // Check if the file is a PDF
    if (file.type === "application/pdf") {
      // Get the base64 representation of the file
      if (!file.url && !file.preview) {
        file.preview = await this.props.getBase64(file.originFileObj);
      }
      this.setState({
        previewImage: file.preview,
        previewVisible: true,
        previewTitle:
          file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
      });
    }
  };

  // handleChange = ({ fileList }) => this.setState({ fileList });
  handleChange = ({ fileList }) => {
    this.setState({ fileList });
    this.uploadedFiles = fileList.reduce((acc, file) => {
      acc[file.name] = file;
      return acc;
    }, {});
    // this.props.props.setPdfFiles(this.uploadedFiles);
    this.props.props.setPdfFiles(fileList);
  };

  handleSubmit = (event) => {
    event.preventDefault();

    let formData = new FormData();
    for (var a = 0; a < this.state.fileList.length; a++) {
      formData.append("file[]", this.state.fileList[a].originFileObj);
    }
  };

  render() {
    const { t } = this.props;
    const { previewVisible, previewImage, fileList, previewTitle } = this.state;
    const uploadButton = (
      <div>
        <div className="uploadbtn" style={{ marginTop: 8 }}>
          <Cloud />
          <p>{t("invoice.subtitle")}</p>
        </div>
      </div>
    );
    return (
      <>
        <Upload
          accept=".pdf"
          listType="picture-card"
          fileList={fileList}
          onPreview={this.handlePreview}
          onChange={this.handleChange}
          beforeUpload={() => false}
          multiple
          maxFile={10}
        >
          {fileList.length >= 10 ? null : uploadButton}
        </Upload>
        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={this.handleCancel}
        >
          <object
            alt="example"
            style={{ width: "100%" }}
            type="applocation/pdf"
            src={previewImage}
          />
        </Modal>
      </>
    );
  }
}

export default withTranslation()(PicturesWall);
