import React from 'react';
import styles from "./JobsCards.module.scss";
import { ReactComponent as Icon1 } from "../../../assets/icons/pioneer.svg";
import { ReactComponent as Icon2 } from "../../../assets/icons/expert.svg";
import { ReactComponent as Icon3 } from "../../../assets/icons/team.svg";
import { ReactComponent as Icon4 } from "../../../assets/icons/owner.svg";
import { useTranslation } from 'react-i18next';

export default function JobsCards() {

    const { t } = useTranslation();

    const cards = [
        {
            id:1,
            icon: <Icon1 />, 
            title: t('dropdown.title5'),
            desc: t('dropdown.desc5'),
        },
        {
            id:2,
            icon: <Icon2 />, 
            title: t('dropdown.title6'),
            desc: t('dropdown.desc6')
        },
        {
            id:3,
            icon: <Icon3 />, 
            title: t('dropdown.title7'),
            desc: t('dropdown.desc7'),
        },
        {
            id:4,
            icon: <Icon4 />, 
            title: t('dropdown.title8'),
            desc: t('dropdown.desc8'),
        }
    ] 
    return (
        <div className={styles.container}>
            <h1>{t('dropdown.title9')}</h1>
            <div className={styles.cardsHolder}>
                {cards.map((item) => {
                    return (
                        <div  key={item.id} className={styles.card}>
                            <h1>{item.icon}</h1>
                            <h3>{item.title}</h3>
                            <p>{item.desc}</p>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
