import React from 'react';
import "./styles.scss";
import arrow from "../assets/icons/next.png";
import { useNavigate } from "react-router-dom";
export default function Imprint() {
  const navigate = useNavigate();
  return (
    <div className='imprintHolder'>
      <button
            onClick={() => navigate("/")}
            style={{
              color: "#2B3677",
              fontSize: "13px",
              display: "flex",
              alignItems: "center",
              gap: "5px",
              marginTop: "25px",
              marginLeft: "20px",
            }}
          >
            <img
              style={{ width: "13px", transform: "rotate(-180deg)" }}
              src={arrow}
            />{" "}
            Back to Main Page{" "}
          </button>
      <div className='imprintContent'>
        <h1 className='iTitle'>Angaben gemäß § 5 TMG:</h1>
        <h3 className='iSubtitle'>Vermittler von Vermögensanlagen</h3>
        <p className='iDesc'>Finba Technologies GmbH <br /> Königstraße 28 <br /> 22767 Hamburg <br /> Deutschland <br/> Telefon:<a target="_blank" href='https://api.whatsapp.com/send/?phone=%2B491621739274&text&type=phone_number&app_absent=0'> +49 (0) 162 1739274 </a><br /> E-Mail: <a href='mailto: "hello@finba.io"'>hello@finba.io</a> </p>

        <h3 className='iSubtitle'>Geschäftsführer:</h3>
        <p className='iDesc'>Lorian Qorraj, Christian Faber</p>

        <h3 className='iSubtitle'>Handelsregister:</h3>
        <p className='iDesc'> Amtsgericht Hamburg <br /> Registernummer: HRB 178187 </p>

        <h3 className='iSubtitle'>Umsatzsteuer-Identifikationsnummer:</h3>
        <p className='iDesc'>DE335671477</p>

        <h3 className='iSubtitle'>Zuständige Aufsichtsbehörde:</h3>
        <p className='iDesc'>Bezirksamt Hamburg-Mitte<br />
          Abt. Gewerberecht
          <br />
          Klosterwall 2
          <br />
          20095 Hamburg
          <br />
          Mitglied der Industrie- und Handelskammer Hamburg
          <br />
          Adolphspatz 1
          <br />
          20457 Hamburg
          <br />
          Die Finba Technologies GmbH ist ein Finanzanlagenvermittler und hat die Erlaubnis nach § 34 f Abs. 1 Gewerbeordnung.
        </p>

        <h3 className='iSubtitle'>Vermittlerregister <a target="_blank" href='https://www.dihk.de/de/ihk-update/vermittlerregister-aktueller-zugriff-und-umgang-mit-dem-cyberangriff-77120'>(www.vermittlerregister.info):</a></h3>
        <p className='iDesc'>Register-Nr. D-F-152-5YYC-20</p>

        <h3 className='iSubtitle'>Berufsbezeichnung:</h3>
        <p className='iDesc'>Finanzanlagenvermittler nach § 34f Abs. 1 Gewerbeordnung; Bundesrepublik Deutschland <br /> Berufsrechtliche Regelungen: <br /> § 34 f Gewerbeordnung, FinVermV <br /> Die berufsrechtlichen Regelungen können über die vom Bundesministerium der Justiz und von der juris GmbH betriebenen Homepage <a target="_blank" href='https://www.gesetze-im-internet.de/'>www.gesetze-im-internet.de</a> eingesehen und abgerufen werden.</p>

        <h3 className='iSubtitle'>Inhaltlich verantwortlich nach § 55 Abs. 2 RStV:</h3>
        <p className='iDesc'>Lorian Qorraj <br />
          Am Sandtorkai 70
          <br />
          20457 Hamburg
        </p>

        <h3 className='iSubtitle'>Kontaktdaten Datenschutzbeauftragter:</h3>
        <p className='iDesc'>betrieblicher Datenschutzbeauftragter <br />
          Tel. <a target="_blank" href='https://api.whatsapp.com/send/?phone=%2B491621739274&text&type=phone_number&app_absent=0'>+49 (0) 162 1739274</a>
          <br />
          <a href='mailto: "helo@finba.io"'>Email: hello@finba.io</a>
        </p>

        <h3 className='iSubtitle'>Zuständige Verbraucherschlichtungsstelle:</h3>
        <p className='iDesc'>Schlichtungsstelle der Deutschen Bundesbank <br />
          Postfach 11 12 32 <br />
          60047 Frankfurt am Main <br />
          Telefon: <a href='tel: +49 69 2388-1907'>+49 69 2388-1907</a>, <br />
          Telefax: <a href='tel: +49 69 709090-9901'>+49 69 709090-9901 </a><br />
          Email: <a href='mailto: schlichtung@bundesbank.de'>schlichtung@bundesbank.de</a> <br />
          Internet: <a target="_blank" href='https://www.bundesbank.de/de/service/schlichtungsstelle'> www.bundesbank.de/schlichtungsstelle</a></p>

        <h3 className='iSubtitle'>Hinweis auf Online-Streitbeilegung gemäß Art. 14 Abs. 1 ODR-Verordnung:</h3>
        <p className='iDesc'>Die Europäische Kommission stellt eine Plattform zur außergerichtlichen Online-Streitbeilegung (OS-Plattform) bereit, die Sie unter ec.europa.eu/consumers/odr erreichen. Die Finba Technologies GmbH ist zur Teilnahme an einem Schlichtungsverfahren nicht verpflichtet und und hat sich einer außergerichtlichen Streitbeilegung vor einer Verbraucherschlichtungsstelle nicht unterworfen.</p>

        <h3 className='iSubtitle'>Versicherungsschutz Vermögensschaden-Haftpflichtversicherung:</h3>
        <p className='iDesc'>Policennummer: COAB7F8H <br />
          Vers. Risiko: Tätigkeit  § 34f Abs. 1 S. 1 Nr. 3 GewO (§ 1 Abs. 2 VermAnlG), inkl. Plausibilitätsprüfung örtlicher Geltungsbereich: Deutschland sowie (im Rahmen der Dienstleistungsfreiheit) in den Staaten der Europäischen Union, Liechtenstein und Schweiz</p>

        <h3 className='iSubtitle'>Angaben zum Risikoträger:</h3>
        <p className='iDesc'>Liberty Mutual Insurance Europe SE <br />
          Direktion für Deutschland <br />
          Im Mediapark 8, 50670 Köln <br />
          Registriert unter: HRB 53435 <br />
          Amtsgericht Köln <br />
          Fax: <a href='tel: +49 221 50052299'>+49 221 50052299</a> 
        </p>
      </div>
    </div>
  )
}
