import React, { useState } from "react";
import styles from "./FAQ.module.scss";
import { ReactComponent as Arrow } from "../../../assets/icons/faqdefault.svg";
import { ReactComponent as Active } from "../../../assets/icons/faqactive.svg";
import { useTranslation } from "react-i18next";
const FAQ = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const {t} = useTranslation();
  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className={styles.faq}>
      {data.map((item, index) => (
        <div className={styles.faqHolder} key={item.question}>
          <button onClick={() => handleClick(index)} className = {activeIndex === index ? `${styles.active}` : ""}>
          {t(item.question)}
            {activeIndex === index ? <Active />  : <Arrow /> }
          </button>
          {activeIndex === index && <p>{t(item.answer)}</p>}
        </div>
      ))}
    </div>
  );
};

export default FAQ;
