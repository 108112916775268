import React, { useState } from 'react';
import styles from "./AddPayment.module.scss";
import Input from '../../../components/Input';
import Button from '../../atoms/Button/Button';
import { useFormik } from 'formik';
import PaymentCard from '../../atoms/PaymentCard/PaymentCard';

const AddPayment = ({ handleSave, handleClose }) => {
  const [iban, setIban] = useState("");
  const [error, setError] = useState("");

  const validate = (values) => {
    const errors = {};
  
    if (!values.iban) {
      errors.iban = 'IBAN is required';
    } else if (!/^[A-Z]{2}\d{2}[A-Z0-9]{18}$/.test(values.iban)) {
      errors.iban = 'IBAN must contain 22 characters';
    }
  
    return errors;
  };
    
  const handleSaveWithMaskedIban = () => {
    const maskedIban = iban.replace(/.(?=.{4,}$)/g, "●");
    const paymentData = {
      iban: maskedIban,
    };
    handleSave(paymentData);
    setIban("");
  };


  const handleSubmit = (event) => {
    event.preventDefault();
    if (iban.length !== 22) {
      setError("IBAN must contain exactly 22 characters");
      return;
    }
    const paymentData = {
      iban,
    };
    handleSaveWithMaskedIban();
    setError("");
  };
  

  return (
    <div className={styles.container}>
      <h2>Add New Payment Type</h2>
      <form onSubmit={handleSubmit}>
        <Input
          extraClass="paymentInput"
          placeholder="IBAN"
          label="IBAN"
          type="text"
          value={iban}
          onChange={(event) => {
            setIban(event.target.value);
            setError("");
          }}
        />
        {error && <span style={{marginTop: "-10px", marginLeft: "-20px"}}  className="error">{error}</span>}
        <div className={styles.btns}>
          <Button
            type="button"
            bgColor="#EFF2FC"
            color="#33354D"
            onClick={handleClose}
          >
            Close
          </Button>
          <Button type="submit" bgColor="#2B3677" color="white">
            Save changes
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddPayment;