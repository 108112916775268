import React from 'react';
import InvestorProfilePage from '../organisms/InvestorProfilePage';

export default function InvestorProfile() {
  return (
    <div>
      <InvestorProfilePage />
    </div>
  )
}
