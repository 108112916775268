import React, { useState } from 'react';
import styles from "./Header.module.scss";
import { ReactComponent as Login } from "../../../assets/icons/login.svg";
import { ReactComponent as Close } from "../../../assets/icons/close.svg";
import { ReactComponent as Logo } from "../../../assets/icons/finbalogo.svg";
import { useNavigate } from 'react-router-dom';

const SideMenu = ({ closeMenu, extraClass, handleClose }) => {
    const navigate = useNavigate();
    const [showForBusinesses, setShowForBusinesses] = useState(false);
    const [showAboutUs, setShowAboutUs] = useState(false);
    const [showLogin, setShowLogin] = useState(false);

    return (
        <div className={`${styles.sideMenu} ${styles[extraClass]}`}>
            <button className={styles.closeButton} onClick={closeMenu}>
                <Close />
            </button>
            <ul className={styles.menuList}>
            <li className={styles.logo} onClick={() => {navigate("/"); handleClose()}}><Logo /></li>
                <li onClick={() => {navigate("/invest"); handleClose()}}>Invest</li>
                <li className={showForBusinesses == true ? `${styles.activeBtn}` : "" } onClick={() => {
                    setShowForBusinesses(!showForBusinesses);
                    setShowAboutUs(false);
                    setShowLogin(false);
                }}>For Businesses</li>
                {showForBusinesses &&
                    <ul>
                       <li onClick={() => {navigate("/company"); handleClose()}}>Factoring</li>
                        <li>Revenue based financing</li>
                        <li>Embedded finance</li>
                    </ul>
                }
                <li className={showAboutUs == true ? `${styles.activeBtn}` : "" } onClick={() => {
                    setShowAboutUs(!showAboutUs);
                    setShowForBusinesses(false);
                    setShowLogin(false);
                }}>About Us</li>
                {showAboutUs &&
                    <ul>
                        <li>Our Story</li>
                        <li onClick={() => {navigate("/team"); handleClose()}}>Our Team</li>
                        <li>Investors</li>
                        <li onClick={() => {navigate("/jobs"); handleClose()}}>Jobs</li>
                    </ul>
                }
            </ul>
            <ul className={styles.menuList}>
                <li className={showLogin == true ? `${styles.activeBtn}` : "" } onClick={() => {
                    setShowLogin(!showLogin)
                    setShowAboutUs(false);
                    setShowForBusinesses(false);
                }} >Log In</li>
                {showLogin &&
                    <ul>
                        <li onClick={() => navigate("/investor-signup")} >As Investor</li>
                        <li onClick={() => navigate("/company-signup")} >As Company</li>
                    </ul>
                }
            </ul>
        </div>
    )
}
export default SideMenu;
