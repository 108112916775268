import React, { useEffect, useState } from "react";
import "./Login.scss";
import { ReactComponent as Logo } from "../../assets/icons/loginlogo.svg";
import { ReactComponent as Eye } from "../../assets/icons/eye.svg";
import { ReactComponent as EyeClosed } from "../../assets/icons/eyeclosed.svg";
import { Auth, API } from "aws-amplify";
import * as queries from "../../graphql/queries";
import Input from "../Input";
import Button from "../Button";
import { Checkbox } from "@chakra-ui/react";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Login({ onClick, to }) {
  const navigate = useNavigate();
  const { t } = useTranslation(); 
  const [user, setUser] = useState(null);
  const [validEmail, setValidEmail] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  // const [signOut, setSignOut] = useState();
  // useEffect(() => {
  //   let s = localStorage.getItem("signedOud");
  //   console.log("s-----------", s);
  //   if (s) {
  //     setSignOut(s);
  //   }
  // }, []);
  // console.log("signOut-=-=-=-=-", signOut);

  const togglePassword = (e) => {
    e.preventDefault();
    setPasswordShown(!passwordShown);
  };

  const toggleConfirmPassword = (e) => {
    e.preventDefault();
    setConfirmPasswordShown(!confirmPasswordShown);
  };

  const initialValues = {
    email: "",
    password: "",
    confirmPassword: "",
    code: "",
  };

  const validate = (values) => {
    let errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.email) {
      errors.email = `${t("errors.email")}`;
    } else if (!regex.test(values.email)) {
      errors.email = `${t("signup.emailInvalid")}`;
    }
    if (!forgotPassword && !values.password) {
      errors.password = `${t("errors.passwordr")}`;
    } else if (!forgotPassword && values.password.length < 4) {
      errors.password = `${t("errors.passwordsh")}`
    }

    if (validEmail && !values.code) {
      errors.code = `${t("errors.code")}`;
    }
    if (validEmail && !values.password) {
      errors.password = `${t("errors.passwordr")}`;
    }
    if (validEmail && !values.confirmPassword) {
      errors.confirmPassword = `${t("signup.confirmPasswordRequired")}`;
    }

    if (validEmail && values.password !== values.confirmPassword) {
      errors.confirmPassword = `${t("signup.passwordConfirm")}`;
    }
    console.log("validating ... errors:", errors);
    return errors;
  };

  // useEffect(() => {
  //   const fetchInvestors = async () => {

  //   };
  //   fetchInvestors();
  // }, []);

  const handleSubmit = async (values, actions) => {
    try {
      const user = await Auth.signIn(values?.email, values?.password);
      setUser(user);
      console.log("Successful Sign In. User:", user);
      const groups =
        user.signInUserSession.accessToken.payload["cognito:groups"];
      console.log("Groups:", groups);
      const companyID = user.attributes.email;

      let filter = { email: { eq: companyID } };
      const accounts = await API.graphql({
        query: queries.listCompanyBankAccounts,
        variables: { filter: filter },
      });
      // const investors = await API.graphql({
      //   query: queries.listInvestors,
      //   variables: { filter: filter },
      // });
      // console.log(
      //   "investors------------",
      //   investors?.data?.listInvestors?.items
      // );
      const no_of_accounts = accounts.data.listCompanyBankAccounts.items.length;
      console.log("No of accounts:", no_of_accounts);

      // check if user attributes for locale includes investor
      if (user?.attributes?.locale?.includes("investor")) {
        navigate("/investor-dashboard");
      } else if (user?.attributes?.locale?.includes("admin")) {
        navigate("/admin");
      } else {
        navigate(no_of_accounts > 0 ? "/company-dashboard" : "/profile");
      }
    } catch (error) {
      console.log("error signing in", error);
      actions.setFieldError("password", error.message);
    }
  };

  const handleReset = async (values, actions) => {
    if (!validEmail) {
      try {
        console.log("Forgot password");

        // Send confirmation code to user's email
        const data = await Auth.forgotPassword(values.email);
        console.log("Forgot password .. data", data);
        setValidEmail(true);
      } catch (error) {
        console.log("error resetting password", error);
        if (error.name === "UserNotFoundException") {
          actions.setFieldError("email", "Email not found");
        } else {
          actions.setFieldError("email", error.message);
        }
      }
    } else {
      try {
        console.log("Resetting password");

        // Send confirmation code to user's email
        const data = await Auth.forgotPasswordSubmit(
          values.email,
          values.code,
          values.password
        );
        console.log("Resetting password .. data:", data);
        setForgotPassword(false);
        setValidEmail(false);
        setPasswordShown(false);
        setConfirmPasswordShown(false);
        await handleSubmit(values, actions);
      } catch (error) {
        console.log("error resetting password", error);
        if (error.name === "UserNotFoundException") {
          actions.setFieldError("email", "Email not found");
        } else {
          actions.setFieldError("email", error.message);
        }
      }
    }
  };

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        document.getElementById("loginBtnnn").click();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  return (
    <div className="loginWrapper">
      <div className="loginPopup">
        <div className="headerlogin">
          <Logo />
          <Button
            onClick={onClick}
            extraClass="closebtn"
            bgColor="transparent"
            color="white"
          >
            x
          </Button>
        </div>
        <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={handleSubmit}
        >
          {(formik) => {
            const { values, handleChange, handleSubmit, errors, touched } =
              formik;
            return user ? (
              <h3 className="logtitle">`${t("errors.success")}`</h3>
            ) : (
              <form onSubmit={handleSubmit}>
                <h3 className="logtitle">{t("signin.title")}</h3>
                <p className="logdesc">
                {t("login.title")}
                </p>
                <div className="inputs">
                  <div className="inputHolder">
                    <Input
                      extraClass="loginInput"
                      name="email"
                      label={t("login.email")}
                      id="email"
                      value={values.email}
                      onChange={handleChange}
                      placeholder={t("login.email")}
                      className={
                        errors.email && touched.email ? "input-error" : null
                      }
                    />
                    {errors.email && touched.email && (
                      <span className="error">{errors.email}</span>
                    )}
                  </div>
                  <div className="inputHolder">
                    <span
                      onClick={() => setForgotPassword(true)}
                      className="forgot"
                    >
                      {t("login.forgot")}
                    </span>
                    <Input
                      extraClass="loginInput"
                      name="password"
                      type={passwordShown ? "text" : "password"}
                      id="password"
                      label={t("login.psw")}
                      value={values.password}
                      onChange={handleChange}
                      placeholder={t("login.psw")}
                    />
                    {errors.password && touched.password && (
                      <span className="error">{errors.password}</span>
                    )}
                    <button
                      className="eyebtn"
                      onClick={(e) => togglePassword(e)}
                    >
                      {passwordShown ? <Eye /> : <EyeClosed />}
                    </button>
                  </div>
                </div>
                <div className="checkboxHolder">
                  <Checkbox
                    className="logincheck"
                    width={20}
                    height={20}
                    colorScheme="red"
                    checked
                  ></Checkbox>
                  <p>{t("login.remember")}</p>
                </div>
                <Button
                  extraClass="loginbtn"
                  bgColor="#2B3677"
                  color="white"
                  id="loginBtnnn"
                  type="submit"
                >
                  {t("signin.title")}
                </Button>
                <p className="accDesc">
                {t("login.acc")} <a href={to}> {t("companytabs.title1")} </a>
                </p>
              </form>
            );
          }}
        </Formik>
        {forgotPassword && (
          <div className="forgotPassword">
            <h3 className="logtitle">{t("login.forgotpsw")}</h3>
            <p className="logdesc">
            {t("login.enteremail")}
            </p>
            <Formik
              initialValues={initialValues}
              validate={validate}
              onSubmit={handleReset}
            >
              {(props) => {
                return !validEmail ? (
                  <div className="inputHolder">
                    <Input
                      extraClass="loginInput"
                      name="email"
                      label={t("login.email")}
                      id="email"
                      value={props.values.email}
                      onChange={props.handleChange}
                      placeholder={t("login.email")}
                      className={
                        props.errors.email && props.touched.email
                          ? "input-error"
                          : null
                      }
                    />
                    {props.errors.email && props.touched.email && (
                      <span className="error">{props.errors.email}</span>
                    )}
                    <Button
                      onClick={props.handleSubmit}
                      extraClass="loginbtn forgotpsw"
                      bgColor="#2B3677"
                      color="white"
                    >
                      {t("login.reset")}
                    </Button>
                  </div>
                ) : (
                  <div className="inputs">
                    <Input
                      placeholder={t("login.verify")}
                      extraClass="fullWidth "
                      label={t("login.verify")}
                      type="code"
                      name="code"
                      onChange={props.handleChange}
                      value={props.values.code}
                    />
                    {props.errors.code && props.touched.code && (
                      <span className="error">{props.errors.code}</span>
                    )}
                    <div className="inputHolder">
                      <Input
                        extraClass="loginInput"
                        name="password"
                        type={passwordShown ? "text" : "password"}
                        id="password"
                        label={t("login.psw")} 
                        value={props.values.password}
                        onChange={props.handleChange}
                        placeholder={t("login.psw")}
                      />
                      <button
                        className="eyebtn"
                        onClick={(e) => togglePassword(e)}
                      >
                        {passwordShown ? <Eye /> : <EyeClosed />}
                      </button>
                      {props.errors.password && props.touched.password && (
                        <span className="error">{props.errors.password}</span>
                      )}
                    </div>

                    <div className="inputHolder">
                      <Input
                        extraClass="loginInput"
                        name="confirmPassword"
                        type={confirmPasswordShown ? "text" : "password"}
                        id="confirmPassword"
                        label={t("signup.confirm")}
                        value={props.values.confirmPassword}
                        onChange={props.handleChange}
                        placeholder={t("signup.confirm")}
                      />
                      {props.errors.confirmPassword &&
                        props.touched.confirmPassword && (
                          <span className="error">
                            {props.errors.confirmPassword}
                          </span>
                        )}
                      <button
                        className="eyebtn"
                        onClick={(e) => toggleConfirmPassword(e)}
                      >
                        {confirmPasswordShown ? <Eye /> : <EyeClosed />}
                      </button>
                    </div>

                    <Button
                      onClick={props.handleSubmit}
                      extraClass="loginbtn forgotpsw"
                      bgColor="#2B3677"
                      color="white"
                    >
                      {t("login.set")}
                    </Button>
                  </div>
                );
              }}
            </Formik>
          </div>
        )}
      </div>
    </div>
  );
}
