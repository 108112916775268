import React from "react";
import loader from "../assets/icons/Loader.gif";

const Loader = () => {
  return (
    <div className="spinnerBody">
      {/* <span className="spinner"></span> */}
      <img src={loader} alt="loader" />
    </div>
  );
};

export default Loader;
