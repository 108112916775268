import React from 'react';
import styles from "./TeamBanner.module.scss";
import team1 from "../../../assets/images/team1.png";
import team2 from "../../../assets/images/team2.png";
import team3 from "../../../assets/images/team3.png";

export default function TeamBanner() {
  return (
    <div className={styles.container}>
      <h1>We are finba.</h1>
      <div className={styles.images}>
        <img src={team1} alt="team" />
        <img src={team2} alt="team" />
        <img src={team3} alt="team" />
      </div>
    </div>
  )
}
