/* eslint-disable */

import React, { useEffect, useRef, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import "../components/styles.scss";
import Header from "../components/Header";
import Benefits from "../components/Benefits";
import ForInvestors from "../components/ForInvestors";
import Possibilites from "../components/Possibilities";
import HowDoesItWork from "../components/HowDoesItWork";
import CaseStudies from "../components/CaseStudies";
import FAQ from "../components/FAQ";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import {
  Flex,
  Text,
  View,
  useBreakpointValue,
  TextField,
} from "@aws-amplify/ui-react";
import Input from "../components/Input";
import Button from "../components/Button";
import TrustedBy from "../components/TrustedBy";
import ForCompanies from "../components/ForCompanies";
import { HashLink } from "react-router-hash-link";
import { useLocation, useNavigate } from "react-router-dom";
import FinbaLogo from "../ui-components/FinbaLogo";
import { Trans, useTranslation } from "react-i18next";
import { useFormik } from "formik";
import Login from "../components/Login/Login";
import { ReactComponent as Menu } from "../assets/icons/menu.svg";

export default function Home(props) {
  const investors = useRef(null);
  const casestudies = useRef(null);
  const faq = useRef(null);
  const benefits = useRef(null);
  const companies = useRef(null);
  const { t, i18n } = useTranslation();
  const [menu, setMenu] = useState(false);
  const [popup, setPopup] = useState(false);
  const [success, setSuccess] = useState(false);
  const [newsletterErrors, setNewsletterErrors] = useState(null);
  const location = useLocation();
  useEffect(() => {
    if (location?.state?.login) {
      setPopup(location?.state?.login);
    } else {
      setPopup(false);
    }
  }, []);

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const validate = (values) => {
    const errors = {};

    setSuccess(false);
    setNewsletterErrors(null);

    if (!values.email) {
      errors.email = t("signup.emailRequired");
      setNewsletterErrors(errors.email);
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = t("signup.emailInvalid");
      setNewsletterErrors(errors.email);
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validate,
    onSubmit: async (values) => {
      const entry = { ...values };

      try {
        console.log("Entry:", entry);

        // await DataStore.save(new Newsletter(entry));
        await API.graphql({
          query: mutations.createNewsletter,
          variables: { input: entry },
          authMode: "AWS_IAM",
        });
        console.log("Saved Successfully!");

        // BUG: Reset the DropDown
        formik.resetForm();
        setNewsletterErrors(null);
        setSuccess(true);
      } catch (err) {
        console.error("ERROR:", err);
      }
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  setTimeout(() => {
    history.replaceState(
      "",
      document.title,
      window.location.origin + window.location.pathname + window.location.search
    );
  }, 1);

  const navigate = useNavigate();
  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  };
  const { overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      overrides: {
        AllRights: {},
        text6762639: {},
        text6762640: {},
        text6762641: {},
        contact: {},
        text6762643: {},
        text6762644: {},
        text6762645: {},
        text6762646: {},
        menu6762642: {},
        text6762648: {},
        text6762649: {},
        text6762650: {},
        text6762651: {},
        text6762652: {},
        text6762653: {},
        menu6762647: {},
        icon: {},
        Placeholder: {},
        "icons / bx-envelope": {},
        "input/M": {},
        btn: {},
        label: {},
        "form-label/M": {},
        text6762661: {},
        "icons / bxl-instagram": {},
        "social-btn/M6762663": {},
        "icons / bxl-linkedin": {},
        "social-btn/M6762665": {},
        "icons / bxl-youtube": {},
        hover: {},
        Socials: {},
        FinbaLogo: {},
        Footer: {},
        "For Companies": {},
        "For Investors": {},
        "Case Studies": {},
        FAQ: {},
        Menu: {},
        DE: {},
        Line: {},
        EN: {},
        LanguageSwitch: {},
        JoinWaitlist: {},
        FinbaLogo: {},
        IconMenu: {},
        Navbar: {},
      },
      variantValues: { breakpoint: "large" },
    },
    {
      overrides: {
        AllRights: { top: "509px", left: "calc(50% - 100.5px - 0.5px)" },
        text6762639: {},
        text6762640: {},
        text6762641: {},
        contact: { top: "252px", left: "calc(50% - 72.5px - -220.5px)" },
        text6762643: {},
        text6762644: {},
        text6762645: {},
        text6762646: {},
        menu6762642: { top: "252px", left: "calc(50% - 69.5px - 2.5px)" },
        text6762648: {},
        text6762649: {},
        text6762650: {},
        text6762651: {},
        text6762652: {},
        text6762653: {},
        menu6762647: { top: "252px", left: "calc(50% - 53px - 239px)" },
        icon: {},
        Placeholder: { width: "522px" },
        "icons / bx-envelope": {},
        "input/M": { width: "582px" },
        btn: {},
        label: { width: "582px" },
        "form-label/M": {
          width: "582px",
          top: "106px",
          left: "calc(50% - 291px - -1px)",
        },
        text6762661: { top: "55px", left: "calc(50% - 208px - 84px)" },
        "icons / bxl-instagram": {},
        "social-btn/M6762663": {},
        "icons / bxl-linkedin": {},
        "social-btn/M6762665": {},
        "icons / bxl-youtube": {},
        hover: {},
        Socials: { top: "404px", left: "calc(50% - 52px - 23px)" },
        FinbaLogo: { top: "0px", left: "calc(50% - 60.5px - 231.5px)" },
        Footer: { width: "770px", height: "528px" },
        "For Companies": {},
        "For Investors": {},
        "Case Studies": {},
        FAQ: {},
        Menu: { display: "none" },
        DE: {},
        Line: {},
        EN: {},
        LanguageSwitch: { display: "none" },
        JoinWaitlist: { right: "90px" },
        FinbaLogo: { left: "35px" },
        IconMenu: { display: "block", right: "35px" },
        Navbar: { width: "768px" },
      },
      variantValues: { breakpoint: "medium" },
    },
    {
      overrides: {
        AllRights: { top: "709px", left: "calc(50% - 100.5px - 0.5px)" },
        text6762639: {},
        text6762640: {},
        text6762641: {},
        contact: { top: "502px", left: "calc(50% - 72.5px - 69.5px)" },
        text6762643: {},
        text6762644: {},
        text6762645: {},
        text6762646: {},
        menu6762642: { top: "261px", left: "calc(50% - 69.5px - -77.5px)" },
        text6762648: {},
        text6762649: {},
        text6762650: {},
        text6762651: {},
        text6762652: {},
        text6762653: {},
        menu6762647: { top: "261px", left: "calc(50% - 53px - 91px)" },
        icon: {},
        Placeholder: { width: "260px" },
        "icons / bx-envelope": {},
        "input/M": { width: "320px" },
        btn: {},
        label: { width: "320px" },
        "form-label/M": {
          width: "320px",
          top: "142px",
          left: "calc(50% - 160px - 0px)",
        },
        text6762661: {
          textAlign: "center",
          width: "219px",
          top: "53px",
          left: "calc(50% - 109.5px - 0.5px)",
        },
        "icons / bxl-instagram": { width: "36px", height: "36px" },
        "social-btn/M6762663": { borderRadius: "10.799999237060547px" },
        "icons / bxl-linkedin": { width: "36px", height: "36px" },
        "social-btn/M6762665": { borderRadius: "10.799999237060547px" },
        "icons / bxl-youtube": { width: "36px", height: "36px" },
        hover: { gap: "18px", borderRadius: "10.799999237060547px" },
        Socials: {
          gap: "18px",
          direction: "column",
          top: "508px",
          left: "calc(50% - 18px - -75px)",
        },
        FinbaLogo: { top: "0px", left: "calc(50% - 60.5px - 0.5px)" },
        Footer: { width: "360px", height: "728px" },
        "For Companies": {},
        "For Investors": {},
        "Case Studies": {},
        FAQ: {},
        Menu: { display: "none" },
        DE: {},
        Line: {},
        EN: {},
        LanguageSwitch: { display: "none" },
        JoinWaitlist: { display: "none" },
        FinbaLogo: { top: "26px", left: "25px" },
        IconMenu: { display: "block", right: "25px" },
        Navbar: { width: "360px" },
      },
      variantValues: { breakpoint: "small" },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "small",
    small: "small",
    medium: "medium",
    large: "large",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <div className="wrapper">
      <div className="navbar">
        <View
          className="navbarwrap"
          width="1920px"
          height="76px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          overflow="hidden"
          position="relative"
          padding="0px 0px 0px 0px"
          {...rest}
          {...getOverrideProps(overrides, "Navbar")}
        >
          <Flex
            gap="24px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            position="absolute"
            top="calc(50% - 13px - 0px)"
            left="487px"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Menu")}
          >
            <HashLink smooth to="#companies">
              <View
                fontFamily="Lato"
                fontSize="16px"
                fontWeight="600"
                color="rgba(43,54,119,1)"
                lineHeight="25.600000381469727px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
              >
                <Trans i18nKey="companytabs.tabtitle1">
                  <Text
                    children="For Companies"
                    {...getOverrideProps(overrides, "For Companies")}
                  ></Text>
                </Trans>
              </View>
            </HashLink>
            <HashLink smooth to="#investors">
              <View
                fontFamily="Lato"
                fontSize="16px"
                fontWeight="600"
                color="rgba(43,54,119,1)"
                lineHeight="25.600000381469727px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
              >
                <Trans i18nKey="companytabs.tabtitle2">
                  <Text
                    children="For Investors"
                    {...getOverrideProps(overrides, "For Investors")}
                  ></Text>
                </Trans>
              </View>
            </HashLink>
            <HashLink smooth to="#casestudies">
              <View
                fontFamily="Lato"
                fontSize="16px"
                fontWeight="600"
                color="rgba(43,54,119,1)"
                lineHeight="25.600000381469727px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
              >
                <Trans i18nKey="casestudies.title">
                  <Text
                    children="Case Studies"
                    {...getOverrideProps(overrides, "Case Studies")}
                  ></Text>
                </Trans>
              </View>
            </HashLink>
            <HashLink smooth to="#faq">
              <Text
                fontFamily="Lato"
                fontSize="16px"
                fontWeight="600"
                color="rgba(43,54,119,1)"
                lineHeight="25.600000381469727px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="FAQ"
                {...getOverrideProps(overrides, "FAQ")}
              ></Text>
            </HashLink>
          </Flex>
          <Flex
            gap="3px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            position="absolute"
            top="15px"
            right="488.5px"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "LanguageSwitch")}
          >
            <button className="langbtn" onClick={() => changeLanguage("de")}>
              <Text
                fontFamily="Lato"
                fontSize="16px"
                fontWeight="600"
                color="rgba(43,54,119,1)"
                lineHeight="25.600000381469727px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="DE"
                {...getOverrideProps(overrides, "DE")}
              ></Text>
            </button>
            <Text
              fontFamily="Lato"
              fontSize="16px"
              fontWeight="600"
              color="rgba(43,54,119,1)"
              lineHeight="25.600000381469727px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="|"
              {...getOverrideProps(overrides, "Line")}
            ></Text>
            <button
              className={
                changeLanguage === true ? "activelanguage langbtn" : "langbtn"
              }
              onClick={() => changeLanguage("en")}
            >
              <Text
                fontFamily="Lato"
                fontSize="16px"
                fontWeight="700"
                color="rgba(43,54,119,1)"
                lineHeight="25.600000381469727px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 20px 0px 0px"
                whiteSpace="pre-wrap"
                children="EN"
                {...getOverrideProps(overrides, "EN")}
              ></Text>
            </button>
            <Button
              extraClass="waitlist"
              bgColor="#618BE8"
              color="white"
              onClick={() => setPopup(true)}
            >
              <Trans i18nKey="signin.title">Log In</Trans>
            </Button>
          </Flex>
          <div className="mobileiIcon">
            {menu ? (
              <span onClick={() => setMenu(false)} className="closemenu">
                X
              </span>
            ) : (
              <Menu onClick={() => setMenu(true)} />
            )}
          </div>
          <FinbaLogo
            width="121px"
            height="24px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="25px"
            left="315px"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "FinbaLogo")}
          ></FinbaLogo>
          <View
            width="24px"
            height="24px"
            display="none"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            overflow="hidden"
            position="absolute"
            top="26px"
            right="559px"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "IconMenu")}
          ></View>
        </View>
      </div>
      <div className="banner">
        <Header />
      </div>
      <TrustedBy />
      <div ref={benefits}>
        <Benefits className="benefits" />
      </div>
      <div ref={companies}>
        <ForCompanies />
      </div>
      <div ref={investors}>
        <ForInvestors />
      </div>
      <Possibilites />
      <HowDoesItWork />
      {/* <div ref={casestudies}>
        <CaseStudies />
      </div> */}
      <div ref={faq}>
        <FAQ />
      </div>
      <div className="footer">
        <View
          width="1920px"
          height="371px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="relative"
          padding="0px 0px 0px 0px"
          {...rest}
          {...getOverrideProps(overrides, "Footer")}
        >
          <Text
            fontFamily="Lato"
            fontSize="12px"
            fontWeight="400"
            color="rgba(147,151,173,1)"
            lineHeight="19.200000762939453px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="285px"
            left="calc(50% - 100.5px - 547.5px)"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="2022. All rights reserved. Finba GmbH "
            {...getOverrideProps(overrides, "AllRights")}
          >
            <Trans i18nKey="footer.copyright"></Trans>
          </Text>
          <Flex
            gap="8px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            position="absolute"
            top="72px"
            left="calc(50% - 72.5px - -524.5px)"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "contact")}
          >
            <Text
              fontFamily="Lato"
              fontSize="16px"
              fontWeight="600"
              color="rgba(51,53,77,1)"
              lineHeight="25.600000381469727px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Contact Us"
              {...getOverrideProps(overrides, "text6762639")}
            >
              <Trans i18nKey="footer.title3"></Trans>
            </Text>
            <Text
              fontFamily="Lato"
              fontSize="16px"
              fontWeight="600"
              color="rgba(51,53,77,1)"
              lineHeight="22.399999618530273px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Finba GmbH&#x2028;Königstraße 28&#xA;22767 Hamburg&#xA;Deutschland"
              {...getOverrideProps(overrides, "text6762640")}
            >
              Finba Technologies GmbH <br />
              Königstraße 28 <br />
              22767 Hamburg <br />
              Deutschland
            </Text>
            <a href="tel:+491621739274">
              <Text
                textDecoration="none"
                fontFamily="Lato"
                fontSize="16px"
                fontWeight="600"
                color="rgba(51,53,77,1)"
                lineHeight="25.600000381469727px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="+491621739274"
                {...getOverrideProps(overrides, "text6762641")}
              ></Text>
            </a>
            <a href="mailto:hello@finba.io">
              <Text
                fontFamily="Lato"
                fontSize="16px"
                fontWeight="600"
                color="rgba(43,54,119,1)"
                lineHeight="25.600000381469727px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                textDecoration="underline"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="hello@finba.io"
                {...getOverrideProps(overrides, "text6762641")}
              ></Text>
            </a>
          </Flex>
          <Flex
            gap="8px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            position="absolute"
            top="72px"
            left="calc(50% - 69.5px - -301.5px)"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "menu6762642")}
          >
            <Text
              className="imprint"
              onClick={() => navigate("/imprint")}
              fontFamily="Lato"
              fontSize="16px"
              fontWeight="600"
              color="rgba(51,53,77,1)"
              lineHeight="25.600000381469727px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Imprint"
              {...getOverrideProps(overrides, "text6762643")}
            >
              <Trans i18nKey="footer.title2"></Trans>
            </Text>
            <Text
              fontFamily="Lato"
              fontSize="16px"
              fontWeight="600"
              color="rgba(51,53,77,1)"
              lineHeight="25.600000381469727px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Terms & Conditions"
              {...getOverrideProps(overrides, "text6762644")}
            >
              <Trans i18nKey="footer.jobs1"></Trans>
            </Text>
            <Text
              fontFamily="Lato"
              fontSize="16px"
              fontWeight="600"
              color="rgba(51,53,77,1)"
              lineHeight="25.600000381469727px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              cursor="pointer !important"
              children="Privacy Policy"
              {...getOverrideProps(overrides, "text6762645")}
            >
              <Trans i18nKey="footer.jobs2"></Trans>
            </Text>
            <Text
              fontFamily="Lato"
              fontSize="16px"
              fontWeight="600"
              color="rgba(51,53,77,1)"
              lineHeight="25.600000381469727px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Cookies"
              {...getOverrideProps(overrides, "text6762646")}
            ></Text>
            <>
              {" "}
              <a
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/jobs")}
              >
                {" "}
                <Text
                  fontFamily="Lato"
                  fontSize="16px"
                  fontWeight="600"
                  color="rgba(51,53,77,1)"
                  lineHeight="25.600000381469727px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  marginTop="0px"
                  cursor="pointer"
                  children="Jobs"
                  {...getOverrideProps(overrides, "text6762646")}
                >
                  Jobs
                </Text>
              </a>
            </>
          </Flex>
          <Flex
            onClick={() => navigate("/")}
            gap="8px"
            direction="column"
            width="unset"
            height="unset"
            curspor="pointer"
            justifyContent="flex-start"
            alignItems="flex-start"
            position="absolute"
            top="72px"
            left="calc(50% - 53px - -65px)"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "menu6762647")}
          >
            <Text
              cursor="pointer"
              fontFamily="Lato"
              fontSize="16px"
              fontWeight="600"
              color="rgba(51,53,77,1)"
              lineHeight="25.600000381469727px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Home"
              {...getOverrideProps(overrides, "text6762648")}
            >
              <Trans i18nKey="footer.title1"></Trans>
            </Text>
            <a onClick={() => scrollToSection(companies)}>
              <Text
                fontFamily="Lato"
                fontSize="16px"
                fontWeight="600"
                color="rgba(51,53,77,1)"
                lineHeight="25.600000381469727px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="For Companies"
                {...getOverrideProps(overrides, "text6762649")}
              >
                <Trans i18nKey="footer.home1"></Trans>
              </Text>
            </a>
            <a onClick={() => scrollToSection(investors)}>
              <Text
                fontFamily="Lato"
                fontSize="16px"
                fontWeight="600"
                color="rgba(51,53,77,1)"
                lineHeight="25.600000381469727px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="For Investors"
                {...getOverrideProps(overrides, "text6762650")}
              >
                <Trans i18nKey="footer.home2"></Trans>
              </Text>
            </a>
            <a onClick={() => scrollToSection(casestudies)}>
              <Text
                fontFamily="Lato"
                fontSize="16px"
                fontWeight="600"
                color="rgba(51,53,77,1)"
                lineHeight="25.600000381469727px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Case Studies"
                {...getOverrideProps(overrides, "text6762651")}
              >
                <Trans i18nKey="footer.home3"></Trans>
              </Text>
            </a>
            <a onClick={() => scrollToSection(faq)}>
              <Text
                fontFamily="Lato"
                fontSize="16px"
                fontWeight="600"
                color="rgba(51,53,77,1)"
                lineHeight="25.600000381469727px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="FAQ"
                {...getOverrideProps(overrides, "text6762652")}
              ></Text>
            </a>
            <a onClick={() => scrollToSection(benefits)}>
              <Text
                fontFamily="Lato"
                fontSize="16px"
                fontWeight="600"
                color="rgba(51,53,77,1)"
                lineHeight="25.600000381469727px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="About"
                {...getOverrideProps(overrides, "text6762653")}
              >
                <Trans i18nKey="footer.home4"></Trans>
              </Text>
            </a>
          </Flex>
          <form onSubmit={formik.handleSubmit}>
            <View
              width="416px"
              height="74px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="191px"
              left="calc(50% - 208px - 440px)"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "form-label/M")}
            >
              <View
                width="416px"
                height="44px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                position="absolute"
                top="30px"
                bottom="0px"
                left="0px"
                right="0px"
                borderRadius="6px"
                padding="0px 0px 0px 0px"
                backgroundColor="rgba(255,255,255,1)"
                {...getOverrideProps(overrides, "input/M")}
              >
                <View
                  width="20px"
                  height="20px"
                  display="none"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  overflow="hidden"
                  position="absolute"
                  top="12px"
                  right="16px"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "icon")}
                ></View>

                <Input
                  type="text"
                  placeholder="Email"
                  id="email"
                  name="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
                {/* <TextField
                inset="0"
                width='100%'
                placeholder="Email"
                errorMessage="There is an error"
                fontFamily="Lato"
                fontSize="14px"
                fontWeight="400"
                color="rgba(180,183,201,1)"
                lineHeight="22.399999618530273px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                gap="unset"
                border='none'
                focus='none'
                marginTop='1px'
                alignItems="unset"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
              /> */}
                {/* <Text
                fontFamily="Lato"
                fontSize="14px"
                fontWeight="400"
                color="rgba(180,183,201,1)"
                lineHeight="22.399999618530273px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                gap="unset"
                alignItems="unset"
                position="absolute"
                top="11px"
                bottom="11px"
                left="44px"
                right="16px"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                // children="Your Email"
                {...getOverrideProps(overrides, "Placeholder")}
              ></Text> */}
                <View
                  width="20px"
                  height="20px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  overflow="hidden"
                  position="absolute"
                  top="12px"
                  left="16px"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "icons / bx-envelope")}
                ></View>
              </View>
              <Flex
                gap="8px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                position="absolute"
                top="20px"
                right="-27px"
                borderRadius="0px 6px 6px 0px"
                padding="11px 28px 11px 28px"
                display="flex"
                {...getOverrideProps(overrides, "btn")}
              >
                <Button type="submit" bgColor="rgba(43,54,119,1)">
                  Subscribe
                </Button>
              </Flex>
              <Text
                fontFamily="Lato"
                fontSize="14px"
                fontWeight="600"
                color="rgba(11,15,25,1)"
                lineHeight="22.399999618530273px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="416px"
                height="unset"
                gap="unset"
                alignItems="unset"
                position="absolute"
                top="0px"
                left="0px"
                right="0px"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Subscribe to our newsletter"
                {...getOverrideProps(overrides, "label")}
              >
                <Trans i18nKey="footer.subscribe"></Trans>
              </Text>
              {newsletterErrors && (
                <Text
                  key="Email is required"
                  fontFamily="Lato"
                  fontSize="12px"
                  fontWeight="400"
                  color="rgba(220,20,60,1)"
                  lineHeight="25.600000381469727px"
                  textAlign="left"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  // position="relative"
                  padding="70px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Email is invalid"
                  {...getOverrideProps(overrides, "label")}
                >
                  <Trans i18nKey="footer.emailRequired"></Trans>
                </Text>
              )}
              {success && (
                <Text
                  key="Thank You"
                  fontFamily="Lato"
                  fontSize="12px"
                  fontWeight="400"
                  color="rgba(11,15,25,1)"
                  lineHeight="25.600000381469727px"
                  textAlign="left"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  // position="relative"
                  padding="70px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Thank you for subscribing"
                  {...getOverrideProps(overrides, "label")}
                >
                  <Trans i18nKey="footer.thankYouSubscribe"></Trans>
                </Text>
              )}
            </View>
          </form>
          <Text
            fontFamily="Lato"
            fontSize="14px"
            fontWeight="400"
            color="rgba(86,89,115,1)"
            lineHeight="22.399999618530273px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="416px"
            height="unset"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="140px"
            left="calc(50% - 208px - 440px)"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="The marketplace for your liquidity and financial freedom."
            {...getOverrideProps(overrides, "text6762661")}
          >
            <Trans i18nKey="footer.title"></Trans>
          </Text>
          <Flex
            gap="13.000000953674316px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            position="absolute"
            top="224px"
            left="calc(50% - 52px - -281px)"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Socials")}
          >
            <Flex
              gap="0"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              border="0px SOLID rgba(226,229,241,1)"
              borderRadius="7.800000190734863px"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "social-btn/M6762663")}
            >
              <View
                width="26px"
                height="26px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "icons / bxl-instagram")}
              ></View>
            </Flex>
            <Flex
              gap="0"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              border="0px SOLID rgba(226,229,241,1)"
              borderRadius="7.800000190734863px"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "social-btn/M6762665")}
            >
              <View
                width="26px"
                height="26px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "icons / bxl-linkedin")}
              ></View>
            </Flex>
            <Flex
              gap="13.000000953674316px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              border="0px SOLID rgba(226,229,241,1)"
              borderRadius="7.800000190734863px"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "hover")}
            >
              <View
                width="26px"
                height="26px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "icons / bxl-youtube")}
              ></View>
            </Flex>
          </Flex>
          <View
            width="121px"
            height="24px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="85px"
            left="calc(50% - 60.5px - 587.5px)"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "FinbaLogo")}
          ></View>
        </View>
      </div>
      {popup && (
        <div className="loginn">
          <div onClick={() => setPopup(false)} className="shades"></div>
          <Login to="/company-signup" onClick={() => setPopup(false)} />
        </div>
      )}

      {menu && (
        <div className={setMenu ? "menu-open" : "menu-closed"}>
          <ul>
            <HashLink onClick={() => setPopup(true)}>
              <li>Log In</li>
            </HashLink>
            <HashLink smooth to="#companies">
              <li>For Companies</li>
            </HashLink>
            <HashLink smooth to="#investors">
              <li>For Investors</li>
            </HashLink>
            <HashLink smooth to="#casestudies">
              <li>Case Studies</li>
            </HashLink>
            <HashLink smooth to="#faq">
              <li>FAQ</li>
            </HashLink>
          </ul>
        </div>
      )}
    </div>
  );
}
