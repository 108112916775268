import React, { useEffect, useState } from "react";
import styles from "./Investor.module.scss";
import img from "../../../assets/images/profile.png";
import Input from "../../../components/Input";
import Button from "../../atoms/Button/Button";
import { useFormik } from "formik";
import PaymentCard from "../../atoms/PaymentCard/PaymentCard";
import { payments } from "../../constants/data";
import { ReactComponent as Add } from "../../../assets/icons/add.svg";
import Logout from "../../../assets/icons/log-out.png";
import AddPayment from "../AddPayment/AddPayment";
import style from "../../atoms/PaymentCard/PaymentCard.module.scss";
import { API, Auth, graphqlOperation } from "aws-amplify";
import * as queries from "../../../graphql/queries";
import * as mutations from "../../../graphql/mutations";
import Loader from "../../../components/Loader";
import { ToastContainer, toast } from "react-toastify";

export default function Investor() {
  const [index, setIndex] = useState(1);
  const [add, setAdd] = useState(false);
  const [signout, setSignout] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paymentCards, setPaymentCards] = useState([]);
  const [payments, setPayments] = useState([]);
  const [user, setUser] = useState();
  const [userProfile, setUserProfile] = useState(null);
  const [filterId, setFilterId] = useState("");

  useEffect(() => {
    setInterval(() => {
      setLoading(true);
    }, 2000);
  }, []);

  const handleSave = (paymentData) => {
    console.log(paymentData);
    setPaymentCards([...paymentCards, paymentData]);
    setAdd(false); // close the modal
  };

  const handleButtonClick = (buttonIndex) => {
    setIndex(buttonIndex);
  };

  const signOut = async () => {
    try {
      await Auth.signOut();
      window.location.href = window.location.origin + "/";
      localStorage.removeItem("logState");
    } catch (err) {
      console.log("err in SIGNOUT", err);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const currentUser = await Auth.currentAuthenticatedUser();
      setUser(currentUser);
      setFilterId(currentUser.attributes.email);
      let id = currentUser && currentUser.attributes.email;
      let filter = { email: { eq: id } };
      const matched = await API.graphql(
        graphqlOperation(queries.listInvestors, {
          filter: filter,
        })
      );
      const profile = matched.data.listInvestors.items[0];
      setUserProfile(profile);
    };
    fetchData();
  }, []);

  const formik = useFormik({
    initialValues: {
      firstName: userProfile?.firstName ? userProfile?.firstName : "",
      lastName: userProfile?.lastName ? userProfile?.lastName : "",
      email: userProfile?.email ? userProfile?.email : "",
      phone: userProfile?.phone ? userProfile?.phone : "",
      id: userProfile?.id ? userProfile?.id : "",
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      const {
        language,
        updatedAt,
        createdAt,
        country,
        state,
        zip,
        city,
        address,
        address2,
        ...newInvestor
      } = values;
      const editedInvestor = await API.graphql({
        query: mutations.updateInvestor,
        variables: { input: newInvestor },
      });
      if (editedInvestor) {
        toast.success("Basic Info updated successfully");
      }
    },
    validate: (values) => {
      const errors = {};
      if (!values.firstName) {
        errors.firstName = "First name is required";
      }
      if (!values.lastName) {
        errors.lastName = "Last name is required";
      }
      if (!values.email || !/^\S+@\S+\.\S+$/.test(values.email)) {
        errors.email = "Please enter a valid email address";
      }
      if (!values?.phone || !/^\+?\d{9,}$/.test(values?.phone)) {
        errors.phone = "Please enter a valid phone number";
      }
      return errors;
    },
    validateOnChange: true,
    validateOnBlur: true,
  });
  const formik2 = useFormik({
    initialValues: {
      country: userProfile?.country ? userProfile?.country : "",
      state: userProfile?.state ? userProfile?.state : "",
      city: userProfile?.city ? userProfile?.city : "",
      zip: userProfile?.zip ? userProfile?.zip : "",
      address: userProfile?.address ? userProfile?.address : "",
      address2: userProfile?.address2 ? userProfile?.address2 : "",
      id: userProfile?.id ? userProfile?.id : "",
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      const {
        language,
        updatedAt,
        createdAt,
        firstName,
        lastName,
        email,
        phone,
        ...newInvestor
      } = values;
      const editedInvestor = await API.graphql({
        query: mutations.updateInvestor,
        variables: { input: newInvestor },
      });
      if (editedInvestor) {
        toast.success("Address updated successfully");
      }
    },
    validate: (values) => {
      const errors = {};

      if (!values.country) {
        errors.country = "Country is required";
      }
      if (!values.state) {
        errors.state = "State is required";
      }
      if (!values.city) {
        errors.city = "City is required";
      }
      if (!values.zip) {
        errors.zip = "Zip Code is required";
      }
      if (!values.address) {
        errors.address = "Address is required";
      }
      if (!values.address2) {
        errors.address2 = "Address is required";
      }
      return errors;
    },
    validateOnChange: true,
    validateOnBlur: true,
  });

  const formik3 = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    onSubmit: (values) => {
      console.log(values);
    },
    validate: (values) => {
      const errors = {};

      if (!values.currentPassword) {
        errors.currentPassword = "Current Password is required";
      }
      if (!values.newPassword) {
        errors.newPassword = "New Password is required";
      }
      if (!values.confirmPassword) {
        errors.confirmPassword = "Confirm Password is required";
      }
      return errors;
    },
    validateOnChange: true,
    validateOnBlur: true,
  });
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value);
    formik.setFieldError(name, "");
  };

  const handleInputChange2 = (event) => {
    const { name, value } = event.target;
    formik2.setFieldValue(name, value);
    formik2.setFieldError(name, "");
  };

  const handleInputChange3 = (event) => {
    const { name, value } = event.target;
    formik3.setFieldValue(name, value);
    formik3.setFieldError(name, "");
  };

  const saveChangesButtonColor =
    Object.keys(formik.errors).length === 0 ? "#2B3677" : "#5D648A";
  const saveChangesButtonColor2 =
    Object.keys(formik2.errors).length === 0 ? "#2B3677" : "#5D648A";
  const saveChangesButtonColor3 =
    Object.keys(formik3.errors).length === 0 ? "#2B3677" : "#5D648A";

  const handleDelete = (index) => {
    const newPayments = [...payments];
    newPayments.splice(index, 1);
    setPayments(newPayments);
  };

  return (
    <>
      <ToastContainer />
      {loading ? (
        <>
          <div className={styles.investor}>
            <div className={styles.profileNav}>
              <div className={styles.profile}>
                <img src={img} alt="profile" />
                <div className={styles.btns}>
                  <button
                    onClick={() => handleButtonClick(1)}
                    className={index === 1 ? styles.activeButton : ""}
                  >
                    Account Details
                  </button>
                  <button
                    onClick={() => handleButtonClick(2)}
                    className={index === 2 ? styles.activeButton : ""}
                  >
                    Security
                  </button>
                  <button
                    onClick={() => handleButtonClick(4)}
                    className={index === 4 ? styles.activeButton : ""}
                  >
                    Payment Details
                  </button>
                  <button
                    onClick={() => setSignout(true)}
                    className={index === 5 ? styles.activeButton : ""}
                  >
                    Sign out
                  </button>
                </div>
              </div>
            </div>
            <div className={styles.content}>
              {index === 1 && (
                <div className={styles.firstTab}>
                  <h1>Account Details</h1>
                  <form onSubmit={formik.handleSubmit}>
                    <div style={{ width: "100%" }}>
                      <p>Basic Info</p>
                      <div className={styles.inputs}>
                        <div>
                          <Input
                            label="First name"
                            placeholder="John"
                            name="firstName"
                            value={formik.values?.firstName}
                            onChange={handleInputChange}
                          />
                          {formik.errors.firstName && (
                            <div className="error">
                              {formik.errors.firstName}
                            </div>
                          )}
                        </div>
                        <div>
                          <Input
                            label="Last name"
                            placeholder="Doe"
                            name="lastName"
                            value={formik.values?.lastName}
                            onChange={handleInputChange}
                          />
                          {formik.errors.lastName && (
                            <div className="error">
                              {formik.errors.lastName}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className={styles.inputs}>
                        <div>
                          <Input
                            label="Email Address"
                            placeholder="example@gmail.com"
                            name="email"
                            value={formik.values?.email}
                            onChange={handleInputChange}
                          />
                          {formik.errors.email && (
                            <div className="error">{formik.errors.email}</div>
                          )}
                        </div>
                        <div>
                          <Input
                            label="Phone"
                            placeholder="Enter your phone"
                            name="phone"
                            value={formik.values?.phone}
                            onChange={handleInputChange}
                          />
                          {formik.errors.phone && (
                            <div className="error">{formik.errors.phone}</div>
                          )}
                        </div>
                      </div>
                      {/* <div className={styles.bio}>
                    <label>Bio</label>
                    <textarea rows={8} cols={10} />
                  </div> */}

                      <div className={styles.buttons}>
                        {/* <Button bgColor="#EFF2FC" color="#33354D">
                      Cancel
                    </Button> */}
                        <Button
                          onClick={formik.handleSubmit}
                          bgColor={saveChangesButtonColor}
                          color="white"
                          type="submit"
                        >
                          Save Changes
                        </Button>
                      </div>
                    </div>
                  </form>
                  <div className={styles.addressSection}>
                    <p>Address</p>
                    <form onSubmit={formik2.handleSubmit}>
                      <div style={{ width: "100%" }}>
                        <div className={styles.inputs}>
                          <div>
                            <Input
                              label="Country"
                              placeholder="Germany"
                              name="country"
                              value={formik2.values.country}
                              onChange={handleInputChange2}
                            />
                            {formik2.errors.country && (
                              <div className="error">
                                {formik2.errors.country}
                              </div>
                            )}
                          </div>
                          <div>
                            <Input
                              label="State"
                              placeholder="Bavaria"
                              name="state"
                              value={formik2.values.state}
                              onChange={handleInputChange2}
                            />
                            {formik2.errors.state && (
                              <div className="error">
                                {formik2.errors.state}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className={styles.inputs}>
                          <div>
                            <Input
                              label="City"
                              placeholder="Munich"
                              name="city"
                              value={formik2.values.city}
                              onChange={handleInputChange2}
                            />
                            {formik2.errors.city && (
                              <div className="error">{formik2.errors.city}</div>
                            )}
                          </div>
                          <div>
                            <Input
                              label="Zip code"
                              placeholder="Zip code"
                              name="zip"
                              value={formik2.values.zip}
                              onChange={handleInputChange2}
                            />
                            {formik2.errors.zip && (
                              <div className="error">{formik2.errors.zip}</div>
                            )}
                          </div>
                        </div>
                        <div>
                          <Input
                            extraClass="addressInput1"
                            placeholder="Address 1"
                            label="Address line 1"
                            name="address"
                            value={formik2.values.address}
                            onChange={handleInputChange2}
                          />
                          {formik2.errors.address && (
                            <div className="error">
                              {formik2.errors.address}
                            </div>
                          )}
                        </div>
                        <Input
                          extraClass="addressInput"
                          placeholder="Address 2"
                          label="Address line 2"
                          name="address2"
                          value={formik2.values.address2}
                          onChange={handleInputChange2}
                        />
                        {formik2.errors.address2 && (
                          <div className="error">{formik2.errors.address2}</div>
                        )}
                        <div className={styles.buttons}>
                          {/* <Button bgColor="#EFF2FC" color="#33354D">
                        Cancel
                      </Button> */}
                          <Button
                            onClick={formik2.handleSubmit}
                            bgColor={saveChangesButtonColor2}
                            color="white"
                            type="submit"
                          >
                            Save Changes
                          </Button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div>
                    <p className={styles.delete}>Delete account</p>
                    <span>
                      When you delete your account, your public profile will be
                      deactivated immediately. If you change your mind before
                      the 14 <br /> days are up, sign in with your email and
                      password, and we’ll send you a link to reactivate your
                      account.
                    </span>
                    <div className={styles.check}>
                      <Input extraClass="checkboxInput" type="checkbox" />
                      <span>Yes, I want to delete my account.</span>
                    </div>
                    <Button
                      extraClass="deletebtn"
                      bgColor="#CC564D"
                      color="white"
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              )}
              {index === 2 && (
                <div className={styles.secondTab}>
                  <h1>Security</h1>
                  <p>Password</p>
                  <div>
                    <Input
                      type="password"
                      label="Current password"
                      placeholder="Current password"
                      onChange={handleInputChange3}
                      name="currentPassword"
                      value={formik3.values.currentPassword}
                    />
                    {formik3.errors.currentPassword && (
                      <div className="error">
                        {formik3.errors.currentPassword}
                      </div>
                    )}
                  </div>
                  <div className={styles.inputs}>
                    <div>
                      <Input
                        name="newPassword"
                        value={formik3.values.newPassword}
                        type="password"
                        label="New password"
                        placeholder="New password"
                        onChange={handleInputChange3}
                      />
                      {formik3.errors.newPassword && (
                        <div className="error">
                          {formik3.errors.newPassword}
                        </div>
                      )}
                    </div>
                    <div>
                      <Input
                        name="confirmPassword"
                        value={formik3.values.confirmPassword}
                        type="password"
                        label="Confirm new password"
                        placeholder="Confirm new password"
                        onChange={handleInputChange3}
                      />
                      {formik3.errors.confirmPassword && (
                        <div className="error">
                          {formik3.errors.confirmPassword}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.buttons}>
                    <Button bgColor="#EFF2FC" color="#33354D">
                      Cancel
                    </Button>
                    <Button
                      onClick={formik3.handleSubmit}
                      bgColor={saveChangesButtonColor3}
                      color="white"
                    >
                      Change Password
                    </Button>
                  </div>
                </div>
              )}
              {index === 3 && <p>Content for Documents</p>}
              {index === 4 && (
                <div className={styles.payment}>
                  <h1>Payment Details</h1>
                  <p>Your saved payment options</p>
                  <div className={styles.cards}>
                    {payments.map((item, index) => {
                      return (
                        <PaymentCard
                          key={index}
                          onDelete={handleDelete}
                          icon={item.icon}
                          date={item.expiryMonth}
                          cvv={item.cvv}
                          iban={item.iban}
                          primary
                        />
                      );
                    })}
                  </div>
                  <Button
                    extraClass="addpayment"
                    bgColor="#2B3677"
                    color="white"
                    onClick={() => setAdd(true)}
                  >
                    <Add /> Add new payment option
                  </Button>
                </div>
              )}
              <div className={styles.paymentCards}></div>
            </div>
          </div>
          {add && (
            <div className={styles.addmodal}>
              <div className={styles.shades}></div>
              <AddPayment
                handleSave={(paymentData) => {
                  setPayments([...payments, paymentData]);
                  setAdd(false);
                }}
                handleClose={() => setAdd(false)}
              />
            </div>
          )}
          {signout && (
            <div className={styles.addmodal}>
              <div className={styles.shades}></div>
              <div className={style.deleteModal}>
                <div className={style.shades}></div>
                <div className={style.modal}>
                  <img
                    width={70}
                    src={Logout}
                    alt="signout"
                    style={{ marginTop: "30px" }}
                  />
                  <p>Are you sure you want to sign out?</p>
                  <div className={style.btns}>
                    <Button color="#2B3677" onClick={() => setSignout(false)}>
                      No
                    </Button>
                    <Button color="#ff000096" onClick={signOut}>
                      Yes
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}
