import React from 'react';
import Card from '../../atoms/Card/Card';
import styles from "./Cards.module.scss";
import img1 from "../../../assets/images/cardimg1.png";
import img2 from "../../../assets/images/cardimg2.png";
import img3 from "../../../assets/images/cardimg3.png";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {ReactComponent as Arrow} from "../../../assets/icons/fundArrow.svg";
import { useTranslation } from 'react-i18next';

export default function Cards() {

  const { t } = useTranslation();

  
    const cardList = [
        {
          id: 1,
          text: t('industries.list1'),
        },
        {
            id: 2,
            text: t('industries.list2'),
          },
          {
            id: 3,
            text: t('industries.list3'),
          }
      ]

      const cardList2 = [
        {
          id: 1,
          text: t('industries.list4'),
        },
        {
            id: 2,
            text: t('industries.list5'),
          },
          {
            id: 3,
            text: t('industries.list6'),
          }
      ]
      const cardList3 = [
        {
          id: 1,
          text: t('industries.list7'),
        },
        {
            id: 2,
            text: t('industries.list8'),
          },
          {
            id: 3,
            text: t('industries.list9'),
          }
      ]
      const cardList4 = [
        {
          id: 1,
          text:  t('industries.list10'),
        },
        {
            id: 2,
            text: t('industries.list11'),
          },
          {
            id: 3,
            text: t('industries.list12'),
          }
      ]
      const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 575,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            },
          },
          {
            breakpoint: 1299,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 1499,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          }
        ]
      };
  return (
    <div className={styles.cards}>
      <h1>{t('industries.title')}</h1>
      <div className={styles.content}>
          <p>{t('industries.desc1')}</p>
          <p>{t('industries.desc2')}</p>
      </div>
    <div className={`slider ${styles.slider}`}>
      <Slider {...settings}>
        <Card img={img1} title="Factoring" list={cardList} />
        <Card img={img2} title={t('industries.listitle1')} list={cardList2} />
        <Card disabled img={img3} title={t('industries.listitle2')} list={cardList3} />
        <Card disabled img={img3} title="Leasing" list={cardList4} />
      </Slider>
      </div>
      <div className={styles.lastSection}>
      <div className={styles.companyPopup}>
        <h3>{t('industries.ptitle')}</h3>
        <p>{t('industries.pdesc')}</p>
        <a href='/company-signup'>{t('industries.plink')} <Arrow/></a>
      </div>
      </div>
    </div>
  )
}
