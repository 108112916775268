import React from "react";
import Summary from "../../../ui/molecules/Summary/Summary";
import Button from "../../Button";

const SummaryModal = (props) => {
  return (
    <div className="paymentPart">
      <div className="paymentContainer">
        <Summary
          displayAccount={props.displayAccount}
          displayBank={props.displayBank}
          invoiceSummary={props.invoiceSummary}
          selectedPayment={props.selectedPayment}
          pdfFiles={props.pdfFiles}
        />
      </div>
      <div className="paymentBtns">
        <Button
          bgColor="#2B3677"
          onClick={() => {
            props.setPayment(true);
            props.setSummary(false);
          }}
        >
          Back
        </Button>
        <Button
          bgColor="#22C55E"
          processing={props.successInvoice}
          onClick={props.addInvoiceSummary}
        >
          Submit request
        </Button>
      </div>
    </div>
  );
};

export default SummaryModal;
