import React, { useEffect, useState } from "react";
import DashboardNavbar from "../CompanyDashboard/DashboardNavbar";
import "./InvestorDashboard.scss";
import Input from "../Input";
import Button from "../Button";
import refreshIcon from "../../assets/icons/refresh.png";
import { ReactComponent as Options } from "../../assets/icons/dots.svg";
import SecondSection from "./SecondSection/SecondSection";
import FirstSection from "./FirstSection/FirstSection";
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
} from "react-simple-maps";
import ProgressBar from "../ProgressBar";
import Loader from "../Loader";
const geoUrl =
  "https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json";

const InvestorDashboard = () => {
  //Second Section
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setInterval(() => {
      setLoading(true);
    }, 2000);
  }, []);
  const [color, setColor] = useState("");
  const [dataKey, setDataKey] = useState("");
  const countryData = [
    { state: "Albania", value: "56526", color: "#474D56", completed: 56.5 },
    { state: "Kosovo", value: "24563", color: "#F9BC0D", completed: 24.5 },
    { state: "Germany", value: "76234", color: "#44BADC", completed: 76.2 },
    { state: "France", value: "46832", color: "#42D29D", completed: 46.8 },
    { state: "Spain", value: "38965", color: "#50FE65", completed: 38.9 },
  ];
  return (
    <>
      {loading ? (
        <div className="investorDashboard">
          <DashboardNavbar />
          <div className="topElements">
            {/* <h2>Investor Dashboard</h2>
        <div className="buttonsHolder">
          <Input name="date" type="date" />
          <Button
            bgColor="#618BE8"
            color="white"
            children={<img src={refreshIcon} />}
          ></Button>
        </div> */}
          </div>
          <div className="mainContainer">
            <div className="firstSection">
              <FirstSection />
            </div>
            <div className="secondSection">
              <SecondSection
                color={color}
                setColor={setColor}
                dataKey={dataKey}
                setDataKey={setDataKey}
              />
              <div className="countriesPart">
                <div className="optionsHolder">
                  <p>CURRENT HOLDINGS</p>
                  <Options />
                </div>
                <ComposableMap width={1000}>
                  <ZoomableGroup>
                    <Geographies geography={geoUrl}>
                      {({ geographies }) =>
                        geographies.map((geo) => (
                          <Geography
                            key={geo.rsmKey}
                            geography={geo}
                            fill="#D0DDF9"
                            stroke="#FFFFFF"
                          />
                        ))
                      }
                    </Geographies>
                  </ZoomableGroup>
                </ComposableMap>
                <div className="countryList">
                  {countryData?.map((country) => (
                    <ProgressBar
                      state={country?.state}
                      value={country?.value}
                      completed={country?.completed}
                      color={country?.color}
                    />
                  ))}
                </div>
              </div>
              {/* <div className="rightPart"></div> */}
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default InvestorDashboard;
