import React from "react";
import PaymentCards from "../../../ui/molecules/PaymentCards/PaymentCards";
import Button from "../../Button";

const PaymentModal = (props) => {
  return (
    <div className="paymentPart">
      <div className="paymentContainer">
        <PaymentCards
          selectedPayment={props.selectedPayment}
          setSelectedPayment={props.setSelectedPayment}
          setIsPaymentSelected={props.setIsPaymentSelected}
          invoiceSummary={props.invoiceSummary}
        />
      </div>
      <div className="paymentBtns">
        <Button bgColor="#2B3677" onClick={() => props.setPayment(false)}>
          Back
        </Button>
        <Button
          bgColor={props.isPaymentSelected ? "rgb(34, 197, 94)" : "#B4B7C9"}
          processing={props.isPaymentSelected ? false : true}
          onClick={() => props.setSummary(true)}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default PaymentModal;
