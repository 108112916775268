import React from 'react';
import styles from "./ReviewCard.module.scss";
import { ReactComponent as Quotes } from "../../../assets/icons/quotes.svg";
import { ReactComponent as Stars } from "../../../assets/icons/stars.svg";
export default function ReviewCard({img, name, position, content}) {
  return (
    <div>
    <div className={styles.container}>
    <div className={styles.icon}>
      <Quotes />
      </div>
      <p>{content}</p>
      <Stars />
    </div>
    <div className={styles.imgHolder}>
        <div className={styles.img}>
            <img src={img} alt="review" />
        </div>
        <div className={styles.name}>
            <h4>{name}</h4>
            <span>{position}</span>
        </div>
    </div>
    </div>
  )
}
