import React from "react";
import styles from "./Button.module.scss";

const Button = ({
  bgColor,
  color,
  children,
  onClick,
  extraClass,
  border,
  type,
  id,
}) => {
  return (
    <button
      id={id}
      target="_blank"
      className={`${styles.button} ${styles[extraClass]}`}
      style={{ backgroundColor: bgColor, color, border: border }}
      onClick={onClick}
      type={type || "button"}
    >
      {children}
    </button>
  );
};
export default Button;
