import React from 'react';
import NumberCard from '../../atoms/NumberCard/NumberCard';
import styles from "./TeamHistory.module.scss";
import {months} from "../../constants/data";
import { useTranslation } from 'react-i18next';

export default function TeamHistory() {
  const {t} = useTranslation();
  return (
    <div className={styles.container}>
      <h1>{t("team.title")}</h1>
      <div className={styles.desc}>
        <p>{t("team.subtitle1")}</p>
        <p>{t("team.subtitle2")}</p>
      </div>
      <div className={styles.months}> 
      {months.map((item)=>{
        return(
            <NumberCard key={item.id} circle title={item.title} desc={item.desc} />
        )
      })}
      </div>
    </div>
  )
}
