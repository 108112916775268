import React from 'react';
import Button from '../../atoms/Button/Button';
import styles from "./Banner.module.scss";
import { ReactComponent as Scroll } from "../../../assets/icons/scroll.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export default function Banner({ title,extraClass, description, ...rest }) {

  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClick = () => {
    window.scroll({
      top: 750,
      left: 0,
      behavior: 'smooth'
    });
  }


  return (
    <>
      <div className={`${styles.banner} ${styles[extraClass]}`}>
        <div className={styles.bannerHolder}>
          <div className={styles.content}>
            <h1 className={styles.title}>{title}</h1>
            <p className={styles.desc}>{description}</p>
            <div className={styles.btns}>
            <Button onClick={() => navigate(rest?.path)} bgColor={rest?.bgColor} color={rest?.color}>{rest?.button}</Button>
            {rest?.button2 && (
            <Button onClick={rest?.path1} bgColor={rest?.bgColor1} color={rest?.color1}>{rest?.button1}</Button>
            )}
            </div>
            {rest?.scroll && 
            <p className={styles.scroll}><Scroll onClick={handleClick} />{t('investorBanner.scroll')}</p>
            } 
          </div>
          <div className={styles.img}>
            <img src={rest?.image} alt="dashboard" />
          </div>
        </div>
      </div>
    </>
  )
}
