import React from 'react';
import TeamPage from '../organisms/TeamPage';

export default function Team() {
  return (
    <div>
      <TeamPage />
    </div>
  )
}
