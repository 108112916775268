import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import "./styles.scss";

export default function Jobs  () {
  const {t} = useTranslation();
  useEffect(() => {
    const script = document.createElement('script');
    script.defer = true;
    script.type = 'text/javascript';
    script.src = 'https://join.com/api/widget/bundle/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZXR0aW5ncyI6eyJzaG93Q2F0ZWdvcnlGaWx0ZXIiOnRydWUsInNob3dMb2NhdGlvbkZpbHRlciI6dHJ1ZSwic2hvd0VtcGxveW1lbnRUeXBlRmlsdGVyIjp0cnVlLCJsYW5ndWFnZSI6ImVuIiwiam9ic1BlclBhZ2UiOjEwfSwiam9icyI6e30sImRlc2lnbiI6eyJzaG93TG9nbyI6dHJ1ZSwic2hvd0xvY2F0aW9uIjp0cnVlLCJzaG93RW1wbG95bWVudFR5cGUiOnRydWUsInNob3dDYXRlZ29yeSI6dHJ1ZSwiY29sb3JzIjp7IndpZGdldCI6eyJiYWNrZ3JvdW5kIjoiI0ZGRkZGRiIsImZpbHRlckJvcmRlciI6IiNENEQ0RDgiLCJwYWdpbmF0aW9uIjoiIzYxOEJFOCJ9LCJqb2JDYXJkIjp7InNoYWRvdyI6IiNENEQ0RDgiLCJiYWNrZ3JvdW5kIjoiI0ZGRkZGRiIsInByaW1hcnlUZXh0IjoiIzJCMzY3NyIsInNlY29uZGFyeVRleHQiOiIjNTY1OTczIn19fSwidmVyc2lvbiI6MiwiY29tcGFueVB1YmxpY0lkIjoiY2Y0MDgzOTQ2ZTk1ZWE4MTllZjg2NDM1ODcxZmU2MTEiLCJpYXQiOjE2NzI5Mjg1NTMsImp0aSI6IjBiNWQ0OGE0LTRkYTctNDEwZi04MWNlLTAwNWJmNjMwODEzZCJ9.PXip-94ykgQh4KtiZbZX6O3GVqAspVmp2Q7v0KJOTmY';
    script.setAttribute('data-mount-in', '#join-widget');
    document.body.appendChild(script);
  }, []);

 
  return (
    <>
    <div className='jobspage'>
        <h1 className="positionTitle">{t('talk.positions')}</h1>
    <div id="join-widget">
      {/* The widget will be rendered here */}
    </div>
    </div>
    </>
  );
};
