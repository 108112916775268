import React from 'react';
import Button from '../../atoms/Button/Button';
import styles from "./TalkSection.module.scss";
import { useTranslation } from 'react-i18next';

export default function TalkSection() {
  const handleClick = () => {
    window.location.href = "mailto:lorian.qorraj@finba.io";
  };
  const {t} = useTranslation();
  return (
    <div className={styles.container}>
      <p>{t('talk.desc')}</p>
      <h1>{t('talk.title')}</h1>
      <Button onClick={handleClick} bgColor="#618BE8" color="white" >{t('talk.btn')}</Button>
    </div>
  )
}
