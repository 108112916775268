import React from 'react';
import robot from "../../../assets/icons/robot.svg";
import styles from "./404.module.scss";
import Button from '../../atoms/Button/Button';
import { ReactComponent as Home } from "../../../assets/icons/home.svg";
import { useNavigate } from 'react-router-dom';
import Header from '../Header/Header';

export default function Index() {

    const navigate = useNavigate();

  return (
      <div className={styles.nopage}>
        <Header extraClass="noPageHeader" />
        <div className={styles.contentHolder}>
      <div className={styles.content}>
        <h1>Error 404</h1>
        <p>The page you are looking for was moved, removed or might never existed.</p>
        <Button onClick={() => navigate("/")} bgColor="#2B3677" color="white"><Home/> Go to homepage</Button>
      </div>
      <div className={styles.imgHolder}>
        <img src={robot} />
      </div>
      </div>
    </div>
  )
}
