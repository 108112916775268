import Button from "../Button";
import React, { useEffect, useState } from "react";
import DashboardNavbar from "../CompanyDashboard/DashboardNavbar";
import refreshIcon from "../../assets/icons/refresh.png";
// import profile from "../../assets/images/user-img.png";
import dataIcon from "../../assets/icons/data.svg";
import exportIcon from "../../assets/icons/export.svg";
// import filterUp from "../../assets/icons/filterUp.png";
import filterDown from "../../assets/icons/filterDown.png";
import "./InvestorMarketplace.scss";
import info from "../../assets/icons/info.png";
import {
  marketplaceCards,
  marketplaceBalance,
  marketplaceTableData,
} from "../../constants/data";
import InvestorCard from "../InvestorDashboard/FirstSection/InvestorCard";
import Loader from "../Loader";

const InvestorMarketplace = () => {
  const [loading, setLoading] = useState(false);
  const [toggleTabs, setToggleTabs] = useState(1);

  const toggleTheTab = (index) => {
    setToggleTabs(index);
  };

  useEffect(() => {
    setInterval(() => {
      setLoading(true);
    }, 2000);
  }, []);

  const customTh = (name, imgDown) => {
    return (
      <th>
        {name} &nbsp;
        <img src={imgDown} />
      </th>
    );
  };

  return (
    <>
      {loading ? (
        <div className="investorMarketplace">
          <DashboardNavbar />
          <div className="innerContent">
            <div className="topElements">
              <h2>Marketplace</h2>
              <div className="buttonsHolder">
                <Button
                  bgColor="#618BE8"
                  color="white"
                  children={
                    <>
                      Support&nbsp;&nbsp;
                      <img src={info} />
                    </>
                  }
                />
                <Button
                  bgColor="#618BE8"
                  color="white"
                  children={<img src={refreshIcon} />}
                />
              </div>
            </div>
            <div className="firstSec">
              <div className="investorcards">
                {marketplaceCards.map((item) => {
                  return (
                    <InvestorCard
                      key={item.id}
                      title={item.title}
                      icon={item.icon}
                      amount={item.amount}
                      time={item.time}
                      percent={item.percent}
                      arrow={item.arrow}
                      extraClass={item.extraClass}
                    />
                  );
                })}
              </div>
              <div className="profileHolder">
                <div className="investorinfo">
                  <div className="information">
                    <p>Investable Balance</p>
                    <h2>€5,254.18</h2>
                  </div>
                  <div className="profileinfo">
                    {/* <span>Connected</span>
                    <div className="infouser">
                      <h3>Invest Waller</h3>
                      <p>0xd....23462</p>
                    </div>
                    <img src={profile} /> */}
                    <img src={dataIcon} />
                  </div>
                </div>
                <div className="balanceContainer">
                  {marketplaceBalance.map((item) => {
                    return (
                      <div key={item.id} className="balanceItem">
                        {item.icon}
                        <div className="info">
                          <p>{item.name}</p>
                          <p className="balanceAmount">{item.amount}</p>
                        </div>
                      </div>
                    );
                  })}
                  <Button
                    bgColor="#618BE8"
                    color="white"
                    children={"Buy Stablecoin"}
                  />
                </div>
              </div>
            </div>
            <div className="secondSec">
              <div className="infoSec">
                <p>MARKET</p>
                <p>
                  Export <img src={exportIcon} />
                </p>
              </div>
              <div className="tabs">
                <Button
                  bgColor={toggleTabs === 1 ? "#618BE8" : "#EFF2FC"}
                  color={toggleTabs === 1 ? "white" : "#33354D"}
                  children={"All Asset Types"}
                  onClick={() => toggleTheTab(1)}
                />
                <Button
                  bgColor={toggleTabs === 2 ? "#618BE8" : "#EFF2FC"}
                  color={toggleTabs === 2 ? "white" : "#33354D"}
                  children={"Trade Receivable Financing"}
                  onClick={() => toggleTheTab(2)}
                />
                <Button
                  bgColor={toggleTabs === 3 ? "#618BE8" : "#EFF2FC"}
                  color={toggleTabs === 3 ? "white" : "#33354D"}
                  children={"Revenue Based Financing"}
                  onClick={() => toggleTheTab(3)}
                />
              </div>
              <table className="marketTable">
                <thead className="marketTableHead">
                  <th>{customTh("COMPANY", filterDown)}</th>
                  <th> {customTh("APY", filterDown)}</th>
                  <th>{customTh("OPEN VOLUME", filterDown)}</th>
                  <th> {customTh("RISK", filterDown)}</th>
                  <th>{customTh("DUE DATE", filterDown)}</th>
                  <th> {customTh("TYPE", filterDown)}</th>
                  <th> {customTh("REMAINING TIME", filterDown)}</th>
                  <th>&nbsp; &nbsp; &nbsp; &nbsp;</th>
                </thead>
                <tbody>
                  {marketplaceTableData.map((data) => (
                    <tr key={data.id}>
                      <td>{`${data.name} `}</td>
                      <td>{`${data.apy}%`}</td>
                      <td>{`${data.openVolume} of ${data.totalVolume}`}</td>
                      <td>
                        <span className={`${data?.risk}`}>{data?.risk}</span>
                      </td>
                      <td>{data.date}</td>
                      <td>{data.type}</td>
                      <td>{data.timeRemaining}</td>
                      <td>
                        <Button
                          bgColor="#618BE8"
                          color="white"
                          children={"Invest"}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default InvestorMarketplace;
