import React from 'react'; 
import JobsPage from '../organisms/JobsPage';

export default function Jobs() {
  return (
    <div>
      <JobsPage />
    </div>
  )
}
