import { ReactComponent as Clock } from "../../assets/icons/clock.svg";
import { ReactComponent as Track } from "../../assets/icons/track.svg";
import { ReactComponent as Data } from "../../assets/icons/data.svg";
import { ReactComponent as Security } from "../../assets/icons/security.svg";
import { ReactComponent as Partners } from "../../assets/icons/partners.svg";
import { ReactComponent as Legally } from "../../assets/icons/legally.svg";
import member1 from "../../assets/images/Lorian.jpg";
import member2 from "../../assets/images/anonymous.jpeg";
import member3 from "../../assets/images/Johannes.jpg";
import member5 from "../../assets/images/Guillermo.png";
import review1 from "../../assets/images/review1.png";
import review2 from "../../assets/images/review2.png";
import review3 from "../../assets/images/review3.png";

export const faqData = [
  {
    question: "faq.faq1",
    answer: "faq.content1",
  },
  {
    question: "faq.faq2",
    answer: "faq.content2",
  },
  {
    question: "faq.faq3",
    answer: "faq.content3",
  },
  {
    question: "faq.faq4",
    answer: "faq.content4",
  },
  {
    question: "faq.faq5",
    answer: "faq.content5",
  },
  {
    question: "faq.faq6",
    answer: "faq.content6",
  },
];

export const stepsData = [
  {
    id: 1,
    title: "investortabs.title1",
    desc: "investortabs.subtitle1",
  },
  {
    id: 2,
    title: "investortabs.title2",
    desc: "investortabs.subtitle2",
  },
  {
    id: 3,
    title: "investortabs.title3",
    desc: "investortabs.subtitle3",
  },
  {
    id: 4,
    title: "investortabs.title4",
    desc: "investortabs.subtitle4",
  },
];

export const companySteps = [
  {
    id: 1,
    title: "companytabs.title1",
    desc: "companytabs.subtitle1",
  },
  {
    id: 2,
    title: "companytabs.title2",
    desc: "companytabs.subtitle2",
  },
  {
    id: 3,
    title: "companytabs.title3",
    desc: "companytabs.subtitle3",
  },
  {
    id: 4,
    title: "companytabs.title4",
    desc: "companytabs.subtitle4",
  },
];

export const solutionCards = [
  {
    id: 1,
    icon: <Partners />,
    title: "benefits.title1",
    desc: "benefits.description1",
  },
  {
    id: 2,
    icon: <Clock />,
    title: "benefits.title2",
    desc: "benefits.description2",
  },
  {
    id: 3,
    icon: <Track />,
    title: "benefits.title3",
    desc: "benefits.description3",
  },
  {
    id: 4,
    icon: <Legally />,
    title: "benefits.title4",
    desc: "benefits.description4",
  },
  {
    id: 5,
    icon: <Data />,
    title: "benefits.title5",
    desc: "benefits.description5",
  },
  {
    id: 6,
    icon: <Security />,
    title: "benefits.title6",
    desc: "benefits.description6",
  },
];

export const months = [
  {
    id: 1,
    title: "team.month1",
    desc: "team.item1",
  },
  {
    id: 2,
    title: "team.month2",
    desc: "team.item2",
  },
  {
    id: 3,
    title: "team.month3",
    desc: "team.item3",
  },
  {
    id: 4,
    title: "team.month4",
    desc: "team.item4",
  },
];

export const members = [
  {
    id: 1,
    member: member1,
    toLinkedin: "https://www.linkedin.com/in/lorian-qorraj-bb698ab0/",
    name: "Lorian Qorraj",
    position: "Co-Founder & CEO",
  },
  {
    id: 2,
    member: member2,
    toLinkedin: "https://www.linkedin.com/",
    name: "Confidential",
    position: "Co-Founder & CTO",
  },
  {
    id: 3,
    member: member3,
    toLinkedin: "https://www.linkedin.com/in/johannesschaaf/",
    name: "Johannes Schaaf",
    position: "COO",
  },
  {
    id: 4,
    member: member5,
    toLinkedin: "https://www.linkedin.com/in/guillermo-liebelt-97142492/",
    name: "Guillermo Liebelt",
    position: "Head of Growth",
  },
];

export const reviews = [
  {
    id: 1,
    name: "Dr. Markus Franke",
    position: "CEO of Mento Labs",
    content:
      "I am pleased to support Finba. Alternative and 'real-world' assets on-chain are becoming increasingly important and Web3 technology can accelerate the efficiency of alternative asset platforms. Finba builds solid infrastructure on Celo for alternative investments.",
    img: review1,
  },
  // {
  //   id: 2,
  //   name: "Andrew Bosomworth",
  //   position: "Ex-Head of PIMCO Germany",
  //   img: review2
  // }    ,
  // {
  //   id: 3,
  //   name: "Dr. Christopher Schröder",
  //   position: "CEO of Paribus Holding GmbH & Co. KG",
  //   img: review3
  // }    ,
];

export const paymentCards = [
  {
    days: "60 days",
    percent: 0.38,
    value1: "777,39",
    value2: "3,87",
    value3: "781,26",
    extraClass: "secondCard",
  },
  {
    days: "90 days",
    percent: 0.42,
    value1: "777,39",
    value2: "3,87",
    value3: "781,26",
    extraClass: "secondCard",
  },
  {
    days: "180 days",
    percent: 0.5,
    value1: "777,39",
    value2: "3,87",
    value3: "781,26",
    extraClass: "secondCard",
  },
];

export const paymentList = [
  {
    title: "BANK ACCOUNT",
    list: [
      {
        title: "Auer and Sons GmbH",
      },
      {
        title: "John Doe",
      },
      {
        title: "DE24 2220 2339 0002 0001",
      },
    ],
  },
  {
    title: "INVOICE ISSUER",
    list: [
      {
        title: "Mustermann Inc.",
      },
      {
        title: "Theodor-Heuss-Strasse 1070173 Stuttgart",
      },
      {
        title: "Germany",
      },
    ],
  },
  {
    title: "FILES UPLOADED",
    list: [
      {
        title: "invoice2023201.pdf",
      },
      {
        title: "receipt1232.pdf",
      },
      {
        title: "personalrecommendation.doc",
      },
      {
        title: "businesscardscan.jpg ",
      },
    ],
  },
  {
    title: "INVOICE SUMMARY",
    options: true,
    list: [
      {
        title: "Invoice Number",
      },
      {
        title: "Invoice Date",
      },
      {
        title: "Payment Due",
      },
      {
        title: "Amount Due (EUR)",
      },
      {
        title: "Discounted (EUR)",
      },
    ],
    optionsList: [
      {
        title: "20",
      },
      {
        title: "16/09/2022",
      },
      {
        title: "16/10/2022",
      },
      {
        title: "€ 9.206,31",
      },
      {
        title: "€ 230,34",
      },
    ],
  },
  {
    title: "PRICING",
    options: true,
    list: [
      {
        title: "Runtime",
      },
      {
        title: "Monthly Repayment",
      },
      {
        title: "Monthly Interest",
      },
      {
        title: "Monthly Rate",
      },
      {
        title: "Rate Due",
      },
    ],
    optionsList: [
      {
        title: "60 days",
      },
      {
        title: "€ 777,39",
      },
      {
        title: "€ 3,87",
      },
      {
        title: "€ 781,26",
      },
      {
        title: "16/03/2023",
      },
    ],
  },
];
