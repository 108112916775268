/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getNewsletter = /* GraphQL */ `
  query GetNewsletter($id: ID!) {
    getNewsletter(id: $id) {
      id
      email
      createdAt
      updatedAt
    }
  }
`;
export const listNewsletters = /* GraphQL */ `
  query ListNewsletters(
    $filter: ModelNewsletterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNewsletters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRequestConsultation = /* GraphQL */ `
  query GetRequestConsultation($id: ID!) {
    getRequestConsultation(id: $id) {
      id
      email
      createdAt
      updatedAt
    }
  }
`;
export const listRequestConsultations = /* GraphQL */ `
  query ListRequestConsultations(
    $filter: ModelRequestConsultationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRequestConsultations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCompanyBankAccount = /* GraphQL */ `
  query GetCompanyBankAccount($id: ID!) {
    getCompanyBankAccount(id: $id) {
      id
      companyName
      IBAN
      ust
      tax
      firstName
      lastName
      bic
      companyAddress
      gender
      birthDate
      address
      companyAddress2
      companyZip
      companyCity
      address2
      zip
      city
      email
      createdAt
      updatedAt
    }
  }
`;
export const listCompanyBankAccounts = /* GraphQL */ `
  query ListCompanyBankAccounts(
    $filter: ModelCompanyBankAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyBankAccounts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyName
        IBAN
        ust
        tax
        firstName
        lastName
        bic
        companyAddress
        gender
        birthDate
        address
        companyAddress2
        companyZip
        companyCity
        address2
        zip
        city
        email
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInvoiceRecipient = /* GraphQL */ `
  query GetInvoiceRecipient($id: ID!) {
    getInvoiceRecipient(id: $id) {
      id
      company
      country
      address
      address2
      zip
      city
      ustid
      firstName
      lastName
      contact
      mail
      phone
      recipient
      business
      client
      revenue
      delayed
      average
      email
      createdAt
      updatedAt
    }
  }
`;
export const listInvoiceRecipients = /* GraphQL */ `
  query ListInvoiceRecipients(
    $filter: ModelInvoiceRecipientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvoiceRecipients(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        company
        country
        address
        address2
        zip
        city
        ustid
        firstName
        lastName
        contact
        mail
        phone
        recipient
        business
        client
        revenue
        delayed
        average
        email
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInvoiceSummary = /* GraphQL */ `
  query GetInvoiceSummary($id: ID!) {
    getInvoiceSummary(id: $id) {
      id
      invoiceNumber
      buyerCompany
      invoiceDate
      paymentDate
      amount
      email
      doc
      invoiceType
      status
      days
      percent
      monthlyRepayment
      monthlyInterest
      monthlyRate
      createdAt
      updatedAt
    }
  }
`;
export const listInvoiceSummaries = /* GraphQL */ `
  query ListInvoiceSummaries(
    $filter: ModelInvoiceSummaryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvoiceSummaries(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        invoiceNumber
        buyerCompany
        invoiceDate
        paymentDate
        amount
        email
        doc
        invoiceType
        status
        days
        percent
        monthlyRepayment
        monthlyInterest
        monthlyRate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getWaitlist = /* GraphQL */ `
  query GetWaitlist($id: ID!) {
    getWaitlist(id: $id) {
      id
      firstName
      lastName
      email
      phoneNumber
      jobTitle
      companyName
      country
      businessType
      anyInfo
      language
      createdAt
      updatedAt
    }
  }
`;
export const listWaitlists = /* GraphQL */ `
  query ListWaitlists(
    $filter: ModelWaitlistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWaitlists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        email
        phoneNumber
        jobTitle
        companyName
        country
        businessType
        anyInfo
        language
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInvestor = /* GraphQL */ `
  query GetInvestor($id: ID!) {
    getInvestor(id: $id) {
      id
      firstName
      lastName
      email
      language
      phone
      country
      state
      city
      zip
      address
      address2
      createdAt
      updatedAt
    }
  }
`;
export const listInvestors = /* GraphQL */ `
  query ListInvestors(
    $filter: ModelInvestorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvestors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        email
        language
        phone
        country
        state
        city
        zip
        address
        address2
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInvoice = /* GraphQL */ `
  query GetInvoice($id: ID!) {
    getInvoice(id: $id) {
      id
      buyerName
      buyerIban
      buyerEmail
      brs
      sector
      revenue
      delayedPayment
      eai
      suppName
      suppLastName
      suppEmail
      invoiceNumber
      invoiceDate
      paymentDate
      amount
      discount
      doc
      createdAt
      updatedAt
    }
  }
`;
export const listInvoices = /* GraphQL */ `
  query ListInvoices(
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvoices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        buyerName
        buyerIban
        buyerEmail
        brs
        sector
        revenue
        delayedPayment
        eai
        suppName
        suppLastName
        suppEmail
        invoiceNumber
        invoiceDate
        paymentDate
        amount
        discount
        doc
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
