import { useFormik } from "formik"
import * as Yup from "yup"
import React from "react"
import { API } from "aws-amplify"
import * as mutations from "../../../graphql/mutations"
import Button from "../../atoms/Button/Button"
import styles from "./RequestCard.module.scss"
import { useTranslation } from "react-i18next"

export default function RequestCard() {
  const { t } = useTranslation();
  const [requestConsultSuccess, setRequestConsultSuccess] =
    React.useState(false)

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Email is Required"),
    }),
    onSubmit: async (values) => {
      const entry = { ...values }

      try {
        console.log("Entry:", entry)
        await API.graphql({
          query: mutations.createRequestConsultation,
          variables: { input: entry },
          authMode: "AWS_IAM",
        })
        console.log("Saved Request Successfully!")
        setRequestConsultSuccess(true)
        formik.resetForm()
      } catch (err) {
        console.error("ERROR:", err)
      }
    },
    validateOnChange: false,
    validateOnBlur: false,
  })

  return (
    <div className={styles.card}>
      <h1>{t('request.title')}</h1>
      <p>
      {t('request.desc')}
      </p>
      <div className={styles.inputHolder}>
        <form onSubmit={formik.handleSubmit}>
          <div className={styles.subscribe}>
          <div className={styles.inputs}>
          <input
            id="email"
            name="email"
            type="email"
            placeholder={t('request.placeholder')}
            {...formik.getFieldProps("email")}
          />

          {formik.touched.email && formik.errors.email ? (
            <div className={styles.error}>{formik.errors.email}</div>
          ) : null}
          {requestConsultSuccess ? <div>We will contact you soon</div> : null}
          </div>
          <Button bgColor="#2B3677" color="white" type="submit">
          {t('request.btn')}
          </Button>
        </div>
        </form>
      </div>
    </div>
  )
}
