import React, { useState } from 'react';
import ReviewCard from '../../atoms/ReviewCard/ReviewCard';
import styles from "./Reviews.module.scss";
import { reviews } from '../../constants/data';

export default function Reviews() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevious = () => {
    setCurrentIndex(currentIndex - 1);
  };

  const handleNext = () => {
    setCurrentIndex(currentIndex + 1);
  };

  return (
    <div className={styles.container}>
      <h1>Trusted by a global team of supporters and investors</h1>
      <div className={styles.reviews}>
        {reviews.slice(currentIndex, currentIndex + 3).map((item) => {
          return (
            <ReviewCard key={item.id} name={item.name} position={item.position} img={item.img} content={item.content} />
          )
        })}
      </div>

      {/* Slider for future use */}
      {/* <div className={styles.arrows}>
        <button disabled={currentIndex === 0} onClick={handlePrevious}>Previous</button>
        <button disabled={currentIndex + 3 >= reviews.length} onClick={handleNext}>Next</button>
      </div> */}
      
    </div>
  )
}