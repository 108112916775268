import React, { useState } from 'react';
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Text, View } from "@aws-amplify/ui-react";
import {ReactComponent as Arrow} from '../assets/icons/faqarrow.svg';
import FaqContact from './FaqContact';
import { Trans } from 'react-i18next';
import "./media-queries.scss"
export default function FAQ(props) {

  const [isMobile] = useState(window.innerWidth <= 1200 ? true : false);

    const { overrides, ...rest } = props;
    const [accordion, setAccordion] = useState(0);

    return (
        <div className='faq' id='faq'>
            <div className='title'>
            <Trans className='faqtitle' i18nKey="faq.title" >
                <h1 className='faqtitle'>Any questions?</h1>
                </Trans>
                <br/>
                <Trans i18nKey="faq.secondline" >
                <h1 className='faqtitle'>Check out the FAQs</h1>
                </Trans>
                <div className='faqbuttons'>
                    <FaqContact />
                </div>
            </div>
            <div className='accordions'>
                <div className='accordion'>
                    <div onClick={() => setAccordion(0)}>
                        <Flex
                                className='faqHolder'
                            gap="24px"
                            direction="column"
                            width="746px"
                            height="unset"
                            justifyContent="flex-start"
                            alignItems="center"
                            position="relative"
                            boxShadow="0px 4.400000095367432px 12px rgba(0.07450980693101883, 0.062745101749897, 0.13333334028720856, 0.05999999865889549)"
                            borderRadius="8px"
                            padding="16px 24px 16px 24px"
                            backgroundColor="rgba(255,255,255,1)"
                            {...rest}
                            {...getOverrideProps(overrides, "Panel")}
                        >
                            <Flex
                                className='faqHolder'
                                padding="0px 0px 0px 0px"
                                width="698px"
                                height="36px"
                                display="block"
                                gap="unset"
                                alignItems="unset"
                                justifyContent="unset"
                                shrink="0"
                                position="relative"
                                {...getOverrideProps(overrides, "accordion")}
                            >
                                <Text
                                className='faqHolder faqText'
                                    fontFamily="Lato"
                                    fontSize="16px"
                                    fontWeight="600"
                                    color="rgba(11,15,25,1)"
                                    lineHeight="25.600000381469727px"
                                    textAlign="left"
                                    display="block"
                                    direction="column"
                                    justifyContent="unset"
                                    width="638px"
                                    height="unset"
                                    gap="unset"
                                    alignItems="unset"
                                    position="absolute"
                                    top="calc(50% - 13px - 0px)"
                                    left="calc(50% - 319px - 30px)"
                                    padding="0px 0px 0px 0px"
                                    whiteSpace="pre-wrap"
                                    children="What is Finba?"
                                    {...getOverrideProps(overrides, "text")}
                                ><Trans i18nKey="faq.faq1"></Trans></Text>
                                <Flex
                                    gap="0"
                                    direction="row"
                                    width="unset"
                                    height="unset"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    position="absolute"
                                    top="0px"
                                    right="0px"
                                    borderRadius="40px"
                                    padding="8px 8px 8px 8px"
                                    backgroundColor="rgba(243,246,255,1)"
                                    {...getOverrideProps(overrides, "arrow")}
                                >
                                    <View
                                        width="20px"
                                        height="20px"
                                        display="block"
                                        gap="unset"
                                        alignItems="unset"
                                        justifyContent="unset"
                                        overflow="hidden"
                                        shrink="0"
                                        position="relative"
                                        padding="0px 0px 0px 0px"
                                        {...getOverrideProps(overrides, "chevron")}
                                    >
                                        <Arrow className={accordion === 0 ? ' activefaq' : 'chevron'}/>
                                    </View>
                                </Flex>
                            </Flex>
                            {accordion === 0 && (
                                <p><Trans i18nKey="faq.content1"></Trans></p>
                            )}
                        </Flex>
                    </div>
                    <div>
                    </div>
                </div>
                <div className='accordion'>
                    <div onClick={() => setAccordion(1)}>
                        <Flex
                            className='faqHolder'
                            gap="24px"
                            direction="column"
                            width="746px"
                            height="unset"
                            justifyContent="flex-start"
                            alignItems="center"
                            position="relative"
                            boxShadow="0px 4.400000095367432px 12px rgba(0.07450980693101883, 0.062745101749897, 0.13333334028720856, 0.05999999865889549)"
                            borderRadius="8px"
                            padding="16px 24px 16px 24px"
                            backgroundColor="rgba(255,255,255,1)"
                            {...rest}
                            {...getOverrideProps(overrides, "Panel")}
                        >
                            <Flex
                                className='faqHolder'
                                padding="0px 0px 0px 0px"
                                width="698px"
                                height="36px"
                                display="block"
                                gap="unset"
                                alignItems="unset"
                                justifyContent="unset"
                                shrink="0"
                                position="relative"
                                {...getOverrideProps(overrides, "accordion")}
                            >
                                <Text
                                className='faqHolder faqText'
                                    fontFamily="Lato"
                                    fontSize="16px"
                                    fontWeight="600"
                                    color="rgba(11,15,25,1)"
                                    lineHeight="25.600000381469727px"
                                    textAlign="left"
                                    display="block"
                                    direction="column"
                                    justifyContent="unset"
                                    width="638px"
                                    height="unset"
                                    gap="unset"
                                    alignItems="unset"
                                    position="absolute"
                                    top="calc(50% - 13px - 0px)"
                                    left="calc(50% - 319px - 30px)"
                                    padding="0px 0px 0px 0px"
                                    whiteSpace="pre-wrap"
                                    children="Am I qualified as a company?"
                                    {...getOverrideProps(overrides, "text")}
                                ><Trans i18nKey="faq.faq2" ></Trans></Text>
                                <Flex
                                    gap="0"
                                    direction="row"
                                    width="unset"
                                    height="unset"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    position="absolute"
                                    top="0px"
                                    right="0px"
                                    borderRadius="40px"
                                    padding="8px 8px 8px 8px"
                                    backgroundColor="rgba(243,246,255,1)"
                                    {...getOverrideProps(overrides, "arrow")}
                                >
                                    <View
                                        width="20px"
                                        height="20px"
                                        display="block"
                                        gap="unset"
                                        alignItems="unset"
                                        justifyContent="unset"
                                        overflow="hidden"
                                        shrink="0"
                                        position="relative"
                                        padding="0px 0px 0px 0px"
                                        {...getOverrideProps(overrides, "chevron")}
                                    >
                                        <Arrow  className={accordion === 1 ? ' activefaq' : 'chevron'}/>
                                    </View>
                                </Flex>
                            </Flex>
                            {accordion === 1 && (
                                <p><Trans i18nKey="faq.content2"></Trans></p>
                            )}
                        </Flex>
                    </div>
                </div>
                <div className='accordion'>
                    <div onClick={() => setAccordion(2)}>
                        <Flex
                            className='faqHolder'
                            gap="24px"
                            direction="column"
                            width="746px"
                            height="unset"
                            justifyContent="flex-start"
                            alignItems="center"
                            position="relative"
                            boxShadow="0px 4.400000095367432px 12px rgba(0.07450980693101883, 0.062745101749897, 0.13333334028720856, 0.05999999865889549)"
                            borderRadius="8px"
                            padding="16px 24px 16px 24px"
                            backgroundColor="rgba(255,255,255,1)"
                            {...rest}
                            {...getOverrideProps(overrides, "Panel")}
                        >
                            <Flex
                                className='faqHolder'
                                padding="0px 0px 0px 0px"
                                width="698px"
                                height="36px"
                                display="block"
                                gap="unset"
                                alignItems="unset"
                                justifyContent="unset"
                                shrink="0"
                                position="relative"
                                {...getOverrideProps(overrides, "accordion")}
                            >
                                <Text
                                    className='faqHolder faqText'
                                    fontFamily="Lato"
                                    fontSize="16px"
                                    fontWeight="600"
                                    color="rgba(11,15,25,1)"
                                    lineHeight="25.600000381469727px"
                                    textAlign="left"
                                    display="block"
                                    direction="column"
                                    justifyContent="unset"
                                    width="638px"
                                    height="unset"
                                    gap="unset"
                                    alignItems="unset"
                                    position="absolute"
                                    top="calc(50% - 13px - 0px)"
                                    left="calc(50% - 319px - 30px)"
                                    padding="0px 0px 0px 0px"
                                    whiteSpace="pre-wrap"
                                    children="What does Finba charge?"
                                    {...getOverrideProps(overrides, "text")}
                                ><Trans i18nKey="faq.faq3" ></Trans></Text>
                                <Flex
                                    gap="0"
                                    direction="row"
                                    width="unset"
                                    height="unset"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    position="absolute"
                                    top="0px"
                                    right="0px"
                                    borderRadius="40px"
                                    padding="8px 8px 8px 8px"
                                    backgroundColor="rgba(243,246,255,1)"
                                    {...getOverrideProps(overrides, "arrow")}
                                >
                                    <View
                                        width="20px"
                                        height="20px"
                                        display="block"
                                        gap="unset"
                                        alignItems="unset"
                                        justifyContent="unset"
                                        overflow="hidden"
                                        shrink="0"
                                        position="relative"
                                        padding="0px 0px 0px 0px"
                                        {...getOverrideProps(overrides, "chevron")}
                                    >
                                        <Arrow className={accordion === 2 ? ' activefaq' : 'chevron'} />
                                    </View>
                                </Flex>
                            </Flex>
                            {accordion === 2 && (
                                <p><Trans i18nKey="faq.content3"></Trans></p>
                            )}
                        </Flex>
                    </div>
                </div>
                <div className='accordion'>
                    <div onClick={() => setAccordion(3)}>
                        <Flex
                            className='faqHolder'
                            gap="24px"
                            direction="column"
                            width="746px"
                            height="unset"
                            justifyContent="flex-start"
                            alignItems="center"
                            position="relative"
                            boxShadow="0px 4.400000095367432px 12px rgba(0.07450980693101883, 0.062745101749897, 0.13333334028720856, 0.05999999865889549)"
                            borderRadius="8px"
                            padding="16px 24px 16px 24px"
                            backgroundColor="rgba(255,255,255,1)"
                            {...rest}
                            {...getOverrideProps(overrides, "Panel")}
                        >
                            <Flex
                                className='faqHolder'
                                padding="0px 0px 0px 0px"
                                width="698px"
                                height="36px"
                                display="block"
                                gap="unset"
                                alignItems="unset"
                                justifyContent="unset"
                                shrink="0"
                                position="relative"
                                {...getOverrideProps(overrides, "accordion")}
                            >
                                <Text
                                    className='faqHolder faqText'
                                    fontFamily="Lato"
                                    fontSize="16px"
                                    fontWeight="600"
                                    color="rgba(11,15,25,1)"
                                    lineHeight="25.600000381469727px"
                                    textAlign="left"
                                    display="block"
                                    direction="column"
                                    justifyContent="unset"
                                    width="638px"
                                    height="unset"
                                    gap="unset"
                                    alignItems="unset"
                                    position="absolute"
                                    top="calc(50% - 13px - 0px)"
                                    left="calc(50% - 319px - 30px)"
                                    padding="0px 0px 0px 0px"
                                    whiteSpace="pre-wrap"
                                    children="Who buys my accounts receivables?"
                                    {...getOverrideProps(overrides, "text")}
                                ><Trans i18nKey="faq.faq4"></Trans></Text>
                                <Flex
                                    gap="0"
                                    direction="row"
                                    width="unset"
                                    height="unset"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    position="absolute"
                                    top="0px"
                                    right="0px"
                                    borderRadius="40px"
                                    padding="8px 8px 8px 8px"
                                    backgroundColor="rgba(243,246,255,1)"
                                    {...getOverrideProps(overrides, "arrow")}
                                >
                                    <View
                                        width="20px"
                                        height="20px"
                                        display="block"
                                        gap="unset"
                                        alignItems="unset"
                                        justifyContent="unset"
                                        overflow="hidden"
                                        shrink="0"
                                        position="relative"
                                        padding="0px 0px 0px 0px"
                                        {...getOverrideProps(overrides, "chevron")}
                                    >
                                        <Arrow  className={accordion === 3 ? ' activefaq' : 'chevron'}/>
                                    </View>
                                </Flex>
                            </Flex>
                            {accordion === 3 && (
                                <p><Trans i18nKey="faq.content4"></Trans></p>
                            )}
                        </Flex>
                    </div>
                </div>
                <div className='accordion'>
                    <div onClick={() => setAccordion(4)}>
                        <Flex
                            className='faqHolder'
                            gap="24px"
                            direction="column"
                            width="746px"
                            height="unset"
                            justifyContent="flex-start"
                            alignItems="center"
                            position="relative"
                            boxShadow="0px 4.400000095367432px 12px rgba(0.07450980693101883, 0.062745101749897, 0.13333334028720856, 0.05999999865889549)"
                            borderRadius="8px"
                            padding="16px 24px 16px 24px"
                            backgroundColor="rgba(255,255,255,1)"
                            {...rest}
                            {...getOverrideProps(overrides, "Panel")}
                        >
                            <Flex
                                className='faqHolder'
                                padding="0px 0px 0px 0px"
                                width="698px"
                                height="36px"
                                display="block"
                                gap="unset"
                                alignItems="unset"
                                justifyContent="unset"
                                shrink="0"
                                position="relative"
                                {...getOverrideProps(overrides, "accordion")}
                            >
                                <Text
                                    className='faqHolder faqText'
                                    fontFamily="Lato"
                                    fontSize="16px"
                                    fontWeight="600"
                                    color="rgba(11,15,25,1)"
                                    lineHeight="25.600000381469727px"
                                    textAlign="left"
                                    display="block"
                                    direction="column"
                                    justifyContent="unset"
                                    width="638px"
                                    height="unset"
                                    gap="unset"
                                    alignItems="unset"
                                    position="absolute"
                                    top="calc(50% - 13px - 0px)"
                                    left="calc(50% - 319px - 30px)"
                                    padding="0px 0px 0px 0px"
                                    whiteSpace="pre-wrap"
                                    children="How can Finba help me with my business?"
                                    {...getOverrideProps(overrides, "text")}
                                ><Trans i18nKey="faq.faq5"></Trans></Text>
                                <Flex
                                    gap="0"
                                    direction="row"
                                    width="unset"
                                    height="unset"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    position="absolute"
                                    top="0px"
                                    right="0px"
                                    borderRadius="40px"
                                    padding="8px 8px 8px 8px"
                                    backgroundColor="rgba(243,246,255,1)"
                                    {...getOverrideProps(overrides, "arrow")}
                                >
                                    <View
                                        width="20px"
                                        height="20px"
                                        display="block"
                                        gap="unset"
                                        alignItems="unset"
                                        justifyContent="unset"
                                        overflow="hidden"
                                        shrink="0"
                                        position="relative"
                                        padding="0px 0px 0px 0px"
                                        {...getOverrideProps(overrides, "chevron")}
                                    >
                                        <Arrow className={accordion === 4 ? ' activefaq' : 'chevron'} />
                                    </View>
                                </Flex>
                            </Flex>
                            {accordion === 4 && (
                                <p><Trans i18nKey="faq.content5"></Trans></p>
                            )}
                        </Flex>
                    </div>
                </div>
                <div className='accordion'>
                    <div onClick={() => setAccordion(5)}>
                        <Flex
                            className='faqHolder'
                            gap="24px"
                            direction="column"
                            width="746px"
                            height="unset"
                            justifyContent="flex-start"
                            alignItems="center"
                            position="relative"
                            boxShadow="0px 4.400000095367432px 12px rgba(0.07450980693101883, 0.062745101749897, 0.13333334028720856, 0.05999999865889549)"
                            borderRadius="8px"
                            padding="16px 24px 16px 24px"
                            backgroundColor="rgba(255,255,255,1)"
                            {...rest}
                            {...getOverrideProps(overrides, "Panel")}
                        >
                            <Flex
                                className='faqHolder'
                                padding="0px 0px 0px 0px"
                                width="698px"
                                height="36px"
                                display="block"
                                gap="unset"
                                alignItems="unset"
                                justifyContent="unset"
                                shrink="0"
                                position="relative"
                                {...getOverrideProps(overrides, "accordion")}
                            >
                                <Text
                                    className='faqHolder faqText'
                                    fontFamily="Lato"
                                    fontSize="16px"
                                    fontWeight="600"
                                    color="rgba(11,15,25,1)"
                                    lineHeight="25.600000381469727px"
                                    textAlign="left"
                                    display="block"
                                    direction="column"
                                    justifyContent="unset"
                                    width="638px"
                                    height="unset"
                                    gap="unset"
                                    alignItems="unset"
                                    position="absolute"
                                    top="calc(50% - 13px - 0px)"
                                    left="calc(50% - 319px - 30px)"
                                    padding="0px 0px 0px 0px"
                                    whiteSpace="pre-wrap"
                                    children="What happens to my personal data?"
                                    {...getOverrideProps(overrides, "text")}
                                ><Trans i18nKey="faq.faq6"></Trans></Text>
                                <Flex
                                    gap="0"
                                    direction="row"
                                    width="unset"
                                    height="unset"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    position="absolute"
                                    top="0px"
                                    right="0px"
                                    borderRadius="40px"
                                    padding="8px 8px 8px 8px"
                                    backgroundColor="rgba(243,246,255,1)"
                                    {...getOverrideProps(overrides, "arrow")}
                                >
                                    <View
                                        width="20px"
                                        height="20px"
                                        display="block"
                                        gap="unset"
                                        alignItems="unset"
                                        justifyContent="unset"
                                        overflow="hidden"
                                        shrink="0"
                                        position="relative"
                                        padding="0px 0px 0px 0px"
                                        {...getOverrideProps(overrides, "chevron")}
                                    >
                                        <Arrow className={accordion === 5 ? ' activefaq' : 'chevron'} />
                                    </View>
                                </Flex>
                            </Flex>
                            {accordion === 5 && (
                                <p><Trans i18nKey="faq.content6"></Trans></p>
                            )}
                        </Flex>
                    </div>
                </div>
            </div>
            {isMobile && (
                <>
                <FaqContact />
                </>
            )}
        </div>
    )
}
