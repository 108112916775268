import React from 'react';
import InfoCard from '../../atoms/InfoCard/InfoCard';
import styles from "./Solutions.module.scss";
import { useTranslation } from 'react-i18next';

export default function Solutions({cards}) {
  const {t} = useTranslation();
  return (
    <div className={styles.container}>
      <h1>{t('benefits.title')}</h1>
      <p>{t('benefits.description')}</p>
      <div className={styles.cards}>
        {cards.map((item) => {
            return(
                <InfoCard extraClass="solutionsCard"   icon={item.icon} title={item.title} desc={item.desc}  />
            )
        })}
      </div>
    </div>
  )
}
