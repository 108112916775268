/* eslint-disable */

/* for images - changed Icon tags to Views, couldn't recognize the images as icons */
/* button inside View */

import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import {
  Flex,
  Icon,
  Text,
  View,
  useBreakpointValue,
} from "@aws-amplify/ui-react";
import Button from "./Button";
import Investor1 from "../assets/images/investor1.png";
import Investor2 from "../assets/images/investor2.png";
import Investor3 from "../assets/images/investor3.png";
import Investor4 from "../assets/images/investor4.png";
import { useNavigate } from "react-router-dom";
import { Trans } from "react-i18next";
export default function ForInvestors(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const navigate = useNavigate();
  const variants = [
    {
      overrides: {
        JoinWaitlist: {},
        "Ellipse 7536612412": {
          paths: [
            {
              d: "M357 178.5C357 277.083 277.083 357 178.5 357C79.9172 357 0 277.083 0 178.5C0 79.9172 79.9172 0 178.5 0C277.083 0 357 79.9172 357 178.5Z",
              fill: "rgba(99,102,241,0.0009)",
              fillRule: "nonzero",
            },
          ],
        },
        "Ellipse 7526612413": {
          paths: [
            {
              d: "M281 140.5C281 218.096 218.096 281 140.5 281C62.904 281 0 218.096 0 140.5C0 62.904 62.904 0 140.5 0C218.096 0 281 62.904 281 140.5Z",
              fill: "rgba(99,102,241,0.0009)",
              fillRule: "nonzero",
            },
          ],
        },
        "Ellipse 7516612414": {
          paths: [
            {
              d: "M203 101.5C203 157.557 157.557 203 101.5 203C45.4431 203 0 157.557 0 101.5C0 45.4431 45.4431 0 101.5 0C157.557 0 203 45.4431 203 101.5Z",
              fill: "rgba(99,102,241,0.0009)",
              fillRule: "nonzero",
            },
          ],
        },
        "Ellipse 7506612415": {},
        "400K+": {},
        graphic: {},
        "Ellipse 7536612419": {
          paths: [
            {
              d: "M357 178.5C357 277.083 277.083 357 178.5 357C79.9172 357 0 277.083 0 178.5C0 79.9172 79.9172 0 178.5 0C277.083 0 357 79.9172 357 178.5Z",
              fill: "rgba(99,102,241,0.0009)",
              fillRule: "nonzero",
            },
          ],
        },
        "Ellipse 7526612420": {
          paths: [
            {
              d: "M281 140.5C281 218.096 218.096 281 140.5 281C62.904 281 0 218.096 0 140.5C0 62.904 62.904 0 140.5 0C218.096 0 281 62.904 281 140.5Z",
              fill: "rgba(99,102,241,0.0009)",
              fillRule: "nonzero",
            },
          ],
        },
        "Ellipse 7516612421": {
          paths: [
            {
              d: "M203 101.5C203 157.557 157.557 203 101.5 203C45.4431 203 0 157.557 0 101.5C0 45.4431 45.4431 0 101.5 0C157.557 0 203 45.4431 203 101.5Z",
              fill: "rgba(99,102,241,0.0009)",
              fillRule: "nonzero",
            },
          ],
        },
        "Ellipse 7506612422": {},
        "132b+": {},
        "Group 48095421": {},
        "Ellipse 749": {},
        "Group 48095425": {},
        "Ellipse 7516612426": {},
        "Ellipse 7526612427": {},
        "Ellipse 7536612428": {},
        faces: {},
        image: {},
        subtitle6612431: {},
        number6612432: {},
        item6612430: {},
        subtitle6612434: {},
        number6612435: {},
        item6612433: {},
        title: {},
        subtitle6612439: {},
        "Frame 2120": {},
        heading: {},
        divider: {},
        content: {},
        ForInvestors: {},
      },
      variantValues: { breakpoint: "large" },
    },
    {
      overrides: {
        JoinWaitlist: { bottom: "34px" },
        "Ellipse 7536612412": {
          width: "216.15px",
          height: "216.15px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 216.1474609375,
            height: 216.147216796875,
          },
          paths: [
            {
              d: "M216.147 108.074C216.147 167.761 167.761 216.147 108.074 216.147C48.3862 216.147 0 167.761 0 108.074C0 48.3862 48.3862 0 108.074 0C167.761 0 216.147 48.3862 216.147 108.074Z",
              fill: "rgba(99,102,241,0.03)",
              fillRule: "nonzero",
            },
          ],
          left: "calc(50% - 108.07px - 0px)",
        },
        "Ellipse 7526612413": {
          width: "170.13px",
          height: "170.13px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 170.1328125,
            height: 170.1328125,
          },
          paths: [
            {
              d: "M170.133 85.0664C170.133 132.047 132.047 170.133 85.0664 170.133C38.0855 170.133 0 132.047 0 85.0664C0 38.0855 38.0855 0 85.0664 0C132.047 0 170.133 38.0855 170.133 85.0664Z",
              fill: "rgba(99,102,241,0.03)",
              fillRule: "nonzero",
            },
          ],
          top: "23.01px",
          left: "calc(50% - 85.07px - 0px)",
        },
        "Ellipse 7516612414": {
          width: "122.91px",
          height: "122.91px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 122.9072265625,
            height: 122.9073486328125,
          },
          paths: [
            {
              d: "M122.907 61.4536C122.907 95.3935 95.3935 122.907 61.4536 122.907C27.5137 122.907 0 95.3935 0 61.4536C0 27.5137 27.5137 0 61.4536 0C95.3935 0 122.907 27.5137 122.907 61.4536Z",
              fill: "rgba(99,102,241,0.03)",
              fillRule: "nonzero",
            },
          ],
          top: "46.62px",
          left: "calc(50% - 61.45px - 0px)",
        },
        "Ellipse 7506612415": {
          width: "56.31px",
          height: "56.31px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 56.30712890625,
            height: 56.3072509765625,
          },
          paths: [
            {
              d: "M56.3073 28.1536C56.3073 43.7025 43.7025 56.3073 28.1536 56.3073C12.6048 56.3073 0 43.7025 0 28.1536C0 12.6048 12.6048 0 28.1536 0C43.7025 0 56.3073 12.6048 56.3073 28.1536Z",
              fill: "rgba(43,54,119,1)",
              fillRule: "nonzero",
            },
          ],
          top: "79.92px",
          left: "calc(50% - 28.15px - 0px)",
        },
        "400K+": {
          fontSize: "12.109090805053711px",
          lineHeight: "16.952726364135742px",
          top: "99.9px",
          left: "calc(50% - 18.5px - 0.57px)",
        },
        graphic: { width: "216.15px", height: "216.15px" },
        "Ellipse 7536612419": {
          width: "216.15px",
          height: "216.15px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 216.1474609375,
            height: 216.147216796875,
          },
          paths: [
            {
              d: "M216.147 108.074C216.147 167.761 167.761 216.147 108.074 216.147C48.3862 216.147 0 167.761 0 108.074C0 48.3862 48.3862 0 108.074 0C167.761 0 216.147 48.3862 216.147 108.074Z",
              fill: "rgba(99,102,241,0.03)",
              fillRule: "nonzero",
            },
          ],
          left: "calc(50% - 108.07px - 0px)",
        },
        "Ellipse 7526612420": {
          width: "170.13px",
          height: "170.13px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 170.1328125,
            height: 170.1328125,
          },
          paths: [
            {
              d: "M170.133 85.0664C170.133 132.047 132.047 170.133 85.0664 170.133C38.0855 170.133 0 132.047 0 85.0664C0 38.0855 38.0855 0 85.0664 0C132.047 0 170.133 38.0855 170.133 85.0664Z",
              fill: "rgba(99,102,241,0.03)",
              fillRule: "nonzero",
            },
          ],
          top: "23.01px",
          left: "calc(50% - 85.07px - 0px)",
        },
        "Ellipse 7516612421": {
          width: "122.91px",
          height: "122.91px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 122.9072265625,
            height: 122.9073486328125,
          },
          paths: [
            {
              d: "M122.907 61.4536C122.907 95.3935 95.3935 122.907 61.4536 122.907C27.5137 122.907 0 95.3935 0 61.4536C0 27.5137 27.5137 0 61.4536 0C95.3935 0 122.907 27.5137 122.907 61.4536Z",
              fill: "rgba(99,102,241,0.03)",
              fillRule: "nonzero",
            },
          ],
          top: "46.62px",
          left: "calc(50% - 61.45px - 0px)",
        },
        "Ellipse 7506612422": {
          width: "56.31px",
          height: "56.31px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 56.30712890625,
            height: 56.3072509765625,
          },
          paths: [
            {
              d: "M56.3073 28.1536C56.3073 43.7025 43.7025 56.3073 28.1536 56.3073C12.6048 56.3073 0 43.7025 0 28.1536C0 12.6048 12.6048 0 28.1536 0C43.7025 0 56.3073 12.6048 56.3073 28.1536Z",
              fill: "rgba(43,54,119,1)",
              fillRule: "nonzero",
            },
          ],
          top: "79.92px",
          left: "calc(50% - 28.15px - 0px)",
        },
        "132b+": {
          fontSize: "12.109090805053711px",
          lineHeight: "16.952726364135742px",
          top: "99.9px",
          left: "calc(50% - 18px - 1.07px)",
        },
        "Group 48095421": { width: "216.15px", height: "216.15px" },
        "Ellipse 749": {
          width: "126.54px",
          height: "126.54px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 126.5400390625,
            height: 126.5400390625,
          },
          paths: [
            {
              d: "M126.54 63.27C126.54 98.2131 98.2131 126.54 63.27 126.54C28.3269 126.54 0 98.2131 0 63.27C0 28.3269 28.3269 0 63.27 0C98.2131 0 126.54 28.3269 126.54 63.27Z",
              fillRule: "nonzero",
            },
          ],
          left: "calc(50% - 63.27px - 0px)",
        },
        "Group 48095425": { width: "126.54px", height: "126.54px" },
        "Ellipse 7516612426": {
          width: "100.51px",
          height: "100.51px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 100.50537109375,
            height: 100.5054931640625,
          },
          paths: [
            {
              d: "M100.505 50.2527C100.505 78.0065 78.0065 100.505 50.2527 100.505C22.4989 100.505 0 78.0065 0 50.2527C0 22.4989 22.4989 0 50.2527 0C78.0065 0 100.505 22.4989 100.505 50.2527Z",
              fillRule: "nonzero",
            },
          ],
          top: "50.25px",
          left: "calc(50% - 50.25px - -102.93px)",
        },
        "Ellipse 7526612427": {
          width: "107.77px",
          height: "107.77px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 107.77099609375,
            height: 107.77099609375,
          },
          paths: [
            {
              d: "M107.771 53.8855C107.771 83.6456 83.6456 107.771 53.8855 107.771C24.1253 107.771 0 83.6456 0 53.8855C0 24.1253 24.1253 0 53.8855 0C83.6456 0 107.771 24.1253 107.771 53.8855Z",
              fillRule: "nonzero",
            },
          ],
          top: "188.9px",
          left: "calc(50% - 53.89px - 98.69px)",
        },
        "Ellipse 7536612428": {
          width: "112.01px",
          height: "112.01px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 112.00927734375,
            height: 112.009033203125,
          },
          paths: [
            {
              d: "M112.009 56.0045C112.009 86.935 86.935 112.009 56.0045 112.009C25.0741 112.009 0 86.935 0 56.0045C0 25.0741 25.0741 0 56.0045 0C86.935 0 112.009 25.0741 112.009 56.0045Z",
              fillRule: "nonzero",
            },
          ],
          top: "220.99px",
          left: "calc(50% - 56px - -66.9px)",
        },
        faces: { width: "306.36px", height: "333px" },
        image: {
          width: "306.36px",
          height: "333px",
          top: "4.97%",
          bottom: "41.67%",
          left: "55.32%",
          right: "4.89%",
        },
        subtitle6612431: {
          textAlign: "center",
          width: "116.28px",
          top: "62px",
          left: "calc(50% - 58.14px - 36.86px)",
        },
        number6612432: {
          fontSize: "48px",
          lineHeight: "62.39999771118164px",
          width: "184px",
          left: "calc(50% - 92px - -3px)",
        },
        item6612430: {
          width: "190px",
          height: "88px",
          top: "81.89%",
          right: "50.35%",
        },
        subtitle6612434: {
          textAlign: "center",
          width: "227.67px",
          top: "62px",
          left: "calc(50% - 113.84px - 0px)",
        },
        number6612435: {
          fontSize: "48px",
          lineHeight: "62.39999771118164px",
          width: "152.81px",
          left: "calc(50% - 76.4px - -19.89px)",
        },
        item6612433: {
          width: "227.67px",
          height: "88px",
          top: "81.89%",
          left: "40.5%",
        },
        title: { fontSize: "30px", lineHeight: "39px", width: "333px" },
        subtitle6612439: {},
        "Frame 2120": { width: "343px", left: "calc(50% - 171.5px - 0px)" },
        heading: {
          width: "343px",
          height: "357px",
          bottom: "26.54%",
          left: "1.57%",
          right: "8.8%",
        },
        divider: {
          width: "350px",
          viewBox: { minX: 0, minY: 0, width: 350, height: 1 },
          paths: [
            {
              d: "M0 0.5L350 0.5L350 -0.5L0 -0.5L0 0.5Z",
              stroke: "rgba(226,229,241,1)",
              fillRule: "nonzero",
              strokeWidth: 1,
            },
          ],
          left: "calc(50% - 175px - 10.34px)",
        },
        content: {
          width: "382.67px",
          height: "486px",
          top: "3.04%",
          bottom: "19.07%",
          left: "3.9%",
          right: "46.41%",
        },
        ForInvestors: { width: "770px", height: "624px" },
      },
      variantValues: { breakpoint: "medium" },
    },
    {
      overrides: {
        JoinWaitlist: { width: "320px", left: "calc(50% - 160px - 1px)" },
        "Ellipse 7536612412": {
          width: "199.92px",
          height: "199.92px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 199.919921875,
            height: 199.9200439453125,
          },
          paths: [
            {
              d: "M199.92 99.96C199.92 155.166 155.166 199.92 99.96 199.92C44.7536 199.92 0 155.166 0 99.96C0 44.7536 44.7536 0 99.96 0C155.166 0 199.92 44.7536 199.92 99.96Z",
              fill: "rgba(99,102,241,0.03)",
              fillRule: "nonzero",
            },
          ],
          left: "calc(50% - 99.96px - 0px)",
        },
        "Ellipse 7526612413": {
          width: "157.36px",
          height: "157.36px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 157.35986328125,
            height: 157.3599853515625,
          },
          paths: [
            {
              d: "M157.36 78.68C157.36 122.134 122.134 157.36 78.68 157.36C35.2262 157.36 0 122.134 0 78.68C0 35.2262 35.2262 0 78.68 0C122.134 0 157.36 35.2262 157.36 78.68Z",
              fill: "rgba(99,102,241,0.03)",
              fillRule: "nonzero",
            },
          ],
          top: "21.28px",
          left: "calc(50% - 78.68px - 0px)",
        },
        "Ellipse 7516612414": {
          width: "113.68px",
          height: "113.68px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 113.68017578125,
            height: 113.6800537109375,
          },
          paths: [
            {
              d: "M113.68 56.84C113.68 88.2319 88.2319 113.68 56.84 113.68C25.4481 113.68 0 88.2319 0 56.84C0 25.4481 25.4481 0 56.84 0C88.2319 0 113.68 25.4481 113.68 56.84Z",
              fill: "rgba(99,102,241,0.03)",
              fillRule: "nonzero",
            },
          ],
          top: "43.12px",
          left: "calc(50% - 56.84px - 0px)",
        },
        "Ellipse 7506612415": {
          width: "52.08px",
          height: "52.08px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 52.080078125,
            height: 52.0799560546875,
          },
          paths: [
            {
              d: "M52.08 26.04C52.08 40.4215 40.4215 52.08 26.04 52.08C11.6585 52.08 0 40.4215 0 26.04C0 11.6585 11.6585 0 26.04 0C40.4215 0 52.08 11.6585 52.08 26.04Z",
              fill: "rgba(43,54,119,1)",
              fillRule: "nonzero",
            },
          ],
          top: "73.92px",
          left: "calc(50% - 26.04px - 0px)",
        },
        "400K+": {
          fontSize: "11.199999809265137px",
          lineHeight: "15.679999351501465px",
          top: "92.4px",
          left: "calc(50% - 17px - 0.64px)",
        },
        graphic: { width: "199.92px", height: "199.92px" },
        "Ellipse 7536612419": {
          width: "199.92px",
          height: "199.92px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 199.919921875,
            height: 199.9200439453125,
          },
          paths: [
            {
              d: "M199.92 99.96C199.92 155.166 155.166 199.92 99.96 199.92C44.7536 199.92 0 155.166 0 99.96C0 44.7536 44.7536 0 99.96 0C155.166 0 199.92 44.7536 199.92 99.96Z",
              fill: "rgba(99,102,241,0.03)",
              fillRule: "nonzero",
            },
          ],
          left: "calc(50% - 99.96px - 0px)",
        },
        "Ellipse 7526612420": {
          width: "157.36px",
          height: "157.36px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 157.35986328125,
            height: 157.3599853515625,
          },
          paths: [
            {
              d: "M157.36 78.68C157.36 122.134 122.134 157.36 78.68 157.36C35.2262 157.36 0 122.134 0 78.68C0 35.2262 35.2262 0 78.68 0C122.134 0 157.36 35.2262 157.36 78.68Z",
              fill: "rgba(99,102,241,0.03)",
              fillRule: "nonzero",
            },
          ],
          top: "21.28px",
          left: "calc(50% - 78.68px - 0px)",
        },
        "Ellipse 7516612421": {
          width: "113.68px",
          height: "113.68px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 113.68017578125,
            height: 113.6800537109375,
          },
          paths: [
            {
              d: "M113.68 56.84C113.68 88.2319 88.2319 113.68 56.84 113.68C25.4481 113.68 0 88.2319 0 56.84C0 25.4481 25.4481 0 56.84 0C88.2319 0 113.68 25.4481 113.68 56.84Z",
              fill: "rgba(99,102,241,0.03)",
              fillRule: "nonzero",
            },
          ],
          top: "43.12px",
          left: "calc(50% - 56.84px - 0px)",
        },
        "Ellipse 7506612422": {
          width: "52.08px",
          height: "52.08px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 52.080078125,
            height: 52.0799560546875,
          },
          paths: [
            {
              d: "M52.08 26.04C52.08 40.4215 40.4215 52.08 26.04 52.08C11.6585 52.08 0 40.4215 0 26.04C0 11.6585 11.6585 0 26.04 0C40.4215 0 52.08 11.6585 52.08 26.04Z",
              fill: "rgba(43,54,119,1)",
              fillRule: "nonzero",
            },
          ],
          top: "73.92px",
          left: "calc(50% - 26.04px - 0px)",
        },
        "132b+": {
          fontSize: "11.199999809265137px",
          lineHeight: "15.679999351501465px",
          top: "92.4px",
          left: "calc(50% - 16.5px - 1.14px)",
        },
        "Group 48095421": { width: "199.92px", height: "199.92px" },
        "Ellipse 749": {
          width: "117.04px",
          height: "117.04px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 117.0400390625,
            height: 117.0400390625,
          },
          paths: [
            {
              d: "M117.04 58.52C117.04 90.8397 90.8397 117.04 58.52 117.04C26.2003 117.04 0 90.8397 0 58.52C0 26.2003 26.2003 0 58.52 0C90.8397 0 117.04 26.2003 117.04 58.52Z",
              fillRule: "nonzero",
            },
          ],
          left: "calc(50% - 58.52px - 0px)",
        },
        "Group 48095425": { width: "117.04px", height: "117.04px" },
        "Ellipse 7516612426": {
          width: "92.96px",
          height: "92.96px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 92.9599609375,
            height: 92.9599609375,
          },
          paths: [
            {
              d: "M92.96 46.48C92.96 72.1502 72.1502 92.96 46.48 92.96C20.8098 92.96 0 72.1502 0 46.48C0 20.8098 20.8098 0 46.48 0C72.1502 0 92.96 20.8098 92.96 46.48Z",
              fillRule: "nonzero",
            },
          ],
          top: "46.48px",
          left: "calc(50% - 46.48px - -95.2px)",
        },
        "Ellipse 7526612427": {
          width: "99.68px",
          height: "99.68px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 99.68017578125,
            height: 99.6800537109375,
          },
          paths: [
            {
              d: "M99.68 49.84C99.68 77.3659 77.3659 99.68 49.84 99.68C22.3141 99.68 0 77.3659 0 49.84C0 22.3141 22.3141 0 49.84 0C77.3659 0 99.68 22.3141 99.68 49.84Z",
              fillRule: "nonzero",
            },
          ],
          top: "174.72px",
          left: "calc(50% - 49.84px - 91.28px)",
        },
        "Ellipse 7536612428": {
          width: "103.6px",
          height: "103.6px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 103.60009765625,
            height: 103.5999755859375,
          },
          paths: [
            {
              d: "M103.6 51.8C103.6 80.4083 80.4083 103.6 51.8 103.6C23.1917 103.6 0 80.4083 0 51.8C0 23.1917 23.1917 0 51.8 0C80.4083 0 103.6 23.1917 103.6 51.8Z",
              fillRule: "nonzero",
            },
          ],
          top: "204.4px",
          left: "calc(50% - 51.8px - -61.88px)",
        },
        faces: { width: "283.36px", height: "308px" },
        image: {
          width: "283.36px",
          height: "308px",
          top: "39.67%",
          bottom: "24.18%",
          left: "10.83%",
          right: "10.46%",
        },
        subtitle6612431: {
          fontSize: "12px",
          lineHeight: "19.200000762939453px",
          width: "91px",
          top: "57px",
          left: "calc(50% - 45.5px - 17.5px)",
        },
        number6612432: { fontSize: "40px", lineHeight: "52px", width: "144px" },
        item6612430: {
          height: "77px",
          top: "90.09%",
          left: "2.89%",
          right: "50.8%",
        },
        subtitle6612434: {
          fontSize: "12px",
          lineHeight: "19.200000762939453px",
          width: "147px",
          top: "57px",
          left: "calc(50% - 73.5px - 0px)",
        },
        number6612435: {
          fontSize: "40px",
          lineHeight: "52px",
          width: "111px",
          left: "calc(50% - 55.5px - -3px)",
        },
        item6612433: {
          width: "147px",
          height: "77px",
          top: "90.09%",
          left: "52.73%",
        },
        title: {
          fontSize: "20px",
          lineHeight: "26px",
          textAlign: "center",
          children:
            "For Investors\n\nFind suitable company \ninvoices and buy \nthem at a discount",
        },
        subtitle6612439: { textAlign: "center" },
        "Frame 2120": { width: "298px", left: "calc(50% - 149px - 0px)" },
        heading: {
          width: "298px",
          height: "318px",
          bottom: "59.07%",
          right: "4.18%",
        },
        divider: {
          width: "298px",
          viewBox: { minX: 0, minY: 0, width: 298, height: 1 },
          paths: [
            {
              d: "M0 0.5L298 0.5L298 -0.5L0 -0.5L0 0.5Z",
              stroke: "rgba(226,229,241,1)",
              fillRule: "nonzero",
              strokeWidth: 1,
            },
          ],
          top: "679px",
          left: "calc(50% - 149px - 6.5px)",
        },
        content: {
          // width: "311px",
          height: "777px",
          top: "0%",
          bottom: "8.8%",
          left: "8.33%",
          right: "5.28%",
        },
        ForInvestors: { width: "360px", height: "852px" },
      },
      variantValues: { breakpoint: "small" },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "small",
    small: "small",
    medium: "medium",
    large: "large",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <div id="investors">
      <View
      className="investorsContainer"
        width="1232px"
        height="647px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="relative"
        padding="0px 0px 0px 0px"
        {...rest}
        {...getOverrideProps(overrides, "ForInvestors")}
      >
        <View
          display="flex"
          gap="8px"
          direction="row"
          width="307px"
          height="unset"
          justifyContent="center"
          alignItems="center"
          borderRadius="6px"
          position="absolute"
          bottom="0px"
          left="calc(50% - 153.5px - 0.5px)"
          backgroundColor="rgba(43,54,119,1)"
          property1="M"
          property2="dark"
          {...getOverrideProps(overrides, "JoinWaitlist")}
        >
          <Button
            extraClass="waitlist"
            color="white"
            onClick={() => navigate("/investor-signup")}
          >
            <Trans i18nKey="banner.btn2" >
              Join Waitlist
            </Trans>
          </Button>
        </View>
        <View
          padding="0px 0px 0px 0px"
          width="506px"
          height="550px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0%"
          bottom="14.99%"
          left="58.93%"
          right="0%"
          {...getOverrideProps(overrides, "image")}
        >
          <View
            padding="0px 0px 0px 0px"
            width="357px"
            height="357px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="17.45%"
            bottom="17.64%"
            left="13.44%"
            right="16.01%"
            {...getOverrideProps(overrides, "graphic")}
          >
            <Icon
              width="357px"
              height="357px"
              viewBox={{ minX: 0, minY: 0, width: 357, height: 357 }}
              paths={[
                {
                  d: "M357 178.5C357 277.083 277.083 357 178.5 357C79.9172 357 0 277.083 0 178.5C0 79.9172 79.9172 0 178.5 0C277.083 0 357 79.9172 357 178.5Z",
                  fill: "rgba(99,102,241,0.03)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="0px"
              left="calc(50% - 178.5px - 0px)"
              {...getOverrideProps(overrides, "Ellipse 7536612412")}
            ></Icon>
            {/* <Icon
              width="281px"
              height="281px"
              viewBox={{ minX: 0, minY: 0, width: 281, height: 281 }}
              paths={[
                {
                  d: "M281 140.5C281 218.096 218.096 281 140.5 281C62.904 281 0 218.096 0 140.5C0 62.904 62.904 0 140.5 0C218.096 0 281 62.904 281 140.5Z",
                  fill: "rgba(99,102,241,0.03)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="38px"
              left="calc(50% - 140.5px - 0px)"
              {...getOverrideProps(overrides, "Ellipse 7526612413")}
            ></Icon> */}
            {/* <Icon
              width="203px"
              height="203px"
              viewBox={{ minX: 0, minY: 0, width: 203, height: 203 }}
              paths={[
                {
                  d: "M203 101.5C203 157.557 157.557 203 101.5 203C45.4431 203 0 157.557 0 101.5C0 45.4431 45.4431 0 101.5 0C157.557 0 203 45.4431 203 101.5Z",
                  fill: "rgba(99,102,241,0.03)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="77px"
              left="calc(50% - 101.5px - 0px)"
              {...getOverrideProps(overrides, "Ellipse 7516612414")}
            ></Icon> */}
            {/* <Icon
              width="93px"
              height="93px"
              viewBox={{ minX: 0, minY: 0, width: 93, height: 93 }}
              paths={[
                {
                  d: "M93 46.5C93 72.1812 72.1812 93 46.5 93C20.8188 93 0 72.1812 0 46.5C0 20.8188 20.8188 0 46.5 0C72.1812 0 93 20.8188 93 46.5Z",
                  fill: "rgba(43,54,119,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="132px"
              left="calc(50% - 46.5px - 0px)"
              {...getOverrideProps(overrides, "Ellipse 7506612415")}
            ></Icon> */}
            {/* <Text
              fontFamily="Lato"
              fontSize="20px"
              fontWeight="800"
              color="rgba(255,255,255,1)"
              lineHeight="28px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              position="absolute"
              top="165px"
              left="calc(50% - 30.5px - 1px)"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="400K+"
              {...getOverrideProps(overrides, "400K+")}
            ></Text> */}
          </View>
          <View
            className="profiles"
            padding="0px 0px 0px 0px"
            width="506px"
            height="550px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0%"
            bottom="0%"
            left="0%"
            right="0%"
            {...getOverrideProps(overrides, "faces")}
          >
            <View
              padding="0px 0px 0px 0px"
              width="357px"
              height="357px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="17.45%"
              bottom="17.64%"
              left="13.44%"
              right="16.01%"
              {...getOverrideProps(overrides, "Group 48095421")}
            >
              <Icon
                width="357px"
                height="357px"
                viewBox={{ minX: 0, minY: 0, width: 357, height: 357 }}
                paths={[
                  {
                    d: "M357 178.5C357 277.083 277.083 357 178.5 357C79.9172 357 0 277.083 0 178.5C0 79.9172 79.9172 0 178.5 0C277.083 0 357 79.9172 357 178.5Z",
                    fill: "rgba(99,102,241,0.03)",
                    fillRule: "nonzero",
                  },
                ]}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="calc(50% - 178.5px - 0px)"
                {...getOverrideProps(overrides, "Ellipse 7536612419")}
              ></Icon>
              <Icon
                width="281px"
                height="281px"
                viewBox={{ minX: 0, minY: 0, width: 281, height: 281 }}
                paths={[
                  {
                    d: "M281 140.5C281 218.096 218.096 281 140.5 281C62.904 281 0 218.096 0 140.5C0 62.904 62.904 0 140.5 0C218.096 0 281 62.904 281 140.5Z",
                    fill: "rgba(99,102,241,0.03)",
                    fillRule: "nonzero",
                  },
                ]}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="38px"
                left="calc(50% - 140.5px - 0px)"
                {...getOverrideProps(overrides, "Ellipse 7526612420")}
              ></Icon>
              <Icon
                width="203px"
                height="203px"
                viewBox={{ minX: 0, minY: 0, width: 203, height: 203 }}
                paths={[
                  {
                    d: "M203 101.5C203 157.557 157.557 203 101.5 203C45.4431 203 0 157.557 0 101.5C0 45.4431 45.4431 0 101.5 0C157.557 0 203 45.4431 203 101.5Z",
                    fill: "rgba(99,102,241,0.03)",
                    fillRule: "nonzero",
                  },
                ]}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="77px"
                left="calc(50% - 101.5px - 0px)"
                {...getOverrideProps(overrides, "Ellipse 7516612421")}
              ></Icon>
              <Icon
                width="93px"
                height="93px"
                viewBox={{ minX: 0, minY: 0, width: 93, height: 93 }}
                paths={[
                  {
                    d: "M93 46.5C93 72.1812 72.1812 93 46.5 93C20.8188 93 0 72.1812 0 46.5C0 20.8188 20.8188 0 46.5 0C72.1812 0 93 20.8188 93 46.5Z",
                    fill: "rgba(43,54,119,1)",
                    fillRule: "nonzero",
                  },
                ]}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="132px"
                left="calc(50% - 46.5px - 0px)"
                {...getOverrideProps(overrides, "Ellipse 7506612422")}
              ></Icon>
              <Text

                fontFamily="Lato"
                fontSize="20px"
                fontWeight="800"
                color="rgba(255,255,255,1)"
                lineHeight="28px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                position="absolute"
                top="165px"
                left="calc(50% - 29px - 2.5px)"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="132b+"
                {...getOverrideProps(overrides, "132b+")}
              ></Text>
            </View>
            <View
              padding="0px 0px 0px 0px"
              width="209px"
              height="209px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="0%"
              bottom="62%"
              left="0%"
              right="58.7%"
              {...getOverrideProps(overrides, "Group 48095425")}
            >
              {/* <Icon
              width="209px"
              height="209px"
              viewBox={{ minX: 0, minY: 0, width: 209, height: 209 }}
              paths={[
                {
                  d: "M209 104.5C209 162.214 162.214 209 104.5 209C46.7862 209 0 162.214 0 104.5C0 46.7862 46.7862 0 104.5 0C162.214 0 209 46.7862 209 104.5Z",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="0px"
              left="calc(50% - 104.5px - 0px)"
            
            >  </Icon> */}
              <img
                className="investorimg"
                width={209}
                height={209}
                src={Investor1}
              />
            </View>
            <View
              width="166px"
              height="166px"
              viewBox={{ minX: 0, minY: 0, width: 166, height: 166 }}
              paths={[
                {
                  d: "M166 83C166 128.84 128.84 166 83 166C37.1604 166 0 128.84 0 83C0 37.1604 37.1604 0 83 0C128.84 0 166 37.1604 166 83Z",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="83px"
              left="calc(50% - 83px - -170px)"
              {...getOverrideProps(overrides, "Ellipse 7516612426")}
            >
              <img
                className="investorimg"
                width={209}
                height={209}
                src={Investor2}
              />
            </View>
            <View
              width="178px"
              height="178px"
              viewBox={{ minX: 0, minY: 0, width: 178, height: 178 }}
              paths={[
                {
                  d: "M178 89C178 138.153 138.153 178 89 178C39.8467 178 0 138.153 0 89C0 39.8467 39.8467 0 89 0C138.153 0 178 39.8467 178 89Z",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="312px"
              left="calc(50% - 89px - 163px)"
              {...getOverrideProps(overrides, "Ellipse 7526612427")}
            >
              <img
                className="investorimg"
                width={209}
                height={209}
                src={Investor3}
              />
            </View>
            <View
              width="185px"
              height="185px"
              viewBox={{ minX: 0, minY: 0, width: 185, height: 185 }}
              paths={[
                {
                  d: "M185 92.5C185 143.586 143.586 185 92.5 185C41.4137 185 0 143.586 0 92.5C0 41.4137 41.4137 0 92.5 0C143.586 0 185 41.4137 185 92.5Z",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="365px"
              left="calc(50% - 92.5px - -110.5px)"
              {...getOverrideProps(overrides, "Ellipse 7536612428")}
            >
              <img
                className="investorimg"
                width={209}
                height={209}
                src={Investor4}
              />
            </View>
          </View>
        </View>
        <View
          padding="0px 0px 0px 0px"
          width="526px"
          height="511px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="2.94%"
          bottom="18.08%"
          left="0%"
          right="57.31%"
          {...getOverrideProps(overrides, "content")}
        >
          <View
          className="numbersInvestor"
            padding="0px 0px 0px 0px"
            width="144px"
            height="113px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="77.89%"
            bottom="0%"
            left="0%"
            right="72.62%"
            {...getOverrideProps(overrides, "item6612430")}
          >
            <Text
              fontFamily="Lato"
              fontSize="16px"
              fontWeight="400"
              color="rgba(86,89,115,1)"
              lineHeight="25.600000381469727px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              position="absolute"
              top="87px"
              left="calc(50% - 45.5px - 26.5px)"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Average APY"
              {...getOverrideProps(overrides, "subtitle6612431")}
            ></Text>
            <Text
              fontFamily="Lato"
              fontSize="64px"
              fontWeight="800"
              color="rgba(11,15,25,1)"
              lineHeight="83.19999694824219px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              position="absolute"
              top="0px"
              left="calc(50% - 72px - 0px)"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="7,3%"
              {...getOverrideProps(overrides, "number6612432")}
            ></Text>
          </View>
          <View
            padding="0px 0px 0px 0px"
            width="222px"
            height="113px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="77.89%"
            bottom="0%"
            left="57.79%"
            right="0%"
            {...getOverrideProps(overrides, "item6612433")}
          >
            <Text
             className="textInvestor"
              fontFamily="Lato"
              fontSize="16px"
              fontWeight="400"
              color="rgba(86,89,115,1)"
              lineHeight="25.600000381469727px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="222px"
              height="unset"
              gap="unset"
              alignItems="unset"
              position="absolute"
              top="87px"
              left="calc(50% - 111px - 0px)"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Expected Volume per year"
              {...getOverrideProps(overrides, "subtitle6612434")}
            ></Text>
            <Text
             className="textInvestor"
              fontFamily="Lato"
              fontSize="64px"
              fontWeight="800"
              color="rgba(11,15,25,1)"
              lineHeight="83.19999694824219px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              position="absolute"
              top="0px"
              left="calc(50% - 74.5px - 36.5px)"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="132b"
              {...getOverrideProps(overrides, "number6612435")}
            ></Text>
          </View>
          <View
            padding="0px 0px 0px 0px"
            width="526px"
            height="370px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0%"
            bottom="27.59%"
            left="0%"
            right="0%"
            {...getOverrideProps(overrides, "heading")}
          >
            <Flex
              gap="32px"
              direction="column"
              width="526px"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              position="absolute"
              top="0px"
              left="calc(50% - 263px - 0px)"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 2120")}
            >
              <View
                fontFamily="Lato"
                fontSize="40px"
                fontWeight="800"
                color="#618BE8"
                lineHeight="52px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
              >
                <Trans i18nKey="investors.title" >
                  <Text
                    children="For Investors"
                    {...getOverrideProps(overrides, "title")}
                  ></Text>
                </Trans>
              </View>
              <View
              className="investordesc"
                fontFamily="Lato"
                fontSize="40px"
                fontWeight="800"
                color="rgba(11,15,25,1)"
                lineHeight="52px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
              >
                <Trans i18nKey="investors.subtitle" >
                  <Text
                    children="Find suitable company invoices and buy them &#xA;at a discount"
                    {...getOverrideProps(overrides, "title")}
                  ></Text>
                </Trans>
              </View>
              <View
                fontFamily="Lato"
                fontSize="16px"
                fontWeight="400"
                color="rgba(86,89,115,1)"
                lineHeight="25.600000381469727px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
              >
                <Trans i18nKey="investors.description" >
                  <Text
                    children="Use the financial platform of tomorrow. Explore accounts receivables from many different companies and invest flexibly. Benefit from attractive returns and secure technology enabled by decentralized infrastructure."
                    {...getOverrideProps(overrides, "subtitle6612439")}
                  ></Text>
                </Trans>
              </View>
            </Flex>
          </View>
          <Icon
            width="526px"
            height="0px"
            viewBox={{ minX: 0, minY: 0, width: 526, height: 1 }}
            paths={[
              {
                d: "M0 0.5L526 0.5L526 -0.5L0 -0.5L0 0.5Z",
                stroke: "rgba(226,229,241,1)",
                fillRule: "nonzero",
                strokeWidth: 1,
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="374px"
            left="calc(50% - 263px - 0px)"
            {...getOverrideProps(overrides, "divider")}
          ></Icon>
        </View>
      </View>
    </div>
  );
}
