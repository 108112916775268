import React from 'react';
import NoPage from "../molecules/404/404"

export default function index() {
  return (
    <div>
      <NoPage />
    </div>
  )
}
