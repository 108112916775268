import React from 'react';
import TextImage from '../../atoms/TextImage/TextImage';
import styles from "./FactoryBanner.module.scss";
import img from "../../../assets/images/factoryimg.png";
import InfoCard from '../../atoms/InfoCard/InfoCard';
import {ReactComponent as Clock} from "../../../assets/icons/clock.svg";
import {ReactComponent as Track} from "../../../assets/icons/track.svg";
import {ReactComponent as Data} from "../../../assets/icons/data.svg";
import {ReactComponent as Security} from "../../../assets/icons/security.svg";
import { useTranslation } from 'react-i18next';

export default function FactoryBanner() {

    const {t} = useTranslation();

    const cards = [
        {
            id: 1,
            icon: <Clock/>,
            title: t('benefits.title2'),
            desc: t('benefits.description2')
        },
        {
            id: 2,
            icon: <Track />,
            title: t('benefits.title3'),
            desc: t('benefits.description3')
        },
        {
            id: 3,
            icon: <Data />,
            title: t('benefits.title5'),
            desc: t('benefits.description5')
        },
        {
            id: 4,
            icon: <Security />,
            title: t('benefits.title6'),
            desc: t('benefits.description6')
        },
    ]

  return (
    <div className={styles.factoryBanner}>
      <TextImage extraClass="bannerChildren" image={img}>
        <div className={styles.content}>
        <h1>{t('investBanner.subtitle')}</h1>
        <div className={styles.cards}>
        {cards.map((item)=>{
            return(
                <InfoCard key={item.id} title={item.title} icon={item.icon} desc={item.desc} />
            )
        })}
        </div>
        </div>
        </TextImage>
    </div>
  )
}
