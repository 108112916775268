import React, { useState } from 'react'
import './styles.scss';
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Icon, Text, View } from "@aws-amplify/ui-react";
import { Trans } from 'react-i18next';

export default function HowDoesItWork(props) {
    const { overrides, ...rest } = props;
    const [isClicked, setClicked] = useState(true)

    return (
        <div className='stepscontainer'>
            <h2 className='stepstitle'><Trans i18nKey="companytabs.tabtitle" >How does it work?</Trans></h2>
            <div class='switchcontainer'>
                <div id="toggle-btn" >
                    <input onChange={() => setClicked(!isClicked)} type='checkbox' id='checkbox-input' />
                    <label htmlFor="checkbox-input" class='round-slider-container'>
                        <div  className={isClicked === false ? 'forinvestors activetab' : 'forinvestors' }  ><Trans i18nKey="companytabs.tabtitle2">For Investors</Trans></div>
                        <div className={isClicked  === true ? 'forcompanies activetab' : 'forcompanies' }  ><Trans i18nKey="companytabs.tabtitle1" >For Companies</Trans></div>
                        <div class='round-slider'></div>
                    </label>
                </div>
            </div>
            {isClicked ? (
                <div>
                <div className='steps'>
                    <div className='step1'>
                        <View
                            width="240px"
                            height="206px"
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="relative"
                            padding="0px 0px 0px 0px"
                            {...rest}
                            {...getOverrideProps(overrides, "StepOne")}
                        >
                            <Flex
                                gap="16px"
                                direction="column"
                                width="240px"
                                height="unset"
                                justifyContent="flex-start"
                                alignItems="center"
                                position="absolute"
                                top="50.49%"
                                bottom="0%"
                                left="0%"
                                right="0%"
                                padding="0px 0px 0px 0px"
                                {...getOverrideProps(overrides, "text")}
                            >
                                <View
                                    fontFamily="Lato"
                                    fontSize="21px"
                                    fontWeight="800"
                                    color="rgba(11,15,25,1)"
                                    lineHeight="33.599998474121094px"
                                    textAlign="center"
                                    display="block"
                                    direction="column"
                                    justifyContent="unset"
                                    width="unset"
                                    height="unset"
                                    gap="unset"
                                    alignItems="unset"
                                    shrink="0"
                                    alignSelf="stretch"
                                    position="relative"
                                    padding="0px 0px 0px 0px"
                                    whiteSpace="pre-wrap"
                                >
                                    <Trans i18nKey="companytabs.title1" >
                                        <Text
                                            children="Sign up "
                                            {...getOverrideProps(overrides, "title")}
                                        ></Text>
                                    </Trans>
                                </View>
                                <View
                                    fontFamily="Lato"
                                    fontSize="16px"
                                    fontWeight="400"
                                    color="rgba(86,89,115,1)"
                                    lineHeight="25.600000381469727px"
                                    textAlign="center"
                                    display="block"
                                    direction="column"
                                    justifyContent="unset"
                                    width="unset"
                                    height="unset"
                                    gap="unset"
                                    alignItems="unset"
                                    shrink="0"
                                    alignSelf="stretch"
                                    position="relative"
                                    padding="0px 0px 0px 0px"
                                    whiteSpace="pre-wrap"
                                >
                                    <Trans i18nKey="companytabs.subtitle1" >
                                        <Text
                                            children="Register as a company and verify  your email adress"
                                            {...getOverrideProps(overrides, "subtitle")}
                                        ></Text>
                                    </Trans>
                                </View>
                            </Flex>
                            <View
                                width="80px"
                                height="80px"
                                display="block"
                                gap="unset"
                                alignItems="unset"
                                justifyContent="unset"
                                position="absolute"
                                top="0%"
                                bottom="61.17%"
                                left="33.33%"
                                right="33.33%"
                                padding="0px 0px 0px 0px"
                                {...getOverrideProps(overrides, "step")}
                            >
                                <Icon
                                    width="80px"
                                    height="80px"
                                    viewBox={{ minX: 0, minY: 0, width: 80, height: 80 }}
                                    paths={[
                                        {
                                            d: "M80 40C80 62.0914 62.0914 80 40 80C17.9086 80 0 62.0914 0 40C0 17.9086 17.9086 0 40 0C62.0914 0 80 17.9086 80 40Z",
                                            fill: "rgba(243,246,255,1)",
                                            fillRule: "nonzero",
                                        },
                                    ]}
                                    display="block"
                                    gap="unset"
                                    alignItems="unset"
                                    justifyContent="unset"
                                    position="absolute"
                                    top="0%"
                                    bottom="0%"
                                    left="0%"
                                    right="0%"
                                    {...getOverrideProps(overrides, "Ellipse 747")}
                                ></Icon>
                                <Icon
                                    width="56px"
                                    height="56px"
                                    viewBox={{ minX: 0, minY: 0, width: 56, height: 56 }}
                                    paths={[
                                        {
                                            d: "M56 28C56 43.464 43.464 56 28 56C12.536 56 0 43.464 0 28C0 12.536 12.536 0 28 0C43.464 0 56 12.536 56 28Z",
                                            fill: "rgba(255,255,255,1)",
                                            fillRule: "nonzero",
                                        },
                                    ]}
                                    display="block"
                                    gap="unset"
                                    alignItems="unset"
                                    justifyContent="unset"
                                    position="absolute"
                                    top="15%"
                                    bottom="15%"
                                    left="15%"
                                    right="15%"
                                    {...getOverrideProps(overrides, "Ellipse 748")}
                                ></Icon>
                                <Text
                                    fontFamily="Lato"
                                    fontSize="24px"
                                    fontWeight="800"
                                    color="rgba(11,15,25,1)"
                                    lineHeight="33.599998474121094px"
                                    textAlign="center"
                                    display="block"
                                    direction="column"
                                    justifyContent="unset"
                                    width="42px"
                                    height="unset"
                                    gap="unset"
                                    alignItems="unset"
                                    position="absolute"
                                    top="calc(50% - 17px - 0px)"
                                    left="calc(50% - 21px - 0px)"
                                    padding="0px 0px 0px 0px"
                                    whiteSpace="pre-wrap"
                                    children="1"
                                    {...getOverrideProps(overrides, "1")}
                                ></Text>
                            </View>
                        </View>

                    </div>
                    <div className='step2'>
                        <View
                            width="240px"
                            height="206px"
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="relative"
                            padding="0px 0px 0px 0px"
                            {...rest}
                            {...getOverrideProps(overrides, "StepTwo")}
                        >
                            <Flex
                                gap="16px"
                                direction="column"
                                width="240px"
                                height="unset"
                                justifyContent="flex-start"
                                alignItems="center"
                                position="absolute"
                                top="50.49%"
                                bottom="0%"
                                left="0%"
                                right="0%"
                                padding="0px 0px 0px 0px"
                                {...getOverrideProps(overrides, "text")}
                            >
                                <View
                                    fontFamily="Lato"
                                    fontSize="21px"
                                    fontWeight="800"
                                    color="rgba(11,15,25,1)"
                                    lineHeight="33.599998474121094px"
                                    textAlign="center"
                                    display="block"
                                    direction="column"
                                    justifyContent="unset"
                                    width="unset"
                                    height="unset"
                                    gap="unset"
                                    alignItems="unset"
                                    shrink="0"
                                    alignSelf="stretch"
                                    position="relative"
                                    padding="0px 0px 0px 0px"
                                    whiteSpace="pre-wrap"
                                >
                                    <Trans i18nKey="companytabs.title2" >
                                        <Text
                                            children="Upload invoice"
                                            {...getOverrideProps(overrides, "title")}
                                        ></Text>
                                    </Trans>
                                </View>
                                <View
                                    fontFamily="Lato"
                                    fontSize="16px"
                                    fontWeight="400"
                                    color="rgba(86,89,115,1)"
                                    lineHeight="25.600000381469727px"
                                    textAlign="center"
                                    display="block"
                                    direction="column"
                                    justifyContent="unset"
                                    width="unset"
                                    height="unset"
                                    gap="unset"
                                    alignItems="unset"
                                    shrink="0"
                                    alignSelf="stretch"
                                    position="relative"
                                    padding="0px 0px 0px 0px"
                                    whiteSpace="pre-wrap"
                                >
                                    <Trans i18nKey="companytabs.subtitle2" >
                                        <Text
                                            children="Upload one or more invoices and verify them afterwards"
                                            {...getOverrideProps(overrides, "subtitle")}
                                        ></Text>
                                    </Trans>
                                </View>
                            </Flex>
                            <View
                                width="80px"
                                height="80px"
                                display="block"
                                gap="unset"
                                alignItems="unset"
                                justifyContent="unset"
                                position="absolute"
                                top="0%"
                                bottom="61.17%"
                                left="33.33%"
                                right="33.33%"
                                padding="0px 0px 0px 0px"
                                {...getOverrideProps(overrides, "step")}
                            >
                                <Icon
                                    width="80px"
                                    height="80px"
                                    viewBox={{ minX: 0, minY: 0, width: 80, height: 80 }}
                                    paths={[
                                        {
                                            d: "M80 40C80 62.0914 62.0914 80 40 80C17.9086 80 0 62.0914 0 40C0 17.9086 17.9086 0 40 0C62.0914 0 80 17.9086 80 40Z",
                                            fill: "rgba(243,246,255,1)",
                                            fillRule: "nonzero",
                                        },
                                    ]}
                                    display="block"
                                    gap="unset"
                                    alignItems="unset"
                                    justifyContent="unset"
                                    position="absolute"
                                    top="0%"
                                    bottom="0%"
                                    left="0%"
                                    right="0%"
                                    {...getOverrideProps(overrides, "Ellipse 747")}
                                ></Icon>
                                <Icon
                                    width="56px"
                                    height="56px"
                                    viewBox={{ minX: 0, minY: 0, width: 56, height: 56 }}
                                    paths={[
                                        {
                                            d: "M56 28C56 43.464 43.464 56 28 56C12.536 56 0 43.464 0 28C0 12.536 12.536 0 28 0C43.464 0 56 12.536 56 28Z",
                                            fill: "rgba(255,255,255,1)",
                                            fillRule: "nonzero",
                                        },
                                    ]}
                                    display="block"
                                    gap="unset"
                                    alignItems="unset"
                                    justifyContent="unset"
                                    position="absolute"
                                    top="15%"
                                    bottom="15%"
                                    left="15%"
                                    right="15%"
                                    {...getOverrideProps(overrides, "Ellipse 748")}
                                ></Icon>
                                <Text
                                    fontFamily="Lato"
                                    fontSize="24px"
                                    fontWeight="800"
                                    color="rgba(11,15,25,1)"
                                    lineHeight="33.599998474121094px"
                                    textAlign="center"
                                    display="block"
                                    direction="column"
                                    justifyContent="unset"
                                    width="42px"
                                    height="unset"
                                    gap="unset"
                                    alignItems="unset"
                                    position="absolute"
                                    top="calc(50% - 17px - 0px)"
                                    left="calc(50% - 21px - 0px)"
                                    padding="0px 0px 0px 0px"
                                    whiteSpace="pre-wrap"
                                    children="2"
                                    {...getOverrideProps(overrides, "2")}
                                ></Text>
                            </View>
                        </View>
                    </div>
                    <div className='step3'>
                        <View
                            width="240px"
                            height="206px"
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="relative"
                            padding="0px 0px 0px 0px"
                            {...rest}
                            {...getOverrideProps(overrides, "StepThree")}
                        >
                            <Flex
                                gap="16px"
                                direction="column"
                                width="240px"
                                height="unset"
                                justifyContent="flex-start"
                                alignItems="center"
                                position="absolute"
                                top="50.49%"
                                bottom="0%"
                                left="0%"
                                right="0%"
                                padding="0px 0px 0px 0px"
                                {...getOverrideProps(overrides, "text")}
                            >
                                <View
                                    fontFamily="Lato"
                                    fontSize="21px"
                                    fontWeight="800"
                                    color="rgba(11,15,25,1)"
                                    lineHeight="33.599998474121094px"
                                    textAlign="center"
                                    display="block"
                                    direction="column"
                                    justifyContent="unset"
                                    width="unset"
                                    height="unset"
                                    gap="unset"
                                    alignItems="unset"
                                    shrink="0"
                                    alignSelf="stretch"
                                    position="relative"
                                    padding="0px 0px 0px 0px"
                                    whiteSpace="pre-wrap"
                                >
                                    <Trans i18nKey="companytabs.title3" >
                                        <Text
                                            children="Post listing"
                                            {...getOverrideProps(overrides, "title")}
                                        ></Text>
                                    </Trans>
                                </View>
                                <View
                                    fontFamily="Lato"
                                    fontSize="16px"
                                    fontWeight="400"
                                    color="rgba(86,89,115,1)"
                                    lineHeight="25.600000381469727px"
                                    textAlign="center"
                                    display="block"
                                    direction="column"
                                    justifyContent="unset"
                                    width="unset"
                                    height="unset"
                                    gap="unset"
                                    alignItems="unset"
                                    shrink="0"
                                    alignSelf="stretch"
                                    position="relative"
                                    padding="0px 0px 0px 0px"
                                    whiteSpace="pre-wrap"
                                >
                                    <Trans i18nKey="companytabs.subtitle3" >
                                        <Text
                                            children="Post account receivables and sell them to investors"
                                            {...getOverrideProps(overrides, "subtitle")}
                                        ></Text>
                                    </Trans>
                                </View>
                            </Flex>
                            <View
                                width="80px"
                                height="80px"
                                display="block"
                                gap="unset"
                                alignItems="unset"
                                justifyContent="unset"
                                position="absolute"
                                top="0%"
                                bottom="61.17%"
                                left="33.33%"
                                right="33.33%"
                                padding="0px 0px 0px 0px"
                                {...getOverrideProps(overrides, "step")}
                            >
                                <Icon
                                    width="80px"
                                    height="80px"
                                    viewBox={{ minX: 0, minY: 0, width: 80, height: 80 }}
                                    paths={[
                                        {
                                            d: "M80 40C80 62.0914 62.0914 80 40 80C17.9086 80 0 62.0914 0 40C0 17.9086 17.9086 0 40 0C62.0914 0 80 17.9086 80 40Z",
                                            fill: "rgba(243,246,255,1)",
                                            fillRule: "nonzero",
                                        },
                                    ]}
                                    display="block"
                                    gap="unset"
                                    alignItems="unset"
                                    justifyContent="unset"
                                    position="absolute"
                                    top="0%"
                                    bottom="0%"
                                    left="0%"
                                    right="0%"
                                    {...getOverrideProps(overrides, "Ellipse 747")}
                                ></Icon>
                                <Icon
                                    width="56px"
                                    height="56px"
                                    viewBox={{ minX: 0, minY: 0, width: 56, height: 56 }}
                                    paths={[
                                        {
                                            d: "M56 28C56 43.464 43.464 56 28 56C12.536 56 0 43.464 0 28C0 12.536 12.536 0 28 0C43.464 0 56 12.536 56 28Z",
                                            fill: "rgba(255,255,255,1)",
                                            fillRule: "nonzero",
                                        },
                                    ]}
                                    display="block"
                                    gap="unset"
                                    alignItems="unset"
                                    justifyContent="unset"
                                    position="absolute"
                                    top="15%"
                                    bottom="15%"
                                    left="15%"
                                    right="15%"
                                    {...getOverrideProps(overrides, "Ellipse 748")}
                                ></Icon>
                                <Text
                                    fontFamily="Lato"
                                    fontSize="24px"
                                    fontWeight="800"
                                    color="rgba(11,15,25,1)"
                                    lineHeight="33.599998474121094px"
                                    textAlign="center"
                                    display="block"
                                    direction="column"
                                    justifyContent="unset"
                                    width="42px"
                                    height="unset"
                                    gap="unset"
                                    alignItems="unset"
                                    position="absolute"
                                    top="28.75%"
                                    bottom="28.75%"
                                    left="23.75%"
                                    right="23.75%"
                                    padding="0px 0px 0px 0px"
                                    whiteSpace="pre-wrap"
                                    children="3"
                                    {...getOverrideProps(overrides, "3")}
                                ></Text>
                            </View>
                        </View>
                    </div>
                    <div className='step4'>
                        <View
                            width="240px"
                            height="206px"
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="relative"
                            padding="0px 0px 0px 0px"
                            {...rest}
                            {...getOverrideProps(overrides, "StepFour")}
                        >
                            <Flex
                                gap="16px"
                                direction="column"
                                width="240px"
                                height="unset"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                position="absolute"
                                top="50.49%"
                                bottom="0%"
                                left="0%"
                                right="0%"
                                padding="0px 0px 0px 0px"
                                {...getOverrideProps(overrides, "text")}
                            >
                                <View
                                    fontFamily="Lato"
                                    fontSize="21px"
                                    fontWeight="800"
                                    color="rgba(11,15,25,1)"
                                    lineHeight="33.599998474121094px"
                                    textAlign="center"
                                    display="block"
                                    direction="column"
                                    justifyContent="unset"
                                    width="unset"
                                    height="unset"
                                    gap="unset"
                                    alignItems="unset"
                                    shrink="0"
                                    alignSelf="stretch"
                                    position="relative"
                                    padding="0px 0px 0px 0px"
                                    whiteSpace="pre-wrap"
                                >
                                    <Trans i18nKey="companytabs.title4" >
                                        <Text
                                            children="Receive payment"
                                            {...getOverrideProps(overrides, "title")}
                                        ></Text>
                                    </Trans>
                                </View>
                                <View
                                    fontFamily="Lato"
                                    fontSize="16px"
                                    fontWeight="400"
                                    color="rgba(86,89,115,1)"
                                    lineHeight="25.600000381469727px"
                                    textAlign="center"
                                    display="block"
                                    direction="column"
                                    justifyContent="unset"
                                    width="unset"
                                    height="unset"
                                    gap="unset"
                                    alignItems="unset"
                                    shrink="0"
                                    alignSelf="stretch"
                                    position="relative"
                                    padding="0px 0px 0px 0px"
                                    whiteSpace="pre-wrap"
                                >
                                    <Trans i18nKey="companytabs.subtitle4" >
                                        <Text
                                            children="Receive your selling price as instant payment"
                                            {...getOverrideProps(overrides, "subtitle")}
                                        ></Text>
                                    </Trans>
                                </View>
                            </Flex>
                            <View
                                width="80px"
                                height="80px"
                                display="block"
                                gap="unset"
                                alignItems="unset"
                                justifyContent="unset"
                                position="absolute"
                                top="0%"
                                bottom="61.17%"
                                left="33.33%"
                                right="33.33%"
                                padding="0px 0px 0px 0px"
                                {...getOverrideProps(overrides, "step")}
                            >
                                <Icon
                                    width="80px"
                                    height="80px"
                                    viewBox={{ minX: 0, minY: 0, width: 80, height: 80 }}
                                    paths={[
                                        {
                                            d: "M80 40C80 62.0914 62.0914 80 40 80C17.9086 80 0 62.0914 0 40C0 17.9086 17.9086 0 40 0C62.0914 0 80 17.9086 80 40Z",
                                            fill: "rgba(243,246,255,1)",
                                            fillRule: "nonzero",
                                        },
                                    ]}
                                    display="block"
                                    gap="unset"
                                    alignItems="unset"
                                    justifyContent="unset"
                                    position="absolute"
                                    top="0%"
                                    bottom="0%"
                                    left="0%"
                                    right="0%"
                                    {...getOverrideProps(overrides, "Ellipse 747")}
                                ></Icon>
                                <Icon
                                    width="56px"
                                    height="56px"
                                    viewBox={{ minX: 0, minY: 0, width: 56, height: 56 }}
                                    paths={[
                                        {
                                            d: "M56 28C56 43.464 43.464 56 28 56C12.536 56 0 43.464 0 28C0 12.536 12.536 0 28 0C43.464 0 56 12.536 56 28Z",
                                            fill: "rgba(255,255,255,1)",
                                            fillRule: "nonzero",
                                        },
                                    ]}
                                    display="block"
                                    gap="unset"
                                    alignItems="unset"
                                    justifyContent="unset"
                                    position="absolute"
                                    top="15%"
                                    bottom="15%"
                                    left="15%"
                                    right="15%"
                                    {...getOverrideProps(overrides, "Ellipse 748")}
                                ></Icon>
                                <Text
                                    fontFamily="Lato"
                                    fontSize="24px"
                                    fontWeight="800"
                                    color="rgba(11,15,25,1)"
                                    lineHeight="33.599998474121094px"
                                    textAlign="center"
                                    display="block"
                                    direction="column"
                                    justifyContent="unset"
                                    width="42px"
                                    height="unset"
                                    gap="unset"
                                    alignItems="unset"
                                    position="absolute"
                                    top="calc(50% - 17px - 0px)"
                                    left="calc(50% - 21px - 0px)"
                                    padding="0px 0px 0px 0px"
                                    whiteSpace="pre-wrap"
                                    children="4"
                                    {...getOverrideProps(overrides, "4")}
                                ></Text>
                            </View>
                        </View>
                    </div>
                </div>
            </div>
            ): (
                <div className='steps'>
<div className='step1'>
                        <View
                            width="240px"
                            height="206px"
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="relative"
                            padding="0px 0px 0px 0px"
                            {...rest}
                            {...getOverrideProps(overrides, "StepOne")}
                        >
                            <Flex
                                gap="16px"
                                direction="column"
                                width="240px"
                                height="unset"
                                justifyContent="flex-start"
                                alignItems="center"
                                position="absolute"
                                top="50.49%"
                                bottom="0%"
                                left="0%"
                                right="0%"
                                padding="0px 0px 0px 0px"
                                {...getOverrideProps(overrides, "text")}
                            >
                                <View
                                    fontFamily="Lato"
                                    fontSize="21px"
                                    fontWeight="800"
                                    color="rgba(11,15,25,1)"
                                    lineHeight="33.599998474121094px"
                                    textAlign="center"
                                    display="block"
                                    direction="column"
                                    justifyContent="unset"
                                    width="unset"
                                    height="unset"
                                    gap="unset"
                                    alignItems="unset"
                                    shrink="0"
                                    alignSelf="stretch"
                                    position="relative"
                                    padding="0px 0px 0px 0px"
                                    whiteSpace="pre-wrap"
                                >
                                    <Trans i18nKey="companytabs.title1" >
                                        <Text
                                            children="Sign up "
                                            {...getOverrideProps(overrides, "title")}
                                        ></Text>
                                    </Trans>
                                </View>
                                <View
                                    fontFamily="Lato"
                                    fontSize="16px"
                                    fontWeight="400"
                                    color="rgba(86,89,115,1)"
                                    lineHeight="25.600000381469727px"
                                    textAlign="center"
                                    display="block"
                                    direction="column"
                                    justifyContent="unset"
                                    width="unset"
                                    height="unset"
                                    gap="unset"
                                    alignItems="unset"
                                    shrink="0"
                                    alignSelf="stretch"
                                    position="relative"
                                    padding="0px 0px 0px 0px"
                                    whiteSpace="pre-wrap"
                                >
                                    <Trans i18nKey="investortabs.subtitle1" >
                                        <Text
                                            children="Register as a company and verify  your email adress"
                                            {...getOverrideProps(overrides, "subtitle")}
                                        ></Text>
                                    </Trans>
                                </View>
                            </Flex>
                            <View
                                width="80px"
                                height="80px"
                                display="block"
                                gap="unset"
                                alignItems="unset"
                                justifyContent="unset"
                                position="absolute"
                                top="0%"
                                bottom="61.17%"
                                left="33.33%"
                                right="33.33%"
                                padding="0px 0px 0px 0px"
                                {...getOverrideProps(overrides, "step")}
                            >
                                <Icon
                                    width="80px"
                                    height="80px"
                                    viewBox={{ minX: 0, minY: 0, width: 80, height: 80 }}
                                    paths={[
                                        {
                                            d: "M80 40C80 62.0914 62.0914 80 40 80C17.9086 80 0 62.0914 0 40C0 17.9086 17.9086 0 40 0C62.0914 0 80 17.9086 80 40Z",
                                            fill: "rgba(243,246,255,1)",
                                            fillRule: "nonzero",
                                        },
                                    ]}
                                    display="block"
                                    gap="unset"
                                    alignItems="unset"
                                    justifyContent="unset"
                                    position="absolute"
                                    top="0%"
                                    bottom="0%"
                                    left="0%"
                                    right="0%"
                                    {...getOverrideProps(overrides, "Ellipse 747")}
                                ></Icon>
                                <Icon
                                    width="56px"
                                    height="56px"
                                    viewBox={{ minX: 0, minY: 0, width: 56, height: 56 }}
                                    paths={[
                                        {
                                            d: "M56 28C56 43.464 43.464 56 28 56C12.536 56 0 43.464 0 28C0 12.536 12.536 0 28 0C43.464 0 56 12.536 56 28Z",
                                            fill: "rgba(255,255,255,1)",
                                            fillRule: "nonzero",
                                        },
                                    ]}
                                    display="block"
                                    gap="unset"
                                    alignItems="unset"
                                    justifyContent="unset"
                                    position="absolute"
                                    top="15%"
                                    bottom="15%"
                                    left="15%"
                                    right="15%"
                                    {...getOverrideProps(overrides, "Ellipse 748")}
                                ></Icon>
                                <Text
                                    fontFamily="Lato"
                                    fontSize="24px"
                                    fontWeight="800"
                                    color="rgba(11,15,25,1)"
                                    lineHeight="33.599998474121094px"
                                    textAlign="center"
                                    display="block"
                                    direction="column"
                                    justifyContent="unset"
                                    width="42px"
                                    height="unset"
                                    gap="unset"
                                    alignItems="unset"
                                    position="absolute"
                                    top="calc(50% - 17px - 0px)"
                                    left="calc(50% - 21px - 0px)"
                                    padding="0px 0px 0px 0px"
                                    whiteSpace="pre-wrap"
                                    children="1"
                                    {...getOverrideProps(overrides, "1")}
                                ></Text>
                            </View>
                        </View>

                    </div>
             
                    <div className='step2'>
                        <View
                            width="240px"
                            height="206px"
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="relative"
                            padding="0px 0px 0px 0px"
                            {...rest}
                            {...getOverrideProps(overrides, "StepTwo")}
                        >
                            <Flex
                                gap="16px"
                                direction="column"
                                width="240px"
                                height="unset"
                                justifyContent="flex-start"
                                alignItems="center"
                                position="absolute"
                                top="50.49%"
                                bottom="0%"
                                left="0%"
                                right="0%"
                                padding="0px 0px 0px 0px"
                                {...getOverrideProps(overrides, "text")}
                            >
                                <View
                                    fontFamily="Lato"
                                    fontSize="21px"
                                    fontWeight="800"
                                    color="rgba(11,15,25,1)"
                                    lineHeight="33.599998474121094px"
                                    textAlign="center"
                                    display="block"
                                    direction="column"
                                    justifyContent="unset"
                                    width="unset"
                                    height="unset"
                                    gap="unset"
                                    alignItems="unset"
                                    shrink="0"
                                    alignSelf="stretch"
                                    position="relative"
                                    padding="0px 0px 0px 0px"
                                    whiteSpace="pre-wrap"
                                >
                                    <Trans i18nKey="investortabs.title2" >
                                        <Text
                                            children="Upload invoice"
                                            {...getOverrideProps(overrides, "title")}
                                        ></Text>
                                    </Trans>
                                </View>
                                <View
                                    fontFamily="Lato"
                                    fontSize="16px"
                                    fontWeight="400"
                                    color="rgba(86,89,115,1)"
                                    lineHeight="25.600000381469727px"
                                    textAlign="center"
                                    display="block"
                                    direction="column"
                                    justifyContent="unset"
                                    width="unset"
                                    height="unset"
                                    gap="unset"
                                    alignItems="unset"
                                    shrink="0"
                                    alignSelf="stretch"
                                    position="relative"
                                    padding="0px 0px 0px 0px"
                                    whiteSpace="pre-wrap"
                                >
                                    <Trans i18nKey="investortabs.subtitle2" >
                                        <Text
                                            children="Upload one or more invoices and verify them afterwards"
                                            {...getOverrideProps(overrides, "subtitle")}
                                        ></Text>
                                    </Trans>
                                </View>
                            </Flex>
                            <View
                                width="80px"
                                height="80px"
                                display="block"
                                gap="unset"
                                alignItems="unset"
                                justifyContent="unset"
                                position="absolute"
                                top="0%"
                                bottom="61.17%"
                                left="33.33%"
                                right="33.33%"
                                padding="0px 0px 0px 0px"
                                {...getOverrideProps(overrides, "step")}
                            >
                                <Icon
                                    width="80px"
                                    height="80px"
                                    viewBox={{ minX: 0, minY: 0, width: 80, height: 80 }}
                                    paths={[
                                        {
                                            d: "M80 40C80 62.0914 62.0914 80 40 80C17.9086 80 0 62.0914 0 40C0 17.9086 17.9086 0 40 0C62.0914 0 80 17.9086 80 40Z",
                                            fill: "rgba(243,246,255,1)",
                                            fillRule: "nonzero",
                                        },
                                    ]}
                                    display="block"
                                    gap="unset"
                                    alignItems="unset"
                                    justifyContent="unset"
                                    position="absolute"
                                    top="0%"
                                    bottom="0%"
                                    left="0%"
                                    right="0%"
                                    {...getOverrideProps(overrides, "Ellipse 747")}
                                ></Icon>
                                <Icon
                                    width="56px"
                                    height="56px"
                                    viewBox={{ minX: 0, minY: 0, width: 56, height: 56 }}
                                    paths={[
                                        {
                                            d: "M56 28C56 43.464 43.464 56 28 56C12.536 56 0 43.464 0 28C0 12.536 12.536 0 28 0C43.464 0 56 12.536 56 28Z",
                                            fill: "rgba(255,255,255,1)",
                                            fillRule: "nonzero",
                                        },
                                    ]}
                                    display="block"
                                    gap="unset"
                                    alignItems="unset"
                                    justifyContent="unset"
                                    position="absolute"
                                    top="15%"
                                    bottom="15%"
                                    left="15%"
                                    right="15%"
                                    {...getOverrideProps(overrides, "Ellipse 748")}
                                ></Icon>
                                <Text
                                    fontFamily="Lato"
                                    fontSize="24px"
                                    fontWeight="800"
                                    color="rgba(11,15,25,1)"
                                    lineHeight="33.599998474121094px"
                                    textAlign="center"
                                    display="block"
                                    direction="column"
                                    justifyContent="unset"
                                    width="42px"
                                    height="unset"
                                    gap="unset"
                                    alignItems="unset"
                                    position="absolute"
                                    top="calc(50% - 17px - 0px)"
                                    left="calc(50% - 21px - 0px)"
                                    padding="0px 0px 0px 0px"
                                    whiteSpace="pre-wrap"
                                    children="2"
                                    {...getOverrideProps(overrides, "2")}
                                ></Text>
                            </View>
                        </View>
                    </div>
                    <div className='step3'>
                        <View
                            width="240px"
                            height="206px"
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="relative"
                            padding="0px 0px 0px 0px"
                            {...rest}
                            {...getOverrideProps(overrides, "StepThree")}
                        >
                            <Flex
                                gap="16px"
                                direction="column"
                                width="240px"
                                height="unset"
                                justifyContent="flex-start"
                                alignItems="center"
                                position="absolute"
                                top="50.49%"
                                bottom="0%"
                                left="0%"
                                right="0%"
                                padding="0px 0px 0px 0px"
                                {...getOverrideProps(overrides, "text")}
                            >
                                <View
                                    fontFamily="Lato"
                                    fontSize="21px"
                                    fontWeight="800"
                                    color="rgba(11,15,25,1)"
                                    lineHeight="33.599998474121094px"
                                    textAlign="center"
                                    display="block"
                                    direction="column"
                                    justifyContent="unset"
                                    width="unset"
                                    height="unset"
                                    gap="unset"
                                    alignItems="unset"
                                    shrink="0"
                                    alignSelf="stretch"
                                    position="relative"
                                    padding="0px 0px 0px 0px"
                                    whiteSpace="pre-wrap"
                                >
                                    <Trans i18nKey="investortabs.title3" >
                                        <Text
                                            children="Post listing"
                                            {...getOverrideProps(overrides, "title")}
                                        ></Text>
                                    </Trans>
                                </View>
                                <View
                                    fontFamily="Lato"
                                    fontSize="16px"
                                    fontWeight="400"
                                    color="rgba(86,89,115,1)"
                                    lineHeight="25.600000381469727px"
                                    textAlign="center"
                                    display="block"
                                    direction="column"
                                    justifyContent="unset"
                                    width="unset"
                                    height="unset"
                                    gap="unset"
                                    alignItems="unset"
                                    shrink="0"
                                    alignSelf="stretch"
                                    position="relative"
                                    padding="0px 0px 0px 0px"
                                    whiteSpace="pre-wrap"
                                >
                                    <Trans i18nKey="investortabs.subtitle3" >
                                        <Text
                                            children="Post account receivables and sell them to investors"
                                            {...getOverrideProps(overrides, "subtitle")}
                                        ></Text>
                                    </Trans>
                                </View>
                            </Flex>
                            <View
                                width="80px"
                                height="80px"
                                display="block"
                                gap="unset"
                                alignItems="unset"
                                justifyContent="unset"
                                position="absolute"
                                top="0%"
                                bottom="61.17%"
                                left="33.33%"
                                right="33.33%"
                                padding="0px 0px 0px 0px"
                                {...getOverrideProps(overrides, "step")}
                            >
                                <Icon
                                    width="80px"
                                    height="80px"
                                    viewBox={{ minX: 0, minY: 0, width: 80, height: 80 }}
                                    paths={[
                                        {
                                            d: "M80 40C80 62.0914 62.0914 80 40 80C17.9086 80 0 62.0914 0 40C0 17.9086 17.9086 0 40 0C62.0914 0 80 17.9086 80 40Z",
                                            fill: "rgba(243,246,255,1)",
                                            fillRule: "nonzero",
                                        },
                                    ]}
                                    display="block"
                                    gap="unset"
                                    alignItems="unset"
                                    justifyContent="unset"
                                    position="absolute"
                                    top="0%"
                                    bottom="0%"
                                    left="0%"
                                    right="0%"
                                    {...getOverrideProps(overrides, "Ellipse 747")}
                                ></Icon>
                                <Icon
                                    width="56px"
                                    height="56px"
                                    viewBox={{ minX: 0, minY: 0, width: 56, height: 56 }}
                                    paths={[
                                        {
                                            d: "M56 28C56 43.464 43.464 56 28 56C12.536 56 0 43.464 0 28C0 12.536 12.536 0 28 0C43.464 0 56 12.536 56 28Z",
                                            fill: "rgba(255,255,255,1)",
                                            fillRule: "nonzero",
                                        },
                                    ]}
                                    display="block"
                                    gap="unset"
                                    alignItems="unset"
                                    justifyContent="unset"
                                    position="absolute"
                                    top="15%"
                                    bottom="15%"
                                    left="15%"
                                    right="15%"
                                    {...getOverrideProps(overrides, "Ellipse 748")}
                                ></Icon>
                                <Text
                                    fontFamily="Lato"
                                    fontSize="24px"
                                    fontWeight="800"
                                    color="rgba(11,15,25,1)"
                                    lineHeight="33.599998474121094px"
                                    textAlign="center"
                                    display="block"
                                    direction="column"
                                    justifyContent="unset"
                                    width="42px"
                                    height="unset"
                                    gap="unset"
                                    alignItems="unset"
                                    position="absolute"
                                    top="28.75%"
                                    bottom="28.75%"
                                    left="23.75%"
                                    right="23.75%"
                                    padding="0px 0px 0px 0px"
                                    whiteSpace="pre-wrap"
                                    children="3"
                                    {...getOverrideProps(overrides, "3")}
                                ></Text>
                            </View>
                        </View>
                    </div>
                    <div className='step4'>
                        <View
                            width="240px"
                            height="206px"
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="relative"
                            padding="0px 0px 0px 0px"
                            {...rest}
                            {...getOverrideProps(overrides, "StepFour")}
                        >
                            <Flex
                                gap="16px"
                                direction="column"
                                width="240px"
                                height="unset"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                position="absolute"
                                top="50.49%"
                                bottom="0%"
                                left="0%"
                                right="0%"
                                padding="0px 0px 0px 0px"
                                {...getOverrideProps(overrides, "text")}
                            >
                                <View
                                    fontFamily="Lato"
                                    fontSize="21px"
                                    fontWeight="800"
                                    color="rgba(11,15,25,1)"
                                    lineHeight="33.599998474121094px"
                                    textAlign="center"
                                    display="block"
                                    direction="column"
                                    justifyContent="unset"
                                    width="unset"
                                    height="unset"
                                    gap="unset"
                                    alignItems="unset"
                                    shrink="0"
                                    alignSelf="stretch"
                                    position="relative"
                                    padding="0px 0px 0px 0px"
                                    whiteSpace="pre-wrap"
                                >
                                    <Trans i18nKey="investortabs.title4" >
                                        <Text
                                            children="Receive payment"
                                            {...getOverrideProps(overrides, "title")}
                                        ></Text>
                                    </Trans>
                                </View>
                                <View
                                    fontFamily="Lato"
                                    fontSize="16px"
                                    fontWeight="400"
                                    color="rgba(86,89,115,1)"
                                    lineHeight="25.600000381469727px"
                                    textAlign="center"
                                    display="block"
                                    direction="column"
                                    justifyContent="unset"
                                    height="unset"
                                    gap="unset"
                                    alignItems="unset"
                                    shrink="0"
                                    alignSelf="stretch"
                                    position="relative"
                                    padding="0px 0px 0px 0px"
                                    whiteSpace="pre-wrap"
                                >
                                    <Trans i18nKey="investortabs.subtitle4" >
                                        <Text
                                            width="max-content"
                                            children="Receive your selling price as instant payment"
                                            {...getOverrideProps(overrides, "subtitle")}
                                        ></Text>
                                    </Trans>
                                </View>
                            </Flex>
                            <View
                                width="80px"
                                height="80px"
                                display="block"
                                gap="unset"
                                alignItems="unset"
                                justifyContent="unset"
                                position="absolute"
                                top="0%"
                                bottom="61.17%"
                                left="33.33%"
                                right="33.33%"
                                padding="0px 0px 0px 0px"
                                {...getOverrideProps(overrides, "step")}
                            >
                                <Icon
                                    width="80px"
                                    height="80px"
                                    viewBox={{ minX: 0, minY: 0, width: 80, height: 80 }}
                                    paths={[
                                        {
                                            d: "M80 40C80 62.0914 62.0914 80 40 80C17.9086 80 0 62.0914 0 40C0 17.9086 17.9086 0 40 0C62.0914 0 80 17.9086 80 40Z",
                                            fill: "rgba(243,246,255,1)",
                                            fillRule: "nonzero",
                                        },
                                    ]}
                                    display="block"
                                    gap="unset"
                                    alignItems="unset"
                                    justifyContent="unset"
                                    position="absolute"
                                    top="0%"
                                    bottom="0%"
                                    left="0%"
                                    right="0%"
                                    {...getOverrideProps(overrides, "Ellipse 747")}
                                ></Icon>
                                <Icon
                                    width="56px"
                                    height="56px"
                                    viewBox={{ minX: 0, minY: 0, width: 56, height: 56 }}
                                    paths={[
                                        {
                                            d: "M56 28C56 43.464 43.464 56 28 56C12.536 56 0 43.464 0 28C0 12.536 12.536 0 28 0C43.464 0 56 12.536 56 28Z",
                                            fill: "rgba(255,255,255,1)",
                                            fillRule: "nonzero",
                                        },
                                    ]}
                                    display="block"
                                    gap="unset"
                                    alignItems="unset"
                                    justifyContent="unset"
                                    position="absolute"
                                    top="15%"
                                    bottom="15%"
                                    left="15%"
                                    right="15%"
                                    {...getOverrideProps(overrides, "Ellipse 748")}
                                ></Icon>
                                <Text
                                    fontFamily="Lato"
                                    fontSize="24px"
                                    fontWeight="800"
                                    color="rgba(11,15,25,1)"
                                    lineHeight="33.599998474121094px"
                                    textAlign="center"
                                    display="block"
                                    direction="column"
                                    justifyContent="unset"
                                    width="42px"
                                    height="unset"
                                    gap="unset"
                                    alignItems="unset"
                                    position="absolute"
                                    top="calc(50% - 17px - 0px)"
                                    left="calc(50% - 21px - 0px)"
                                    padding="0px 0px 0px 0px"
                                    whiteSpace="pre-wrap"
                                    children="4"
                                    {...getOverrideProps(overrides, "4")}
                                ></Text>
                            </View>
                        </View>
                    </div>
            </div>

            )} 
        </div>
    )
}
