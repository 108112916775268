import React, { useState } from "react";
import "./styles.scss";
import { ReactComponent as Eye } from "../assets/icons/eye.svg";
import { ReactComponent as EyeClosed } from "../assets/icons/eyeclosed.svg";

export default function Input({
  type,
  id,
  name,
  placeholder,
  label,
  value,
  extraClass,
  onChange,
  minLength,
  maxLength,
  error,
  text,
  min,
  max,
  onPaste,
  showPassword,
  title
}) {
  return (
    <div className={`input ${extraClass}`}>
      <label>{label}</label>
      {title && <p className="inputTitle">{title}</p>}
      <input
        id={id}
        name={name}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        maxLength={maxLength}
        minLength={minLength}
        min={min}
        max={max}
        onPaste={onPaste}
      ></input>
      <span>{text}</span>
      {error && <span className="input-error">{error}</span>}
    </div>
  );
}
