import React from 'react';
import styles from "./Card.module.scss";
import {ReactComponent as Arrow} from "../../../assets/icons/rightarrow.svg"
import { useTranslation } from 'react-i18next';

export default function Card({img, list=[], title, disabled}) {

  const { t } = useTranslation();
 
  return (
    <div className={styles.cardHolder}>
    <div className={styles.card}>
      <div className={disabled ? `${styles.disabledImg} ${styles.imgHolder}` : `${styles.imgHolder}`}>
      <img src={img} />
      </div>
      {disabled && (
        <>
        <h2 className={styles.disabled}>{t('investSection.soon')}</h2>
        </>
      )}
      <div className={disabled ? `${styles.disabledContent} ${styles.content}` : `${styles.content}`}>
        <h4>{title}</h4>
        <ul>
          {list.map((item) =>{
            return(
            <li key={item.id}>{item.text}</li>
            )
          })}
        </ul>
        <a>{t('industries.more')}<Arrow/> </a>
      </div>
      </div>
    </div>
  )
}
