import React, { useEffect, useState, useRef } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { createWaitlist } from "../graphql/mutations";
import Input from "./Input";
import Dropdown from "./Dropdown";
import Button from "./Button";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Checkbox } from "@chakra-ui/react";
import { Formik, useFormik } from "formik";
import { ReactComponent as Eye } from "../assets/icons/eye.svg";
import { ReactComponent as EyeClosed } from "../assets/icons/eyeclosed.svg";
import * as Yup from "yup";
import { ReactComponent as Arrow } from "../assets/icons/leftarrow.svg";
import { ReactComponent as Logo } from "../assets/icons/logo.svg";
import { Auth } from "aws-amplify";
import "./styles.scss";
import LoginPopup from "./Login/Login";

export default function SignUp(props) {
  const { t, i18n } = useTranslation();
  const togglePassword = (e) => {
    e.preventDefault();
    setPasswordShown(!passwordShown);
  };
  const toggleConfirmPassword = (e) => {
    e.preventDefault();
    setConfirmPasswordShown(!confirmPasswordShown);
  };
  const [isVerifyCode, setIsVerifyCode] = useState(false);
  const [isSignupSuccessful, setIsSignupSuccessful] = useState(false);
  const [username, setUsername] = useState("");
  const [popup, setPopup] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };
  const [userExists, setUserExists] = useState("");
  const navigate = useNavigate();
  const { overrides, ...rest } = props;
  const validate = (values) => {
    const errors = {};
    if (!values.firstName) {
      errors.firstName = t("signup.firstnameRequired");
    }
    if (!values.lastName) {
      errors.lastName = t("signup.lastnameRequired");
    }
    if (!values.companyName) {
      errors.companyName = t("signup.companynameRequired");
    }
    if (!values.phoneNumber) {
      errors.phoneNumber = t("signup.companynameRequired");
    }
    if (!values.jobTitle) {
      errors.jobTitle = t("signup.jobtitle");
    }
    if (!values.email) {
      errors.email = t("signup.emailRequired");
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = t("signup.emailInvalid");
    }
    if (!values.termsAccept) {
      errors.termsAccept = t("signup.termsAccept");
    }

    const passwordRegex = /^.{6,}$/;
    if (!passwordRegex.test(values.password)) {
      errors.password = t("signup.passwordInvalid");
    }

    if (!values.confirmPassword) {
      errors.confirmPassword = t("signup.confirmPasswordRequired");
    }

    if (values.password !== values.confirmPassword) {
      errors.confirmPassword = t("signup.passwordConfirm");
    }

    return errors;
  };

  const Schema = Yup.object().shape({
    password: Yup.string().required(t("signup.passwordRequired")),
    confirmPassword: Yup.string().when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        t("signup.passwordConfirm")
      ),
    }),
  });

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    jobTitle: "",
    companyName: "",
    country: "",
    businessType: "Only B2B",
    anyInfo: "",
    language: "",
    termsAccept: "",
    password: "",
    confirmPassword: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    // validationSchema: Schema,
    validate: validate,
    onSubmit: async (values) => {
      if (isVerifyCode) {
        console.log(`Values: ${values} Code: ${values.code}`);

        try {
          await Auth.confirmSignUp(username, values.code);

          const entry = {
            ...values,
            language: i18n.language,
          };
          delete entry["termsAccept"];
          delete entry["password"];
          delete entry["confirmPassword"];
          delete entry["code"];

          // await DataStore.save(new Waitlist(entry));
          // await API.graphql(graphqlOperation(createWaitlist, {input: entry}));
          await API.graphql({
            query: createWaitlist,
            variables: { input: entry },
            authMode: "AWS_IAM",
          });
          console.log("Saved Successfully!");

          // BUG: Reset the DropDown
          // formik.resetForm();
          navigate("/success");
          console.log("sign up confirmed!");

          setIsSignupSuccessful(true);
        } catch (error) {
          console.log("error confirming sign up", error);
          formik.setFieldError("code", t("signup.codeIncorrect"));
        }
      } else {
        //Submit form
        console.log(`Values: ${values} Email: ${values.email}`);
        const username = values.email;
        const password = values.password;
        try {
          const { user } = await Auth.signUp({
            username,
            password,
            attributes: {
              locale: i18n.language + "-company",
            },
          });
          console.log(user);
          setUsername(username);
          setIsVerifyCode(true);
        } catch (error) {
          if (error.name === "UsernameExistsException") {
            setUserExists(
              "The email address entered already exists in out database - try logging in or entering a different email address."
            );
          }
          console.log("error signing up:", error);
          // setUserExists(`${error.message}`);
          formik.setSubmitting(false);
        }
      }
    },
    validateOnChange: true,
    validateOnBlur: false,
  });

  const countries = [
    {
      id: 1,
      text: t("signup.dropdown1"),
    },
    {
      id: 2,
      text: t("signup.dropdown2"),
    },
    {
      id: 3,
      text: t("signup.dropdown3"),
    },
  ];

  const businessTypes = [
    {
      id: 1,
      text: "Only B2B",
    },
    {
      id: 2,
      text: ">50% B2B",
    },
    {
      id: 3,
      text: "<50% B2B",
    },
    {
      id: 4,
      text: "Only B2C",
    },
  ];
  const handleVerificationCode = (e) => {
    formik.setFieldValue("code", e.target.value.replaceAll(" ", ""));
  };

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        document.getElementById("loginBtnnn").click();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);
  return (
    <>
      {isSignupSuccessful ? (
        <h3>Signup Successful</h3>
      ) : (
        <>
          <form onSubmit={formik.handleSubmit}>
            <div className="signupWrapper">
              <div className="signupInputs">
                {!isVerifyCode && (
                  <>
                    <button
                      className="goBack"
                      onClick={() => navigate("/company")}
                    >
                      <Arrow /> {t("navbar.signup")}
                    </button>
                    <div className="navSignup">
                      <Logo />
                      <ul>
                        <li onClick={() => changeLanguage("de")}>DE</li>
                        <li>|</li>
                        <li onClick={() => changeLanguage("en")}>EN</li>
                      </ul>
                    </div>
                    <div className="allInputs">
                      <h1>
                        <Trans i18nKey="signup.title"></Trans>
                        <br /> <Trans i18nKey="signup.title1"></Trans>
                      </h1>
                      <div className="rowInputs">
                        <div>
                          <Input
                            type="text"
                            label={t("signup.firstname")}
                            placeholder={t("placeholder.firstname")}
                            id="firstName"
                            name="firstName"
                            onChange={formik.handleChange}
                            value={formik.values.firstName}
                          ></Input>
                          {formik.errors.firstName &&
                            formik.touched.firstName && (
                              <span className="error">
                                <Trans i18nKey="signup.firstnameRequired">
                                  {formik.errors.firstName}
                                </Trans>
                              </span>
                            )}
                        </div>
                        <div>
                          <Input
                            type="text"
                            label={t("signup.lastname")}
                            placeholder={t("placeholder.lastname")}
                            id="lastName"
                            name="lastName"
                            onChange={formik.handleChange}
                            value={formik.values.lastName}
                            tabIndex="2"
                          />
                          {formik.errors.lastName &&
                            formik.touched.lastName && (
                              <span className="error">
                                <Trans i18nKey="signup.lastnameRequired">
                                  {formik.errors.lastName}
                                </Trans>
                              </span>
                            )}
                        </div>
                      </div>
                      <div className="rowInputs">
                        <div>
                          <Input
                            label="Email"
                            type="text"
                            placeholder={t("placeholder.email")}
                            id="email"
                            name="email"
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            tabIndex="3"
                          />
                          {formik.errors.email && formik.touched.email && (
                            <span className="error">
                              <Trans i18nKey="signup.emailRequired">
                                {formik.errors.email}
                              </Trans>
                            </span>
                          )}
                        </div>
                        <div>
                          <Input
                            label={t("signup.number")}
                            type="text"
                            placeholder={t("placeholder.phone")}
                            id="phoneNumber"
                            name="phoneNumber"
                            onChange={formik.handleChange}
                            value={formik.values.phoneNumber}
                            tabIndex="4"
                          />
                            {formik.errors.phoneNumber && formik.touched.phoneNumber && (
                            <span className="error">
                              <Trans i18nKey="signup.phone">
                                {formik.errors.phoneNumber}
                              </Trans>
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="rowInputs">
                        <div>
                          <Input
                            label={t("signup.job")}
                            type="text"
                            placeholder={t("placeholder.job")}
                            id="jobTitle"
                            name="jobTitle"
                            onChange={formik.handleChange}
                            value={formik.values.jobTitle}
                            tabIndex="5"
                          />
                           {formik.errors.jobTitle && formik.touched.jobTitle && (
                            <span className="error">
                              <Trans i18nKey="signup.jobtitle">
                                {formik.errors.jobTitle}
                              </Trans>
                            </span>
                          )}
                        </div>
                        <div>
                          <Input
                            label={t("signup.company")}
                            type="text"
                            placeholder={t("placeholder.company")}
                            id="companyName"
                            name="companyName"
                            onChange={formik.handleChange}
                            value={formik.values.companyName}
                            tabIndex="6"
                          />
                          {formik.errors.companyName &&
                            formik.touched.companyName && (
                              <span className="error">
                                <Trans i18nKey="signup.companynameRequired">
                                  {formik.errors.companyName}
                                </Trans>
                              </span>
                            )}
                        </div>
                      </div>
                      <div className="signupDropdown" tabIndex="7">
                        <Dropdown
                          autoFocus
                          label={t("signup.country")}
                          list={countries}
                          onSelect={(v) => formik.setFieldValue("country", v)}
                          tabIndex="7"
                        />
                      </div>
                      <div className="signupDropdown">
                        <Dropdown
                          autoFocus
                          label={t("signup.business")}
                          list={businessTypes}
                          onSelect={(v) =>
                            formik.setFieldValue("businessType", v)
                          }
                        />
                      </div>
                      <div className="passwordHolder">
                        <Input
                          label={t("signup.password")}
                          placeholder={t("placeholder.password")}
                          type={passwordShown ? "text" : "password"}
                          id="Password"
                          name="password"
                          onChange={formik.handleChange}
                          value={formik.values.password}
                        />
                        <span
                          className="showPassword"
                          onClick={(e) => togglePassword(e)}
                        >
                          {passwordShown ? <Eye /> : <EyeClosed />}
                        </span>
                        {formik.errors.password && formik.touched.password && (
                          <span className="error">
                            <Trans i18nKey="signup.passwordRequired">
                              {formik.errors.password}
                            </Trans>
                          </span>
                        )}
                      </div>
                      <div className="passwordHolder">
                        <Input
                          label={t("signup.confirm")}
                          placeholder={t("placeholder.password")}
                          type={confirmPasswordShown ? "text" : "password"}
                          id="confirmPassword"
                          name="confirmPassword"
                          onChange={formik.handleChange}
                          value={formik.values.confirmPassword}
                        />
                        <span
                          className="showPassword"
                          onClick={(e) => toggleConfirmPassword(e)}
                        >
                          {confirmPasswordShown ? <Eye /> : <EyeClosed />}
                        </span>
                        {formik.errors.confirmPassword &&
                          formik.touched.confirmPassword && (
                            <span className="error">
                              <Trans i18nKey="signup.passwordConfirm">
                                {formik.errors.confirmPassword}
                              </Trans>
                            </span>
                          )}
                        {userExists && (
                          <div className="error">{userExists}</div>
                        )}
                      </div>
                      <div className="rowInputs checkboxText">
                        <div className="checkboxHolder">
                          <Checkbox
                            width={20}
                            height={20}
                            style={{
                              border: "2px solid #B4B7C9",
                              fontSize: "12px",
                              borderRadius: "2px",
                              color: "#2B3677",
                              display: "flex",
                              alignItems: "center",
                            }}
                            colorScheme="red"
                            onChange={(el) =>
                              formik.setFieldValue(
                                "termsAccept",
                                el.target.checked
                              )
                            }
                          ></Checkbox>
                          <p>
                          {t("signup.text1")} <a href="/privacy-policy" style={{textDecoration: "underline", color: "#555a"}}>{t("signup.link1")}</a> {t("signup.text2")} <a href="/terms-conditions" style={{textDecoration: "underline", color:"#555a"}}>{t("signup.link2")}</a> {t("signup.text3")}
                          </p>
                        </div>
                        {formik.errors.termsAccept &&
                          formik.touched.termsAccept && (
                            <span className="error">
                              <Trans i18nKey="signup.termsAccept">
                                {formik.errors.termsAccept}
                              </Trans>
                            </span>
                          )}
                      </div>
                      <Button
                        color="white"
                        bgColor="#2B3677"
                        width="300px"
                        type="submit"
                        id="loginBtnnn"
                      >
                        <Trans i18nKey="signup.submit">Submit</Trans>
                      </Button>
                      <p className="alreadyacc">{t("placeholder.acc")} <a onClick={() => setPopup(true)}>{t("placeholder.btn")}</a> </p>
                    </div>
                  </>
                )}
                {isVerifyCode && (
                  <>
                   <button
                      className="goBack"
                      onClick={() => setIsVerifyCode(false)}
                    >
                      <Arrow /> {t("signup.back")}
                    </button>
                  <div className="verify">
                    <Input
                      title={t("signup.verify")}
                      placeholder={t("login.verify")}
                      extraClass="fullWidth"
                      label={t("login.verify")}
                      type="code"
                      name="code"
                      // onChange={formik.handleChange}
                      onChange={(e) => handleVerificationCode(e)}
                      value={formik.values.code}
                    />
                    {formik.errors.code && formik.touched.code && (
                      <span className="error">
                        <Trans i18nKey="signup.codeIncorrect">
                          {formik.errors.code}
                        </Trans>
                      </span>
                    )}
                    <Button
                      color="white"
                      bgColor="#2B3677"
                      width="300px"
                      type="submit"
                      id="loginBtnnn"
                    >
                     {t("login.verifybtn")}
                    </Button>
                  </div>
                  </>
                )}
              </div>
              {/* <br/> */}
              <div className="signupBackground"></div>
            </div>
          </form>
          {popup && (
        <div className="loginn">
          <div onClick={() => setPopup(false)} className="shades"></div>
          <LoginPopup to="/company-signup" onClick={() => setPopup(false)} />
        </div>
      )}
        </>
      )}
    </>
  );
}
