// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Newsletter, Waitlist, Invoice } = initSchema(schema);

export {
  Newsletter,
  Waitlist,
  Invoice
};