import React, { useState, useEffect } from 'react';
import styles from "./Invest.module.scss";
import vector from "../../../assets/images/invest1.gif";
import wrapper from "../../../assets/images/GifWrapper.png";
import { ReactComponent as Star } from "../../../assets/icons/star.svg";
import { ReactComponent as Revenue } from "../../../assets/icons/revenue.svg";
import { ReactComponent as Factoring } from "../../../assets/icons/factoring.svg";
import { ReactComponent as Estate } from "../../../assets/icons/estate.svg";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useTranslation } from 'react-i18next';

export default function Invest() {

    const { t } = useTranslation();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767 ? true : false);
    const [sliderSettings, setSliderSettings] = useState({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    });

    useEffect(() => {
        function handleResize() {
            const newIsMobile = window.innerWidth <= 767 ? true : false;
            setIsMobile(newIsMobile);

            if (newIsMobile) {
                setSliderSettings({
                    dots: true,
                    infinite: true,
                    speed: 500,
                    slidesToShow: 1,
                    slidesToScroll: 1
                });
            } else {
                setSliderSettings({
                    dots: false,
                    infinite: true,
                    speed: 500,
                    slidesToShow: 2,
                    slidesToScroll: 2
                });
            }
        }

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div className={styles.invest}>
            <div className={styles.investHeader}>
                <h1>{t('investSection.mainTitle')}</h1>
                <p>{t('investSection.mainDesc')}</p>
            </div>
            <div className={styles.investContent}>
                {/* <img src={vector} /> */}
                {isMobile ? (
                    <>
                        <div className={styles.imgHolder}>
                            <img className={styles.gifWrapper} src={wrapper} />
                            <img className={styles.vector} src={vector} />
                        </div>
                        <Slider {...sliderSettings}>

                            <div className={`${styles.iconText} ${styles.moveRight}`}>
                                <Factoring />
                                <p>Factoring</p>
                                <span>{t('investSection.desc1')}</span>
                            </div>
                            <div className={`${styles.iconText} ${styles.moveLeft} `}>
                                <Revenue />
                                <p>{t('investSection.title2')}</p>
                                <span>{t('investSection.desc2')}</span>
                            </div>
                            <div className={`${styles.iconText} ${styles.disabled}  ${styles.remove}`}>
                                <Star />
                                <p>{t('investSection.title3')}<br /> ({t('investSection.soon')})</p>
                                <span>{t('investSection.desc3')}</span>
                            </div>
                            <div className={`${styles.iconText} ${styles.disabled}`}>
                                <Estate />
                                <p>Leasing<br />  ({t('investSection.soon')})</p>
                                <span>{t('investSection.desc4')}</span>
                            </div>
                        </Slider>
                    </>
                ) : (
                    <>
                        <div className={`${styles.wrapper} ${styles.withMargin}`}>
                            <div className={`${styles.iconText} ${styles.moveRight}  ${styles.first}`}>
                                <Factoring />
                                <p>Factoring</p>
                                <span>{t('investSection.desc1')}</span>
                            </div>
                            <div className={`${styles.iconText} ${styles.moveLeft} ${styles.second}`}>
                                <Revenue />
                                <p>{t('investSection.title2')}</p>
                                <span>{t('investSection.desc2')}</span>
                            </div>
                        </div>
                        <div className={styles.imgHolder}>
                            <img className={styles.gifWrapper} src={wrapper} />
                            <img className={styles.vector} src={vector} />
                        </div>
                        <div className={styles.wrapper}>
                            <div className={`${styles.iconText} ${styles.disabled}  ${styles.remove} ${styles.third}`}>
                                <Star />
                                <p>{t('investSection.title3')}<br /> ({t('investSection.soon')})</p>
                                <span>{t('investSection.desc3')}</span>
                            </div>
                            <div className={`${styles.iconText} ${styles.disabled} ${styles.fourth}`}>
                                <Estate />
                                <p>Leasing <br /> ({t('investSection.soon')})</p>
                                <span>{t('investSection.desc4')}</span>
                            </div>
                        </div>
                    </>
                )
                }
            </div>
        </div>
    )
}
