import React, { useState, useRef } from "react";
import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";
export default function Dropdown({
  label,
  list = [],
  onSelect = null,
  add,
  clickEvent,
  accountList,
  setSelectedAcc,
}) {
  const [dropdown, setDropdown] = useState(false);
  const [state, setState] = useState(null); 
  const { t } = useTranslation();
  const dropdownRef = useRef(null);

  function setSelection(item) {
    setState(item);
    setDropdown(false); 
    if (onSelect) {
      onSelect(item.text);
    }
  }

  return (
    <div className="dropdownContainer">
      <label>{label}</label>
      <div className="dropdown" onClick={() => setDropdown(!dropdown)} ref={dropdownRef} tabIndex="0">
        <p>{state ? state.text :`${t("placeholder.country")}` }</p> 

        {dropdown && (
          <div className="options">
            {list?.map((item) => {
              const isSelected = item.id === state?.id;
              return (
                
                <p
                  key={item.id}
                  onClick={() => setSelection(item)}
                  style={{ color: isSelected ? "#2B3677" : "#979797" }}
                >
                  {item.text}
                  {add && <p onClick={clickEvent}>Add New Recipient</p>}
                  {accountList?.map((acc) => (
                    <p onClick={() => setSelectedAcc(acc?.company)}>
                      {acc?.company && acc?.company}
                    </p>
                  ))}
                </p>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
