import React from 'react'; 
import CompanyPage from '../organisms/CompanyPage';

export default function Company() {
  return (
    <div>
      <CompanyPage />
    </div>
  )
}
