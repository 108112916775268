import React from 'react';
import TextImage from '../../atoms/TextImage/TextImage';
import styles from "./CompanySection.module.scss";
import img from "../../../assets/images/companyimg.png";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function CompanySection() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    return (
        <div>
            <TextImage clickEvent={() => navigate("/company-signup")} button text={t('mission.btn')} bgColor="#2B3677" extraClass="companySection" image={img}>
                <div className={styles.content}>
                    <h3>{t('investors.subtitle')}</h3>
                  <p>{t('investors.description')}</p>
                  <div className={styles.textHolder}>
                  {/* <div className={styles.smallText}>
                    <h1>7,3%</h1>
                    <span>Average APY</span>
                  </div> */}
                  {/* <div className={styles.smallText}>
                    <h1>132b</h1>
                    <span>Expected Volume per year</span>
                  </div> */}
                  </div>
                </div>
            </TextImage>
        </div>
    )
}
