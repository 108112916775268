/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import Button from "./Button";
import {
  Flex,
  Icon,
  Image,
  Text,
  View,
  useBreakpointValue,
} from "@aws-amplify/ui-react";
import dashboard from "../assets/images/companydashboard.png";
import { useNavigate } from "react-router-dom";
import { Trans } from "react-i18next";
export default function ForCompanies(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const navigate = useNavigate();
  const variants = [
    {
      overrides: {
        bg: {},
        Vector8502685: {},
        "icons / bx-check8502684": {},
        subtitle8502686: {},
        Argument8502683: {},
        Vector8502689: {},
        "icons / bx-check8502688": {},
        subtitle8502690: {},
        Argument8502687: {},
        Vector8502693: {},
        "icons / bx-check8502692": {},
        subtitle8502694: {},
        Argument8502691: {},
        Vector8502697: {},
        "icons / bx-check8502696": {},
        subtitle8502698: {},
        Argument8502695: {},
        Vector8502701: {},
        "icons / bx-check8502700": {},
        subtitle8502702: {},
        Argument8502699: {},
        Vector8502705: {},
        "icons / bx-check8502704": {},
        subtitle8502706: {},
        Argument8502703: {},
        items: {},
        title: {},
        btn: {},
        Content: {},
        "Company Dashboard 1": {},
        ForCompanies: {},
      },
      variantValues: { breakpoint: "large" },
    },
    {
      overrides: {
        bg: { width: "100%", height: "898px" },
        Vector8502685: {
          width: "11.94px",
          height: "9.21px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 11.9365234375,
            height: 9.20947265625,
          },
          paths: [
            {
              d: "M3.89798 6.86764L1.17097 4.14062L0 5.31159L3.89798 9.20958L11.9366 1.17097L10.7656 0L3.89798 6.86764Z",
              fill: "rgba(43,54,119,1)",
              fillRule: "nonzero",
            },
          ],
        },
        "icons / bx-check8502684": { width: "19.88px", height: "19.88px" },
        subtitle8502686: {
          fontSize: "13.25px",
          lineHeight: "21.200000762939453px",
        },
        Argument8502683: {
          gap: "4.140625px",
          width: "191.3px",
          left: "calc(50% - 95.65px - 222.35px)",
        },
        Vector8502689: {
          width: "11.94px",
          height: "9.21px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 11.9365234375,
            height: 9.20947265625,
          },
          paths: [
            {
              d: "M3.89798 6.86764L1.17097 4.14062L0 5.31159L3.89798 9.20958L11.9366 1.17097L10.7656 0L3.89798 6.86764Z",
              fill: "rgba(43,54,119,1)",
              fillRule: "nonzero",
            },
          ],
        },
        "icons / bx-check8502688": { width: "19.88px", height: "19.88px" },
        subtitle8502690: { fontSize: "16.5625px", lineHeight: "26.5px" },
        Argument8502687: {
          gap: "4.140625px",
          width: "179.7px",
          left: "calc(50% - 89.85px - 28.57px)",
        },
        Vector8502693: {
          width: "11.94px",
          height: "9.21px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 11.9365234375,
            height: 9.20947265625,
          },
          paths: [
            {
              d: "M3.89798 6.86764L1.17097 4.14062L0 5.31159L3.89798 9.20958L11.9366 1.17097L10.7656 0L3.89798 6.86764Z",
              fill: "rgba(43,54,119,1)",
              fillRule: "nonzero",
            },
          ],
        },
        "icons / bx-check8502692": { width: "19.88px", height: "19.88px" },
        subtitle8502694: { fontSize: "16.5625px", lineHeight: "26.5px" },
        Argument8502691: {
          gap: "4.140625px",
          width: "245.95px",
          left: "calc(50% - 122.98px - -192.54px)",
        },
        Vector8502697: {
          width: "11.94px",
          height: "9.21px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 11.9365234375,
            height: 9.20947265625,
          },
          paths: [
            {
              d: "M3.89798 6.86764L1.17097 4.14062L0 5.31159L3.89798 9.20958L11.9366 1.17097L10.7656 0L3.89798 6.86764Z",
              fill: "rgba(43,54,119,1)",
              fillRule: "nonzero",
            },
          ],
        },
        "icons / bx-check8502696": { width: "19.88px", height: "19.88px" },
        subtitle8502698: { fontSize: "16.5625px", lineHeight: "26.5px" },
        Argument8502695: {
          gap: "4.140625px",
          width: "186.33px",
          top: "32.3px",
          left: "calc(50% - 93.16px - 224.84px)",
        },
        Vector8502701: {
          width: "11.94px",
          height: "9.21px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 11.9365234375,
            height: 9.20947265625,
          },
          paths: [
            {
              d: "M3.89798 6.86764L1.17097 4.14062L0 5.31159L3.89798 9.20958L11.9366 1.17097L10.7656 0L3.89798 6.86764Z",
              fill: "rgba(43,54,119,1)",
              fillRule: "nonzero",
            },
          ],
        },
        "icons / bx-check8502700": { width: "19.88px", height: "19.88px" },
        subtitle8502702: { fontSize: "16.5625px", lineHeight: "26.5px" },
        Argument8502699: {
          gap: "4.140625px",
          width: "229.39px",
          top: "32.3px",
          left: "calc(50% - 114.7px - 8.7px)",
        },
        Vector8502705: {
          width: "11.94px",
          height: "9.21px",
          viewBox: {
            minX: 0,
            minY: 0,
            width: 11.9365234375,
            height: 9.20947265625,
          },
          paths: [
            {
              d: "M3.89798 6.86764L1.17097 4.14062L0 5.31159L3.89798 9.20958L11.9366 1.17097L10.7656 0L3.89798 6.86764Z",
              fill: "rgba(43,54,119,1)",
              fillRule: "nonzero",
            },
          ],
        },
        "icons / bx-check8502704": { width: "19.88px", height: "19.88px" },
        subtitle8502706: { fontSize: "16.5625px", lineHeight: "26.5px" },
        Argument8502703: {
          gap: "4.140625px",
          width: "203.72px",
          top: "32.3px",
          left: "calc(50% - 101.86px - -216.14px)",
        },
        items: { width: "636px", height: "58.8px", top: "189px", left: "67px" },
        title: {
          fontSize: "30px",
          lineHeight: "39px",
          width: "386px",
          left: "calc(50% - 193px - 0px)",
          children: "For Companies\nTurn outstanding invoices into money fast.",
        },
        btn: { top: "810px" },
        Content: { width: "100%", height: "898px" },
        "Company Dashboard 1": {
          width: "704px",
          height: "492.43px",
          top: "287px",
          left: "calc(50% - 352px - 0px)",
        },
        ForCompanies: { width: "770px", height: "898px" },
      },
      variantValues: { breakpoint: "medium" },
    },
    {
      overrides: {
        bg: { width: "360px", height: "791px" },
        Vector8502685: {
          viewBox: {
            minX: 0,
            minY: 0,
            width: 14.4140625,
            height: 11.1209716796875,
          },
        },
        "icons / bx-check8502684": {},
        subtitle8502686: {},
        Argument8502683: { left: "calc(50% - 115.5px - 33px)" },
        Vector8502689: {
          viewBox: {
            minX: 0,
            minY: 0,
            width: 14.4140625,
            height: 11.1209716796875,
          },
        },
        "icons / bx-check8502688": {},
        subtitle8502690: {},
        Argument8502687: { top: "36px", left: "calc(50% - 108.5px - 40px)" },
        Vector8502693: {
          viewBox: {
            minX: 0,
            minY: 0,
            width: 14.4140625,
            height: 11.1209716796875,
          },
        },
        "icons / bx-check8502692": {},
        subtitle8502694: {},
        Argument8502691: { top: "72px", left: "calc(50% - 148.5px - 2px)" },
        Vector8502697: {
          viewBox: {
            minX: 0,
            minY: 0,
            width: 14.4140625,
            height: 11.1209716796875,
          },
        },
        "icons / bx-check8502696": {},
        subtitle8502698: {},
        Argument8502695: { top: "108px", left: "calc(50% - 112.5px - 36px)" },
        Vector8502701: {
          viewBox: {
            minX: 0,
            minY: 0,
            width: 14.4140625,
            height: 11.1209716796875,
          },
        },
        "icons / bx-check8502700": {},
        subtitle8502702: {},
        Argument8502699: { top: "144px", left: "calc(50% - 138.5px - 10px)" },
        Vector8502705: {
          viewBox: {
            minX: 0,
            minY: 0,
            width: 14.4140625,
            height: 11.1209716796875,
          },
        },
        "icons / bx-check8502704": {},
        subtitle8502706: {},
        Argument8502703: { top: "180px", left: "calc(50% - 123px - 35.5px)" },
        items: { width: "297px", height: "212px", top: "189px", left: "32px" },
        title: {
          fontSize: "28px",
          lineHeight: "36.39999771118164px",
          width: "312px",
          left: "calc(50% - 156px - 0px)",
          children: "For Companies\nTurn outstanding invoices into money fast.",
        },
        btn: { top: "704px" },
        Content: { width: "360px", height: "791px" },
        "Company Dashboard 1": {
          width: "326px",
          height: "228.03px",
          top: "437px",
          left: "calc(50% - 163px - 0px)",
        },
        ForCompanies: { width: "360px", height: "791px" },
      },
      variantValues: { breakpoint: "small" },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "small",
    small: "small",
    medium: "medium",
    large: "large",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <div id="companies">
      <View
        className="companiesWrapper"
        width="1920px"
        height="1286px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="relative"
        padding="0px 0px 0px 0px"
        {...rest}
        {...getOverrideProps(overrides, "ForCompanies")}
      >
        <View
          padding="0px 0px 0px 0px"
          width="1920px"
          height="1286px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0px"
          left="0px"
          {...getOverrideProps(overrides, "Content")}
        >
          <View
            className="backCompanies"
            width="1920px"
            height="1286px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0px"
            bottom="0px"
            left="0px"
            right="0px"
            padding="0px 0px 0px 0px"
            backgroundColor="rgba(97,139,232,0.3)"
            {...getOverrideProps(overrides, "bg")}
          ></View>
          <View
            width="768px"
            height="71px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="218px"
            left="577px"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "items")}
          >
            <Flex
              className="text text1"
              gap="5px"
              direction="row"
              width="231px"
              height="unset"
              justifyContent="center"
              alignItems="center"
              position="absolute"
              top="0px"
              left="calc(38% - 115.5px - 268.5px)"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Argument8502683")}
            >
              <View
                width="24px"
                height="24px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "icons / bx-check8502684")}
              >
                <Icon
                  width="14.41px"
                  height="11.12px"
                  viewBox={{
                    minX: 0,
                    minY: 0,
                    width: 14.4140625,
                    height: 11.120999336242676,
                  }}
                  paths={[
                    {
                      d: "M4.707 8.293L1.414 5L0 6.414L4.707 11.121L14.414 1.414L13 0L4.707 8.293Z",
                      fill: "rgba(43,54,119,1)",
                      fillRule: "nonzero",
                    },
                  ]}
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="30.39%"
                  bottom="23.28%"
                  left="22.05%"
                  right="17.89%"
                  {...getOverrideProps(overrides, "Vector8502685")}
                ></Icon>
              </View>
              <View
                fontFamily="Lato"
                fontSize="20px"
                fontWeight="400"
                color="rgba(86,89,115,1)"
                lineHeight="32px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
              >
                <Trans i18nKey="company.item1">
                  <Text
                    children="Increase your liquidity"
                    {...getOverrideProps(overrides, "subtitle8502686")}
                  ></Text>
                </Trans>
              </View>
            </Flex>
            <Flex
              className="text text2"
              gap="5px"
              direction="row"
              width="217px"
              height="unset"
              justifyContent="center"
              alignItems="center"
              position="absolute"
              top="0px"
              left="calc(50% - 108.5px - 34.5px)"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Argument8502687")}
            >
              <View
                width="24px"
                height="24px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "icons / bx-check8502688")}
              >
                <Icon
                  width="14.41px"
                  height="11.12px"
                  viewBox={{
                    minX: 0,
                    minY: 0,
                    width: 14.4140625,
                    height: 11.120999336242676,
                  }}
                  paths={[
                    {
                      d: "M4.707 8.293L1.414 5L0 6.414L4.707 11.121L14.414 1.414L13 0L4.707 8.293Z",
                      fill: "rgba(43,54,119,1)",
                      fillRule: "nonzero",
                    },
                  ]}
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="30.39%"
                  bottom="23.28%"
                  left="22.05%"
                  right="17.89%"
                  {...getOverrideProps(overrides, "Vector8502689")}
                ></Icon>
              </View>
              <View
                fontFamily="Lato"
                fontSize="20px"
                fontWeight="400"
                color="rgba(86,89,115,1)"
                lineHeight="32px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
              >
                <Trans i18nKey="company.item2">
                  <Text
                    children="Minimize default risk"
                    {...getOverrideProps(overrides, "subtitle8502690")}
                  ></Text>
                </Trans>
              </View>
            </Flex>
            <Flex
              className="text text3"
              gap="5px"
              direction="row"
              width="297px"
              height="unset"
              justifyContent="center"
              alignItems="center"
              position="absolute"
              top="0px"
              left="calc(65% - 148.5px - -232.5px)"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Argument8502691")}
            >
              <View
                width="24px"
                height="24px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "icons / bx-check8502692")}
              >
                <Icon
                  width="14.41px"
                  height="11.12px"
                  viewBox={{
                    minX: 0,
                    minY: 0,
                    width: 14.4140625,
                    height: 11.120999336242676,
                  }}
                  paths={[
                    {
                      d: "M4.707 8.293L1.414 5L0 6.414L4.707 11.121L14.414 1.414L13 0L4.707 8.293Z",
                      fill: "rgba(43,54,119,1)",
                      fillRule: "nonzero",
                    },
                  ]}
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="30.39%"
                  bottom="23.28%"
                  left="22.05%"
                  right="17.89%"
                  {...getOverrideProps(overrides, "Vector8502693")}
                ></Icon>
              </View>
              <View
                fontFamily="Lato"
                fontSize="20px"
                fontWeight="400"
                color="rgba(86,89,115,1)"
                lineHeight="32px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
              >
                <Trans i18nKey="company.item3" >
                  <Text
                    children="Access to numerous investors"
                    {...getOverrideProps(overrides, "subtitle8502694")}
                  ></Text>
                </Trans>
              </View>
            </Flex>
            <Flex
              className="text text4"
              gap="5px"
              direction="row"
              width="225px"
              height="unset"
              justifyContent="center"
              alignItems="center"
              position="absolute"
              top="39px"
              left="calc(43% - 112.5px - 271.5px)"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Argument8502695")}
            >
              <View
                width="24px"
                height="24px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "icons / bx-check8502696")}
              >
                <Icon
                  width="14.41px"
                  height="11.12px"
                  viewBox={{
                    minX: 0,
                    minY: 0,
                    width: 14.4140625,
                    height: 11.12099838256836,
                  }}
                  paths={[
                    {
                      d: "M4.707 8.293L1.414 5L0 6.414L4.707 11.121L14.414 1.414L13 0L4.707 8.293Z",
                      fill: "rgba(43,54,119,1)",
                      fillRule: "nonzero",
                    },
                  ]}
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="30.39%"
                  bottom="23.28%"
                  left="22.05%"
                  right="17.89%"
                  {...getOverrideProps(overrides, "Vector8502697")}
                ></Icon>
              </View>
              <View
                fontFamily="Lato"
                fontSize="20px"
                fontWeight="400"
                color="rgba(86,89,115,1)"
                lineHeight="32px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
              >
                <Trans i18nKey="company.item4">
                  <Text
                    children="Fast transaction times"
                    {...getOverrideProps(overrides, "subtitle8502698")}
                  ></Text>
                </Trans>
              </View>
            </Flex>
            <Flex
              className="text text6"
              gap="5px"
              direction="row"
              width="277px"
              height="unset"
              justifyContent="center"
              alignItems="center"
              position="absolute"
              top="39px"
              left="calc(50% - 138.5px - 10.5px)"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Argument8502699")}
            >
              <View
                width="24px"
                height="24px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "icons / bx-check8502700")}
              >
                <Icon
                  width="14.41px"
                  height="11.12px"
                  viewBox={{
                    minX: 0,
                    minY: 0,
                    width: 14.4140625,
                    height: 11.12099838256836,
                  }}
                  paths={[
                    {
                      d: "M4.707 8.293L1.414 5L0 6.414L4.707 11.121L14.414 1.414L13 0L4.707 8.293Z",
                      fill: "rgba(43,54,119,1)",
                      fillRule: "nonzero",
                    },
                  ]}
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="30.39%"
                  bottom="23.28%"
                  left="22.05%"
                  right="17.89%"
                  {...getOverrideProps(overrides, "Vector8502701")}
                ></Icon>
              </View>
              <View
                fontFamily="Lato"
                fontSize="20px"
                fontWeight="400"
                color="rgba(86,89,115,1)"
                lineHeight="32px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
              >
                <Trans i18nKey="company.item5" >
                  <Text
                    children="Transparent price discovery"
                    {...getOverrideProps(overrides, "subtitle8502702")}
                  ></Text>
                </Trans>
              </View>
            </Flex>
            <Flex
              className="text text5"
              gap="5px"
              direction="row"
              width="246px"
              height="unset"
              justifyContent="center"
              alignItems="center"
              position="absolute"
              top="39px"
              left="calc(62% - 123px - -261px)"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Argument8502703")}
            >
              <View
                width="24px"
                height="24px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "icons / bx-check8502704")}
              >
                <Icon
                  width="14.41px"
                  height="11.12px"
                  viewBox={{
                    minX: 0,
                    minY: 0,
                    width: 14.4140625,
                    height: 11.12099838256836,
                  }}
                  paths={[
                    {
                      d: "M4.707 8.293L1.414 5L0 6.414L4.707 11.121L14.414 1.414L13 0L4.707 8.293Z",
                      fill: "rgba(43,54,119,1)",
                      fillRule: "nonzero",
                    },
                  ]}
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="30.39%"
                  bottom="23.28%"
                  left="22.05%"
                  right="17.89%"
                  {...getOverrideProps(overrides, "Vector8502705")}
                ></Icon>
              </View>
              <View
                fontFamily="Lato"
                fontSize="20px"
                fontWeight="400"
                color="rgba(86,89,115,1)"
                lineHeight="32px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
              >
                <Trans i18nKey="company.item6">
                  <Text
                    children="Flexible digital upload"
                    {...getOverrideProps(overrides, "subtitle8502706")}
                  ></Text>
                </Trans>
              </View>
            </Flex>
          </View>
          <View
            className="companyTitle"
            fontFamily="Lato"
            fontSize="40px"
            fontWeight="800"
            lineHeight="52px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="652px"
            height="unset"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="48px"
            left="calc(50% - 326px - 0px)"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
          >
            <Text color="#2B3677"
              children="For Companies"
              {...getOverrideProps(overrides, "title")}
            ><Trans i18nKey="company.title" >For Companies</Trans></Text><br /><Text className="textCompany" marginTop="-45px" maxWidth="500px" margin="-50px auto 0 auto"><Trans i18nKey="company.subtitle" >Turn outstanding invoices <br />
              into money fast.</Trans></Text>
            <br />
          </View>
          {/* <Text
              fontSize="40px"
              top="0"
                color="#0B0F19"
                children="Turn outstanding invoices 
                into money fast."
                {...getOverrideProps(overrides, "title")}
              > <Trans i18nKey="company.subtitle" >Turn outstanding invoices 
              into money fast.</Trans></Text> */}
          <Flex
            gap="8px"
            direction="row"
            width="307px"
            height="unset"
            justifyContent="center"
            alignItems="center"
            position="absolute"
            top="1168px"
            left="calc(50% - 153.5px - 1px)"
            borderRadius="6px"
            padding="0"
            backgroundColor="rgba(43,54,119,1)"
            display="flex"
            {...getOverrideProps(overrides, "btn")}
          >
            <Button
              color="white"
              bgColor="#2B3677"
              onClick={() => navigate("/company-signup")}
            >
              <Trans i18nKey="banner.btn2" >
                Join Waitlist
              </Trans>
            </Button>
          </Flex>
        </View>
        <View
          className="dashboardcompany"
          width="1128px"
          height="789px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="319px"
          left="calc(50% - 564px - 0px)"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          {...getOverrideProps(overrides, "Company Dashboard 1")}
        >
          <img style={{ width: "100%", maxWidth: "100%" }} src={dashboard} />
        </View>
      </View>
    </div>
  );
}
